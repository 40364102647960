import Container from "../Components/Layouts/Container"
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getToken } from '../api/getHeaders';
import PropTypes from 'prop-types';

// Private routes defined
export default function PrivateRoutes({ children,feature }) {
    let location = useLocation();

    if (!getToken()) {
      return <React.Fragment><Navigate to="/" state={{ from: location }} /></React.Fragment>;
    }
  
    return <Container feature={feature}>{children}</Container>;

}

PrivateRoutes.propTypes = {
  children: PropTypes.any
};