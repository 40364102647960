import DoneIcon from "@material-ui/icons/Done";
import ErrorIcon from '@material-ui/icons/Error';
import RotateRight from '@material-ui/icons/RotateRight';
import ShoppingBasketTwoToneIcon from '@material-ui/icons/ShoppingBasketTwoTone';
import CancelIcon from '@material-ui/icons/Cancel';
import { ListorderbyId } from "../api/orderops";
import { Visibility } from "@material-ui/icons";
import React from "react";

const Order_Status_String = {
  '':'',
  "ordered":'Order Placed',
  "order amendment":'Order Amendment',
  "order delivered":'Order Delivered',
  "order updated through statistics":'Order Updated Through Statistics',
  "script held":'Script Held',
  "script received":'Script Received',
  "order received":'Order Received',
  "order requested":'Order Requested',
  "prescription received":'Prescription Received',
  'prescription complete' : 'Prescription Complete',
  "prescription switched to trade":'Prescription Switched To Trade',
  "script validated":'Script Validated',
  'script awaiting print':'Script Awaiting Print',
  'script label printed / outstanding':'Script Label Printed / Outstanding',
  'script transferred':'Script Transferred',
  'script rejected':'Script Rejected',
  'script paid / matched':'Script Paid / Matched',
  'script deleted':'Script Deleted',
  'order updated':'Order Updated',
  'request submitted':'Request Submitted',
  'return to nhs spine':'Return To NHS Spine',
  "request cancelled":"Request Cancelled",
  "order cancelled":"Order Cancelled",
  "please contact wardles":"Please Contact Wardles",
  "request received":"Request Received",
  "attempted to call - item oos":"Attempted to call - item OOS",
  "attempted to call - to follow query":"Attempted to call - to follow query",
  "attempted to call - order cancelled":"Attempted to call - order cancelled"
}


export const displayOrderId = (orderDetails) => {
  // const prescriptionType = ;
  if (orderDetails.awards_script_number) {
    return (<>{orderDetails.awards_script_number}</>)
  } else if (orderDetails.order_type === 'eps') {
    return (<>{orderDetails.id}</>)
  } else if (orderDetails.order_type === 'manual') {
    return (<>Manual</>)
  }
};

export const normalizedEntries1 = (item) => {
  let date = new Date(item * 1000);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};
export const normalizedEntries2 = (item) => {
  let date = new Date(item );
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const normalizedEntries = (item) => {
  let today = new Date(item * 1000);
  let date = today;
  var year = date.getFullYear();
  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;
  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;
  return day + "/" + month + "/" + year;
};

export const normalizedDate = (value) => {
  return (
    <>
      {" "}
      {normalizedEntries(value.created_date)}{" "}
      {normalizedEntries1(value.created_date)}{" "}
    </>
  );
};

export const captlize= (text)=>{
  let capText = text?.slice(0,1)?.toUpperCase()+text?.slice(1,text.lenght).toLowerCase()
  return capText
}

export const showOrderStatus = (order_status) => {
  return (
    <>
      {order_status === "ordered" &&
        <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 3,
              padding: 8.8,
              borderRadius: 4,
              color: "#3D8541",
              background: "#E9F5EA",
            }}
          >
          <DoneIcon/>
          {Order_Status_String[order_status]}
          </div>}
      {order_status === "request cancelled" &&
        <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 3,
              padding: 3,
              borderRadius: 4,
              color: "#222222",
              background: "#CDCDCD",
            }}
          >
          <CancelIcon/>
          {Order_Status_String[order_status]}
          </div>}
      {order_status === "request submitted" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#007BB8",
            background: "#EBF8FF",
          }}
        >
          <ShoppingBasketTwoToneIcon/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
      {order_status === "return to nhs spine" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#BB4035",
            background: "#FFF0F0",
          }}
        >
          <ErrorIcon/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
      {order_status === "attempted to call - item oos" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#BB4035",
            background: "#FFF0F0",
          }}
        >
          <ErrorIcon/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
      {order_status === "attempted to call - to follow query" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#BB4035",
            background: "#FFF0F0",
          }}
        >
          <ErrorIcon/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
      {order_status === "please contact wardles" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#BB4035",
            background: "#FFF0F0",
          }}
        >
          <ErrorIcon/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
      {order_status === "attempted to call - order cancelled" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#BB4035",
            background: "#FFF0F0",
          }}
        >
          <ErrorIcon/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
      {order_status != "return to nhs spine" && order_status != "ordered" && order_status != "request submitted" && order_status != "request cancelled" && order_status != "attempted to call - item oos" && order_status != "attempted to call - to follow query" && order_status != "attempted to call - order cancelled" && order_status != "please contact wardles" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#FFF0F0",
            background: "#BB4035",
          }}
        >
          <RotateRight/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
    </>
  );
};

export const showOrderStatusWardlestaff = (order_status) => {
  return (
    <>
      {order_status === "ordered" &&
        <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 3,
              padding: 8.8,
              borderRadius: 4,
              color: "#3D8541",
              background: "#E9F5EA",
            }}
          >
          <DoneIcon/>
          {Order_Status_String[order_status]}
          </div>}
      {order_status === "request cancelled" &&
        <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 3,
              padding: 3,
              borderRadius: 4,
              color: "#222222",
              background: "#CDCDCD",
            }}
          >
          <CancelIcon/>
          {Order_Status_String[order_status]}
          </div>}
      {order_status === "request submitted" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#007BB8",
            background: "#EBF8FF",
          }}
        >
          <ShoppingBasketTwoToneIcon/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
      {order_status === "return to nhs spine" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#BB4035",
            background: "#FFF0F0",
          }}
        >
          <ErrorIcon/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
      {order_status === "please contact wardles" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#BB4035",
            background: "#FFF0F0",
          }}
        >
          <ErrorIcon/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
      {order_status === "attempted to call - item oos" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#BB4035",
            background: "#FFF0F0",
          }}
        >
          <ErrorIcon/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
      {order_status === "attempted to call - to follow query" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#BB4035",
            background: "#FFF0F0",
          }}
        >
          <ErrorIcon/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
      {order_status === "attempted to call - order cancelled" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#BB4035",
            background: "#FFF0F0",
          }}
        >
          <ErrorIcon/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
      {order_status != "return to nhs spine" && order_status != "ordered" && order_status != "request submitted" && order_status != "request cancelled" && order_status != "attempted to call - item oos" && order_status != "attempted to call - to follow query" && order_status != "attempted to call - order cancelled" && order_status != "please contact wardles" && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#FFF0F0",
            background: "#BB4035",
          }}
        >
          <RotateRight/>
          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
          {Order_Status_String[order_status]}
          </p>
        </div>
      )}
    </>
  );
};

export function FetchorderdetailsId(item,orderdetailsId, setOrderdetailsId ,handleOpen) {
  
  ListorderbyId(item?.id)
    .then((res) => res.json())
    .then(
      (data) => {
        if (data.error) {
          return setOrderdetailsId({
            ...orderdetailsId,
            err: data.error.message,
            success: "",
          });
        } else {
          setOrderdetailsId(data);
          handleOpen();
        }
      },
      (error) => {
        console.error("There was an error!", error);
      }
    );}


export function visibilityicon(item,orderdetailsId,setOrderdetailsId,handleOpen) {
      return <Visibility
          onClick={() => FetchorderdetailsId(item,orderdetailsId,setOrderdetailsId,handleOpen)}
          style={{ color: "#007BB8", cursor: "pointer", width: 17, height: 17 }}
        />
      
    }
 
 export function visibilityicon1(item,orderdetailsId,setOrderdetailsId,handleOpen) {
   return  <Visibility
       onClick={() =>FetchorderdetailsId(item, orderdetailsId, setOrderdetailsId,handleOpen) }
       style={{ color: "#007BB8", cursor: "pointer", width: 17, height: 17 }}
     />  
 }
 
