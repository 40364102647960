import { Button, Grid, InputAdornment, makeStyles, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeft';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logEventWithProperties } from '../../analytics/Analytics';
import { getUserDetails } from '../../api/getUserDetails';
import { AdminAddUserStart, GetUserEmailExist } from '../../api/userOps';
import { ValidatePharmacyDetails } from '../../api/validations';
import AccountCircleOutlinedgreen from '../../Assets/AccountCircleOutlinedgreen.svg';
import ArrowDown from '../../Assets/Arrowdown.svg';
import EmailIcon from '../../Assets/Mailicongreen.svg';
import Question from '../../Assets/questionmark.svg';
import Dropdown from '../../Components/Dropdown/UserroleDropdown';
import { InformationModal } from '../../Components/Modal/InformationModal';
import { showErrMsg, showErrMsgEmpty, showSuccessmsg } from '../../Components/notification/Notification';
import { isEmail, isEmpty, isSpace } from '../../Components/validation/Validation';



const initialState = {
  firstname: "",
  lastname: "",
  email: "",
  role: "",
  err: '',
  err1: '',
  success: '',
  phoneNumber: '',
  pharm_accno: "",
  pharm_code: "",
  pharm_name: "ddff",
}

const useStyles =  makeStyles((theme) => ({
  arrowBack:{
    cursor: "pointer",
    textDecoration:"none",
    color:"#5E5E5E",
    display:"flex",
    alignItems:"center",
    margin:"0",
    paddingTop:"100px",
    [theme.breakpoints.only('xs')]: {
     justifyContent:"left",
    paddingLeft:0,

    },
    [theme.breakpoints.only('sm')]: {
      justifyContent:"left",
      paddingLeft:0, 
    },
    [theme.breakpoints.only('xs')]: {
      justifyContent:"left",
      paddingLeft:0,
     },
     [theme.breakpoints.only('md')]: {
      paddingLeft:(props)=>props?150:300,     
     },
     [theme.breakpoints.only('lg')]: {
      paddingLeft:(props)=>props?150:300,     
     },
     [theme.breakpoints.only('xl')]: {
      paddingLeft:(props)=>props?150:300,     
     },
  }
}))

function Adduser(props) {

  const classes = useStyles(props)
  const collection_address = getUserDetails().collection_address;
  const pharmacy_name = getUserDetails().pharmacy_name;
  const pharmacy_account_number = getUserDetails().pharmacy_account_number;
  const pharmacy_post_code = getUserDetails().pharmacy_post_code;
  const cookiesrole = getUserDetails().role;

  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const paperStyle = { padding: 10, paddingTop: 30, width: 340, margin: "auto" };
  const style = { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: '#EBF8FF', border: "1px solid #7DD4FF", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)", borderRadius: 3, p: 3, width: "30%" }; // eslint-disable-line no-unused-vars

  const [user, setUser] = useState(initialState)
  const { firstname, lastname, email, role, err, err1, success, pharm_accno, pharm_code, pharm_name } = user




  const [pharmacies, setPharmacies] = useState()

  useEffect(() => {
     setPharmacies(props.associatedPharmacies)

  },[props.associatedPharmacies])

  const modaltext = [
    { value: "If you set your user to be an admin of the account they will have access to commission statements and to manage users within the account."},
    { value: "A standard user will have access to everything execpt manage users and commission statements."},
    { value: "You can change your users role at anytime."},]


  const handleChangeInput = e => {
    
    const { name, value } = e.target

    if(cookiesrole === "fp@wardleHqAdmin" || cookiesrole === "fp@wardleHqUser"){
    if(name === "pharm_accno"){
      let pharma = pharmacies?.find((item)=>item.pharmacy_account_number === value)
       setUser({ ...user,[name]: value, pharm_code: pharma?.post_code, err: '' })

    }else{
    setUser({ ...user,[name]: value, err: '' })

    }
  }else{
    setUser({ ...user,[name]: value, err: '' })

  }
  }


  const handleSubmit = async e => {
    e.preventDefault()
    if (isEmpty(email) || isEmpty(firstname) || isEmpty(lastname) || isEmpty(role) ) {
      return setUser({ ...user, err: "This field is required", err1: "Please check the information above is correct", success: '' })
    }
    if (isSpace(email) || isSpace(firstname) || isSpace(lastname) || isSpace(role) ) {
      return setUser({ ...user, err1: "Please check the information above is correct", success: '' })
    }
    if (!isEmail(email))
    return setUser({ ...user,err: "Invalid email", err1: "Please check the information above is correct", success: '' })

    GetUserEmailExist(email)
      .then((res) => res.json())
      .then(
        (data) => {
          if (data.userExist !== true) {
            AdminAddUserStart(email, firstname, lastname, role, collection_address, pharmacy_name, pharmacy_account_number, pharmacy_post_code)
              .then((res) => res.json())
              .then((data) => {
                if (data.error) {
                  logEventWithProperties("Add new user failed",{
                    email: email,
                    role: role,
                    pharmacyAccountNumber: pharmacy_account_number})
                  return setUser({ ...user, err1: data.error.message, success: '' })
                } else {
                  logEventWithProperties("New user added successfully",{email: email,
                    role: role,
                    pharmacyAccountNumber: pharmacy_account_number})
                  setUser({ ...user, err1: "", success: 'New user added. We’ve sent an email for them to create an account' })
                  navigate("/manageusers")
                }
              },
                error => {
                  console.error('There was an error!', error);
                });
          } else {
            setUser({
              ...user,
              err1: "Email address is already in use. Choose another email address and try again.",
              success: "",
            });
          }
        },
        (error) => {
          console.error("There was an error!", error);
        }
      );

  }



  const handleSubmit1 = async e => {
    e.preventDefault()

    if (isEmpty(email.trim()) || isEmpty(firstname.trim()) || isEmpty(lastname.trim()) || isEmpty(role.trim()) || isEmpty(pharm_accno.trim()) || isEmpty(pharm_code.trim())) {
      return setUser({ ...user, err: "This field is required", err1: "Please check the information above is correct", success: '' })
    }
    if (isSpace(email) || isSpace(firstname) || isSpace(lastname) || isSpace(role) || isSpace(pharm_accno) || isSpace(pharm_code) ) {
      return setUser({ ...user, err1: "Please check the information above is correct", success: '' })
    }
    if (!isEmail(email))
    return setUser({ ...user,err: "Invalid email", err1: "Please check the information above is correct", success: '' })

    let response = [];



    GetUserEmailExist(email.trim())
      .then((res) => res.json())
      .then(
        (data) => {
          if (data.userExist !== true) {
            ValidatePharmacyDetails(
              pharm_name, pharm_accno, pharm_code, email
            ).then((res) => res.json())
              .then((data) => {
                if (Object.prototype.hasOwnProperty.call(data,'pharmacyDetails')) {
                  response = data.pharmacyDetails[0];

                  AdminAddUserStart(email.trim(), firstname.trim(), lastname.trim(), role, response.collection_address, response.pharmacy_name, pharm_accno.trim(), pharm_code.trim(),)
                    .then((res) => res.json())
                    .then((data) => {
                      if (data.error) {
                        logEventWithProperties("Add new user failed",{...{email: email,
                          role: role,
                          pharmacyAccountNumber: pharm_accno},...AccountCircleOutlinedgreen.error})
                        return setUser({ ...user, err1: data.error.message, success: '' })
                      } else {
                        logEventWithProperties("New user added sucessfully",{...{email: email,
                          role: role,
                          pharmacyAccountNumber: pharm_accno},...data})
                        setUser({ ...user, err1: "", success: 'New user added. We’ve sent an email for them to create an account' })
                        navigate("/manageusers")
                      }
                    },
                      error => {
                        console.error('There was an error!', error);
                      });
                }

                else {
                  setUser({
                    ...user,
                    err1: "Pharmacy postcode and account number don’t match. Check you have entered the correct information.",
                  });
                }
              })
              .catch(error => {
                setUser({ ...user, err1: error });
              });
          } else {
            setUser({
              ...user,
              err1: "Email address is already in use. Choose another email address and try again.",
              success: "",
            });
          }
        },
        (error) => {
          console.error("There was an error!", error);
        }
      );



  }

  useEffect(()=>{
    logEventWithProperties("User lands on adduser page",getUserDetails()?.id)
  },[])

  return (
    <>
      <div className={classes.arrowBack}  >
        <ArrowBackIcon onClick={() => { navigate("/manageusers") }}/>
        <p data-testid="back-button" onClick={() => { navigate("/manageusers") }}>Back</p>
      </div>
      <Grid>
        <form onSubmit={cookiesrole === "fp@wardleAdmin" ? handleSubmit : handleSubmit1}>
          <Paper elevation={0} style={paperStyle}>
            <h2 style={{ fontSize: 25, margin: 0, marginBottom: 15, textAlign: "start" }}>Add user</h2>
            <Typography variant="subtitle2" style={{ fontSize: "75%", textAlign: "start", lineHeight: "17px", color: "#4E4E4E" }}>Enter your new users name, email address and role. The email address used will be the one that they need to access their account. </Typography>

            <TextField
              variant="outlined"
              type={'text'}
              placeholder="First name"
              fullWidth
              data-testid="fistName"
              error={isEmpty(firstname) && err}
              label={isEmpty(firstname) ? null : "First name"}
              onChange={handleChangeInput}
              style={{ marginBottom: 7, marginTop: 20, height: 45, boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)" }}
              size="small"
              name="firstname"
              value={firstname}
              id="firstname"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={AccountCircleOutlinedgreen} alt="error" />
                  </InputAdornment>
                ),
              }}
            />
            {isEmpty(firstname) && showErrMsgEmpty(err)}


            <TextField
              variant="outlined"
              type={'text'}
              error={isEmpty(lastname) && err}
              label={isEmpty(lastname) ? null : "Last name"}
              placeholder="Last name"
              fullWidth
              data-testid="lastName"
              id="lastname"
              name="lastname"
              onChange={handleChangeInput}
              value={lastname}
              style={{ marginBottom: 7, marginTop: 5, height: 45, boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)" }}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={AccountCircleOutlinedgreen} alt="error" />
                  </InputAdornment>
                ),
              }}
            />
            {isEmpty(lastname) && showErrMsgEmpty(err)}

            <TextField
              variant="outlined"
              type={'text'}
              error={isEmpty(email) && err}
              label={isEmpty(email) ? null : "Email"}
              value={email}
              onChange={handleChangeInput}
              placeholder="Email address"
              fullWidth
              data-testid="email"
              name="email"
              style={{ marginBottom: 7, marginTop: 5, height: 45, boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)" }}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={EmailIcon} style={{ width: 16, height: 17 }} alt="error" />
                  </InputAdornment>
                ),
              }}
            />
            {isEmpty(email) && showErrMsgEmpty(err) ||
            !isEmail(email) && showErrMsgEmpty(err)}


            {cookiesrole === "fp@wardleAdmin" ? null :
              <>
                <TextField
                  variant="outlined"
                  type={'text'}
                  data-testid="account_number"
                  select={cookiesrole === "fp@wardleHqAdmin" || cookiesrole === "fp@wardleHqUser"}
                  error={isEmpty(pharm_accno) && err}
                  label={"Account number" }
                  value={pharm_accno}
                  onChange={handleChangeInput}
                  placeholder="Account number"
                  fullWidth
                  name="pharm_accno"
                  style={{ marginBottom: 7, marginTop: 5, height: 45, boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)" }}
                  size="small"
                >
                  {pharmacies?.map((option) => (
                    <MenuItem key={option.pharmacy_account_number} value={option.pharmacy_account_number}>
                      {option.pharmacy_account_number}
                    </MenuItem>
                  ))}
                </TextField>
                {isEmpty(pharm_accno) && showErrMsgEmpty(err)}
                <TextField
                  variant="outlined"
                  type={'text'}
                  data-testid="postcode"
                  error={isEmpty(pharm_code) && err}
                  label={isEmpty(pharm_code) ? null : "Post code"}
                  value={pharm_code}
                  onChange={handleChangeInput}
                  placeholder="Post code"
                  fullWidth
                  disabled={cookiesrole === "fp@wardleHqAdmin" || cookiesrole === "fp@wardleHqUser"}
                  name="pharm_code"
                  style={{ marginBottom: 7, marginTop: 5, height: 45, boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)" }}
                  size="small"
                />
                {isEmpty(pharm_code) && showErrMsgEmpty(err)}
              </>
            }

            <Dropdown name="role" value={role} onChange={handleChangeInput} err={err} />
            <div data-testid="messagetouser">
            {err1 && showErrMsg(err1, () => {
              setUser({ ...user, err1: '' })
            })}
            {success && showSuccessmsg(success)}
            </div>

            <Button onClick={handleOpen} variant="contained" size="Large" disableElevation style={{ background: "#E8F8FF", color: "#0066BE", borderRadius: '4px', border: "1px solid #0066BE", fontSize: "12px", marginTop: "15px", textTransform: "none", display: "flex", justifyContent: "space-between" }} fullWidth>
              <div style={{ display: "flex", width: "80%", alignItems: "center", margin: 0, padding: 0 }}>
                <img src={Question} width="15" height="20" alt="error" />
                <p style={{ margin: 0, padding: 0, marginLeft: 5, fontSize: 11, textAlign: "left", fontWeight: "bold" }}>What is the difference between admin and a standard user?</p>
              </div>
              <img src={ArrowDown} width="10" height="20" alt="error" />
            </Button>


            <Grid align="center" style={{ marginBottom: "2em" }}>
              <Button data-testid="Adduser" variant="contained" fullWidth size="large" disableElevation style={{ background: "#3D8541", color: "white", marginTop: "22px", fontSize: "12px", textTransform: "none", borderRadius: "2px" }} type="submit" >Add new user</Button>
            </Grid>

            <InformationModal
              handleClose={handleClose} 
              open={open} 
              heading={"What is the difference between admin and a standard user?"}
              modaltext={modaltext}
              />

            
          </Paper>
        </form>
      </Grid>

    </>
  );
}
const mapStateToProps = state => {

return {
  associatedPharmacies: state.associatedPharmacies
};
};

Adduser.propTypes = {
  associatedPharmacies: PropTypes.string
};

export default connect(mapStateToProps)(Adduser);