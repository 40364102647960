import React, { useState } from 'react';
import { AccessComplete, VerifyRegisteredEmail } from '../../api/userOps';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { logEventWithProperties } from '../../analytics/Analytics';

function VerifyEmailAddress() {
    let navigate = useNavigate()
    const [message, setMessage] = useState("")
    const { rnd, token, email } = useParams();

    function completeRegistration() {
        VerifyRegisteredEmail(email)
            .then((res) => res.json())
            .then((data) => {
                if (data.registration_status === "registered") {
                    logEventWithProperties("Magic link revisited", { email: email })
                    navigate("/register/alreadyregistered")
                } else {
                    logEventWithProperties("Magic link visited", { email: email })

                    AccessComplete(rnd, token)
                        .then((res) => res.json())
                        .then((data) => {
                            if (data.message === "email verified successfully!") {
                                logEventWithProperties("Account verified", { email: email })

                                navigate("/register/successfull");
                            }
                            else if (data.error != undefined) {
                                setMessage(data.error.message);
                            }
                        },
                            error => {
                                console.error('There was an error!', error);
                            });
                }
            })
    }

    useEffect(() => {
        completeRegistration();
    }, []);

    return (
        <div style={{ height: "98vh" }}>


            {message && <div>
                <p>{message}</p>

            </div>}

            <div style={{ textAlign: "center", minHeight: "100px" }}>

                {!message && <div style={{ position: "absolute", width: "100%", top: "40%", left: "0" }}>
                    <h4>
                        <CircularProgress /><br></br>
                        Verifying your account...
                    </h4>
                </div>}
            </div>
        </div>
    )
}

export default VerifyEmailAddress;