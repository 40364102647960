import React from 'react'
import { Grid , Typography } from '@material-ui/core';
import PropTypes from 'prop-types';


const DetailsComp = ({items}) => {
  return (
    <>
    {items.map((item) => {
      return (
          <>
              <Grid container>
              <Grid item  >
              <Typography variant="subtitle1" style={{ color:"#5E5E5E", fontWeight: 500 }}>
                  {item.key}
                </Typography>
                </Grid>
                <Grid item >
                <Typography variant="subtitle1" style={{ color:"#222222", fontWeight: 700 }}>
                  {item.value}
                </Typography>
              </Grid>
            </Grid>
          </>

          );
        })}
    </>
  )
}

DetailsComp.propTypes = {
  items: PropTypes.array
};

export default DetailsComp