import { filterString } from "../Utilities/filterUtilities";
import { FpServicesRoutes, flattenDictionaryToQueryString } from "./constants";
import { getHeaders } from "./getHeaders";
import { getUserDetails } from "./getUserDetails";
import { handleFetch } from "./HandleApiCall";

//Function for searching product using given text input
export function searchRange(searchText) {
  const requestOptions = {
    method: FpServicesRoutes.ProductList.SEARCH_PRODUCT_ENDPOINT.method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      expr:searchText
    }),
  };
  return handleFetch(FpServicesRoutes.ProductList.SEARCH_PRODUCT_ENDPOINT.uri, requestOptions);
}

//Function for getting pharmacy name
export function getPharmacyName() {
  const uri = FpServicesRoutes.OrderOps.PHARMACY_LIST_ENDPOINT;
  const requestOptions = {
    method: FpServicesRoutes.OrderOps.PHARMACY_LIST_ENDPOINT.method,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return handleFetch(`${uri}`, requestOptions);
}

//Function for saving uploaded file in specific pharmacy account Number
export function savefile(pharmacyAccountNumber) {
  const uri = FpServicesRoutes.Pharmacyops.SAVE_ENDPOINT.uri.replace(
    "{accountNumber}",
    pharmacyAccountNumber
  );
  let header =  {"Content-Type": "application/json"}
  const requestOptions = {
    method: FpServicesRoutes.Pharmacyops.SAVE_ENDPOINT.method,
    headers:{...header,...getHeaders()}
  };
  return handleFetch(`${uri}`, requestOptions);
}

//Function for using search and filter for different types of users based on given arguments & roles
export function searchfilter(
  resourcetype,
  operationstype,
  lastEvaluated,
  searchtext,
  filters,
  pharmacygroupAccountNumber
) {
  let headers = { "Content-Type": "application/json" };
  let pharmacy_account_number = getUserDetails().pharmacy_account_number;
  let email = getUserDetails().email;
  let role = getUserDetails().role;
  let uri = FpServicesRoutes.SearchFilter.SEARCH_ENDPOINT.uri.replace(
    "#resourceType",
    resourcetype
  );
  if (resourcetype === "pharmacy_association" && pharmacygroupAccountNumber) {
    uri = uri + `&pharmacyGroupAccountNumber=${pharmacygroupAccountNumber}`;
  }

  if (
    (role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser") &&
    resourcetype !== "pharmacy_association"
  ) {
    uri = uri + `&pharmacyAccountNumber=${pharmacygroupAccountNumber}`;
  } else if (role === "fp@wardleAdmin") {
    uri = uri + `&pharmacyAccountNumber=${pharmacy_account_number}`;
  } else if (role === "fp@wardleUser") {
    uri = uri + (resourcetype === "order" ?`&pharmacyAccountNumber=${pharmacy_account_number}`:`&email=${email}`);
  }

  if (lastEvaluated)
    uri = uri + `&${flattenDictionaryToQueryString(lastEvaluated)}`;

  let filterStr = filterString(filters, resourcetype);
  uri = filterStr ? `${uri}` + filterStr : `${uri}`;
  if (searchtext) uri = `${uri}` + `&searchText=${searchtext}`;

  if(resourcetype ===  "user"){
    uri = uri + `&active=true`
  }

  const requestOptions = {
    method: FpServicesRoutes.SearchFilter.SEARCH_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
  };

  return handleFetch(`${uri}`, requestOptions);
}
