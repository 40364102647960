/* eslint-disable react/prop-types */
import * as React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {isEmpty} from '../../Components/validation/Validation';
import {showErrMsgEmpty} from '../../Components/notification/Notification';


export default function Dropdown(props) {


  return (
    <>
      <FormControl label="User" style={{width:"100%", marginBottom:12}} variant="outlined" size="small">
        <InputLabel label="User" id="demo-simple-select-label">Role</InputLabel>
        <Select
          error= {isEmpty(props.value) && props.err}
          labelId="demo-simple-select-label"
          data-testid="role-select-choice"
          id="demo-simple-select"
          value={props.value}
          onChange={props.onChange}
          name={props.name}
          label="Role"
        >
          <MenuItem value={"fp@wardleAdmin"}>Admin</MenuItem>
          <MenuItem value={"fp@wardleUser"}>Standard</MenuItem>
        </Select>
      </FormControl>
      {isEmpty(props.value)  && showErrMsgEmpty(props.err)}
      </>
  );
}
