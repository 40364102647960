import { filterString } from "../Utilities/filterUtilities";
import { commissionServices } from "./constants";
import { getHeaders } from "./getHeaders";
import { handleFetch } from "./HandleApiCall";

// Function for getting total commission statement
export function GetTotalCommission(filter) {
  let headers = { "Content-Type": "application/json" };
  let uri =
    commissionServices.commissionOps.TOTAL_COMMISSION.uri +
    filterString(filter, "", "?");
  const requestOptions = {
    method: commissionServices.commissionOps.TOTAL_COMMISSION.method,
    headers: { ...headers, ...getHeaders() },
  };
  return handleFetch(uri, requestOptions);
}
