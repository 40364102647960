import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Dialog, Hidden
} from "@material-ui/core";
import AssociatedPharmacyList from "./AssociatedPharmacyList";
import PharmacyGroupAddress from "./ParmacyGroupAddress";
import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toCamelCase } from "../../../Utilities/pharmcyGroupUtils";

import DrawerComponent from "./DrawerComponent";
import ExitCase from "../../../Assets/ExitCase.svg";
import FileUpload from "../../../Assets/UploadIcon.svg";
import logEvent, { logEventWithProperties } from "../../../analytics/Analytics";

const useStyles = makeStyles((theme) => ({
  maincontainer: {
    marginTop: 30,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: "7%",
    },
    [theme.breakpoints.up('md')]: {
      overflow:"hidden",
      paddingLeft:"8rem"
    },
    [theme.breakpoints.only('sm')]: {
      padding: "3%",
    },
    [theme.breakpoints.only('xs')]: {
      padding: "3%",
    },
  },
  buttontext: {
    padding: "0.25em",
    fontWeight: "bold",
  },
  uploadbutton: {
    background: '#F7F7F7',
    border: '2px solid rgba(0, 154, 219, 0.2)',
    boxShadow: '0px 1px 0px #A7A7A7',
    borderRadius: '8px',
    textTransform: "none",
  },
  Addbutton: {
    marginTop: "2em",
    border: '2px solid #222222',
    backgroundColor: "#fff",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  tableleftcontainer: {
    [theme.breakpoints.up('sm')]: {
      maxHeight: "48.5vh"
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: "48.5vh"
    },
    [theme.breakpoints.up('xl')]: {
      maxHeight: "48.5vh"
    },
  }
}));
function AssociatedPharmacies() {
  const [change, setChange] = useState(false)
  const location = useLocation()
  let groupDetails = location?.state?.groupDetails?.data || location?.state?.item || location?.state?.groupDetails
  groupDetails = groupDetails ? toCamelCase(groupDetails) : undefined;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState("40%")
  const classes = useStyles();
  const handleopen = () => {
    setOpen(false)
  }

  useEffect(()=>{
    logEvent("Pharmacy group visited "+groupDetails)

  },[])
  return (
    <>
      <Paper
        elevation={0}
        className={classes.maincontainer}
      >
        <Grid
          container
          direction="column"
          style={{ paddingTop: "6em" }}
        >
          <Grid item container xs={12} sm={12} md={12} lg={12} style={{ marginBottom: '1em' }}>
            <Grid item style={{ cursor: "pointer" }}>
              <img src={ExitCase} onClick={() => navigate('/managepharmacygroup')} alt="Exiticon" />
            </Grid>
            <Grid item style={{ marginLeft: "0.25em", cursor: "pointer" }}>
              <Typography data-testid="exit-btn" onClick={() => navigate('/managepharmacygroup')} style={{ color: "#BB4035", fontWeight: 600 }}>
                Exit from pharmacy group
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container direction="row">
              <Grid item container direction="column" xs={12} sm={12} md={7} lg={8}>
                <Typography variant="h6" style={{ color: "#5E5E5E" }}>
                  Pharmacy Group
                </Typography>
                <Typography style={{ letterSpacing: '0.005em', fontWeight: 700 }} variant="h4">{groupDetails?.pharmacyGroupName}</Typography>
              </Grid>

              <Grid item container direction="column" xs={12} sm={12} md={5} lg={4}>
                <Grid item >
                  <Typography variant="body2" style={{ fontWeight: 700 }}>
                    Add or update an associated pharmacies file
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container style={{ marginTop: "1em", gap: '1em' }}>
                    <Grid item>
                      <Button
                        data-testid="upload-btn"
                        disableRipple
                        disableElevation
                        variant="contained"
                        className={classes.uploadbutton}
                        onClick={() => {logEventWithProperties("Upload csv file to associate pharmacies clicked",{pharmacyGroupAccountNumber:groupDetails?.pharmacyGroupAccountNumber}); setOpen(true); setHeight("40%")}}
                      >
                        <Typography
                          variant="body2"
                          className={classes.buttontext}
                        >
                          Upload File
                        </Typography>
                        <img src={FileUpload} alt="error" />
                      </Button>
                    </Grid>
                    <Dialog data-testid="upload-dialog" open={open} fullScreen style={{ width: "80%", margin: "auto", height: `${height}` }}   >
                      <DrawerComponent change={() => setChange(true)} close={e => handleopen(e)} setHeight={setHeight} pharmacyGroupName={groupDetails?.pharmacyGroupName} pharmacyGroupAccountNumber={groupDetails?.pharmacyGroupAccountNumber || groupDetails?.pharmacyGroupAccountNo} createdBy={groupDetails?.createdBy} />
                    </Dialog>
                    <Grid item style={{ marginTop: '0.5em' }}>
                      <Typography
                        variant="body1"
                        style={{ fontWeight: 700 }}
                      >
                        or
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        data-testid="manual-btn"
                        variant="contained"
                        disableRipple
                        disableElevation
                        className={classes.uploadbutton}
                        style={{ textTransform: "capitalize" }}
                        onClick={() => {
                          navigate("/manualaddpharmacygroup", { state: { groupDetails: groupDetails } });
                        }}
                      >
                        <Typography
                          variant="body2"
                          className={classes.buttontext}

                        >
                          Enter Manually
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ marginTop: "2em" }}>
              <Hidden smDown implementation='css' >
                <Grid
                  container
                  direction="row"
                  md={12} lg={12} xs={12} sm={12}
                  spacing={2}
                >
                  <Grid item md={2} xs={12} sm={12} lg={3} className={classes.tableleftcontainer}>
                    <PharmacyGroupAddress firstName={groupDetails?.firstName} lastName={groupDetails?.lastName} Email={groupDetails?.managerEmail} Phonenumber={groupDetails?.phoneNumber} address1={groupDetails?.addressLine1} address2={groupDetails?.addressLine2} postCode={groupDetails?.pharmacyGroupPostCode} />
                    <Button
                      variant="contained"
                      data-testid="edit-pharmacy-btn"
                      onClick={() => navigate('/Editpharmacygroup', { state: { groupDetails: groupDetails, isEdit: true } })}
                      fullWidth
                      disableRipple
                      disableElevation
                      className={classes.Addbutton}>
                      <Typography
                        variant="body2"
                        className={classes.buttontext}>

                        Edit pharmacy group
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} >
                    <AssociatedPharmacyList change={change} groupDetails={groupDetails} setChange={()=>setChange(false)}/>
                  </Grid>
                </Grid>
              </Hidden>

              <Hidden mdUp implementation='css' >
                <Grid
                  container
                  direction="row"
                  style={{ marginTop: "1em", maxHeight: "30vh" }}
                  spacing="2"
                >

                  <Grid item xs={12} sm={12} md={12}>
                    <AssociatedPharmacyList change={change} groupDetails={groupDetails} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} >
                    <PharmacyGroupAddress firstName={groupDetails?.firstName} lastName={groupDetails?.lastName} Email={groupDetails?.managerEmail} Phonenumber={groupDetails?.phoneNumber} address1={groupDetails?.addressLine1} address2={groupDetails?.addressLine2} postCode={groupDetails?.pharmacyGroupPostCode} />
                    <Button
                      variant="contained"
                      onClick={() => navigate('/Editpharmacygroup', { state: { groupDetails: groupDetails, isEdit: true } })}
                      fullWidth
                      disableRipple
                      disableElevation
                      className={classes.Addbutton}>
                      <Typography
                        variant="body2"
                        className={classes.buttontext}>

                        Edit pharmacy group
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Hidden>

            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default AssociatedPharmacies;
