import { Box, Button, Grid, Dialog, Paper, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/KeyboardArrowLeft";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AdminEditUser, AdminRemoveUser } from "../../api/userOps";
import ArrowDown from "../../Assets/Arrowdown.svg";
import Question from "../../Assets/questionmark.svg";
import Dropdown from "../../Components/Dropdown/UserroleedituserDropdown";
import {
  showErrMsg,
  showSuccessmsg
} from "../../Components/notification/Notification";
import { makeStyles } from "@material-ui/core/styles";
import DeleteModal from "../../Components/Modal/Modal2";
import { InformationModal } from "../../Components/Modal/InformationModal";
import { logEventWithProperties } from "../../analytics/Analytics";
import { useEffect } from "react";
import { getUserDetails } from "../../api/getUserDetails";

const useStyles = makeStyles((theme) => ({
  arrowBack: {
    cursor: "pointer",
    textDecoration: "none",
    color: "#5E5E5E",
    display: "flex",
    alignItems: "center",
    margin: "0",
    paddingTop: "100px",
    [theme.breakpoints.only('xs')]: {
      justifyContent: "left",
      paddingLeft: 0,

    },
    [theme.breakpoints.only('sm')]: {
      justifyContent: "left",

      paddingLeft: 0,


    },
    [theme.breakpoints.only('xs')]: {
      justifyContent: "left",
      paddingLeft: 0,

    },
    [theme.breakpoints.only('md')]: {
      paddingLeft: (props) => props ? 150 : 300,
    },
    [theme.breakpoints.only('lg')]: {
      paddingLeft: (props) => props ? 150 : 300,
    },
    [theme.breakpoints.only('xl')]: {
      paddingLeft: (props) => props ? 150 : 300,
    },
  }
}))
function Edituser() {
  const classes = useStyles();
  const { id } = useParams();
  const location = useLocation();
  const userrole = location?.state?.role;
  const users = location?.state?.user;
  const email = location?.state?.user?.email;
  const pharmacy_account_number = location?.state?.user?.pharmacy_account_number;

  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // for remove user
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const modaltext = [
    { value: "If you set your user to be an admin of the account they will have access to commission statements and to manage users within the account." },
    { value: "A standard user will have access to everything execpt manage users and commission statements." },
    { value: "You can change your users role at anytime." },]


  const paperStyle = {
    padding: 10,
    paddingTop: 20,
    width: 340,
    margin: "auto",
  };

  const [user, setUser] = useState(users);
  const { role, err, err1, success } = users; // eslint-disable-line no-unused-vars
  useEffect(() => {
    logEventWithProperties("User lands on edituser page", getUserDetails()?.id)
  }, [])
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value, err: "" });
    logEventWithProperties("User role changed", { ...user, role: value })

  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    logEventWithProperties("User details edited", { ...getUserDetails(), ...user })

    AdminEditUser(email, id, user.role)
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Role changed successfully!") {
          logEventWithProperties("User role changed successfully", user)

          setUser({ ...user, err1: "", success: 'We have updated your user’s details' })
        }
        else {

          logEventWithProperties("User role changed failed", data.message)

          return setUser({ ...user, err1: data.message, success: '' })
        }
      },
        (error) => {
          console.error("There was an error!", error);
        }
      );

  };

  const handleSubmitdelete = async (e) => {
    AdminRemoveUser(email, id, pharmacy_account_number)
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          logEventWithProperties("User delet failed", user)

          return setUser({ ...user, err1: data.message, success: '' })
        } else {
          logEventWithProperties("User deleted successfully", user)

          setUser({ ...user, err1: "", success: 'We have updated your user’s details' })
          navigate("/manageusers")
        }
      }
      );
    handleClose1();
    e.preventDefault();
  };

  return (
    <>
      <div className={classes.arrowBack}      >
        <ArrowBackIcon onClick={() => { navigate("/manageusers") }} />
        <p data-testid="back-button" onClick={() => { navigate("/manageusers") }}>Back</p>
      </div>
      <Grid>
        <form onSubmit={handleSubmit}>
          <Paper elevation={0} style={paperStyle}>
            <div data-testid="messagetouser">
              {user.err1 &&
                showErrMsg(user.err1, () => {
                  setUser({ ...user, err1: "" });
                })}
            </div>
            {user.success && showSuccessmsg(user.success)}
            <h2
              style={{
                fontSize: 25,
                margin: 0,
                marginBottom: 15,
                textAlign: "start",
              }}
              data-testid="Header"
            >
              Edit user
            </h2>

            <Box
              style={{
                border: "1px solid #CDCDCD",
                borderRadius: 5,
                padding: 10,
                marginBottom: 17,
              }}
            >
              <Typography
                id="modal-modal-description"
                style={{
                  fontWeight: "bold",
                  color: "#222222",
                  marginBottom: 5,
                }}
              >
                Pharmacy user details
              </Typography>
              <div style={{ display: "grid", justifyItems: "space-between" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    id="modal-modal-description"
                    style={{
                      width: "40%",
                      color: "#5E5E5E",
                      fontSize: 15,
                      marginBottom: 3,
                    }}
                  >
                    Name:
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    style={{
                      width: "60%",
                      color: "#5E5E5E",
                      fontSize: 15,
                      marginBottom: 3,
                    }}
                  >
                    {users?.first_name}
                    {users?.last_name}
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <Typography
                    id="modal-modal-description"
                    style={{
                      width: "40%",
                      color: "#5E5E5E",
                      fontSize: 15,
                      marginBottom: 3,
                    }}
                  >
                    Email address:
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    style={{
                      width: "60%",
                      padding: 0,
                      margin: 0,
                      color: "#5E5E5E",
                      fontSize: 15,
                      marginBottom: 3,
                    }}
                  >
                    {users?.email}
                  </Typography>
                </div>
              </div>
            </Box>
            <Dropdown
              name="role"
              defaultValue={userrole}
              value={user.role}
              onChange={handleChangeInput}
              err={err}
            />

            <Button
              onClick={handleOpen}
              variant="contained"
              size="Large"
              disableElevation
              style={{
                background: "#E8F8FF",
                color: "#0066BE",
                borderRadius: "4px",
                border: "1px solid #0066BE",
                fontSize: "12px",
                marginTop: "15px",
                textTransform: "none",
                display: "flex",
                justifyContent: "space-between",
              }}
              fullWidth
            >
              <div
                style={{
                  display: "flex",
                  width: "80%",
                  alignItems: "center",
                  margin: 0,
                  padding: 0,
                }}
              >
                <img src={Question} width="15" height="20" alt="error" />
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    marginLeft: 5,
                    fontSize: 11,
                    textAlign: "left",
                    fontWeight: "bold",
                  }}
                >
                  What is the difference between admin and a standard user?
                </p>
              </div>
              <img src={ArrowDown} width="10" height="20" alt="error" />
            </Button>

            <Grid align="center">
              <Button
                variant="contained"
                fullWidth
                size="large"
                disableElevation
                data-testid="Edituser"
                style={{
                  background: "#3D8541",
                  color: "white",
                  marginTop: "22px",
                  fontSize: "14px",
                  textTransform: "none",
                  borderRadius: "2px",
                }}
                type="submit"
              >
                Save changes
              </Button>
              <Button
                variant="outlined"
                fullWidth
                size="large"
                data-testid="cancelbutton"
                onClick={() => navigate("/manageusers")}
                disableElevation
                style={{
                  background: "#white",
                  color: "#222222",
                  border: "2px solid #222222",
                  fontWeight: 600,
                  marginTop: "22px",
                  fontSize: "14px",
                  textTransform: "none",
                  borderRadius: "4px",
                }}
              >
                Cancel
              </Button>
            </Grid>

            <p
              onClick={handleOpen1}
              data-testid="removebuttonmodal"
              style={{
                textAlign: "center",
                color: "#BB4035",
                cursor: "pointer",
                fontWeight: "600",
              }}
            >
              Remove user
            </p>

            <InformationModal
              handleClose={handleClose}
              open={open}
              heading={"What is the difference between admin and a standarduser?"}
              modaltext={modaltext}
            />

            {/* modal for remove user */}
            <Dialog open={open1}>
              <DeleteModal

                heading={"Are you sure you want to remove this user?"}
                para={"All data will be removed from your pharmacy’s account."}
                Column={false}
                handleChangeWhiteButton={handleClose1}
                handleChangeGreenButton={handleSubmitdelete}
                buttonGreentext={"Remove"}
                buttonWhitetext={"Cancel"}
              />
            </Dialog>
          </Paper>
        </form>
      </Grid>
    </>
  );
}

export default Edituser;
