import { Grid, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { makeStyles } from "@material-ui/core";
import { Switch, FormControlLabel } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Accountcircleblue from '../../Assets/Accountcircleblue.svg'
import UserAdd from '../../Assets/AccountCircleOutlinedgreen.svg'
import PropTypes from 'prop-types';
import Yourstatements from "../../Assets/Yourstatements.svg";
import ManagePharmacyusers from "../../Assets/ManagePharmacyusers.svg";

import Yourorders from "../../Assets/Yourordersgreen.svg";

import ActionRequired from '../../Assets/ActionRequired.svg'
import { connect } from "react-redux";
import { clearNotifications, enableNotification } from "../../redux/actions/actions";
import { clearNotificationPolling, getIsPollRunning, notificationPolling } from "../../Utilities/NotificationUtils";
import { FeatureEnabled } from "../../api/constants";

const pxToRem = (px, oneRemPx = 17) => `${px / oneRemPx}rem`;

const borderWidth = 1;
const width = pxToRem(50);
const height = pxToRem(32);
const size = pxToRem(20);
const gap = (32 - 20) / 2;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minWidth: "60vh",
    maxHeight:"50vh",
    minHeight:"20vh",
    margin:"10px",
    marginTop:"10px",
    [theme.breakpoints.only('sm')]: {
      marginTop: "10vh",
    width:"100%",
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: "10vh",
      minWidth: "45vh",

    },

  },
  featureNotEnabled:{
    top:"50%",
    left:"10%",
    position:'absolute',
    [theme.breakpoints.only('sm')]: {
      left:"30%",

    },
    [theme.breakpoints.only('xs')]: {
      left:"20%",

    },
  },
  switch: {
    color: "#009ADB",
    size: "10px"
  },
  notificationItem: {
    padding: "2px",
    maxWidth: "200vh"
  },
  icon: {
    alignSelf: "center"
  },
  sidebaricon:{
    backgroundColor: "#e0e0e0",
    borderRadius: "50%",
    height:"30px",
    width:"30px",
    display: "flex",
    alignSelf: "center" ,
    marginRight:"10px",
    justifyContent: "center",
    alignItems: "center"
}
}));

const IOSSwitch = withStyles((theme) => ({

  root: {
    width,
    height,
    padding: 0,
    margin: theme.spacing(1),
    overflow: 'unset',
  },
  switchBase: {
    padding: pxToRem(gap),
    '&$checked': {
      color: '#fff',
      transform: `translateX(16px)`,
      '& + $track': {
        backgroundColor: "#009ADB",
        opacity: 2,
        border: 'none',
      },
      '& $thumb': {
        backgroundColor: '#fff',
      },
    },
  },
  track: {
    borderRadius: 40,
    border: `solid ${theme.palette.grey[400]}`,
    borderWidth,
    backgroundColor: theme.palette.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    boxSizing: 'border-box',
  },
  thumb: {
    boxShadow: 'none',
    backgroundColor: "#fff",
    width: size,
    height: size,
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function Notification(props) {
  const classes = useStyles();
  const [checked, setChecked] = useState(props.isNotificationEnabled);
  const [notifications, setNotifications] = useState([]);
  // const dateformatting = (dateval) =>{
  //   const date = new Date(dateval);
  //   const date1 = new Date()
  //   const total_seconds = Math.abs(date1 - date) / 1000;
  //   const days_difference = Math.floor(total_seconds / (60 * 60 * 24));
  //   const hour = date.getHours()
  //   const minute = date.getMinutes()
  //   if(days_difference<7){
  //       switch(days_difference){
  //           case 0:
  //               return `Today , ${hour} : ${minute}`
  //               break;
  //           case 1:
  //               return `Yesterday , ${hour} : ${minute}`
  //               break;
  //           default:
  //               return `${days_difference} days ago , ${hour} : ${minute}`
  //       }
  //   }else if(days_difference<30){
  //       return `${Math.ceil(days_difference/7)} weeks ago , ${hour} : ${minute}`
  //   }else if(days_difference<365){
  //       return `${Math.ceil(days_difference/30)} months ago , ${hour} : ${minute}`
  //   }else{
  //       return `${Math.ceil((days_difference/30)/12)} years ago , ${hour} : ${minute}`
  //   }
  // }
  
  const handlecheck = () => {

    setChecked(!checked);
    props.dispatch(enableNotification(!checked))
    // EditUserDetails("enableNotification",!checked).then((res)=>res.json())
    // .then((data)=>{
    //   props.dispatch(clearNotifications());
    //   clearNotificationPolling();

    // })
  };

  useEffect(() => {
    if (checked) {
      if (!getIsPollRunning()) {
        FeatureEnabled("Notifications") && notificationPolling(props.dispatch)
      }
    } else {
      clearNotificationPolling()
    }
  }, [checked])

  useEffect(() => {
    setNotifications(props.notificationList)
  }, [props.notificationList])

  return (
     <div style={{}} className={classes.mainContainer}>
    {FeatureEnabled("Notifications")?  <Grid container direction="column" style={{width:"90%",margin:"10px"}} >
        <Grid item style={{marginTop:"10px"}}>
          <Grid container justifyContent="space-between">
            <Grid item style={{display:"flex"}}>
            <Grid container alignItems="center">
            <Grid item>
              <NotificationsNoneIcon fontSize="medium" style={{ verticalAlign: 'bottom' }} />
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Typography variant="h6" style={{ marginLeft: "0.25rem", fontWeight: "bold" }}>
                Notifications
              </Typography>
              </Grid>
            </Grid>
            </Grid>
            <Grid item xs={4} sm={4} md={4} style={{display:"flex",justifyContent:"end"}}>
              <FormControlLabel
                value="start"
                className={classes.switch}
                control={
                  <IOSSwitch
                    checked={checked}
                    onChange={() => handlecheck()}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                }
                label={checked ? "Pause" : "Resume"}
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </Grid>
        {notifications.length > 0 && <Grid item style={{ display: "flex" }} justifyContent="flex-end">

          <Typography variant="body1" style={{ color: "#007BB8", cursor: "pointer", justifyContent: "flex-end" }} onClick={() => props.dispatch(clearNotifications())}>
            Mark all as read
          </Typography>
        </Grid>}

        {notifications.length > 0 ? (
          notifications?.map((notifiction,key) => {
            return (
              <Grid key={key} container direction="column" className={classes.notificationItem}>
                <Grid container direction="row" style={{width:"100%"}}>

                  
                    {notifiction.includes('new user') && <div className={classes.sidebaricon} >
                      <img className={classes.icon} src={UserAdd} alt={notifiction} />
                      </div>}
                    {notifiction.includes('role') &&<div className={classes.sidebaricon}  >
                       <img className={classes.icon} src={Accountcircleblue} alt={notifiction} />
                       </div>}
                    {notifiction.includes('action required') &&
                    <div className={classes.sidebaricon}  > 
                    <img className={classes.icon} src={ActionRequired} alt={notifiction} />
                    </div>}

                    {notifiction.includes('order') &&<div className={classes.sidebaricon}  >
                       <img className={classes.icon} src={Yourorders} alt={notifiction} />
                       </div>}

                       {notifiction.includes('User details updated') &&<div className={classes.sidebaricon} >
                       <img className={classes.icon} src={UserAdd} alt={notifiction} />
                       </div>}
                       {notifiction.includes('commission') &&<div className={classes.sidebaricon}   >
                       <img className={classes.icon} src={Yourstatements} alt={notifiction} />
                       </div>}
                       {notifiction.includes('associated') &&<div className={classes.sidebaricon} >
                       <img className={classes.icon} src={ManagePharmacyusers} alt={notifiction} />
                       </div>}
                  <Grid item md={8} >
                    {notifiction}
                  </Grid>
                </Grid>
                <Grid item>
                  <hr />
                </Grid>
              </Grid>
            );
          })
        ) : (
          <Grid container >
            <Grid items style={{ margin: "auto" }}>
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant="body1">
                    You have no notifications.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>:<div className={classes.featureNotEnabled}>We are working on it. Please Visit again later.</div>}
    </div>
  );
}
const mapStateToProps = state => {
  return {
    userDetails: state.userDetails,
    notificationList: state.notificationList,
    isNotificationEnabled:state.isNotificationEnabled
  };
};

Notification.propTypes = {
  notificationList:PropTypes.array,
  userDetails:PropTypes.object,
  dispatch:PropTypes.func

}
export default connect(mapStateToProps)(Notification);
