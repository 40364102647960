import React from "react";
import "../../App.css";
import { useState } from "react";
import {
  Grid,
  Paper,
  Button,
  TextField,
  Typography,
  makeStyles
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { isEmpty, isSpace } from "../../Components/validation/Validation";
import {
  showErrMsg,
  showErrMsgEmpty,
} from "../../Components/notification/Notification";
import Question from "../../Assets/questionmark.svg";
import ArrowDown from "../../Assets/Arrowdown.svg";
import ArrowBackIcon from "@material-ui/icons/KeyboardArrowLeft";
import { useLocation } from "react-router-dom";
import { AccessStart, AdminAddUserComplete, subscribeToKlaviyo } from "../../api/userOps";
import { ValidatePharmacyDetails } from "../../api/validations";
import { InformationModal } from '../../Components/Modal/InformationModal';
import { logEventWithProperties, setUserGroup, setUserId, setUserProperties } from "../../analytics/Analytics";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  backbutton:{
    cursor: "pointer",
    textDecoration:"none", 
    color:"#5E5E5E", 
    display:"flex", 
    alignItems:"center", 
    paddingTop:130,
    [theme.breakpoints.up('md')]: {
          marginLeft:150,
        },
        [theme.breakpoints.down('md')]: {
          marginLeft:150,
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft:0,
          paddingLeft:"4%",
        },
      },
  pharmacycontainer:{
        margin: "auto",
        [theme.breakpoints.up('md')]: {
          width: "31%",
        },
        [theme.breakpoints.down('md')]: {
          width: "40%",
        },
        [theme.breakpoints.down('sm')]: {
          width: "50%",
        },
        [theme.breakpoints.down('xs')]: {
          width: "90%",
          padding:"4%",
        },
      },
  Textfields:{
      marginBottom: "4%",
      boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)",
      background: "white"
  },
  Modalbutton: {
    borderRadius: 6,
    textTransform: "none",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #7DD4FF",
    height: "5% !important",
  },
  buttongreen:{
    color: "white",
    textTransform: "none",
    marginTop: "3%",
  }
  
}))

function Pharmacydetails() {
  const classes = useStyles()
  let navigate = useNavigate();

  const location = useLocation();
  const verifyDetails = location?.state?.verifyDetails

  const initialState = {
    pharm_name: verifyDetails?.pharm_name || "",
    pharm_code: verifyDetails?.pharm_code || "",
    pharm_accno: verifyDetails?.pharm_accno || "",
    err: "",
    err1: "",
  };

  const [user, setUser] = useState(initialState);
  const { pharm_name, pharm_code, pharm_accno, err, err1 } = user;

  const modaltext = [{ value: "Your pharmacy account number is the number associated with your Wardles account. You should have been provided with this when you first registered your pharmacy."}]

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(()=>{
    logEventWithProperties("User visits pharmacy details page")
  },[])
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: name !== "pharm_name"?value?.toUpperCase():value, err: "" });
  };


  const handleSubmit = async e => {
        e.preventDefault()
        if(isEmpty(pharm_name) || isEmpty(pharm_code) || isEmpty(pharm_accno)) {
            setUser({...user, err: "This field is required",err1: "Please check the information above is correct" })}
        else if(isSpace(pharm_name) || isSpace(pharm_code) || isSpace(pharm_accno)) {
            setUser({...user, err1: "Please check the information above is correct"})
        } else {
            let response = [];
            ValidatePharmacyDetails(
                pharm_name.trim()?.replaceAll("+"," "), pharm_accno.trim()?.replaceAll("+"," "), pharm_code.trim()?.replaceAll("+"," "), location.state?.email
            ).then((res) => res.json())
            .then((data) => {
                if (Object.prototype.hasOwnProperty.call(data, 'pharmacyDetails')) {
                    response = data.pharmacyDetails[0];
                    if(!verifyDetails || Object.keys(verifyDetails).length === 0 ){
                    AccessStart(
                        location.state?.email,
                        location.state?.password,
                        location.state?.phoneNumber,
                        location.state?.firstName,
                        location.state?.lastName,
                        response.pharmacy_name,
                        pharm_code.trim(),
                        pharm_accno.trim(),
                        response.collection_address,
                        location.state?.receiveOffer,
                        location.state?.role,

                    ).then((res) => res.json())
                    .then((data) => {
                        response = data
                        logEventWithProperties("User signed up.",{email:location.state?.email})
                        setUserId(location.state?.email)
                        setUserGroup("fp@wardleUser")
                        setUserProperties({
                          email:location.state?.email,
                          phoneNumber:location.state?.phoneNumber,
                          firstName:location.state?.firstName,
                          lastName:location.state?.lastName,
                          pharmacyName:response.pharmacy_name,
                          pharmacyAccountcode:pharm_code.trim(),
                          pharmacyAccountNumber:pharm_accno.trim(),
                          receiveOffer:location.state?.receiveOffer,
                          role:location.state?.role,
                        })
                        location.state?.receiveOffer  && subscribeToKlaviyo({email:location.state?.email,first_name:location.state?.firstName,last_name:location.state?.lastName})
                        navigate("/emailactivate",{state:{email:location.state?.email,password:location.state?.password,phoneNumber:location.state?.phoneNumber,firstName:location.state?.firstName,
                            lastName:location.state?.lastName,pharm_name:pharm_name,pharm_code:pharm_code,pharm_accno:pharm_accno}})
                    },
                    error => {
                        setUser({...user, err1: error})
                    });
                  }else{
                    AdminAddUserComplete(
                      verifyDetails?.email,
                      location.state.password,
                      location.state.phoneNumber,
                      verifyDetails.firstName === 'first+name'? location.state.firstName : verifyDetails.firstName,
                      verifyDetails.lastName === 'last+name'? location.state.lastName : verifyDetails.lastName,
                      response.pharmacy_name,
                      pharm_code?.replaceAll("+"," "),
                      pharm_accno?.replaceAll("+"," "),
                      response.collection_address,
                      verifyDetails.receiveOffer,
                      verifyDetails.role,
                      verifyDetails.rnd,
                      verifyDetails.token
                  ).then((res) => res.json())
                  .then((data) => {
                      response = data
                      logEventWithProperties("User signed up.",{email:location.state?.email})
                      setUserId(location.state?.id)
                      setUserGroup("fp@wardleUser")
                      setUserProperties({
                        email:location.state?.email,
                        phoneNumber:location.state?.phoneNumber,
                        firstName:location.state?.firstName,
                        lastName:location.state?.lastName,
                        pharmacyName:response.pharmacy_name,
                        pharmacyAccountcode:pharm_code.trim(),
                        pharmacyAccountNumber:pharm_accno.trim(),
                        receiveOffer:location.state?.receiveOffer,
                        role:location.state?.role,
                      })
                      navigate("/register/successfull", {state:{email:location.state?.email,password:location.state?.password}})
                  },
                  error => {
                      setUser({...user, err1: error})
                  });
                  }
                  }else {
              setUser({
                ...user,
                err1: "Account postcode and number don’t match. Check you have entered the correct information.",
              });
            }
          })
          .catch(error => {
            setUser({ ...user, err1: error });
          });
    }
  };

  return (
    <Grid>
      <Grid className={classes.backbutton}>
        <ArrowBackIcon onClick={() => {
          navigate("/yourdetails", {
            state: {
              email: location.state.email,
              password: location.state.password,
              firstName: location.state.firstName,
              lastName: location.state.lastName,
              phoneNumber: location.state.phoneNumber,
              verifyDetails: verifyDetails,
            },
          });
        }}/>
        <Typography onClick={() => {
          navigate("/yourdetails", {
            state: {
              email: location.state.email,
              password: location.state.password,
              firstName: location.state.firstName,
              lastName: location.state.lastName,
              phoneNumber: location.state.phoneNumber,
              verifyDetails: verifyDetails,

            },
          });
        }}>Back</Typography>
      </Grid>

      <form onSubmit={handleSubmit} autoComplete="off">
        <Paper elevation={0} className={classes.pharmacycontainer} >
          <h2 data-testid="Header" style={{margin: 0, marginBottom:"2%", fontSize: 40}}>
            Account details
          </h2>
          <p className="body-3" style={{marginBottom:"2%"}}>
            Enter your account name, postcode and number used on your
            account.
          </p>

          <TextField
            variant="outlined"
            type={"text"}
            disabled={verifyDetails?.pharm_name}
            className={classes.Textfields}
            error={isEmpty(pharm_name) && (err?true:false)}
            placeholder="Account name"
            label={isEmpty(pharm_name) ? null : "Account name"}
            fullWidth
            onChange={handleChangeInput}
            name="pharm_name"
            data-testid="pharmname"
            value={pharm_name.replaceAll("+"," ")}
            id="pharm_name"
            size="small"
          />
          <div data-testid="error">
          {isEmpty(pharm_name) && showErrMsgEmpty(err)}
          </div>
          <TextField
            variant="outlined"
            type={"text"}
            disabled={verifyDetails?.pharm_code}
            error={isEmpty(pharm_code) && (err?true:false)}
            label={isEmpty(pharm_code) ? null : "Account postcode"}
            placeholder="Account postcode"
            fullWidth
            data-testid="pharmcode"
            onChange={handleChangeInput}
            id="pharm_code"
            name="pharm_code"
            value={pharm_code.replace("+"," ")}
            className={classes.Textfields}
            size="small"
          />
          <div data-testid="error">
          {isEmpty(pharm_code) && showErrMsgEmpty(err)}
          </div>
          <TextField
            variant="outlined"
            type={"text"}
            disabled={verifyDetails?.pharm_accno}
            error={isEmpty(pharm_accno) && (err?true:false)}
            placeholder="Account number"
            fullWidth
            label={isEmpty(pharm_accno) ? null : "Account number"}
            onChange={handleChangeInput}
            id="pharm_accno"
            name="pharm_accno"
            data-testid="pharmaccno"
            value={pharm_accno.replace("+"," ")}
            className={classes.Textfields}
            size="small"
          />
          <Grid data-testid="error">
          {isEmpty(pharm_accno) && showErrMsgEmpty(err)}
          </Grid>

          <Button
            onClick={handleOpen}
            variant="contained"
            size="large"
            disableElevation
            style={{color: '#0066BE', background: '#E8F8FF'}}
            className={classes.Modalbutton}
            fullWidth
          >
            <Grid style={{
                background: '#E8F8FF',
                display: "flex",justifyContent: "flex-start"
              }}>
              <img src={Question}  alt="error" />
              <Typography className="sub-title-2" style={{margin: 0, marginLeft: 10}}>
                What is an account number?
              </Typography>
            </Grid>
            <img src={ArrowDown}  alt="error" />
          </Button>

          <Grid data-testid="error1">
          {err1 && showErrMsg(err1,()=>{
                    setUser({...user,err1:''})
                  })}
          </Grid>

          <Grid align="center">
            <Button
              variant="contained"
              fullWidth
              size="large"
              disableElevation
              className={classes.buttongreen}
                style={{
                background: "#3D8541",
                boxShadow: "0px 2px 0px #306B33, inset 0px 1px 0px #62B767",
                }}
              type="submit"
              data-testid="next-btn"
            >
              Next
            </Button>
          </Grid>

          <InformationModal 
          handleClose={handleClose} 
          open={open} 
          heading={"What is an account number?"}
          modaltext={modaltext}
          />

        </Paper>
      </form>
    </Grid>
  );
}

export default Pharmacydetails;
