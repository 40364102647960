import React, { useState } from 'react'
import {
    Table,
    Paper,
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    TableRow,
    CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';


import TableSort from "../../Assets/TableSort Icon.svg";

const useStyles = makeStyles({
    tablecell: {
        backgroundColor: '#5E5E5E',
        color: '#fff'
    },
    image: {
        marginLeft: '0.5em',
    },
    tabledata: {
        // borderBottom: '0px',
    },
    tablerow: {
        "&:hover": {
            backgroundColor: '#C3D8F7'
        },
    },
    selected: {
        '&.Mui-selected': {
            backgroundColor: "#C3D8F7",
            '&:hover': {
                backgroundColor: "#C3D8F7"
            }
        },

    },

})


function Tablecomponent(props) {
    const classes = useStyles();
    const [selected, setSelected] = useState(undefined);

    const handleonClick = (item) => {
        if (item.issortable === true) {
            // console.log('Clicked')
        } else {
            // console.log('Not Clicked')
        }
    }

    function Emaildisplay (email){
           let rowCount = 3
           let rows = []
           let emailCount = email.length

            let columnCount = Math.trunc(emailCount/rowCount) + (emailCount%rowCount === 0 ? 0 : 1)
 
             for(let i = 0;i<3;i++){
              let row = []
               for(let j = 0 ; j < columnCount;j++)
                 if(((i+1)*j) < emailCount){
                row.push(email[columnCount*i+j])}
                // row.push(email[rowCount*j+i])}

               rows.push(row)
             }

           return    <Table>
                        <TableBody style={{border:"none"}}>
                            {rows.map((row,index)=>{
                            return  <TableRow key={index} style={{marginBottom:25, border:"none",}}>
                                {row.map((item,i)=>{
                                return  <TableCell key={i} align="center" style={{margin:5,padding:5,border:"none",}}>
                                    {item} 
                                </TableCell>
                                })}
                            </TableRow>
                            })}

                    </TableBody>
                    </Table>
           
    }
    return (
        <>
            <div style={{ position: "relative", minHeight: "0px" }}>
                <TableContainer component={Paper} classes={{root: classes.customTableContainer}}>
                    <Table stickyHeader>
                        <TableHead>     
                            <TableRow >
                                {props.head.map((item, index) =>
                                    <TableCell key={index} align="center" onClick={() => { handleonClick(item) }} className={classes.tablecell}>{item.value}{item.issortable && <img className={classes.image} src={TableSort} alt="sort" />}</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.body?.length > 0 && props.body?.map((data, index) => {
                                return (
                                    <TableRow key={index} onClick={() => setSelected(index)} selected={index === selected} className={selected === index ? [classes.tablerow, classes.selected] : classes.tablerow}>
                                        {Object.values(data).map((items, i) => {
                                            const emails=items.split(";")

                                            if (i <= 2 ){
                                                return <TableCell key={i} align="center" className={classes.tabledata}   >
                                                    {<div>{items}</div>}        
                                                </TableCell>
                                                } 
                                                
                                                
                                                else{

                                               return <TableCell key={i} align="center" className={classes.tabledata}   >
                          
                                                        {Emaildisplay(emails)}
                                                </TableCell>}
                                        }
                                        )
                                    }
                                    </TableRow>
                                )
                            }
                            )
                        }



                             {props.loading === true &&
                                <div style={{ height: "80px",marginTop:"10px" }}>

                                    <div style={{ "textAlign": "center" }}>

                                        <div style={{ position: "absolute", left: "0px", top: "40px", width: "100%", height: "100%" }}>
                                            <h4>
                                                <CircularProgress /><br></br>
                                                Loading...
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            }

                            {props.body?.length === 0 && props.loading === false &&
                               <div style={{ height: "40px"}}>

                               <div style={{ "textAlign": "center" }}>

                                   <div style={{ position: "absolute", left: "0px", top: "40px", width: "100%", height: "100%" }}>
                                       <h4>
                                         No more data
                                       </h4>
                                   </div>
                               </div>
                           </div>
                            } 


                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

        </>
    )
}
Tablecomponent.propTypes = {
    loading : PropTypes.bool,
    body : PropTypes.array,
    head : PropTypes.array,
    noData: PropTypes.bool
}

export default Tablecomponent