/* eslint-disable no-prototype-builtins */
import React, {useEffect, useState } from 'react';
import { Button, Hidden, Typography, Box, Modal, TextField, InputAdornment, Grid, makeStyles, TableContainer, Table, Paper, TableHead, TableRow, TableBody, TableCell, IconButton } from '@material-ui/core';
import './Orderdetails.css';
import OrderStatus from '../Dropdown/OrderStatus';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { forceCloseOrder, ListorderbyId, UpdateOrderDetails } from '../../api/orderops';
import { ORDER_DELIVERY_DATE_KEY, ORDER_AWARD_SCRIPT_NO_KEY, ORDER_STATUS_KEY, ORDER_PATIENT_NAME, ORDER_SEARCH_ID } from '../../api/constants';
import { connect } from 'react-redux';
import { isEmpty, isAwardscriptnumber } from '../validation/Validation';
import { showErrMsg, showSuccessmsg } from '../notification/Notification';
import CloseIcon from '@material-ui/icons/Close';
import CountdownTimer from '../../Utilities/CountdownTimer';
import Checked from "../../Assets/Checked.svg";
import Edit from '../../Assets/Edit.svg'
import { logEventWithProperties } from '../../analytics/Analytics';
import IsolationIcon from '../../Assets/IsolationModeIcon.svg';

const useStyles = makeStyles((theme) => ({
  detailsPage: {
    boxShadow: "1px 2px 16px rgba(78, 78, 78, 0.1)",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: "1rem",
    [theme.breakpoints.only('md')]: {
      width: "85%",
    },
    [theme.breakpoints.only('xs')]: {
      width: "90%",
      marginTop: "0px",
      height: "100vh",
    },
    [theme.breakpoints.only('sm')]: {
      width: "95%",
      marginTop: "0px",
      height: "100vh",
    },
    [theme.breakpoints.only('lg')]: {
      width: "85%",
    },
    [theme.breakpoints.only('xl')]: {
      width: "85%",
    },
  }
}))
function Orderdetails(props) {

  const { userDetails } = props


  
  const classes = useStyles()
  const normalizedEntries = (item) => {
    let today = new Date(item * 1000);
    let date = today
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return (day + '/' + month + '/' + year);
  };

  const normalizedEntries1 = (item) => {
    let date = new Date(item * 1000);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };
  const [orderDetails,setOrderDetails] = useState(props.orderdetailsId);

  let id = orderDetails?.hasOwnProperty('id') ? orderDetails?.id : ''
  let order_type = orderDetails?.hasOwnProperty('order_type') ? orderDetails?.order_type : ''
  let order_status = orderDetails?.hasOwnProperty('record_status') ? orderDetails?.record_status : ''
  let email = orderDetails?.hasOwnProperty('email') ? orderDetails?.email : ''
  let first_name = orderDetails?.hasOwnProperty('patient') ? orderDetails?.patient.first_name : orderDetails?.patient_name
  let last_name = orderDetails?.hasOwnProperty('patient') ? orderDetails?.patient.last_name : ''
  let created_date = orderDetails?.hasOwnProperty('created_date') ? orderDetails?.created_date : ''
  let pharmacyName = orderDetails?.hasOwnProperty('pharmacy_name') ? orderDetails?.pharmacy_name : ''
  let created_by = orderDetails?.hasOwnProperty('createdBy') ? orderDetails?.createdBy : orderDetails?.hasOwnProperty('created_by') ? orderDetails.created_by : '';
  let order_items = orderDetails?.hasOwnProperty('order_items') ? orderDetails?.order_items : [{ productName: '', quantity: '', singles: '', customisation: '' }];
  let role = userDetails?.role;
  let [patient_name, setPatientName] = useState(orderDetails?.patient_name)
  let [is_patient_name_changed, setisPatientNameChanged] = useState(false)

  const [isEditPatientName, setisEditPatientName] = useState(false)

  const styleerrormodal = { position: 'absolute', top: '8%', left: '50%', transform: 'translate(-50%, -50%)', borderRadius: 10, p: 3, };
  const [openToast, setopenToast] = useState(false)
  const [error, setError] = useState({})
  const [isDateChange, setIsDateChange] = useState(false) // eslint-disable-line no-unused-vars
  const [orderClosed, setIsOrderClosed] = useState(false)
  const [isSavDisabled, setIsSaveDisabled] = useState(true)

  let today = new Date()
  let nextDay = new Date(today)
  nextDay.setDate(nextDay.getDate() + 1)
  let order_delivery_date = orderDetails?.hasOwnProperty('order_delivery_date') ? orderDetails?.order_delivery_date : '';
  let AWARDS_script_number = orderDetails?.hasOwnProperty('awards_script_number') ? orderDetails?.awards_script_number : '';
  let ENVELOPE = orderDetails?.hasOwnProperty('envelope') ? ((orderDetails?.envelope)?"Yes":"No"):"No"
  // let wardle_staff_email = orderDetails.hasOwnProperty('wardle_staff_email') ? orderDetails.wardle_staff_email : '';
  // let order_open = orderDetails.hasOwnProperty('order_open') ? orderDetails.wardle_staff_email : false;
  const [awardsscriptnumber, setAwardsscriptnumber] = useState(AWARDS_script_number);
  const [orderDeliveryDate, setOrderDeliveryDate] = useState(order_delivery_date || nextDay);
  const [isawardsscriptnumberChanged, setisawardsscriptnumberChanged] = useState(false);

  const [isOrderStatusChanged, setIsOrderStatusChanged] = useState(false);
  const [changedOrderStatus, setChangedOrderStatus] = useState('');


  useEffect(()=>{
    order_delivery_date = orderDetails?.hasOwnProperty('order_delivery_date') ? orderDetails?.order_delivery_date : '';
    AWARDS_script_number = orderDetails?.hasOwnProperty('awards_script_number') ? orderDetails?.awards_script_number : '';
    id = orderDetails?.hasOwnProperty('id') ? orderDetails?.id : ''
    order_type = orderDetails?.hasOwnProperty('order_type') ? orderDetails?.order_type : ''
  order_status = orderDetails?.hasOwnProperty('record_status') ? orderDetails?.record_status : ''
    email = orderDetails?.hasOwnProperty('email') ? orderDetails?.email : ''
  first_name = orderDetails?.hasOwnProperty('patient') ? orderDetails?.patient.first_name : orderDetails?.patient_name
    last_name = orderDetails?.hasOwnProperty('patient') ? orderDetails?.patient.last_name : ''
    created_date = orderDetails?.hasOwnProperty('created_date') ? orderDetails?.created_date : ''
    pharmacyName = orderDetails?.hasOwnProperty('pharmacy_name') ? orderDetails?.pharmacy_name : ''
    created_by = orderDetails?.hasOwnProperty('createdBy') ? orderDetails?.createdBy : orderDetails?.hasOwnProperty('created_by') ? orderDetails.created_by : '';
    order_items = orderDetails?.hasOwnProperty('order_items') ? orderDetails?.order_items : [{ productName: '', quantity: '', singles: '', customisation: '' }];
  },[orderDetails])


  const updatePAtientName = () => {
    !isEmpty(patient_name.trim()) && UpdateOrderDetails(ORDER_PATIENT_NAME, patient_name.trim(), orderDetails?.id, orderDetails?.email, awardsscriptnumber)
      .then((res) => res.json())
      .then((data) => {
        setisPatientNameChanged(false)
        logEventWithProperties("Order details updated failed",data?.error)
        if (data.error) {

          return setError({ type: "error", msg: "Patient name  not updated" })
        } else {
        logEventWithProperties("Order details updated successfully",{orderId:id})

          setopenToast(true)
          orderDetails.patient_name = patient_name
          setisEditPatientName(false)

          setError({ type: "success", msg: "patient name updated" })
          setTimeout(() => {
            setError({ type: '', msg: '' })
            setopenToast(false);
          }, 1500);

          let orderSearchId = orderDetails?.order_search_id
          let orderSearchIdArray = orderDetails?.order_search_id?.split("-")
          if (orderSearchIdArray[2]) {
            orderSearchIdArray[2] = patient_name.trim().toLowerCase()
            orderSearchId = orderSearchIdArray?.join("-")
          } else {
            orderSearchId = orderSearchId + patient_name.trim().toLowerCase()
          }

          console.log("orderSearchId", orderSearchId)
          !isEmpty(orderSearchId.trim()) && UpdateOrderDetails(ORDER_SEARCH_ID, orderSearchId.trim(), orderDetails?.id, orderDetails?.email, awardsscriptnumber)

        }
      },
        error => {
          setError({ type: "error", msg: 'There was an error!', error })
        });
  }
  function closeOrder() {
    if (userDetails?.role === 'fp@wardleStaff') {
      UpdateOrderDetails('order_open', "close", id, email, awardsscriptnumber)
        .then((res) => res.json())
        .then(() => {
          id = "";
          order_status = "";
          email = "";
          first_name = "";
          last_name = "";
          created_date = "";
          order_status = "";
          pharmacyName = "";
          order_items = {};
          created_by = "";
          setOrderDeliveryDate(new Date());
          setAwardsscriptnumber("");
          AWARDS_script_number = "";
          order_delivery_date = "";
        },
          error => {
            console.error('There was an error!', error);
          });
    }
  }

  const handleChangeInput = e => {
    let aws = e.target.value
    setAwardsscriptnumber(aws);
    setisawardsscriptnumberChanged(true)
    if (order_status === "request submitted") {
      if (!isEmpty(aws) && changedOrderStatus === "ordered") {
        setIsSaveDisabled(false)
      }
    } else {
      setIsSaveDisabled(false)
    }
  }

  function awardsSubmit() {
    ListorderbyId(id)
    .then((res) => res.json())
    .then(
      (data) => {
        if(data.order_open === "open" && data.modified_by === userDetails?.email){
          if (!isEmpty(patient_name) && is_patient_name_changed) {
            updatePAtientName()
          }
      
          if (!isEmpty(changedOrderStatus)) {
            if (changedOrderStatus === "ordered") {
              if (isEmpty(awardsscriptnumber)) {
                setopenToast(true);
                setError({ type: "error", msg: "Please enter award script number" })
                setTimeout(() => {
                  setError({ type: "", msg: "" })
                  setopenToast(false);
                }, 1500);
              } else {
                UpdateOrderDetails(ORDER_AWARD_SCRIPT_NO_KEY, awardsscriptnumber, id, email)
                  .then((res) => res.json())
                  .then((data) => { // eslint-disable-line no-unused-vars
                    setisawardsscriptnumberChanged(false)
                    setopenToast(true)
                    if(data.error)
                      setError({ type: "error", msg: data.error.message})
                    else
                      setError({ type: "success", msg:"Awards reference number updated"})
                    setTimeout(() => {
                      setError({ type: '', msg: '' })
                      setopenToast(false);
                    }, 1500);
                    if (orderDeliveryDate) {
                      UpdateOrderDetails(ORDER_DELIVERY_DATE_KEY, orderDeliveryDate, id, email)
                        .then((res) => res.json())
                        .then(() => {
                          setIsDateChange(false)
                          setopenToast(true)
                          let d = new Date(orderDeliveryDate)
                          let formated = d ? d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) : d
                          setError({ type: "success", msg: `updated delivery date as ${formated}` })
                          setTimeout(() => {
                            setError({ type: '', msg: '' })
                            setopenToast(false);
                          }, 1500);
                          isOrderStatusChanged && UpdateOrderDetails(ORDER_STATUS_KEY, changedOrderStatus?.toLowerCase(), id, email)
                            .then((res) => res.json())
                            .then((data) => {
                              setIsOrderStatusChanged(false)
                              if (data.error) {
                                return setError({ type: "error", msg: "Order status not updated" })
                              } else {
                                setopenToast(true)
                                setError({ type: "success", msg: "Order status updated" })
                                setTimeout(() => {
                                  setError({ type: '', msg: '' })
                                  setopenToast(false);
                                }, 1500);
      
                              }
                            },
                              error => {
                                setError({ type: "error", msg: 'There was an error!', error })
                              });
                        },
                          error => {
                            console.error('There was an error!', error);
                          });
      
                    }
                  })
      
              }
            } else {
              if (isawardsscriptnumberChanged && !isEmpty(awardsscriptnumber)) {
                UpdateOrderDetails(ORDER_AWARD_SCRIPT_NO_KEY, awardsscriptnumber, id, email)
                  .then((res) => res.json())
                  .then((data) => { // eslint-disable-line no-unused-vars
                    setopenToast(true)
                    if(data.error)
                      setError({ type: "error", msg: data.error.message})
                    else
                      setError({ type: "success", msg:"Awards reference number updated"})
                    setTimeout(() => {
                      setError({ type: '', msg: '' })
                      setopenToast(false);
                    }, 1500);
                    if (orderDeliveryDate) {
                      UpdateOrderDetails(ORDER_DELIVERY_DATE_KEY, orderDeliveryDate, id, email)
                        .then((res) => res.json())
                        .then(() => {
                          setopenToast(true)
                          setIsDateChange(false)
                          let d = new Date(orderDeliveryDate)
                          let formated = d ? d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) : d
                          setError({ type: "success", msg: `updated delivery date as ${formated}` })
                          setTimeout(() => {
                            setError({ type: '', msg: '' })
                            setopenToast(false);
                          }, 1500);
      
                        },
                          error => {
                            console.error('There was an error!', error);
                          });
      
                    }
                  })
      
              }
              isOrderStatusChanged && UpdateOrderDetails(ORDER_STATUS_KEY, changedOrderStatus?.toLowerCase(), id, email)
                .then((res) => res.json())
                .then((data) => {
                  setIsOrderStatusChanged(false)
                  if (data.error) {
                    return setError({ type: "error", msg: "Order status not updated" })
                  } else {
                    setopenToast(true)
                    setError({ type: "success", msg: "Order status updated" })
                    setTimeout(() => {
                      setError({ type: '', msg: '' })
                      setopenToast(false);
                    }, 1500);
      
                  }
                },
                  error => {
                    setError({ type: "error", msg: 'There was an error!', error })
                  });
            }
          } else {
            if (!isEmpty(awardsscriptnumber)) {
              UpdateOrderDetails(ORDER_AWARD_SCRIPT_NO_KEY, awardsscriptnumber, id, email)
                .then((res) => res.json())
                .then((data) => { // eslint-disable-line no-unused-vars
                  setopenToast(true)
                  if(data.error)
                    setError({ type: "error", msg: data.error.message})
                  else
                    setError({ type: "success", msg:"Awards reference number updated"})

                  setTimeout(() => {
                    setError({ type: '', msg: '' })
                    setopenToast(false);
                  }, 1500);
                  setisawardsscriptnumberChanged(false)
                  if (isDateChange && orderDeliveryDate) {
                    UpdateOrderDetails(ORDER_DELIVERY_DATE_KEY, orderDeliveryDate, id, email)
                      .then((res) => res.json())
                      .then(() => {
                        setopenToast(true)
                        setIsDateChange(false)
                        let d = new Date(orderDeliveryDate)
                        let formated = d ? d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) : d
                        setError({ type: "success", msg: `updated delivery date as ${formated}` })
                        setTimeout(() => {
                          setError({ type: '', msg: '' })
                          setopenToast(false);
                        }, 1500);
      
                      },
                        error => {
                          console.error('There was an error!', error);
                        });
      
                  }
                })
      
            }
          }
      
      
      
      
          if (!AWARDS_script_number) {
            console.log("changedOrderStatus", changedOrderStatus)
            if (changedOrderStatus && (changedOrderStatus === "return to nhs spine" || changedOrderStatus === "request cancelled" || changedOrderStatus === 'attempted to call - item oos' || changedOrderStatus === 'attempted to call - to follow query' || changedOrderStatus === 'attempted to call - order cancelled')) {
              UpdateOrderDetails(ORDER_STATUS_KEY, changedOrderStatus?.toLowerCase(), orderDetails?.id, orderDetails?.email, awardsscriptnumber)
                .then((res) => res.json())
                .then((data) => {
                  setIsOrderStatusChanged(false)
                  if (data.error) {
                    return setError({ type: "error", msg: "Order status not updated" })
                  } else {
                    setopenToast(true)
                    setError({ type: "success", msg: "Order status updated" })
                    setTimeout(() => {
                      setError({ type: '', msg: '' })
                      setopenToast(false);
                    }, 1500);
      
                  }
                },
                  error => {
                    setError({ type: "error", msg: 'There was an error!', error })
                  });
            }
            else if (changedOrderStatus && changedOrderStatus === "ordered") {
              if (isEmpty(awardsscriptnumber)) {
                setopenToast(true);
                setError({ type: "error", msg: "Please enter award script number" })
                setTimeout(() => {
                  setError({ type: "", msg: "" })
                  setopenToast(false);
                }, 1500);
              } else {
                UpdateOrderDetails(ORDER_AWARD_SCRIPT_NO_KEY, awardsscriptnumber, id, email)
                  .then((res) => res.json())
                  .then((data) => { // eslint-disable-line no-unused-vars
                    setopenToast(true)
                    if(data.error)
                      setError({ type: "error", msg: data.error.message})
                    else
                      setError({ type: "success", msg:"Awards reference number updated"})
                    setTimeout(() => {
                      setError({ type: '', msg: '' })
                      setopenToast(false);
                    }, 1500);
                    setisawardsscriptnumberChanged(false)
                    if (orderDeliveryDate) {
                      UpdateOrderDetails(ORDER_DELIVERY_DATE_KEY, orderDeliveryDate, id, email, awardsscriptnumber)
                        .then((res) => res.json())
                        .then(() => {
                          setIsDateChange(false)
                          setopenToast(true)
                          let d = new Date(orderDeliveryDate)
                          let formated = d ? d.getFullYear().toString() + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) : d
                          setError({ type: "success", msg: `updated delivery date as ${formated}` })
                          setTimeout(() => {
                            setError({ type: '', msg: '' })
                            setopenToast(false);
                          }, 1500);
                          if (changedOrderStatus && changedOrderStatus === "ordered") {
                            UpdateOrderDetails(ORDER_STATUS_KEY, changedOrderStatus?.toLowerCase(), id, email, awardsscriptnumber)
                              .then((res) => res.json())
                              .then((data) => {
                                setIsOrderStatusChanged(false)
                                if (data.error) {
                                  return setError({ type: "error", msg: "Order status not updated" })
                                } else {
                                  setopenToast(true)
                                  setError({ type: "success", msg: "Order status updated" })
                                  setTimeout(() => {
                                    setError({ type: '', msg: '' })
                                    setopenToast(false);
                                  }, 1500);
      
                                }
                              },
                                error => {
                                  setError({ type: "error", msg: 'There was an error!', error })
                                });
                          }
                        },
                          error => {
                            console.error('There was an error!', error);
                          });
      
                    }
                  })
      
              }
            }
          } else {
            console.log("aw", AWARDS_script_number)
          }
      
        }else{
          setopenToast(true);

          setError({ type: "error", msg: data.modified_by+" is working on this order, chnages you made are not saved." })
          setTimeout(() => {
            setError({ type: '', msg: '' })
            setopenToast(false);
            props.handleClose()
          }, 1500);
        }
      },
      (error) => {
        console.error("There was an error!", error);
      }
    );

  }

  const changeOrderStatusFlag = (value, changedOrderStatus) => {
    setIsOrderStatusChanged(true);

    setChangedOrderStatus(changedOrderStatus);
    if (order_status !== "request submitted" &&  changedOrderStatus === 'return to nhs spine' || changedOrderStatus === 'request cancelled' || changedOrderStatus === 'attempted to call - item oos' || changedOrderStatus === 'attempted to call - to follow query' || changedOrderStatus === 'attempted to call - order cancelled') {
      setIsSaveDisabled(false)
    } else if (!isEmpty(awardsscriptnumber)) {
      setIsSaveDisabled(false)
    }
  
  };

  // function isCloseButtonDisabled() {
  //   if (role === 'fp@wardleStaff') {
  //     if (changedOrderStatus === 'return to nhs spine' || changedOrderStatus === 'request cancelled' || changedOrderStatus === 'please contact wardles' ) {
  //       return false;
  //     } else {
  //       if (awardsscriptnumber === '' ||(!isEmpty(awardsscriptnumber) && order_status === "request submitted") )
  //       return true;
  //       else
  //       return false
  //     }
  //   } else {
  //     return false
  //   }
  // }

  function orderStatusComponent(order_status, id, email) {
    if (role === 'fp@wardleStaff') {
      return (
        <OrderStatus
          changeOrderStatusFlag={changeOrderStatusFlag}
          orderStatus={order_status}
          id={id}
          email={email}
          awardsscriptnumber={awardsscriptnumber}
          onStatusError={(err) => {
            setopenToast(true);
            setError(err);
            setTimeout(() => {
              setError({ type: '', msg: '' })
              setopenToast(false);
            }, 1500);
          }}
        />
      )
    } else {
      return (
        <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: "bold" }}>Order Status&nbsp;:</p>
          <p>&nbsp;{order_status ? order_status === "Request submitted" || order_status === "request submitted" ? "Request Submitted" : order_status : order_status}</p>
        </div>
      )
    }
  }
  // Funtion to display delivery date
  function deliveryTimeComponent() {
    if (role === 'fp@wardleStaff') {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            disablePast
            InputProps={awardsscriptnumber === '' ? { readOnly: true } : { readOnly: false }}
            autoOk
            variant="dialog"
            style={{ width: "100%", fontSize: "12px !important" }}
            label="Estimated delivery date"
            inputVariant="outlined"
            format="yyyy-MM-dd"
            value={orderDeliveryDate}
            InputAdornmentProps={{ position: "start" }}
            onChange={date => {
              setOrderDeliveryDate(date)
              setIsDateChange(true)
              if(order_status !== "request submitted"){
                setIsSaveDisabled(false)
              }
            }}
          />
        </MuiPickersUtilsProvider>
      )
    } else {
      if (order_delivery_date.includes('T')) {
        return (
          <TextField
            size="small"
            inputProps={
              { readOnly: true, }
            }
            label="Estimated delivery date"
            variant="outlined"
            type="text"
            style={{ width: "100%" }}
            name="estomatedDeliveryDate"
            id="deliveryDate"
            value={order_delivery_date.split('T')[0]}
          />
        );
      } else {
        return ''
      }
    }
  }

  function awradsScriptNumberComponent() {
    if (role === 'fp@wardleStaff') {
      return (
        <TextField
          size="small"
          error={!isEmpty(awardsscriptnumber) && !isAwardscriptnumber(awardsscriptnumber)}
          label="Awards script number"
          variant="outlined"
          type="text"
          style={{ width: "100%" }}
          name="awardsscriptnumber"
          id="Awards"
          helperText={!isEmpty(awardsscriptnumber) && !isAwardscriptnumber(awardsscriptnumber) ? "Invalid Award script number format" : null}

          value={awardsscriptnumber}
          onChange={(e) => handleChangeInput(e)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" variant="filled" style={{ width: "100%", height: "100%" }}>
              </InputAdornment>
            )
          }}
        />

      )
    }
  }

  window.onbeforeunload = function(e) {
    props.handleClose();
    // e.preventDefault()
    closeOrder();
    (e || window.event).returnValue = null;
    return undefined;
    }

    const reopenOrder = ()=>{
      if (userDetails?.role === 'fp@wardleStaff') {
        forceCloseOrder(id,email)
          .then((res) => res.json())
          .then(() => {
          

              UpdateOrderDetails('order_open', "open", id, email, true)
                .then((res) => res.json())
                .then((data) => {
                  ListorderbyId(data?.orderId)
                  .then((res) => res.json())
                  .then(
                    (data) => {
                    setOrderDetails(data)
                    },
                    (error) => {
                      console.error("There was an error!", error);
                    }
                  );
                },
                  error => {
                    console.error('There was an error!', error);
                  });
            

          },
            error => {
              console.error('There was an error!', error);
            });
      }
    }

  // window.addEventListener("beforeunload", function (e) {
  //   props.handleClose();
  //   // e.preventDefault()
  //   closeOrder();
  //   (e || window.event).returnValue = null;
  //   return;
  // });

  return (
    <>
      <Modal
        open={props.open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.detailsPage}>

          <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "left", width: "90%" }}>
              {role === 'fp@wardleStaff' && (orderDetails?.order_open === "open" && orderDetails?.modified_by === userDetails?.email) &&
                <>  <p>Modal closes in&nbsp;</p><CountdownTimer onTimeEnd={() => {
                  props.handleClose(); closeOrder()
                }} endTimer={orderClosed} /><p>&nbsp;minutes</p>
                </>}
            </div>

            <div style={{ marginLeft: "auto" }}>

              <Typography id="modal-modal-title"
                style={{ justifyContent: "right", right: "10px" }} onClick={() => {
                  role != 'fp@wardleStaff' ? props.handleClose() : setIsOrderClosed(true); closeOrder()
                }}>

                <Hidden smDown implementation='css' >
                  <Typography onClick={()=>props.handleClose()} style={{ display: "flex", marginTop: "0.5em", alignItems: "center", cursor: "pointer", marginLeft: "auto" }} id="modal-modal-title" variant="h6" component="h2">
                    <p style={{ margin: 0, padding: 0, fontSize: "15px", color: "#007BB8", fontWeight: "bold" }}>Close</p>
                    <CloseIcon style={{ color: "#007BB8", height: 15 }} />
                  </Typography>
                </Hidden>

                <Hidden mdUp implementation='css' >
                  <CloseIcon onClick={()=>props.handleClose()} style={{ color: "#007BB8", height: 15, marginTop: "1.1em", }} />

                </Hidden>

              </Typography>
            </div>

          </div>
          {role != 'fp@wardleStaff' || (orderDetails?.order_open === "close"||  !orderDetails?.modified_by  || (orderDetails?.order_open === "open" && ( orderDetails?.modified_by === userDetails?.email)) ) ?<>
          
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <p style={{ margin: 0, padding: 0, textAlign: "center", fontWeight: "bold", marginLeft: 5, marginTop: 17, fontSize: "19px", color: "#222222" }}>Order Details</p>

          </Typography>

          <Hidden smDown implementation='css' >
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "grid", alignContent: "flex-end" }}>
                <div style={{ display: "flex", alignItems: "center", }}><p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>Order Number:</p><p style={{ margin: 0, padding: 0, marginLeft: 20, color: "#5E5E5E" }}>{AWARDS_script_number}</p></div>
                <div style={{ display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}><p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>Patient Name:</p>
                  {orderDetails?.patient ? <p style={{ margin: 0, padding: 0, marginLeft: 20, color: "#5E5E5E" }}>{first_name} {last_name}</p> :
                    (role === 'fp@wardleStaff' && order_type === "eps" && !orderDetails.patient_name) || isEditPatientName ?
                      <p style={{ margin: 0, padding: 0, marginLeft: 20, color: "#5E5E5E" }}>

                        <TextField
                          variant="outlined"
                          value={patient_name}
                          type={'text'}
                          onChange={(e) => { setPatientName(e.target.value); 
                            setisPatientNameChanged(true); 
                            setIsSaveDisabled(false) }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={updatePAtientName}
                                  edge="end"
                                >
                                  <img
                                    src={Checked}
                                    alt="right"
                                    style={{ color: "#52B057" }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}

                          size="10px"
                          placeholder="Patient name" />
                      </p>
                      :
                      <p style={{ margin: 0, padding: 0, marginLeft: 20, color: "#5E5E5E" }}>
                        {orderDetails?.patient_name}{role === 'fp@wardleStaff' && order_type === "eps" && <img
                          src={Edit}
                          onClick={() => { setisEditPatientName(true); setPatientName(orderDetails?.patient_name) }}
                          alt="error"
                          style={{ cursor: "pointer", marginLeft: "5px", marginTop: "2px" }}
                        />}
                      </p>}
                </div>
                {role === 'fp@wardleStaff' && order_type === "eps" && <div style={{ display: "flex", alignItems: "center", }}><p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>EPS Guid:</p><p style={{ margin: 0, padding: 0, marginLeft: 20, color: "#5E5E5E" }}>{id}</p></div>}
              </div>
              <div style={{ display: "grid", alignContent: "flex-end", }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}><p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>Ordered On:</p><p style={{ margin: 0, padding: 0, marginLeft: 20, color: "#5E5E5E" }}>{(normalizedEntries(created_date))} {(normalizedEntries1(created_date))}</p></div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}><p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>Order Created By:</p><p style={{ margin: 0, padding: 0, marginLeft: 20, color: "#5E5E5E" }}>{created_by}</p></div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}><p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>Pharmacy Name:</p><p style={{ margin: 0, padding: 0, marginLeft: 20, color: "#5E5E5E" }}>{pharmacyName}</p></div>
              </div>
            </div>
          </Hidden>
          <Hidden mdUp implementation='css' >
            <div style={{ margin: "20px", justifyContent: "center" }}>
              <Grid container justifyContent='center' spacing={2}>
                <Grid item xs={6} sm={6} md={6}>
                  <p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>Order Number:</p>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <p style={{ margin: 0, padding: 0, marginLeft: 20, color: "#5E5E5E" }}>{AWARDS_script_number || "-"}</p>
                </Grid>
              </Grid>
              <Grid container justifyContent='center' spacing={2}>

                <Grid item xs={6} sm={6} md={6}>
                  <p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>Patient Name:</p>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <p style={{ margin: 0, padding: 0, marginLeft: 20, color: "#5E5E5E" }}>{first_name} {last_name}</p>
                </Grid>

              </Grid>
              <Grid container justifyContent='center' spacing={2}>

                <Grid item xs={6} sm={6} md={6}>
                  <p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>Ordered On:</p>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <p style={{ margin: 0, padding: 0, marginLeft: 20, color: "#5E5E5E" }}>{(normalizedEntries(created_date))} {(normalizedEntries1(created_date))}</p>
                </Grid>
              </Grid>

              <Grid container justifyContent='center' spacing={2}>

                <Grid item xs={6} sm={6} md={6}>
                  <p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>Order Created By:</p>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <p style={{ margin: 0, padding: 0, marginLeft: 20, color: "#5E5E5E" }}>{created_by}</p>
                </Grid>
              </Grid>
              <Grid container justifyContent='center' spacing={2}>
                <Grid item xs={6} sm={6} md={6}>
                  <p style={{ margin: 0, padding: 0, fontWeight: "bold" }}>Pharmacy Name:</p>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <p style={{ margin: 0, padding: 0, marginLeft: 20, color: "#5E5E5E" }}>{pharmacyName}</p>
                </Grid>
              </Grid>
            </div>
          </Hidden>

          <TableContainer component={Paper} style={{ maxHeight: 300, marginTop: '0.5em' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: "#5E5E5E", color: "white" }}>Item Name</TableCell>
                  <TableCell style={{ backgroundColor: "#5E5E5E", color: "white" }}>Product Code</TableCell>
                  <TableCell style={{ backgroundColor: "#5E5E5E", color: "white" }}>Quantity Requested</TableCell>
                  {/* <TableCell style={{ backgroundColor: "#5E5E5E", color: "white" }}>Packs</TableCell> */}
                  <TableCell style={{ backgroundColor: "#5E5E5E", color: "white" }}>Customisation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order_items.map((items) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell style={{ wordBreak: "break-all" }}>{items.productName}</TableCell>
                        <TableCell>{items.vmpId}</TableCell>
                        <TableCell align="center">{items.quantity}</TableCell>
                        {/* <TableCell>{items.singles}</TableCell> */}
                        <TableCell style={{ wordBreak: "break-all" }}>{items.customisation}</TableCell>
                      </TableRow></>)
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container spacing={4} style={{ marginTop: "0.05em" }}>
            <Grid item xs={12} sm={4}>
              {orderStatusComponent(order_status, id, email)}
            </Grid>
            <Grid item xs={12} sm={4}>
              {deliveryTimeComponent()}
            </Grid>
            <Grid item xs={12} sm={4}>
              {awradsScriptNumberComponent()}
            </Grid>
          </Grid>
          <div style={{ display: 'flex' }}>
          <p style={{ fontWeight: "bold" }}>Envelope Requested&nbsp;:</p>
          <p>&nbsp;{ENVELOPE}</p>
        </div>
          {role === 'fp@wardleStaff' && <div style={{ width: "100%", marginTop: "3%", textAlign: "center" }}>
            {!isSavDisabled ? <Button variant="outlined" disabled={false} onClick={() => { awardsSubmit() }} style={{ width: "50%", textTransform: "none", backgroundColor: "#3D8541", color: "white", cursor: "pointer", fontWeight: 600, margin: "auto" }}>Save</Button>
              : <Button variant="outlined" disabled={false} style={{ width: "50%", textTransform: "none", backgroundColor: "#e0e0e0", color: "white", cursor: "pointer", fontWeight: 600, margin: "auto" }}>Save</Button>}
          </div>}
        </>:   <Grid align="center">
        <Paper elevation={0} className={classes.paperStyle} sx={{textAlign:"center"}}>
          <img src={IsolationIcon} alt="" srcSet=""/>
          <h1 data-testid="Header" style={{fontSize: 25,margin:0, marginBottom:25,width:"70%"}}>{orderDetails?.modified_by} is updating order, do you want to take over?</h1>
          <Button data-testid="btn" variant="outlined" onClick={reopenOrder} size="large" fullWidth disableElevation style={{background:"#3D8541", color:"white",width:"auto", marginTop:"8px", fontSize:"12px", textTransform: "none"}} >Take over</Button>
          
        </Paper>
    </Grid>}
        </Box>
      </Modal>

      <Modal
        open={openToast}
        onClose={() => { setopenToast(false) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleerrormodal}>
          {error.type === "success" && showSuccessmsg(error.msg)}
          {error.type === "error" && showErrMsg(error.msg, () => {
            setopenToast(false)
          })}
        </Box>
      </Modal>
    </>
  );
}

const mapStateToProps = state => {
  return {
    userDetails: state.userDetails
  };
};

export default connect(mapStateToProps)(Orderdetails);
