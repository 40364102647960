import React from 'react'
import {
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';



const useStyle = makeStyles({
  savebutton: {
    textTransform: "none",
    backgroundColor: "#007BB8",
    borderRadius: "2px",
    color: "white",
    "&:hover": {
      backgroundColor: "#007BB8",
    },
  },
  buttontext: {
    padding: "0.25em",
    fontWeight: 600,
  },
});

function BlueButton({text,handleChangeBlueButton}) {
  const classes = useStyle();
  return (
    <>
    <Button
                  disableRipple
                  data-testid='blue-btn'
                  fullWidth
                  disableElevation
                  className={classes.savebutton}
                  variant="contained"
                  onClick={handleChangeBlueButton}
                >
                  <Typography data-testid="label" variant="body2" className={classes.buttontext}>
                    {text}
                  </Typography>
      </Button>
    </>
  )
}

BlueButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleChangeBlueButton : PropTypes.func.isRequired
}

export default BlueButton
