import React from "react";
import {
  Typography,
  Grid,
  Paper,
  makeStyles
} from "@material-ui/core";
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "1em", 
    border: "1px solid #E9E9E9"  , 
    boxShadow: '2px 2px 7px rgba(205, 204, 220, 0.5)', 
    height : '100%',
    [theme.breakpoints.only('md')]: {
      height : '104%',
    },
    [theme.breakpoints.only('xs')]: {
      height : '95%',
    },
    [theme.breakpoints.only('sm')]: {
      height : '95%',
    },
    [theme.breakpoints.only('lg')]: {
      height : '98%',
    },
    [theme.breakpoints.only('xl')]: {
      height : '100%',
    },
  },
 
}));

function PaperCompOne({headline,row,items}) {
  const classes = useStyles();

  return (
    <Paper
      className={classes.container}
      elevation={0}
    >
      <Typography variant="subtitle1" style={{fontWeight:"bold" , color:"#222222"}}>{headline}</Typography>
        {row ? 
          <Grid container sm={12} md={12}>
            {items.map((item) => {
                return (
                    <>
                  <Grid container style={{marginTop:'1em'}}>
                  <Grid item sm={2} md={2}>
                    <img src={item.img} alt={item.alt} />
                  </Grid>
                  <Grid item sm={8} md={8}>
                    <Typography variant="body2" style={{ color:"#222222", fontWeight: 400 }}>
                      {item.text}
                    </Typography>
                  </Grid>
                  </Grid>
                </>
              );
            })}
          </Grid>
        :
        <Grid container style={{ gap: "0.25em" }} direction="column">
        {items.map((item) => {
            return (
            <>
              <Grid item style={{marginTop:"0.5em"}}>
              <Typography variant="body2" style={{ color:"#5E5E5E",lineHeight:"1em", fontWeight: 400 }}>
                  {item.key}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" style={{ color:"#222222",lineHeight:"1em", fontWeight: 400 }}>
                  {item.value}
                </Typography>
              </Grid>
            </>
          );
        })}
      </Grid>
        }
    </Paper>
  );
}

PaperCompOne.propTypes = {
  items: PropTypes.array,
  row: PropTypes.bool,
  headline: PropTypes.string,
};

export default PaperCompOne;
