import { Typography, Dialog, Grid } from "@material-ui/core";
import Question from "../../Assets/questionmark.svg";
import ArrowUp from "../../Assets/Arrowup.svg";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import React from "react";
const useStyle = makeStyles({
  Modalstyle: {
    backgroundColor: "#EBF8FF",
    border: "1px solid #7DD4FF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 3,
    padding: "2em",
  },
  headingStyle: {
    color: "#007BB8",
    fontWeight: 700,
  },
  textstyle: {
    marginTop: "1em",
    color: "black",
    fontWeight: 500,
  },
});

export const InformationModal = (props) => {
  const classes = useStyle();

  return (
    <React.Fragment>
      <Dialog fullWidth open={props.open} onClose={props.handleClose}>
        <Grid container direction="column" className={classes.Modalstyle}>
          <Grid item>
            <Grid container>
              <Grid item>
                <img src={Question} alt="error" />
              </Grid>
              <Grid item style={{ marginLeft: "1em" }}>
                <Typography className={classes.headingStyle}>
                  {props.heading}
                </Typography>
              </Grid>

              <Grid item style={{ marginLeft: "auto" }}>
                <img src={ArrowUp} onClick={props.handleClose} alt="error" style={{cursor:"pointer"}} />
              </Grid>
            </Grid>
          </Grid>
          {props.modaltext.map((text, index) => {
            return (
              <Grid item key={index}>
                <Typography variant="body2" className={classes.textstyle}>
                  {text.value}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};

InformationModal.propTypes = {
  open : PropTypes.bool,
  handleClose : PropTypes.func,
  heading: PropTypes.string,
  modaltext : PropTypes.arrayOf(PropTypes.object)
}