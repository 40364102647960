import React from 'react';
import { Grid, Paper,Button,makeStyles } from '@material-ui/core';
import IsolationIcon from '../../Assets/IsolationModeIcon.svg';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paperStyle: 
  {   
      margin:"auto",
      paddingTop:150,
      [theme.breakpoints.up('md')]: {
        width: "25%",
      },
      [theme.breakpoints.down('md')]: {
        width: "40%",
      },
      [theme.breakpoints.down('sm')]: {
        width: "50%",
      },
      [theme.breakpoints.down('xs')]: {
        width: "90%",
      },
  }
}));

// eslint-disable-next-line no-unused-vars
function Alreadyregistered(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
  <>
    <Grid align="center">
        <Paper elevation={0} className={classes.paperStyle} sx={{textAlign:"center"}}>
          <img src={IsolationIcon} alt="" srcSet=""/>
          <h1 data-testid="Header" style={{fontSize: 25,margin:0, marginBottom:25,width:"70%"}}>Your account is already created</h1>
          <Button data-testid="btn" variant="outlined" onClick={() => {navigate("/")}} size="large" fullWidth disableElevation style={{background:"#3D8541", color:"white", marginTop:"8px", fontSize:"12px", textTransform: "none"}} >{"Go to Signin"}</Button>
        </Paper>
    </Grid>
  </>
  );
}

export default Alreadyregistered;
