import { OnTokenExpire } from "../redux/middleware";
import { store } from "../redux/reducer/store";
import { onApiError } from "../redux/actions/actions";


import {updateToken } from "../redux/actions/actions";
import { refreshToken } from "./userOps";
import { getHeaders } from "./getHeaders";
//Function for handling API level errors
let reTryApis = []
let isTokenRefreshing = false
const handleError = (result,url,options,reTryCall) => {
  switch (result?.status) {
    case 401:
      isTokenRefreshing = true
     refreshToken(store.getState().token?.sessionId) .then((res) => res.json()).then((data)=>{
        store.dispatch(updateToken(data))
        isTokenRefreshing = false
      }).then(()=>{
        reTryCall()
      })
      
      break;

    case 502:
      store.dispatch(
        onApiError(result?.statusText || "Something went wrong,try again later.")
      );
      break;
  case 403:
    if(options?.method === "GET"){
      let postApis = reTryApis?.filter((item)=>item.options?.method !== "GET")
    reTryApis = [...postApis,{url:url,options:options}]
      
    }else{
    reTryApis = [...reTryApis,{url:url,options:options}]
    }
    if(!isTokenRefreshing){

    isTokenRefreshing = true

   store.dispatch(OnTokenExpire(()=>{
    reTryCall()
   }))
  }
    break;
    default:
      store.dispatch(
        onApiError(result?.statusText || "Something went wrong,try again later.")
      );
      break;
  }
};






//function for handling API Fetching As Middleware
export const handleFetch = (url, options) => {
  return new Promise(function (resolve) {
    fetch(`${url}`, options)
      .then(function (result) {
        if (!result.ok && result.status !== 200 && result.status !== 500 && result.status !== 404 && result.status !== 400) {
          //if token expired
          errorParser(result,url,options,(api,options)=>{
            return handleFetch(api,options)
             })
        } else {
          resolve(result);
          store.dispatch(onApiError(""));
        }
      })
      .catch((error) => {
        errorParser(error,url,options,(api,options)=>{
          return handleFetch(api,options)

          })
      });
  });
};

export const errorParser = (result,url,options,callback)=>{

  handleError(result,url,options,()=>{
    isTokenRefreshing = false

    reTryApis?.map((api)=>{

      let  headers= { ...api.options.headers, ...getHeaders() }
      // return handleFetch(api.url,{...api.options,...{headers:headers}}).then((res)=>{
      //   console.log("res",res)
      //   callback(res)
      // })
      callback(api.url,{...api.options,...{headers:headers}})

    })
    reTryApis = []
  });
}

