import React, { useEffect } from "react";
import { Grid, LinearProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import GreenButton from "../Buttons/GreenButton";
import NormalButton from "../Buttons/NormalButton";
import PropTypes from 'prop-types';
import { useState } from "react";



const useStyle = makeStyles({
  heading: {
    fontWeight: 600,
    color: "#222222",
  },
  text: {
    fontWeight: 600,
    color: "#222222",
    marginTop:"1em", 
    marginBottom:"1em" 
  },
});

function Modal2({loading,heading,para,Column,buttonWhitetext,handleChangeWhiteButton,buttonGreentext,handleChangeGreenButton}) {
  const classes = useStyle();
  const [isLoading,setIsLoading] = useState(false)

  useEffect(()=>{
    setIsLoading(loading)
  },[loading])

  return (
    <Grid container style={{ padding: "3em", borderRadius:5 }} direction="column">
      <Grid item align="center" style={{marginBottom:"2em"}}>
        <Typography variant="h6" className={classes.heading}>
          {heading}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {para}
        </Typography>
      </Grid>

 {isLoading && <div style={{marginBottom:"10px"}}>
      Deleting...
      <LinearProgress />
      </div>}
     {Column === false ? 
     <Grid item >
      <Grid container spacing={3} columns={{ sm: 12, md: 12 }} justifyContent="center" >
        <Grid item md={6} sm={6} >
        <NormalButton text={buttonWhitetext} handleChangeNormalButton={handleChangeWhiteButton} disable={isLoading}/>
          </Grid>
          <Grid item md={6} sm={6} >
          <GreenButton text={buttonGreentext} handleChangeGreenButton={handleChangeGreenButton} disable={isLoading}/>
          </Grid>
        </Grid> 
      </Grid> 
      : 
      <Grid container direction="column-reverse" style={{gap:"1em",width:"60%",margin:'auto'}}>
        <Grid item>
        <NormalButton text={buttonWhitetext} handleChangeNormalButton={handleChangeWhiteButton} disable={isLoading}/>
        </Grid>
        <Grid item>
        <GreenButton text={buttonGreentext} handleChangeGreenButton={handleChangeGreenButton} disable={isLoading}/>
        </Grid>
      </Grid>
      } 
      
     
      
    </Grid>
  );
}

Modal2.propTypes = {
  heading: PropTypes.string,
  para : PropTypes.string,
  Column: PropTypes.bool,
  buttonWhitetext: PropTypes.string,
  handleChangeWhiteButton: PropTypes.func,
  buttonGreentext:PropTypes.string,
  handleChangeGreenButton:PropTypes.func
}

export default Modal2;
