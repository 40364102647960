import {
  displayOrderId,
  normalizedDate,
  showOrderStatusWardlestaff,
  showOrderStatus,
  visibilityicon,
  visibilityicon1,
  normalizedEntries,
  normalizedEntries2,
  normalizedEntries1,
  captlize
} from "./orderUtilities";
import DoneIcon from "@material-ui/icons/Done";

import { getUserDetails } from "../api/getUserDetails";
import React from "react";
import ErrorIcon from '@material-ui/icons/Error';

import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";



export const ModifyList = (list, recordType, orderdetailsId, setOrderdetailsId, handleOpen, Audittrial, EditFunction, PrintFunction, onStatusViewed) => {
  const role = getUserDetails().role;
  const Email = getUserDetails().email;

  const showAssociationStatus = (item) => {
    return (
      <>

        {item?.association_status?.status === "Success" && <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 8.8,
            borderRadius: 4,
            color: "#3D8541",
            background: "#E9F5EA",
          }}
          onClick={() => onStatusViewed(item)}

        >

          <p style={{ display: "flex", margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
            <DoneIcon style={{ color: "#3D8541", width: 20, height: 20 }} />
            {item?.association_status?.status}
          </p>
        </div>}
        {item?.association_status?.status !== "Success" && <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: 3,
            padding: 3,
            borderRadius: 4,
            color: "#FFF0F0",
            background: "#BB4035",
          }}
        >
          <ErrorIcon />

          <p style={{ margin: 0, padding: 0, marginLeft: 3, fontSize: 13 }}>
            {item?.association_status?.status}
          </p>
        </div>}
        <p style={{color: "blue", cursor: "pointer", textDecoration: "underline"}}
          onClick={() => onStatusViewed(item)}>View details</p>

      </>)

  }


  const ShowRole = (role) => {
    return (
      <React.Fragment>
        {role === "fp@wardleUser" && "Standard"}
        {role === "fp@wardleAdmin" && "admin"}
        {role === "fp@wardleStaff" && "Staff"}
        {role === "fp@fp@wardleHqAdmin" && "Hq Manager"}
        {role === "fp@fp@wardleHqUser" && "Hq Standard"}

      </React.Fragment>
    );
  };

   


  if (recordType === "order" && (role === "fp@wardleStaff")) {
    const items = list?.map((item) => {
      return {
        View: visibilityicon(item, orderdetailsId, setOrderdetailsId, handleOpen),
        "Order number": displayOrderId(item),
        "Order date": normalizedDate(item),
        "Account number": item.pharmacy_account_number,
        "Pharmacy name": item.pharmacy_name,
        "Postcode": item.pharmacy_post_code,
        "Patient name": item.patient_name ? item.patient_name :
          item.patient ? item.patient.first_name + " " + item.patient.last_name
            : "-",
        "Order status": showOrderStatusWardlestaff(item.record_status?.toLowerCase())
      };
    }
    )
    return items
  }
  else if (recordType === "order" && (role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser")) {
    const items = list?.map((item) => {
      return {
        View: visibilityicon(item, orderdetailsId, setOrderdetailsId, handleOpen),
        "Order number": displayOrderId(item),
        "Order date": normalizedDate(item),
        "Account number": item.pharmacy_account_number,

        "Pharmacy name": item.pharmacy_name,
        "Patient name": item.patient_name ? item.patient_name :
          item.patient ? item.patient.first_name + " " + item.patient.last_name
            : "-",
        "Order status": showOrderStatusWardlestaff(item.record_status?.toLowerCase())
      };
    }
    )
    return items
  }
  else if (recordType === "order" && (role === "fp@wardleAdmin" || role === "fp@wardleUser")) {
    const items = list?.map((item) => {
      return {
        "View": visibilityicon1(item, orderdetailsId, setOrderdetailsId, handleOpen),
        "Order number": displayOrderId(item),
        "Order date": normalizedDate(item),
        "Pharmacy name": item.pharmacy_name,
        "Postcode": item.pharmacy_post_code,
        "Patient name": item.patient_name ? item.patient_name :
          item.patient ? item.patient.first_name + " " + item.patient.last_name
            : "-",
        "Order status": showOrderStatus(item.record_status),
      };
    }
    )
    return items
  }

  else if (Audittrial === true) {
    const items = list
      .filter((list) => list.email != Email)
      ?.map((item) => {
        return {
          "Date": normalizedEntries(item.created_date),
          "TIme": normalizedEntries1(item.created_date),
          "Name": item.first_name + " " + item.last_name,
          "Email address": item.email,
          "Status": captlize(item.record_status) || item.record_status,
          "Role": role === 'fp@wardleAdmin' ? "Admin" : "Standard",
        };
      }
      )
    return items
  }
  else if (recordType === "pharmacy_group_association" && (role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser")) {
    const items = list
      .filter((list) => list.email != Email)
      ?.map((item) => {
        return {
          "Pharmacy name": item.pharmacy_name,
          "Account number": item.pharmacy_group_account_number,
          "Postcode": item.pharmacy_post_code,
          "Admin name": item.created_by,
          "Email address": item.admin_user_emails,
        };
      }
      )
    return items
  }
  else if (recordType === "user" && role === "fp@wardleAdmin") {
    let items = list?.filter(item => item.email !== Email)
    items = items.map((item) => {
      return {
        "Edit": EditFunction(item),
        "Name": item.first_name + " " + item.last_name,
        "Email address": item.email,
        "Status": item.record_status === ("link sent" || "Link sent" || "Link Sent") ? "Link Sent" : null || item.record_status === ("registered" || "Registered") ? "Registered" : null,
        "Role": item.role === 'fp@wardleAdmin' ? "Admin" : "Standard"
      };
    }
    )
    return items
  }
  else if (recordType === "user" && role === "fp@wardleStaff") {
    const items = list
      .filter((list) => list.email != Email && list.role != 'fp@wardleStaff' && list.role != 'fp@wardleHqAdmin' && list.role != 'fp@wardleHqUser')
      ?.map((item) => {
        return {

          "Edit": EditFunction(item),
          "Name": item.first_name + " " + item.last_name,
          "Email address": item.email,
          "Pharmacy name": item.pharmacy_name,
          "Postcode": item.pharmacy_post_code,
          "Account number": item.pharmacy_account_number,
          "Status": item.record_status === ("link sent" || "Link sent" || "Link Sent") ? "Link Sent" : null || item.record_status === ("registered" || "Registered") ? "Registered" : null,
          "Role": ShowRole(item.role),
        };
      }
      )
    return items
  } else if (recordType === "user" && (role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser")) {
    const items = list
      .filter((list) => list.email != Email)
      ?.map((item) => {
        return {
          "Edit": EditFunction(item),
          "Name": item.first_name + " " + item.last_name,
          "Email address": item.email,
          "Account number": item.pharmacy_account_number,
          "Pharmacy name": item.pharmacy_name,
          "Postcode": item.pharmacy_post_code,
          "Status": item.record_status === "link sent" ? "Link Sent" : null || item.record_status === "registered" ? "Registered" : null,
          "Role": ShowRole(item.role),
        };
      });
    return items
  }

  else if (recordType === "pharmacy_group" && role === "fp@wardleStaff") {
    const items = list
      .filter((list) => list.email != Email && list.role != 'fp@wardleHq')
      ?.map((item) => {
        return {
          "View": EditFunction(item),
          "Group ID": item.pharmacy_group_account_number,
          "Pharmacy group": item.pharmacy_group_name,
          "Postcode": item.pharmacy_group_post_code,
          "Associated pharmacies": item.associated_pharmacies,
          "Contact email": item.manager_email
        };
      }
      )
    return items
  }
  else if (recordType === "pharmacy_association" && (role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser")) {
    const items = list
    .filter((list) => list.email != Email)
    ?.map((item) => {
        console.log("itemsss", items)
        return {
          "Edit": EditFunction(item),
          "Pharmacy name": item.pharmacy_name,
          "Account number": item.pharmacy_account_number,
          "Postcode": item.pharmacy_post_code,
          "Admin name": item.created_by,
          "Email address": item.admin_user_emails.join(", "),
          "Association status": showAssociationStatus(item)
        };
      }
      )
    return items
  }
  else if (recordType === "stock_amnesty" && (role === "fp@wardleStaff")) {
    const items = list?.map((item) => {
      let d = new Date(item.modified_time ? item.modified_time : item.created_date_string)
      let formated = d ? (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getFullYear().toString()) : d
      return {
        "View": EditFunction(item),
        "Date": formated,
        "Time": normalizedEntries2(item.modified_time ? item.modified_time : item.created_date_string),
        "Pharmacy name": item.pharmacy_name,
        "Postcode": item.pharmacy_post_code,
        "Account number": item.pharmacy_account_number,
        "Phone": item.phone_number,
        "Print": PrintFunction(item)
      };
    }
    )
    return items
  }
  else if (recordType === "stock_amnesty" && (role === "fp@wardleAdmin" || role === "fp@wardleUser")) {
    const items = list?.map((item) => {
      let d = new Date(item.modified_time ? item.modified_time : item.created_date_string)
      let formated = d ? (d.getDate().toString().length == 2 ? d.getDate().toString() : "0" + d.getDate().toString()) + "-" + ((d.getMonth() + 1).toString().length == 2 ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString()) + "-" + (d.getFullYear().toString()) : d
      return {
        "View": EditFunction(item),
        "Submission Date": formated,
        "Collection Address": item.collection_address,
        "Print": PrintFunction(item),
      };
    }
    )
    return items
  }
  else if (recordType === "pharmacy_association" && (role === "fp@wardleStaff")) {
    const items = list?.map((item) => {
      return {
        "Delete": EditFunction(item),
        "Edit": PrintFunction(item),
        "Account Number": item.pharmacy_account_number,
        "Pharmacy Name": item.pharmacy_name,
        "Postcode": item.pharmacy_post_code,
        "Email Address": Emaildisplay(item.admin_user_emails),
        "Association Status": showAssociationStatus(item)

      };
    }
    )
    return items
  }
  else if (recordType === "commission_statement" && (role === "fp@wardleAdmin" || role === "fp@wardleUser")) {
    const items = list?.map((item) => {
      let commission_date= item.commission_cycle; // The 0 there is the key, which sets the date to the epoch

      if(item.commission_date){
        commission_date= new Date(item.commission_date);
        commission_date= commission_date.getDate()+"/"+(commission_date.getMonth()+1)+"/"+commission_date.getFullYear();
  
      }
      return {
        "Reference": commission_date,
        "Commission total": `£${item.commission_amount_due}`,
        "View/Download": EditFunction(item)
      };
    }
    )
    return items
  }
  else if (recordType === "commission_statement" && (role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser")) {
    const items = list?.map((item) => {
      let commission_date= item.commission_cycle; // The 0 there is the key, which sets the date to the epoch

      if(item.commission_date){
        commission_date= new Date(item.commission_date);
        commission_date= commission_date.getDate()+"/"+(commission_date.getMonth()+1)+"/"+commission_date.getFullYear();
  
      }
      return {
        "Reference": commission_date,
        "Account number": item.pharmacy_account_number,
        "Pharmacy name": item.pharmacy_name,
        "Commission total": `£${item.commission_amount_due}`,
        "View/Download": EditFunction(item),
      };
    }
    )
    return items
  }
  else {
    return list;
  }
};
export const Emaildisplay = (email) => {
  let rowCount = 3
  let rows = []
  let emailCount = email.length

  let columnCount = Math.trunc(emailCount / rowCount) + (emailCount % rowCount === 0 ? 0 : 1)

  for (let i = 0; i < 3; i++) {
    let row = []
    for (let j = 0; j < columnCount; j++)
      if (((i + 1) * j) < emailCount) {
        row.push(email[columnCount * i + j])
      }
    // row.push(email[rowCount*j+i])}

    rows.push(row)
  }

  return <Table>
    <TableBody style={{ border: "none" }}>
      {rows.map((row, index) => {
        return <TableRow key={index} style={{ marginBottom: 25, border: "none", }}>
          {row.map((item, i) => {
            return <TableCell key={i} align="center" style={{ margin: 5, padding: 5, border: "none", }}>
              {item}
            </TableCell>
          })}
        </TableRow>
      })}

    </TableBody>
  </Table>

}
