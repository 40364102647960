import { Typography , Grid} from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import PropTypes from 'prop-types';


const useStyle = makeStyles({
    months: (props) => props.disable(props.text)? {
        padding:"0.5em",
        color:"#e0e0e0",
    } :  props.selected?  
    {
        padding:"0.5em",

        border:"1px solid #E9F5EA",
        borderRadius:"20px",
        backgroundColor:'#E9F5EA',
        color:"#3D8541",
    }
    :
    {
        padding:"0.5em",
        "&:hover":{
            border:"1px solid #E9F5EA",
            borderRadius:"20px",
            backgroundColor:'#E9F5EA',
            color:"#3D8541",
        },
    },
})


const MonthComponent = (props) => {
    const classes = useStyle(props);
    
  return (
    <Grid>
        <Typography className={classes.months} onClick={()=>{props.disable(props.text)?"":props.handleClick(props.index)}}>{props.text}</Typography>
    </Grid>
  )
}

MonthComponent.propTypes = {
    selected : PropTypes.bool.isRequired,
    handleClick : PropTypes.func.isRequired,
    text : PropTypes.string.isRequired,
    index : PropTypes.number.isRequired
}

export default MonthComponent