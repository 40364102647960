import * as React from 'react';
import { Grid, Paper,Button,Typography,makeStyles,Hidden } from '@material-ui/core';
import OpenMail from '../../Assets/OpenMail.svg'
import Grnmail from '../../Assets/GrnMail.svg'
import Help from '../../Assets/questionmark.svg'
import ArrowDwn from '../../Assets/Arrowdown.svg'
import { useNavigate } from 'react-router-dom'; 
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useLocation } from 'react-router';
import { ForgotPassStart } from '../../api/userOps';
import { showSuccessmsg } from '../../Components/notification/Notification';
import { InformationModal } from '../../Components/Modal/InformationModal';

const useStyles = makeStyles((theme) => ({
  maincontainer:{
        margin: "auto",
        padding :10,
        [theme.breakpoints.up('md')]: {
          width: "27%",
        },
        [theme.breakpoints.down('md')]: {
          width: "40%",
        },
        [theme.breakpoints.down('sm')]: {
          width: "55%",
        },
        [theme.breakpoints.down('xs')]: {
          width: "90%",
          padding:"4%",
        },
      },
  innercontainer:{
    padding:15, 
    display:"flex",
    border: "1px solid #52B057",
    boxSizing: "border-box",
    boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", 
    borderRadius: 4, 
    overflow:"clip",
    marginBottom:"4%"
  },
  backbutton:{
    cursor: "pointer",
    textDecoration:"none", 
    color:"#5E5E5E", 
    display:"flex", 
    alignItems:"center", 
    paddingTop:130,
    [theme.breakpoints.up('md')]: {
          marginLeft:150,
        },
        [theme.breakpoints.down('md')]: {
          marginLeft:150,
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft:5,
          paddingLeft:"4%",
        },
      }
}))

function Checkemail() {
  let navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.email;
  const classes = useStyles()

  const modaltext = [
    { value: "Try resending the email and check your junk or spam folders. If you still can't see an email from us after a few minutes, use the help button at the top of the screen."},]

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [resendSuccess,setResendSuccess] = React.useState(false)
  const [disableResend,setDisableResend] = React.useState(false)
  const resendEmail = async e => {
    e.preventDefault()
    setDisableResend(true)
    setResendSuccess(false)
    ForgotPassStart(email)
      .then((res) => res.json())
      .then(() => {
        setResendSuccess(true)
        setDisableResend(false)
        navigate("/checkmail",{state:{email:email}});
      },
      error => {
        console.log(error)
      });
  }
  
    return (
      <>
            <Grid className={classes.backbutton} >
              <ArrowBackIcon onClick={() => {navigate("/forgotpasswordemail",{state:{email:email}});}} />
              <Typography data-testid="back-button" onClick={() => {navigate("/forgotpasswordemail",{state:{email:email}});}}>Back</Typography>
            </Grid>
            
      <Grid align="center">
        <Paper elevation={0} className={classes.maincontainer}  >
                <img src={OpenMail} alt="" srcSet="" className="center" />

                <Typography variant="h4" data-testid="Header" style={{margin:0, marginBottom:25,width:"70%", fontWeight:700}}>Check your email to reset your password</Typography>
                
                
                <Paper elevation={0} className={classes.innercontainer} >
                  <Hidden smDown>
                    <img src={Grnmail} alt="error" />
                  </Hidden>
                  
                  <Grid style={{marginLeft:15}}>
                  <Typography style={{fontSize:"20px",textAlign:"start",}}>We’ve sent an email to <span style={{textDecoration:"none",color:"blue"}}> {email} </span>with a secure link to reset your password.</Typography>
                  </Grid>
                </Paper>

                <Button onClick={handleOpen} variant="contained" size="large" disableElevation style={{background: "#E8F8FF",marginTop:17, color:"#0066BE",borderRadius: '6px',marginBottom:17, fontSize:"18px", textTransform: "none", display:"flex", justifyContent:"space-between", border: "1px solid #7DD4FF"}} fullWidth>
                      <Grid style={{display:"flex", justifyContent:"flex-start", margin:0,padding:0}}>
                        <img src={Help} alt="error" />
                        <Typography style={{margin:0,padding:0, marginLeft:10, fontSize:16}}>Didn’t get an email?</Typography>
                      </Grid>
                      <img src={ArrowDwn} alt="error" />
                </Button>

                
                <div data-testid="successmessage">
                  {resendSuccess && showSuccessmsg(`Email has been sent successfully on ${email}`)}
                </div>

                <Button data-testid="btn" disabled={disableResend} variant="outlined" onClick={(e) => {resendEmail(e);}} size="large" fullWidth disableElevation style={{background: disableResend?"gray":"white",color:"black",marginTop:"10px", marginBottom:"17px", border: "1.5px solid #07283C", fontSize:"18px", textTransform: "none"}} type="submit" >Resend email</Button>
                
                <InformationModal
                  handleClose={handleClose} 
                  open={open} 
                  heading={"Didn’t get an email?"}
                  modaltext={modaltext}
                  />
        </Paper>
    </Grid>

    </>
    );
  }
  
  export default Checkemail;