import { CircularProgress, Dialog, Grid, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/KeyboardArrowLeft";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { printPreview } from "../../../Utilities/fileUtilitties.js";

import { useLocation, useNavigate } from "react-router-dom";
import {
  CreateStockAmnestyList,
  UpdateStockAmnestyDetails,
} from "../../../api/stockamnestyops";
import Termsncondition from "../../../Assets/amco_terms_and_conditions.pdf";
import Accordion from "../../../Components/Accordions/Accordion";
import BlueButton from "../../../Components/Buttons/BlueButton";
import GreenButton from "../../../Components/Buttons/GreenButton";
import GreyButton from "../../../Components/Buttons/GreyButton";
import CustomizedCheckbox from "../../../Components/Checkbox/CustomCheckBox";
import Heading from "../../../Components/Headers";
import Modal2 from "../../../Components/Modal/Modal2";
import Modal3 from "../../../Components/Modal/Modal3";
import SuccessModal from "../../../Components/Modal/SuccessModal";
import {
  showErrMsg,
  showSuccessmsg,
} from "../../../Components/notification/Notification";
import Table from "../../../Components/Table/ProductTable";
import TextFieldComponent from "../../../Components/TextField/index3";
import {
  isEmail,
  isEmpty,
  isPhoneValid,
  isSpace,
} from "../../../Components/validation/Validation";
import { logEventWithProperties } from "../../../analytics/Analytics.js";
const useStyles = makeStyles((theme) => ({
  backbutton: {
    textDecoration: "none",
    color: "#5E5E5E",
    display: "flex",
    alignItems: "center",
    paddingTop: 130,
    [theme.breakpoints.up("md")]: {
      marginLeft: 150,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: 150,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      paddingLeft: "4%",
    },
  },
  addItemList: {
    [theme.breakpoints.only("md")]: {
      width: "50%",
      height: "67%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "100%",
      marginTop: "0px",
      height: "100vh",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
      marginTop: "0px",
      height: "100vh",
    },
    [theme.breakpoints.only("lg")]: {
      width: "50%",
      height: "71%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "45%",
      height: "54%",
    },
  },
}));

function index(props) {
  const classes = useStyles();
  const { userDetails } = props;
  const navigate = useNavigate();
  const location = useLocation();
  let item = location?.state?.item;
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [loading, setLoading] = useState(false);
  let id = item?.id ? location.state.item.id : "";
  const email = item?.email ? location.state.item.email : userDetails.email;
  const firstName = item?.first_name ? location.state.item.first_name : userDetails.first_name;
  const lastName = item?.last_name ? location.state.item.last_name : userDetails.last_name;
  const pharmacyAccountNumber = item?.pharmacy_account_number ? location.state.item.pharmacy_account_number : userDetails.pharmacy_account_number;
  const pharmacyName = item?.pharmacy_name ? location.state.item.pharmacy_name : userDetails.pharmacy_name;
  const collectionAddress = item?.collection_address ? location.state.item.collection_address : userDetails.collection_address;
  const phoneNumber = item?.phone_number ? location.state.item.phone_number : userDetails.phone_number;
  const pharmacyPostCode = item?.pharmacy_post_code ? location.state.item.pharmacy_post_code : userDetails.pharmacy_post_code;
  const [check, setCheck] = useState(item ? true : false);
  const [indexVal, setIndexVal] = useState(null);
  const [Error, setError] = useState("");
  const [Pageerror, setPageerror] = useState("");
  const [Success, setSuccess] = useState("");

  const initialState = [
    {
      text: "",
      type: "firstName",
      value: `${firstName}`,
      format: "normal",
      row: false,
      err: false,
      placeholder: "First name",
      disable: false,
      helperText: "",
    },
    {
      text: "",
      type: "lastName",
      value: `${lastName}`,
      format: "normal",
      row: false,
      err: false,
      placeholder: "Last name",
      disable: false,
      helperText: "",
    },
    {
      text: "Pharmacy phone number:",
      type: "pharmacyPhoneNumber",
      value: phoneNumber,
      format: "normal",
      row: false,
      err: false,
      disable: false,
      helperText: "",
    },
    {
      text: "Email address:",
      type: "email",
      value: email,
      format: "normal",
      row: false,
      err: false,
      disable: false,
      helperText: "",
    },
  ];
  const modalState = [
    {
      label: "Quantity",
      type: "quantity",
      value: "",
      row: true,
      format: "dropdown",
      innerformat: "Qty",
    },
    {
      label: "Pack size",
      type: "quantityType",
      value: "",
      row: true,
      format: "dropdown",
      innerformat: "Singles",
    },
  ];
  const additemstate = [
    {
      text: "Enter product name, product code",
      type: "productDesc",
      value: "",
      format: "autocomplete",
      row: false,
      err: false,
    },
    {
      text: "Expiry date",
      type: "expiryDate",
      value: new Date(new Date().getTime() + 182 * 24 * 60 * 60 * 1000).toLocaleString('default', { month: 'long' }).toString() + "-" + (new Date(new Date().getTime() + 182 * 24 * 60 * 60 * 1000).getFullYear().toString()),
      format: "date",
      row: false,
      err: false,
      minDate: new Date(new Date().getTime() + 182 * 24 * 60 * 60 * 1000).toLocaleString('default', { month: 'long' }).toString() + "-" + (new Date(new Date().getTime() + 182 * 24 * 60 * 60 * 1000).getFullYear().toString()),
    },
  ];
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const [dropdown, setDropDown] = useState(modalState);
  const [state, setState] = useState(initialState);
  const [additem, setAdditem] = useState(additemstate);
  const [itemtable, setItemtable] = useState(
    item
      ? isJsonString(item?.order_items)
        ? JSON.parse(item?.order_items)
        : []
      : []
  );

  useEffect(() => {
    logEventWithProperties("Admin visits start new request for stock amnesty", userDetails?.id)
  }, [])
  const modifylist = (itemlists) => {
    if (item) {
      const newList = itemlists.map((items) => {
        return {
          productDesc: items.productDesc,
          singles: items.quantity,
          packSize: items.quantityType,
          expiryDate: items.expiryDate,
        };
      });
      return newList;
    } else {
      const newList = itemlists.map((items) => {
        return {
          productDesc: items.productDesc,
          singles: items.quantity,
          packSize: items.quantityType,
          expiryDate: items.expiryDate,
          Edit: items.Edit,
        };
      });
      return newList;
    }
  };

  const handleChange = (event) => {
    setCheck(event.target.checked);
  };
  const handleContactDetails = () => {
    setState((prev) =>
      prev.map((item) => ({
        ...item,
        err: false,
        helperText: "",
      }))
    );
    if (
      isEmpty(state[0].value) ||
      isEmpty(state[1].value) ||
      isEmpty(state[2].value) ||
      isEmpty(state[3].value)
    ) {
      setState((prev) =>
        prev.map((item) => ({
          ...item,
          err: true,
          helperText: "Please fill all details",
        }))
      );
    } else if (!isPhoneValid(state[2].value)) {
      setState((prev) => {
        let list = prev.map((item) => {
          return item.type === "pharmacyPhoneNumber"
            ? {
              ...item,
              err: true,
              helperText: "Please give valid phone number",
            }
            : item;
        });
        return list;
      });
    } else if (!isEmail(state[3].value)) {
      setState((prev) => {
        let list = prev.map((item) => {
          return item.type === "email"
            ? {
              ...item,
              err: true,
              helperText: "Please give valid email address",
            }
            : item;
        });
        return list;
      });
    } else {
      setOpen2(true);
    }
  };

  const handleChangeTextField = (event, type, format) => {
    if (format === "normal") {
      setState((prev) => {
        let list = prev.map((item) => {
          return item.type === type
            ? { ...item, value: event.target.value }
            : item;
        });
        return list;
      });
    } else if (format === "autocomplete") {
      setState((prev) => {
        let list = prev.map((item) => {
          return item.type === type ? { ...item, value: event } : item;
        });
        return list;
      });
    }
  };
  const handleChangeDropDown = (event, type) => {
    setDropDown((prev) => {
      let list = prev.map((item) => {
        return item.type === type
          ? { ...item, value: event.target.value }
          : item;
      });
      return list;
    });
  };
  const handleadditem = (event, type, format) => {
    if (format === "normal") {
      setAdditem((prev) => {
        let list = prev.map((item) => {
          return item.type === type
            ? { ...item, value: event.target.value }
            : item;
        });
        return list;
      });
    } else if (format === "autocomplete") {
      setAdditem((prev) => {
        let list = prev.map((item) => {
          // item.type === 'prodcode' ? { ...item, value: event } : item
          return item.type === type ? { ...item, value: event } : item;
        });
        return list;
      });
    } else if (format === "date") {
      let date =
        new Date(event).getFullYear().toString() +
        "-" +
        ((new Date(event).getMonth() + 1).toString().length == 2
          ? (new Date(event).getMonth() + 1).toString()
          : "0" + (new Date(event).getMonth() + 1).toString());
      let d = new Date(date)
      let formated = d ? (d.toLocaleString('default', { month: 'long' }).toString()) + "-" + (d.getFullYear().toString()) : d
      setAdditem((prev) => {
        let list = prev.map((item) => {
          return item.type === type ? { ...item, value: formated } : item;
        });
        return list;
      });
    }
  };
  const handleChangeGreenButtonModalOne = () => {
    itemtable.splice(indexVal, 1);
    setOpen1(false);
  };
  const handleEdit = (index) => {
    setIndexVal(index);
    Object.entries(itemtable[index]).forEach(([key, value]) => {
      additem.map((item) => {
        item.type === key ? (item.value = value) : undefined;
      });
      dropdown.map((item) => {
        item.type === key ? (item.value = value) : undefined;
      });
    });
    setOpen3(true);
  };
  const handleCross = (index) => {
    setIndexVal(index);
    setOpen1(true);
  };
  const toobjectformat = (objectcontainer, dropdowncontainer) => {
    let newObj = {};
    objectcontainer.map((items) => {
      newObj[`${items.type}`] = `${items.value}`;
    });
    dropdowncontainer.map((items) => {
      newObj[`${items.type}`] = `${items.value}`;
    });
    newObj["Edit"] = "img";
    return newObj;
  };
  const handleeditRequest = (type) => {
    setLoading(true);
    let newObj =
      type === "edit"
        ? {
          updateValue: itemtable,
          id: id,
          email: email,
          modifiedBy: email,
          updateKey: "order_items",
        }
        : {
          updateValue: "order cancelled",
          id: id,
          email: email,
          modifiedBy: email,
          updateKey: "record_status",
        };
    UpdateStockAmnestyDetails(newObj)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          return setPageerror(data.error.message);
        } else {
          setSuccess(
            "Request successfully sent"
          );
          setPageerror("");
          setLoading(false);
          setTimeout(() => {
            navigate("/stockamnestyhome");
          }, 1500);
        }
        if (type === "Cancel") {
          navigate("/stockamnestyhome");
          setOpen(false);
        }
      });
  };
  const handlesubmit = () => {
    setLoading(true);
    let newObj = {
      orderItems: itemtable,
      pharmacyAccountNumber: pharmacyAccountNumber,
      pharmacyName: pharmacyName,
      firstName: state[0].value,
      phoneNumber: state[2].value,
      lastName: state[1].value,
      collectionAddress: collectionAddress,
      email: state[3].value,
      pharmacyPostCode: pharmacyPostCode,
      orderType: "stock_amnesty",
      orderStatus: "request submitted",
    };

    logEventWithProperties("Stock amnesty request submitted", {
      "orderType": "stockamnesty",
      "pharmacyAccountNumber": pharmacyAccountNumber,
      "userId": userDetails.id
    })
    CreateStockAmnestyList(newObj)
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setLoading(false);
          logEventWithProperties("Stock amnesty request failed", 
            {
              "orderType": "stockamnesty",
              "pharmacyAccountNumber": pharmacyAccountNumber,
              "orderItems": itemtable,

              "userId": userDetails.id
            })

          return setPageerror(data.error.message);
        } else {
          setSuccess(
            "Request successfully sent"
          );
          logEventWithProperties("Stock amnesty request successfully sent", {
            "orderType": "stockamnesty",
            "pharmacyAccountNumber": pharmacyAccountNumber,
            "orderItems": itemtable,

            "userId": userDetails.id
          })

          setLoading(false);
          setPageerror("");
          setTimeout(() => {
            navigate("/stockamnestyhome");
          }, 1500);
        }
      });
  };
  const handleaddproduct = () => {
    if (itemtable.length <= 19) {
      {
        additem.every(
          (items) => !isEmpty(items.value) || !isSpace(items.value)
        ) && dropdown.every((items) => !isEmpty(items.value))
          ? setItemtable([...itemtable, toobjectformat(additem, dropdown)]) ||
          setOpen4(true) ||
          setError("")
          : setError("Check you’ve filled in all of the information above.");
      }
      setTimeout(() => {
        setOpen4(false);
      }, 2000);
      setDropDown(modalState);
      setAdditem(additemstate);
    } else {
      setError("Sorry , You can not add more than 20 items.");
    }
    setOpen2(false)
  };
  const handleEditItems = () => {
    itemtable.splice(indexVal, 1, toobjectformat(additem, dropdown));
    setDropDown(modalState);
    setAdditem(additemstate);
    setOpen3(false);
  };
  const onEditclose = () => {
    setDropDown(modalState);
    setAdditem(additemstate);
    setOpen3(false);
  };

  return (
    <>
      {loading === false && (
        <Grid className={classes.backbutton}>
          <ArrowBackIcon
            onClick={() => {
              navigate("/stockamnestyhome");
            }}
            style={{ cursor: "pointer" }}
          />
          <Typography
            data-testid="back-button"
            onClick={() => {
              navigate("/stockamnestyhome");
            }}
            style={{ cursor: "pointer" }}
          >
            Back
          </Typography>
        </Grid>
      )}
      <Grid
        container
        direction="column"
        lg={4}
        md={5}
        sm={8}
        xs={11}
        style={
          loading
            ? { gap: "1em", margin: "auto", opacity: 0.5 }
            : { gap: "1em", margin: "auto" }
        }
      >
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <div data-testid="message">
            {Pageerror && showErrMsg(Pageerror)}
            {Success && showSuccessmsg(Success)}
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Heading
            heading="Stock Amnesty"
            texts={[
              "Enter the details of the stock you would like to return. All stock returned must have six months until the expiry date. Once you’re happy with the information, accept the terms and conditions before submitting your request. Once the request is sent, we will let you know if you are able to return the items.",
            ]}
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            variant="body1"
            style={{ color: "#222222", fontWeight: 700 }}
          >
            User details
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Accordion
            heading="View pharmacy details"
            items={[
              { key: "Contact name:", value: `${firstName}${" "}${lastName}` },
              { key: "Email address:", value: `${email}` },
              { key: "Account number:", value: `${pharmacyAccountNumber}` },
              { key: "Pharmacy name:", value: `${pharmacyName}` },
              { key: "Pharmacy phone number:", value: `${phoneNumber}` },
              { key: "Collection address:", value: `${collectionAddress}` },
              { key: "Pharmacy post code:", value: `${pharmacyPostCode}` },
            ]}
          />
        </Grid>
        {item ? null
          :
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextFieldComponent
              items={state}
              handleChange={handleChangeTextField}
            />
          </Grid>
        }
        <Grid item lg={12} md={12} sm={12} xs={12}>
          {item ?
            null
            :
            <BlueButton
              role="first"
              text="Add item"
              // handleChangeBlueButton={() => setOpen2(true)}
              handleChangeBlueButton={() => handleContactDetails()}
            />}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            variant="body1"
            style={{ color: "#222222", fontWeight: 700 }}
          >
            Product list
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Table
            theads={item ?
              [
                "Product Name",
                "Total Quantity In Singles",
                "Pack Size",
                "Expiry Date",
              ]
              : [
                "Product Name",
                "Total Quantity In Singles",
                "Pack Size",
                "Expiry Date",
                "Edit",
              ]}
            lists={modifylist(itemtable)}
            handleEdit={(index) => handleEdit(index)}
            handleCross={(index) => handleCross(index)}
          />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          align="center"
          style={{
            border: "1px solid #E9E9E9",
            boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)",
            borderRadius: "4px",
            // padding: "0.5em",
          }}
        >
          {item ? null : <CustomizedCheckbox
            text={
              <p>
                Accept the{" "}
                <a
                  href={Termsncondition}
                  target="_blank"
                  style={{ color: "#007BB8" }}
                  rel="noreferrer"
                >
                  terms and conditions
                </a>{" "}
                for stock returns as per Wardles policy
              </p>
            }
            state={check}
            handleChange={handleChange}
          />}
        </Grid>
        {itemtable.length > 0 && check === true && loading === false ? (
          item ?
            <GreyButton
              text="Print form"
              symbol={true}
              handleChangeGreyButton={() => printPreview(`${item["base64_encoded_form_content"]}`)}
            />
            : (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <GreenButton
                  text="Submit request"
                  handleChangeGreenButton={() => handlesubmit()}
                />
              </Grid>
            )
        ) : (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <GreyButton text="Submit request" disable />
          </Grid>
        )}

      </Grid>
      <Dialog open={open} fullWidth>
        <Modal2
          heading="Would you like to cancel your request?"
          para="By removing your request you are confirming that you no longer want to return any stock. Are sure you want to continue?"
          Column={true}
          buttonWhitetext="Cancel"
          handleChangeWhiteButton={() => setOpen(false)}
          buttonGreentext="Confirm"
          handleChangeGreenButton={() => handleeditRequest("Cancel")}
        />
      </Dialog>
      <Dialog open={open1} fullWidth>
        <Modal2
          heading="Would you like to remove this item from your request?"
          para="By removing this item you are confirming that you no longer wish to return it. Are sure you want to continue?"
          Column={true}
          buttonWhitetext="Cancel"
          handleChangeWhiteButton={() => setOpen1(false)}
          buttonGreentext="Confirm"
          handleChangeGreenButton={handleChangeGreenButtonModalOne}
        />
      </Dialog>
      <Dialog
        open={open2}
        classes={{ paperFullScreen: classes.addItemList }}
        fullScreen
      >
        <Modal3
          Error={Error}
          heading="Add items"
          texts="Enter the details of the products that you like to return. Once you’re happy with the information choose ‘Add to list’ and close this box. On the next screen you can review your request befotr submitting."
          nextheading="Product details"
          items={additem}
          handleChange={handleadditem}
          itemlist={dropdown}
          handleChangeDropDown={handleChangeDropDown}
          primarybutton="blue"
          secondarybutton="bluetextbutton"
          primarybuttontext="Add to list"
          secondarybuttontext="Close"
          handleButtonChange={handleaddproduct}
          onclose={() => setOpen2(false)}
        />
      </Dialog>
      <Dialog
        open={open3}
        classes={{ paperFullScreen: classes.addItemList }}
        fullScreen
      >
        <Modal3
          heading="Add items"
          texts="Enter the details of the products that you like to return. Once you’re happy with the information choose ‘Add to list’ and close this box. On the next screen you can review your request befotr submitting."
          nextheading="Product details"
          items={additem}
          handleChange={handleadditem}
          itemlist={dropdown}
          handleChangeDropDown={handleChangeDropDown}
          primarybutton="green"
          secondarybutton="normalbutton"
          primarybuttontext="Save changes"
          secondarybuttontext="Cancel"
          handleButtonChange={handleEditItems}
          onclose={onEditclose}
        />
      </Dialog>
      <SuccessModal
        open={open4}
        close={() => setOpen4(false)}
        successmessage={"Your item was added to your request."}
      />
      {loading === true && (
        <div style={{ textAlign: "center", minHeight: "80%" }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: "50%",
              left: "0",
            }}
          >
            <h4>
              <CircularProgress />
              <br></br>
              Loading...
            </h4>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.isSignedIn,
    token: state.token,
    userDetails: state.userDetails,
    loginError: state.loginError,
  };
};
export default connect(mapStateToProps)(index);
