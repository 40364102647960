import amplitude from 'amplitude-js';
import { REACT_APP_AMPLITUDE } from '../api/constants';

function logEvent(event) {
    console.log(event)
    amplitude.getInstance().logEvent(event);
}

export default logEvent

export const init =()=>{
    amplitude.getInstance().init(REACT_APP_AMPLITUDE, null, {includeReferrer: true});
    logEvent("Amplitude initialised")
}

export const logEventWithProperties=(event,eventProps)=> {
   console.log(event)
   amplitude.getInstance().logEvent(event,eventProps);
}

export const setUserId = (email)=>{
    amplitude.getInstance().setUserId(email);
    // amplitude.getInstance(process.env.REACT_APP_AMPLITUDE_PROJECT).setUserProperties({'gender':'male'});
    //logEventWithProperties('Logged in ',{"email":email});
}

export const setUserIdentity = (key,value)=>{
    var identify = new amplitude.Identify().setOnce(key, value);
    amplitude.getInstance().identify(identify);
    
}

export const setUserProperties= (properties)=>{
    amplitude.getInstance().setUserProperties(properties);
}

export const setUserGroup = (group)=>{
    amplitude.getInstance().setGroup('role', group);
}

export const trackRevenue=(productId , price, quantity = 1)=>{
    const revenue = new amplitude.Revenue()
      .setProductId(`${productId}`)
      .setPrice(price)
      .setQuantity(quantity);

    amplitude.getInstance().logRevenueV2(revenue);
    this.logEvent("Tracking revenue", { price, quantity });
  }