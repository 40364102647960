

import React, { useEffect, useState } from "react";
import { Button } from "bootstrap";
import { Snackbar } from "@material-ui/core";
import { connect } from "react-redux";
import { onApiError } from "../../redux/actions/actions";
import { Alert } from "@material-ui/lab";
import PropTypes from 'prop-types';

function ApiErrorComponent(props) {

    const [openToast, setopenToast] = useState(false)
    


    useEffect(() => {
        if (props.apiError) {
            setopenToast(true)
        } else {
            setopenToast(false)

        }
    }, [props.apiError])

    return (
        <Snackbar
            open={openToast}
            autoHideDuration={6000}
            onClose={() => { setopenToast(false); props.dispatch(onApiError("")) }}
            message={props.apiError}
            action={() => {
                return (
                    <Button color="inherit" size="small">
                        Retry
                    </Button>)
            }
            }
            sx={{
                color: "success.main",

                bottom: {
                    xs: 90, sm: 0,
                }
            }}
        >
            <Alert onClose={() => {setopenToast(false); props.dispatch(onApiError("")) }} severity="error" sx={{ width: '100%',color:"red" }}>
                {props.apiError}
            </Alert>
        </Snackbar>
    )
}
const mapStateToProps = (state) => {
    return {
        apiError: state.apiError
    }
}
ApiErrorComponent.propTypes = {
    apiError:PropTypes.String,
    dispatch:PropTypes.func
}
export default connect(mapStateToProps)(ApiErrorComponent)