import { Drawer, Hidden } from '@material-ui/core';
import {useEffect,  useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppBar, Badge, IconButton, makeStyles } from '@material-ui/core';
import Belliconblue from '../../Assets/Belliconblue.svg';
import Signout from '../../Assets/Signout.svg';
import { clearNotifications, clearState, setIsSIgnedIn, setToken, setUserDetails } from '../../redux/actions/actions';
import PropTypes from 'prop-types';

import { MenuOutlined } from '@material-ui/icons';
import "react-pro-sidebar/dist/css/styles.css";
import { connect } from "react-redux";
import { EditUserDetails, userLogout } from '../../api/userOps';
import Logo from '../../Assets/Logo.svg';
import { clearNotificationPolling } from '../../Utilities/NotificationUtils';
import SideBar from '../Sidebar/SideBar';
import React from 'react';
import { FeatureEnabled } from '../../api/constants';
import { clearLoginTimer } from '../../Utilities/TokenExpireTimer';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        background: "#007BB8",
        height: "100%"
    },
    drawer: {
        background: "#007BB8",
        [theme.breakpoints.up('sm')]: {
            width: "100%",
            flexShrink: 0,
        }
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        height: "10vh",
        background: "#FCFCFC",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.09)"
    },
    logo: {
        color: "#007BB8",
    },
    help: {
        display: "flex",
        marginRight: "10px",
        cursor: "pointer",
    }
}))
function Navbar(props) {
    const classes = useStyles()
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [notificationCount, setNotificationCount] = useState(0)
    useEffect(() => {
        setNotificationCount(props.notificationList?.length)
      }, [props.notificationList])
    
    function Logout(userId, email, token) {
        clearNotificationPolling()
        clearLoginTimer()

        EditUserDetails("notifications", props.notificationList).then((res) => res.json(), userId, email)
            .then(() => {
                props.dispatch(clearNotifications());
                userLogout(token?.AccessToken,token?.sessionId).then((res) => res.json(), userId, email)
                .then(() => {
        
                props.dispatch(setUserDetails({}))
                props.dispatch(setIsSIgnedIn(false))
        
                props.dispatch(setToken({}))
                props.dispatch(clearState())
                navigate("/")
                })

            })

      
    }


    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>

            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", height:"10vh", width:"100%"}}>
                <IconButton
                    color="blue"
                    className={classes.logo}

                    onClick={() => { setOpen(true) }}
                >
                    <MenuOutlined />
                </IconButton>

                <img style={{ height: "4vh" }} src={Logo} onClick={() => { navigate('/') }} alt="error" />
                <div className={classes.help}>
                    {FeatureEnabled("Notifications") &&  <Badge invisible={notificationCount <= 0} onClick={() => {window.location?.pathname==="/notifications"?navigate("/"):navigate("/notifications")}} variant="dot" color="primary" style={{ margin: "10px" }}>
                        <img src={Belliconblue}  alt="error" />
                    </Badge>}
                    <img src={Signout} alt="error" style={{marginLeft:"5px"}} onClick={
                        () => {
                            let userId = props.userDetails?.id
                            let email = props.userDetails?.email
                            let token = props.token
                            Logout(userId, email, token)
                        }
                    } />
                </div>
            </div>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">

                    <Drawer
                        variant="temporary"
                        anchor={'left'}

                        open={open}
                        onClose={() => { setOpen(false) }}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >

                        <SideBar Logout={Logout} isMobile={true} handleCollapse={() => { setOpen(false); }} />
                    </Drawer>
                </Hidden>

            </AppBar>
        </>
    );
}
const mapStateToProps = state => {
    return {
        isSignedIn: state.isSignedIn,
        userDetails: state.userDetails,
        notificationList: state.notificationList,
    token: state.token
        
    };
};

Navbar.propTypes = {
    userDetails: PropTypes.object,
    notificationList: PropTypes.array,
    isSignedIn: PropTypes.bool,
    dispatch: PropTypes.func,
    token: PropTypes.object,
    toggleSideBar:PropTypes.bool
  
  }
export default connect(mapStateToProps)(Navbar);
