import React from "react";
import {
  Grid,
  Typography,
  Button,
  Hidden,
  Box,
  Dialog} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ListComponent from "../../../Components/ListComponent/ListComponent";
import { CSVLink } from "react-csv";
import EditIcon from '@material-ui/icons/Edit';
import { useNavigate } from "react-router-dom";
import { logEventWithProperties } from "../../../analytics/Analytics";
import { Emaildisplay } from "../../../Utilities/modifyList";
import { useState } from "react";
import Tablecomponent from "../../../Components/Table/Table";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  maincontainer: {
    paddingTop: "2em",
    marginBottom:'2em',
    width:"85%",
    marginLeft:"8em",
  },
  title:{
    fontSize:'2em',
    fontWeight:'bold'
  },
  formControl: {
    minWidth: 200,
  },
  associationStatusPage: {
    boxShadow: "1px 2px 16px rgba(78, 78, 78, 0.1)",
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: 10,
    padding: "1rem",
    [theme.breakpoints?.up('md')]: {
        width: "50%",
    },
    [theme.breakpoints?.down('xs')]: {
        width: "90%",
        marginTop: "0px",
        height: "100vh",
    },
},
  downloadbutton:{
    backgroundColor: '#3D8541',
    boxShadow: '0px 2px 0px #306B33, inset 0px 1px 0px #62B767',
    borderRadius: '4px',
    textTransform:'none',
    width:430,
    color:"white",
    "&:hover":{
        backgroundColor: '#3D8541'
    }
  },
  buttontext:{
      color:'#fff',
      fontSize:'1rem'
  },
  link:{
      textDecoration:'none',
      color: '#007BB8'
  },
  resetfilter:{
    color: '#007BB8',
    cursor:'pointer'
  },
}));

function PharmacyGroup() {
    const classes = useStyles();
    let navigate = useNavigate();
    const [seletedItem, setSelectedItem] = useState(null)
    const [isStatusViewed, setViewStatus] = useState(false)
    const [statusMessage, setStatusMessage] = useState("")


    const csvData = [
      ["pharmacy_name", "account_number", "post_code", "admin_users","users" ]
    ];
    
    function EditAssciatepharmFunc(item) {

      return  <EditIcon
              onClick={() => {navigate("/Editassociatedpharmacyhq", {state:{item:item}});}}
          style={{ color: "#007BB8", cursor: "pointer", width: 17, height: 17 }}
        />  
    }

    function PharmacyGroupBottom(){
      return (
      <Grid item className={classes.maincontainer}>
      <Grid container direction='column' >
      
      <Grid item align='center' style={{marginBottom:'2em'}}>
      <Grid item align='center' style={{marginBottom:'2em'}}>
          <Typography variant="body1">Please note: To update the list of pharmacies under your pharmacy group, including adding and removing pharmacies email your ‘Associated pharmacies list’ to Wardles</Typography>
      </Grid>
        
          <CSVLink  data={csvData} filename={"pharmacy-group-template.csv"} style={{textDecoration:"none", color:"white"}}>
            <Button onClick={()=>logEventWithProperties("CSV template downloaded")} className={classes.downloadbutton} variant='contained'>Download Template</Button>
          </CSVLink>
        
      </Grid>
      <Grid item align="center">
          <p className={classes.link}>Need to add a new pharmacy group? Choose the customer support link below</p>
      </Grid>

      
      </Grid>
      </Grid>
      )
    }
    const onStatusViewed = (item) => {
      setViewStatus(true)
      setStatusMessage(item?.association_status?.status_message)
      let tableData = []

      if (item?.association_status?.pharmacy_details_check) {
          let processObj = {}
          processObj.process = "Validating account number and post code"
          processObj.status =  ((item) => {
            return (<p style={item?.association_status?.pharmacy_details_check === "success" ? { color: "green" } : { color: "red" }}>{item?.association_status?.pharmacy_details_check}</p>)
        })(item)
          tableData.push(processObj)

      }
      if (item?.association_status?.admins_email_check) {
          let processObj = {}

          processObj.process = "Validating admin emails"
          processObj.status = item?.association_status?.admins_email_check?.valid_emails?.length + " valid, " + item?.association_status?.admins_email_check?.invalid_emails?.length + " invalid"
          tableData.push(processObj)
      }
      if (item?.association_status?.users_email_check) {
          let processObj = {}

          processObj.process = "Validating user emails"
          processObj.status = item?.association_status?.users_email_check?.valid_emails?.length + " valid, " + item?.association_status?.users_email_check?.invalid_emails?.length + " invalid"
          tableData.push(processObj)
      }
      let processObj = {}

      processObj.process = "Registration email sent to "
      let adminEmails = item?.association_status?.admins_email_check
      let userEmails = item?.association_status?.users_email_check
      let registeredEmails = []
      let notregisteredEmails = []

      if (adminEmails?.valid_emails.length > 0) {
          registeredEmails = adminEmails?.valid_emails?.filter((item) =>
              item.reg_link_sent
          )?.map((item) => item.email)
      }
      if (userEmails?.valid_emails.length > 0) {
          let userregisteredEmails = userEmails?.valid_emails?.filter((item) =>
              item.reg_link_sent
          )?.map((item) => item.email)
          registeredEmails = [...registeredEmails, ...userregisteredEmails]
          processObj.status = Emaildisplay(registeredEmails)
      }
      tableData.push(processObj)

      processObj = {}

      processObj.process = "Registration email not sent to"
      if (adminEmails?.valid_emails.length > 0) {
          notregisteredEmails = adminEmails?.valid_emails?.filter((item) =>
              !item.reg_link_sent
          )?.map((item) => item.email)
      }
      if (userEmails?.valid_emails.length > 0) {
          let notregisteredUserEmails = userEmails?.valid_emails?.filter((item) =>
              !item.reg_link_sent
          )?.map((item) => item.email)
          notregisteredEmails = [...notregisteredEmails, ...notregisteredUserEmails]
          processObj.status = Emaildisplay(notregisteredEmails)
      }
      tableData.push(processObj)

      setSelectedItem(tableData)

  }
    return (
    <>
      <ListComponent
      heading={"Pharmacy list"}
      searchPlaceHolder={
        "Search by account number or pharmacy name"
      }
      subtext={"View the pharmacies in your group below.If you would like to add another pharmacy group contact us."}
      recordType="pharmacy_association"
      hideFilter
      operation="all"
      EditFunction={EditAssciatepharmFunc}
      onStatusViewed={onStatusViewed}

      head={[
        { value: "Edit", issortable: false },
        { value: "Pharmacy name", issortable: false },
        { value: "Account number", issortable: false },
        { value: "Postcode", issortable: true },
        { value: "Admin name", issortable: false },
        { value: "Email address", issortable: true },
        { value: "Association status", issortable: true },

      ]}
      />

<Dialog open={isStatusViewed} style={{ width: "80%", margin: "auto" }}   >

<Box className={classes.associationStatusPage}>
    <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "left", width: "90%" }}>

        </div>

        <div style={{ marginLeft: "auto" }}>

            <Typography id="modal-modal-title"
            >

                <Hidden smDown implementation='css' >
                    <Typography onClick={() => { setViewStatus(false) }} style={{ display: "flex", marginTop: "0.5em", alignItems: "center", cursor: "pointer", marginLeft: "auto" }} id="modal-modal-title" variant="h6" component="h2">
                        <p style={{ margin: 0, padding: 0, fontSize: "15px", color: "#007BB8", fontWeight: "bold" }}>Close</p>
                        <CloseIcon style={{ color: "#007BB8", height: 15 }} />
                    </Typography>
                </Hidden>

                <Hidden mdUp implementation='css' >
                    <CloseIcon onClick={() => { setViewStatus(false) }} style={{ color: "#007BB8", height: 15, marginTop: "1.1em", }} />

                </Hidden>

            </Typography>
        </div>

    </div>

    <div style={{ marginLeft: "auto" }}>
        <Typography style={{ display: "flex",color:"red", marginTop: "0.5em", alignItems: "center", cursor: "pointer", marginLeft: "auto" }} id="modal-modal-title" variant="h6" component="h2">
            {statusMessage}
        </Typography>
        <Tablecomponent
            head={[{ value: "Process", issortable: false }, { value: "Message", issortable: false }]}
            body={seletedItem}
        />
    </div>
</Box>
</Dialog>
      {PharmacyGroupBottom()}
    </>
  );
}

export default PharmacyGroup;
