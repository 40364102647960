import { Grid, Typography } from '@material-ui/core'
import React from 'react'

function index({ overheading, heading, texts }) {
  return (
    <Grid
      container
      align="start"
      direction="column"
      // style={{ marginTop: "0.5em" }}
    >{overheading &&
      <Grid item>
        <Typography
          variant="h5"
          style={{ color: "#5E5E5E", fontWeight: 600 }}
        >
          {overheading}
        </Typography>
      </Grid>
      }
      {heading &&
        <Grid item>
          <Typography variant="h5" style={{}}>
            {heading}
          </Typography>
        </Grid>
      }
      {texts &&
        <>
          <Grid item style={{ marginTop: "1em" }}>
            {texts.map((text, index) => {
              return (
                <Typography key={index}
                  style={{ color: "#5E5E5E", fontWeight: 500 }}
                  variant="body1"
                >
                  {text}
                </Typography>
              )
            })}
          </Grid>
        </>
      }
    </Grid>
  )
}

export default index