import { Menu, ProSidebar } from "react-pro-sidebar";
import DoubleArrowLeft from "../../Assets/DoubleArrowLeft.svg";
import DoubleArrowRight from "../../Assets/DoubleArrowRight.svg";
import { AdminComponent, HqComponent, StaffComponent } from "./MenuItems";
import "./Sidebar.css";
// import "react-pro-sidebar/dist/css/styles.css";
import { Hidden, IconButton, makeStyles } from '@material-ui/core';
import { CloseOutlined } from "@material-ui/icons";
import PropTypes from 'prop-types';
import React from "react";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    background: "#007BB8",

    [theme.breakpoints.up('md')]: {
      top: "10vh",
      position:"fixed"
    },
    [theme.breakpoints.up('md')]: {
      top: "10vh",
      position:"fixed"
  }
}}))
function SideBar(props) {
  const classes = useStyles()
  const role = props.userDetails?.role;
  return (
    <React.Fragment>
      <section
        className={classes.sidebar}
      >

        <ProSidebar collapsed={props.collapse} collapsedWidth={props.isMobile ? "0px" : "80px"} toggled={true} >
          <Menu style={{ height: "100vh", background: "#007BB8" }}>

            <Hidden smDown implementation="css">
              <div
                style={{
                  display: "flex",
                  cursor: "pointer",
                  justifyContent: "right",
                  marginBottom: "20px",
                  paddingRight: "10px",
                }}
              >
                {!props.collapse ? (
                  <img
                    src={DoubleArrowLeft}
                    alt="error"
                    onMouseOver={props.handleCollapse}
                  />
                ) : (
                  <img
                    src={DoubleArrowRight}
                    alt="error"
                    onMouseOver={props.handleCollapse}
                  />
                )}
              </div>

            </Hidden>
            <Hidden mdUp implementation="css">

              <IconButton
                color="#FFFFFF"
                className={classes.logo}

                onClick={props.handleCollapse}
              >
                <CloseOutlined />
              </IconButton>
            </Hidden>
            {role === "fp@wardleAdmin" && (
              <AdminComponent collapse={props.collapse} onSelect={props.handleCollapse} />
            )}
            {role === "fp@wardleUser" && (
              <AdminComponent collapse={props.collapse} onSelect={props.handleCollapse} />
            )}
            {role === "fp@wardleHqAdmin" && (
              <HqComponent collapse={props.collapse} onSelect={props.handleCollapse} />
            )}
            {role === "fp@wardleHqUser" && (
              <HqComponent collapse={props.collapse} onSelect={props.handleCollapse} />
            )}
            {role === "fp@wardleStaff" && (
              <StaffComponent collapse={props.collapse} onSelect={props.handleCollapse} />
            )}


            {/* <div
          onClick={props.Logout}
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginLeft: 40,
          }}
        >
          <img style={{color:"white"}} src={Signout} alt="error" />
          <p style={{ marginLeft: 5,color:"white" }}>Sign out</p>
        </div> */}
          </Menu>
        </ProSidebar>
      </section>
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {
    isSignedIn: state.isSignedIn,
    userDetails: state.userDetails
  };
};


SideBar.propTypes = {
  userDetails: PropTypes.object,
  collapse: PropTypes.bool,
  handleCollapse: PropTypes.func,
  isMobile:PropTypes.bool
};

export default connect(mapStateToProps)(SideBar);
