import React from 'react';

import Signin from "../pages/Signin/Signin";
import Signup from "../pages/Signup/Signup";
import Yourdetails from '../pages/Signup/Yourdetails';
import Pharmacydetails from '../pages/Signup/Pharmacydetails';
import EMailActivate from '../pages/Signup/EmailActivate';
import ForgotPasswordEmail from '../pages/ForgotPassword/ForgotPasswordEmail';
import Checkemail from "../pages/ForgotPassword/Checkemail";
import ChangePassword from "../pages/ForgotPassword/ChangePassword";
import Notfound from '../pages/NotFound/Notfound';
import Resetsuccess from '../pages/ForgotPassword/Resetsuccess';
import VerifyEmailAddress from '../pages/accessComplete/accessComplete';
import NotificationComponent from '../Components/Navbar/Notification'
import RegisterSuccessAdmin from '../pages/Signup/registersuccess';
import Alreadyregistered from '../pages/Signup/alreadyregistered';

import MyProfile from '../pages/MyProfile/MyProfile';
import ResetPassword from '../pages/MyProfile/ResetPassword';
import Edityourdetails from '../pages/MyProfile/Edityourdetails'


// wardles admin/user
import Createorder from '../pages/Createorder/Createorder';
import Orderlist from '../pages/orderlist/Orderlist';
import ManageUsers from '../pages/ManageUsers/ManageUsers';
import EditUsers from '../pages/ManageUsers/EditUSer';
import Adduser from '../pages/ManageUsers/Adduser';
import Range from '../pages/range/range';
import StockAmnestyHome from '../pages/StockAmnesty/Home'
import StockAmnestyStock from '../pages/StockAmnesty/ListRequest'
import YourstatementsAdmin from '../pages/Yourstatement'

// imports for wardles staff
import StartWardleStaffAccountCreation from '../pages/wardlestaff/signup/provideemailaddress';
import StartWardleStaffEmailActivate from '../pages/wardlestaff/signup/EmailActivatews';
import CompleteWardleStaffAccountCreation from '../pages/wardlestaff/signup/completeregistration';
import RegisterSuccessStaff from '../pages/wardlestaff/signup/registersuccess';
import ManagePharmacyGroup from '../pages/wardlestaff/ManagePharmacyGroup/ManagePharmacyGroup';
import AddNewPharmacyGroup from '../pages/wardlestaff/ManagePharmacyGroup/AddNewPharmacyGroup';
import AssociatedPharmacies from '../pages/wardlestaff/ManagePharmacyGroup/AssociatedPharamacies';
import Editassocitedpharmacies from '../pages/wardlestaff/ManagePharmacyGroup/Editassocitedpharmacies';

// imports for wardles hq
import WardleHQAccountCreation from '../pages/wardlehq/signup/provideemailaddresshq';
import StartWardleHQEmailActivate from '../pages/wardlehq/signup/EmailActivatehq';
import CompleteWardleHQAccountCreation from '../pages/wardlehq/signup/completeregistrationhq';
import RegisterSuccessHQ from '../pages/wardlehq/signup/registersuccesshq';
import Pharmacylisthq from '../pages/wardlehq/Pharmacygroup/PharmacyGroup';
import Editassociatedpharmacyhq from '../pages/wardlehq/Pharmacygroup/Editassocitedpharmacyhq';




import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import PrivateRoutes from './PrivateRoutes';
import EnterManually from '../pages/wardlestaff/ManagePharmacyGroup/EnterManually';
import Container from '../Components/Layouts/Container';





function WardleRoutes() {

  return (
    <Router>
       <Routes>
        <Route exact path="/" element= {<Signin />} />
        <Route path='/access/complete/:rnd/:token/:email' element={<Container hideSideBar hideFooter><VerifyEmailAddress/></Container>} />
        <Route path='/forgotPassword/complete/:rnd/:token/:email' element={<ChangePassword/>} />
        <Route path="/signin" element= {<Signin />} />
        <Route path="/signup" element= {<Container hideSideBar hideFooter feature="Signup"><Signup/></Container>} />
        <Route path="/verify-user/:email/:firstName/:lastName/:role/:pharm_name/:pharm_accno/:pharm_code/:rnd/:token" element= {<Container hideSideBar hideFooter feature="Verify user"><Signup/></Container>} />
        <Route path="/emailactivate" element= {<Container hideSideBar hideFooter><EMailActivate/></Container>} />
        <Route path="/forgotpasswordemail" element= {<Container hideSideBar hideFooter feature="Forgot password"><ForgotPasswordEmail/></Container>} />
        <Route path="/checkmail" element= {<Container hideSideBar hideFooter><Checkemail/></Container>} />
        <Route path="/resetsuccess" element= {<Container hideSideBar hideFooter><Resetsuccess/></Container>} />
        <Route  path="/yourdetails" element={<Container hideSideBar hideFooter feature="Your details"><Yourdetails/></Container>}/>
        <Route  path="/pharmacydetails" element={<Container hideSideBar hideFooter feature="Pharmacy details"><Pharmacydetails/></Container>} />

        <Route path="/register/successfull" element={<Container hideSideBar hideFooter><RegisterSuccessAdmin/></Container>} />
        <Route path="/register/alreadyregistered" element={<Container hideSideBar hideFooter><Alreadyregistered/></Container>} />

        {/* my profile */}
        <Route path="/myprofile" element={
        <PrivateRoutes feature="My profile">
          <MyProfile/>
          </PrivateRoutes>} />
        <Route path="/edityourdetails" element={<PrivateRoutes feature="Edit profile details">
          <Edityourdetails />
        </PrivateRoutes>} />
        <Route path="/resetpassword" element={<PrivateRoutes feature="Reset password">
          <ResetPassword />
        </PrivateRoutes>} />

        {/* //Notification */}

        <Route path="/notifications" element={
        <PrivateRoutes feature="Notifications">
          <NotificationComponent/>
          </PrivateRoutes>} />


        {/* Wardles admin/user */}
        <Route path="/manageusers" element={<PrivateRoutes feature="Manage users">
          <ManageUsers />
        </PrivateRoutes>} />
        <Route path="/editusers/:id" element={<PrivateRoutes feature="Edit users">
          <EditUsers />
        </PrivateRoutes>}/>
        <Route path="/adduser" element={<PrivateRoutes 
        // feature="Add users"
        >
          <Adduser />
        </PrivateRoutes>}/>
        <Route path="/createorder" element={<PrivateRoutes feature="Create Order">
          <Createorder />
        </PrivateRoutes>} />
        <Route path="/orderlist" element={<PrivateRoutes feature="Order list">
          <Orderlist />
        </PrivateRoutes>}/>
        <Route path="/range" element={<PrivateRoutes feature="range">
          <Range />
        </PrivateRoutes>}/>
        <Route path="/stockamnestyhome" element={<PrivateRoutes feature="Stock amnesty">
          <StockAmnestyHome />
        </PrivateRoutes>}/>
        <Route path="/stockamnestyrequest" element={<PrivateRoutes feature={"Stock amnesty request"}>
          <StockAmnestyStock />
        </PrivateRoutes>}/>
        <Route path="/yourstatements" element={<PrivateRoutes feature={"Your statements"}>
          <YourstatementsAdmin />
        </PrivateRoutes>}/>
        
        {/* Wardles staff */}
        <Route path="/staff/register/start" element= {<Container hideSideBar hideFooter feature="Register staff"><StartWardleStaffAccountCreation /></Container>} />
        
        
        <Route path="/staff/emailactivation" element= {<Container hideSideBar hideFooter><StartWardleStaffEmailActivate /></Container>} />
        <Route path="/staff/register/complete/:rnd/:email/:token" element= {<Container hideSideBar hideFooter><CompleteWardleStaffAccountCreation /></Container>} />
        <Route path="/staff/register/successfull" element={<Container hideSideBar hideFooter><RegisterSuccessStaff/></Container>} />

        <Route path="/managepharmacygroup" element={<PrivateRoutes feature="Manage pharmacy group">
          <ManagePharmacyGroup />
        </PrivateRoutes>} />
        <Route path="/pharmacygroup/:id" element={<PrivateRoutes feature="Add new pharmacy group">
          <AddNewPharmacyGroup />
        </PrivateRoutes>}/>
        <Route path="/addpharmacygroup" element={<PrivateRoutes>
          <AddNewPharmacyGroup />
        </PrivateRoutes>}/>
        <Route path="/Editpharmacygroup" element={<PrivateRoutes feature="Edit pharmacy group">
          <AddNewPharmacyGroup />
        </PrivateRoutes>}/>
        <Route path="/manualaddpharmacygroup" element={<PrivateRoutes feature="Associate manual pharmacy">
          <EnterManually />
        </PrivateRoutes>}/>
        <Route path="/group/pharmacy" element={<PrivateRoutes feature="Associated pharmacy list">
          <AssociatedPharmacies />
        </PrivateRoutes>}/>
        <Route path="/Editassociatedpharmacy" element={<PrivateRoutes feature="Associated pharmacy list">
          <Editassocitedpharmacies />
        </PrivateRoutes>}/>

         {/* Wardles hq */}
        <Route path="/hq/register/start" element= {<Container hideSideBar hideFooter feature="Register Hq user"><WardleHQAccountCreation /></Container>} />
        <Route path="/hq/emailactivation" element= {<Container hideSideBar hideFooter><StartWardleHQEmailActivate /></Container>} />
        <Route path="/hq/register/complete/:rnd/:email/:token" element= {<Container hideSideBar hideFooter><CompleteWardleHQAccountCreation /></Container>} />
        <Route path="/hq/register/complete/:rnd/:email/:token/:firstNameIn/:lastNameIn/:phoneNumberIn/:pharmacyGroupAccountNumberIn/:pharmacyGroupNameIn" element= {<Container hideSideBar hideFooter><CompleteWardleHQAccountCreation /></Container>} />
        <Route path="/hq/register/successfull" element={<Container hideSideBar hideFooter><RegisterSuccessHQ/></Container>} />

        <Route path="/pharmacylisthq" element= {<PrivateRoutes feature="Hq pharmacy list">
          <Pharmacylisthq />
        </PrivateRoutes>} />
        <Route path="/Editassociatedpharmacyhq" element= {<PrivateRoutes feature="Hq pharmacy list">
          <Editassociatedpharmacyhq />
        </PrivateRoutes>} />

        
        {/*Not Found Redirecting*/}
        <Route path="*" element= {<Container hideSideBar hideFooter ><Notfound/></Container>}></Route>
        </Routes>
    </Router>
  );
}

export default WardleRoutes;


