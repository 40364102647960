import React from 'react';
import Logo from '../../Assets/Logo.svg';
import { useNavigate } from 'react-router-dom';
import { AppBar, makeStyles, Hidden } from '@material-ui/core';
import Helpicon from "../../Assets/Helpicon.svg"
import PropTypes from 'prop-types';


const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: "10vh",
    background: "#FCFCFC",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.09)"
  },
  logo: {
    cursor: "pointer",
    "&:hover":{
      backgroundColor:"#fff"
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: "10px",
      },
    [theme.breakpoints.up('sm')]: {
      marginLeft: "30px",
    },
  },
  help: {
    cursor: "pointer",
    color: "black",
    [theme.breakpoints.down('sm')]: {
      marginRight: "10px",
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: "30px",
    },
  },

}))
// eslint-disable-next-line no-unused-vars
function Navbar(props) {
  const classes = useStyles()
  let navigate = useNavigate()

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
          

        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", height:"10vh", width:"100%"}}>
          <img src={Logo} className={classes.logo} onClick={()=>{navigate('/')}} alt="error" />


          <div className={classes.help} >
               <a href="https://www.bestwaymedhub.co.uk/Wardles" target='_blank' style={{display:"flex",alignItems:"center",textDecoration:"none",color:"#007BB8"}} rel="noreferrer" >
               <img src={Helpicon} alt="error" />
               <Hidden smDown implementation="css">
                <p style={{marginLeft:5}}>Help</p>
               </Hidden>
               </a>
          </div>
        </div>
      </AppBar>
    </>
  );
}

Navbar.propTypes ={
  toggleSideBar:PropTypes.bool
}
export default Navbar;



