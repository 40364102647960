import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Grid, Typography } from "@material-ui/core";
import GreenButton from "../../../Components/Buttons/GreenButton";
import PaperCompOne from "../../../Components/Paper/PaperCompOne";
import ListComponent from "../../../Components/ListComponent/ListComponent";
import { connect } from 'react-redux';
// import EditIcon from "@material-ui/icons/Edit";
import { Visibility } from "@material-ui/icons";
import PrintIcon from '@material-ui/icons/Print';


import EmptyBox from '../../../Assets/EmptyBox.svg'
import BlankForm from '../../../Assets/amco_stock_amnesty_blank_form.pdf'
import { printPreview } from "../../../Utilities/fileUtilitties.js";
import { logEventWithProperties } from "../../../analytics/Analytics";

function index(props) {
  let navigate = useNavigate()
  const [user, setUser] = useState([])
  let role = props.userDetails.role;
  const email = props.userDetails.email;
  const pharmacyAccountNumber = props.userDetails.pharmacy_account_number;
  const pharmacyName = props.userDetails.pharmacy_name;
  const collectionAddress = props.userDetails.collection_address;
  const phoneNumber = props.userDetails.phone_number;
  const handleChangeGreenButton = () => {
    navigate("/stockamnestyrequest")
  }

  useEffect(() => {
    setUser(props.userDetails);
  }, [props.userDetails])



  function EditFunction(item) {
    return (
      <Visibility
        onClick={() => {
          logEventWithProperties("Admin edits stock amnesty ",{...item,...{userId:props.userDetails?.id,orderType:"stockamnesty"}})
          navigate(`/stockamnestyrequest`, {
            state: { item },
          });
        }}
        style={{ color: "#007BB8", height: 16, width: 16, cursor: 'pointer' }}
      />
    );
  }

  function PrintFunction(item) {
    return (
      role === "fp@wardleStaff" ? <>
      <a href={item.commission_statement_file_name}
        onClick={() =>{
           printPreview(`${item["base64_encoded_form_content"]}`)}}
        onKeyPress={() =>{

           printPreview(`${item["base64_encoded_form_content"]}`)}}>
        <PrintIcon
          style={{ color: "#007BB8", height: 16, width: 16, cursor: 'pointer' }}
        />
      </a>
    </> : <>
    <a href={item.commission_statement_file_name}
    onClick={() =>{
      
      printPreview(`${item["base64_encoded_form_content"]}`)}}
    onKeyPress={() =>{
      
      printPreview(`${item["base64_encoded_form_content"]}`)}}>
      <p style={{marginLeft:"3em", marginRight:"3em"}}>
    <PrintIcon
      style={{ color: "#007BB8", height: 16, width: 16, cursor: 'pointer' }}
    />
    </p>

  </a>
    </>
    );
  }
  function TableLeftContainer() {
    return (
      <PaperCompOne headline={"Pharmacy Details"} row={false} items={[{ key: 'Contact Name:', value: ` ${user.first_name}${" "}${user.last_name}` }, { key: 'Email Address:', value: ` ${email}` }, { key: 'Account Number:', value: ` ${pharmacyAccountNumber}` },  { key: 'Pharmacy Name: ', value: ` ${pharmacyName}` }, { key: 'Pharmacy Phone Number:', value: ` ${phoneNumber}` }, { key: 'Collection Address:', value: ` ${collectionAddress}` }]} />
    );
  }

  function NodataDisplay() {
    return (
      <Grid container direction='column' style={{ marginTop: "6em" }}>
        <Grid item align='center'>
          <img src={EmptyBox} alt="emptyimage" />
        </Grid>
        <Grid item style={{ marginTop: "1em" }}>
          <Typography variant="body1" style={{ fontWeight: 500 }} align='center'>Your list is empty, choose ‘Start a new request’ to get started</Typography>
        </Grid>
      </Grid>
    )
  }

  function Righthead() {
    return (role === "fp@wardleStaff" ?
      <>
      </>
      :
      <>
        <Grid item style={{ marginLeft: "auto", marginTop: "1em" }} >
          <Grid container  direction="column">
            <Grid item align='end'>
            <p style={{  padding:0, margin:0 }}>
                <a
                  href={BlankForm}
                  target="_blank"
                  style={{ color: "#007BB8"}}
                  rel="noreferrer"
                >
                  Download PDF
                </a>
              </p>
            </Grid>
            <Grid item style={{ marginTop: "1em" }}>
              <GreenButton text="Start a new request" handleChangeGreenButton={()=>{
                logEventWithProperties("Admin clicks on Start a new request",{userId:props.userDetails?.id})
                handleChangeGreenButton()}} />
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      {role === "fp@wardleStaff" ?
        <ListComponent
          Righthead={Righthead}
          filterbar={false}
          hideFilter={true}
          heading={"Stock Amnesty"}
          EditFunction={EditFunction}
          // subheading={"View your pharmacies stock amnesty."}
          PrintFunction={PrintFunction}
          recordType="stock_amnesty"
          operation="filter"
          filterParams={{recordStatus:"request submitted"}}
          head={[
            { value: "View", issortable: false },
            { value: "Date", issortable: false },
            { value: "Time", issortable: false },
            { value: "Pharmacy name", issortable: false },
            { value: "Postcode", issortable: false },
            { value: "Account number", issortable: false },
            { value: "Phone", issortable: false },
            { value: "Print", issortable: false },
          ]}
        />
        :
        <ListComponent
          filterbar={false}
          hideFilter={true}
          hideSearch={true}

          recordType="stock_amnesty"
          EditFunction={EditFunction}
          PrintFunction={PrintFunction}
          NodataDisplay={NodataDisplay}
          Righthead={Righthead}
          TableLeftContainer={TableLeftContainer}
          InnertableHead="Stock Amnesty Requests"
          TableLeft={true}
          operation="filter"
          filterParams={{recordStatus:"request submitted"}}

          heading={"Stock Amnesty"}
          subheading={[
            "You can request a Stock Amnesty on this page.",
            "We will buy back unused stock from you, freeing up space in your, dispensary and enhancing your cash flow.",
            "All stock must be unused, in a resaleable condition, and have at least six months left on the expiry.",
            "Please see the T&Cs for more information. Select 'Start a new request' to get going!",
          ]}
          head={[
            { value: "View", issortable: false },
            { value: "Submission Date", issortable: false },
            { value: "Collection Address", issortable: false },
            { value: "Print", issortable: false },
        ]}

        />
      }

    </>
  );
}
const mapStateToProps = state => {
  return {
    isSignedIn: state.isSignedIn,
    token: state.token,
    userDetails: state.userDetails,
    loginError: state.loginError
  };
};

export default connect(mapStateToProps)(index);