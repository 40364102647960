/* eslint-disable react/prop-types */
import * as React from 'react';
import { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';

function Dropdown(props) {

  const [orderstatus, setOrderstatus] = useState(props.orderStatus);

  const handleChangeInput = async e => {
    const {value} = e.target
    setOrderstatus(value);
    props.changeOrderStatusFlag(true, value.toLowerCase());
  }

  return (
      <FormControl label="User" style={{width:"100%"}} variant="outlined" size="small">
        <InputLabel label="User" id="demo-simple-select-label">Order status</InputLabel>
        <Select
            // error= {isEmpty(props.value) && props.err}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={orderstatus === "" ? "Select": orderstatus}
            onChange={handleChangeInput}
            label="Order status"
        >
          <MenuItem value= "return to nhs spine">Return to NHS Spine</MenuItem>
          <MenuItem disabled = {props.awardsscriptnumber === ''? true:false} value="ordered">Ordered</MenuItem>
          <MenuItem value="attempted to call - item oos">Attempted to call - item OOS</MenuItem>
          <MenuItem value="attempted to call - to follow query">Attempted to call - to follow query</MenuItem>
          <MenuItem value="attempted to call - order cancelled">Attempted to call - order cancelled</MenuItem>
          <MenuItem value="request cancelled">Request Cancelled</MenuItem>
          {/* <MenuItem value="please contact wardles">Please Contact Wardles</MenuItem> */}
        </Select>
      </FormControl>
  );
}
const mapStateToProps = state => {
  return {
    userDetails: state.userDetails
  };
};
export default connect(mapStateToProps)(Dropdown)
