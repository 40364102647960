/* eslint-disable react/prop-types */
import * as React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { InputLabel } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";



export default function Dropdown({singles,handleChangeProductdetails,widthfull}) {
  const useStyles = makeStyles((theme) => ({
    dropdown: {
      [theme.breakpoints.only('xs')]: {
        width:widthfull ? "100%" : "68%",
        // background:"yellow" 
      },
      [theme.breakpoints.only('sm')]: {
        width:widthfull ? "100%" : "80%",
      },
      [theme.breakpoints.up('sm')]: {
        width:widthfull ? "100%" : "80%"
      },
    },
    

  }));

  const classes = useStyles();


  return (
    <FormControl label="select-quantity-form" 
    className={classes.dropdown} 
    variant="outlined" size="small">
      <InputLabel label="select-quantity-form" id="select-singles-pack">{!singles?"Select a pack/single":"Pack/Single"}</InputLabel>
      <Select
        id="select-sp"
        data-testid="sp-select-choice"
        onChange={handleChangeProductdetails}
        label={!singles?"Select a pack/single":"Pack/Single"}
        value={singles}
        defaultValue={""}
        name="singles"
        placeholder="Singles"
        size="small"
      >
        <MenuItem value={"select"} disabled hidden>Select</MenuItem>
        <MenuItem value={"Singles"}>Singles</MenuItem>
        <MenuItem value={"Packs"}>Packs</MenuItem>
      </Select>
    </FormControl>
  );
}
