import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Helpicon from "../../Assets/Helpicon.svg"


let useStyles = makeStyles((theme) => ({
  footer: {
    position: 'relative',
    display: 'flex',
    fontWeight: "bold",
    width:"100%",
    // left: 0,
    // bottom: 0,
    // right: 10,
    // padding:0,
    [theme.breakpoints.down('md')]: {
    justifyContent:"end",
    marginRight:"0.5em",
    marginTop:"2em",
    },
    [theme.breakpoints.down('xs')]: {
    justifyContent:"center"
    },
    [theme.breakpoints.up('sm')]: {
    justifyContent:"end",
    marginRight:"0.5em",
    marginTop:"2em",
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent:"center",
    },
    [theme.breakpoints.up('lg')]: {
    justifyContent:"end",
    marginRight:"0.5em",
    marginTop:"0em",
    },
    [theme.breakpoints.up('xl')]: {
    justifyContent:"end",
    marginRight:"0.5em",
    marginTop:"0em",
    },
  }

}))
function Footer() {

  const classes = useStyles()



  return (
    <>

      <Container className={classes.footer}>
        <a href="https://bestwaymedhub.topdesk.net/tas/public/login/form" target='blank' style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "#007BB8",position:"static" }} >
          <img src={Helpicon} alt="error" />
          <p>&nbsp;&nbsp;Customer support</p>
        </a>
      </Container>
    </>
  );
}

export default Footer;


