/* eslint-disable react/prop-types */
import * as React from "react";
import FormControl from "@material-ui/core/FormControl";
import { TextField } from "@material-ui/core";

export default function Dropdown({ quantity, handleChangeProductdetails }) {
  return (
    // <Box sx={{ minWidth: 120 }}>

    <FormControl
      label="quantity-form"
      style={{ width: "100%" }}
      variant="outlined"
      size="small"
    >
      <TextField
        variant="outlined"
        type="text"
        size="small"
        label="Quantity"
        multiline
        onChange={handleChangeProductdetails}
        name="quantity"
        value={quantity}
        inputProps={{ maxLength: 100 }}
      />
    </FormControl>
    // </Box>
  );
}