import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Dialog } from "@material-ui/core";
import CalendarComponent from "../../Components/Calendar/CalendarComponent";
import { connect } from "react-redux";
import { setComissionFilter, setisComissionFilter } from "../../redux/actions/actions";
import PropTypes from 'prop-types';

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

const FilterMonthComponent = (props) => {
  const { commisionFilter } = props
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [dateVal, setdateVal] = commisionFilter.fromDate ? useState(commisionFilter.fromDate?.split("/")[0]) : useState(new Date().getFullYear());
  const [monthVal, setmonthVal] = commisionFilter.fromDate ? useState((commisionFilter.fromDate?.split("/")[1]) - 1) : useState(new Date().getMonth());
  const [dateVal1, setdateVal1] = commisionFilter.toDate ? useState(commisionFilter.toDate?.split("/")[0]) : useState(new Date().getFullYear());
  const [monthVal1, setmonthVal1] = commisionFilter.toDate ? useState((commisionFilter.toDate?.split("/")[1]) - 1) : useState(new Date().getMonth());
  useEffect(() => {
    if (dateVal === dateVal1) {
      if (monthVal1 < monthVal) {
        setmonthVal1(monthVal)
      }
    }
  }, [monthVal, monthVal1, dateVal, dateVal1])

  useEffect(() => {
    let commisionFilter = props.commisionFilter
    setdateVal(commisionFilter.fromDate ? commisionFilter.fromDate?.split("/")[0] : new Date().getFullYear())
    setmonthVal(commisionFilter.fromDate ? (commisionFilter.fromDate?.split("/")[1]) - 1 : new Date().getMonth())
    setdateVal1(commisionFilter.toDate ? commisionFilter.toDate?.split("/")[0] : new Date().getFullYear())
    setmonthVal1(commisionFilter.toDate ? (commisionFilter.toDate?.split("/")[1]) - 1 : new Date().getMonth())
  }, [props.commisionFilter])

  return (
    <>
      <Dialog open={open} fullWidth>
        <CalendarComponent
          type="fromDate"
          otherMonth={monthVal1}
          otherDate={dateVal1}
          currdate={dateVal}
          currmonth={monthVal}
          setCurrDate={setdateVal}
          setCurrMonth={setmonthVal}

          close={() => setOpen(false)}
          handleSubmitt={(month, date) => {
            setdateVal(date)
            setmonthVal(month)
            let mnth = month + 1
            if (+mnth < 10)
              mnth = "0" + mnth

            props.dispatch(setComissionFilter({ "fromDate": date + "/" + (mnth) }))
            if (month >= monthVal1) {
              let tomonth = month + 2 > 11 ? month + 1 : month + 2
              if (+tomonth < 10)
                tomonth = "0" + tomonth
              else tomonth = month + 1 < 10 ? "0" + (month + 1) : (month + 1)
              
              props.dispatch(setComissionFilter({ "toDate": date + "/" + (tomonth) }))
              setmonthVal1(mnth + 1)
            }
            props.dispatch(setisComissionFilter(true))
            props.onSelect()
          }}

          index={monthVal}
        />
      </Dialog>
      <Dialog open={open1} fullWidth>
        <CalendarComponent
          type="toDate"
          otherMonth={monthVal}
          otherDate={dateVal}

          currdate={dateVal1}
          currmonth={monthVal1}
          setCurrDate={setdateVal1}
          setCurrMonth={setmonthVal1}
          close={() => setOpen1(false)}
          index={monthVal1}
          handleSubmitt={(month, currDate) => {
            setdateVal1(currDate)
            setmonthVal1(month)
            let mnth = month + 1
            if (+mnth < 10)
              mnth = "0" + mnth
            props.dispatch(setComissionFilter({ "toDate": currDate + "/" + (mnth) }))
            props.dispatch(setisComissionFilter(true))

            props.onSelect()
          }}


        />
      </Dialog>
      <div style={{ gap: "1em", display: "flex", width: "80%" }}>
        <Paper
          elevation={2}
          onClick={() => { setOpen(true); props.filterClicked() }}
          style={{
            width: "50%",
            border: "2px solid #007BB8",
            boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
            borderRadius: "15px",
            padding: "0.5em"
          }}
        >
          <Grid item style={{ cursor: "pointer" }}>

            <Typography
              variant="body2"
              style={{ color: "#007BB8", fontWeight: 600 }}
            >
              Start month
            </Typography>
            <Grid item>
              <Typography
                variant="subtitle1"
                style={{ color: "#5E5E5E", fontWeight: 600 }}
              >
                {`${months[monthVal]} ${dateVal}`}
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Paper
          elevation={2}

          onClick={() => { setOpen1(true); props.filterClicked() }}
          style={{
            width: "50%",

            border: "2px solid #007BB8",
            boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
            borderRadius: "15px",
            padding: "0.5em",
          }}
        >
          <Grid item style={{ cursor: "pointer" }}>
            <Grid item>
              <Typography
                variant="body2"
                style={{ fontWeight: 600, color: "#007BB8" }}
              >
                End month
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 600, color: "#5E5E5E" }}
              >
                {`${months[monthVal1]} ${dateVal1}`}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    commisionFilter: state.commisionFilter
  };
};
FilterMonthComponent.propTypes = {
  commisionFilter: PropTypes.object,
  dispatch: PropTypes.func,
  onSelect: PropTypes.func,
  filterClicked: PropTypes.func
}
export default connect(mapStateToProps)(FilterMonthComponent);
