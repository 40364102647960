import React from 'react'
import { makeStyles } from "@material-ui/core";
import {
  Typography,
  Button,
} from "@material-ui/core";
import PropTypes from 'prop-types';


const useStyles = makeStyles({
  buttontext: {
    padding: "0.25em",
    fontWeight: 600,
  },
  Addbutton: {
    backgroundColor: "#3D8541",
    textTransform: "none",
    borderRadius: "2px",
    color: "white",
    "&:hover": {
      backgroundColor: "#3D8541",
    },
  }
});

function GreenButton({ text, handleChangeGreenButton, disable }) {
  const classes = useStyles();
  return (
    <>
      <Button
        variant="contained"
        fullWidth
        size='large'
        disabled={disable}
        disableRipple
        disableElevation
        onClick={handleChangeGreenButton}
        className={classes.Addbutton}>
        <Typography
          variant="body2"
          className={classes.buttontext}>
          {text}
        </Typography>
      </Button>
    </>
  )
}

GreenButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleChangeGreenButton: PropTypes.func.isRequired
}


export default GreenButton