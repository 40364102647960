export  const  GET_TOKEN="GET_TOKEN"
export  const  SET_USER_DETAILS="SET_USER_DETAILS"
export const IS_SIGNED_IN = "IS_SIGNED_IN"
export const IS_LOGIN_ERROR = "IS_LOGIN_ERROR"
export const SET_USER_LOGIN_DETAILS="SET_USER_LOGIN_DETAILS"
export const SET_HQ_ASSOCIATED_PHARMACIES = "SET_HQ_ASSOCIATED_PHARMACIES"
export const SET_ORDER_FILTER = "SET_ORDER_FILTER"
export const SET_USER_FILTER = "SET_USER_FILTER"
export const SET_IS_USER_FILTER_APPLIED = "SET_IS_USER_FILTER_APPLIED"
export const SET_IS_ORDER_FILTER_APPLIED = "SET_IS_ORDER_FILTER_APPLIED"
export const SET_RESET_ORDER_FILTER = "SET_RESET_ORDER_FILTER"
export const SET_RESET_USER_FILTER = "SET_RESET_USER_FILTER"
export const SET_NOTIFICATION_LIST="SET_NOTIFICATION_LIST"
export const CLEAR_NOTIFICATION_LIST="CLEAR_NOTIFICATION_LIST"
export const SET_COMISSION_FILTER="SET_COMISSION_FILTER"
export const SET_IS_COMMISSION_FILTER="SET_IS_COMMISSION_FILTER"
export const CLEAR_STATE = "CLEAR_STATE"
export const SET_API_ERROR="SET_API_ERROR"
export const IS_NOTIFICATION_ENABLED="IS_NOTIFICATION_ENABLED"
export const IS_REMEBER_PASSWORD="IS_REMEBER_PASSWORD"
export const IS_TOKEN_REFRESHING="IS_TOKEN_REFRESHING"
export const RETRY_ARRAY_APIS="RETRY_ARRAY_APIS"
export const NAVIGATE_TO_SIGNIN="NAVIGATE_TO_SIGNIN"
export const REFRESH_TOKEN="REFRESH_TOKEN"
export const IS_USER_AWAY="IS_USER_AWAY"
export const IS_LOADING="IS_LOADING"







