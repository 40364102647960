import { Card, CardHeader, Dialog, Grid, makeStyles, Typography, Box, Hidden } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useEffect, useState } from 'react';
import { DeleteAssociatedPharmacy } from '../../../api/pharmacyops';
import ListComponent from "../../../Components/ListComponent/ListComponent";
import PropTypes from 'prop-types';
import DeleteModal from "../../../Components/Modal/Modal2";
import Tablecomponent from '../../../Components/Table/Table';
import CloseIcon from '@material-ui/icons/Close';
import { Emaildisplay } from '../../../Utilities/modifyList';
import EditIcon from '@material-ui/icons/Edit';
import { useNavigate } from "react-router-dom";
import { logEventWithProperties } from '../../../analytics/Analytics';
import { Refresh } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    loading: {
        position: "absolute", width: "100%",
        [theme.breakpoints.only('md')]: {
            top: "35%", left: "15%"

        },
        [theme.breakpoints.only('xs')]: {
            top: "75%", left: "10%"

        },
        [theme.breakpoints.only('sm')]: {
            top: "45%", left: "15%"

        },
        [theme.breakpoints.only('lg')]: {
            top: "35%", left: "10%"

        },
        [theme.breakpoints.only('xl')]: {
            top: "35%", left: "10%"

        },
    },
    associationStatusPage: {
        boxShadow: "1px 2px 16px rgba(78, 78, 78, 0.1)",
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: "1rem",
        [theme.breakpoints.only('md')]: {
            width: "50%",
        },
        [theme.breakpoints.only('xs')]: {
            width: "90%",
            marginTop: "0px",
            height: "100vh",
        },
        [theme.breakpoints.only('sm')]: {
            width: "95%",
            marginTop: "0px",
            height: "100vh",
        },
        [theme.breakpoints.only('lg')]: {
            width: "50%",
        },
        [theme.breakpoints.only('xl')]: {
            width: "50%",
        },
    },
    headerTitle: {
        fontWeight: 700,
        width: "90%"
    },
    container: {
        [theme.breakpoints.up('sm')]: {
            height: "48.5vh"
        },
        [theme.breakpoints.up('lg')]: {
            height: "48.5vh"
        },
        [theme.breakpoints.up('xl')]: {
            height: "35vh"
        },
    }
}))

function AssociatedPharmacyList(props) {

    const [isRefetch, setIsRefech] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [isStatusViewed, setViewStatus] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const [item, setItem] = useState(null)
    let navigate = useNavigate();
    const groupDetails = props.groupDetails
    const [seletedItem, setSelectedItem] = useState(null)
    const [statusMessage, setStatusMessage] = useState("")

    const classes = useStyles()
    useEffect(() => {
        props.change && setIsRefech(true)
    }, [props.change])
    const handledelete = (id, groupAccountNumber) => {
        setIsLoading(true)
        DeleteAssociatedPharmacy(id, groupAccountNumber).then(() => {
            logEventWithProperties("Pharmacy group deleted successfully", groupAccountNumber)
            setIsLoading(false)
            setIsRefech(true)
            setIsDeleteOpen(false)
        })
    }


    function EditFunction(item) {
        return (
            <ClearIcon
                onClick={() => {

                    setIsDeleteOpen(true)
                    setItem(item)
                }}
                style={{ color: "#BB4035", cursor: 'pointer', height: 16, width: 16 }}
            />
        );
    }



    function NodataDisplay() {
        return (
            <Grid container className={classes.container}>
                <Grid item style={{ margin: "auto auto" }}>
                    <Typography variant="body1" style={{ fontWeight: 500, textAlign: "center" }}>
                        There are no associated pharmacies in this group.<br />To get started upload an updated pharmacy file.
                    </Typography>
                </Grid>
            </Grid>
        )
    }

    function EditAssciatepharmFunc(item) {

        return <EditIcon
            onClick={() => { navigate("/Editassociatedpharmacy", { state: { item: item, groupDetails: groupDetails } }); }}
            style={{ color: "#007BB8", cursor: "pointer", width: 17, height: 17 }}
        />
    }


    const onStatusViewed = (item) => {
        setViewStatus(true)
        setStatusMessage(item?.association_status?.status_message)
        let tableData = []

        if (item?.association_status?.pharmacy_details_check) {
            let processObj = {}
            processObj.process = "Validating account number and post code"
            processObj.status = ((item) => {
                return (<p style={item?.association_status?.pharmacy_details_check === "success" ? { color: "green" } : { color: "red" }}>{item?.association_status?.pharmacy_details_check}</p>)
            })(item)
            tableData.push(processObj)

        }
        if (item?.association_status?.admins_email_check) {
            let processObj = {}

            processObj.process = "Validating admin emails"
            processObj.status = item?.association_status?.admins_email_check?.valid_emails?.length + " valid, " + item?.association_status?.admins_email_check?.invalid_emails?.length + " invalid"
            tableData.push(processObj)
        }
        if (item?.association_status?.users_email_check) {
            let processObj = {}

            processObj.process = "Validating user emails"
            processObj.status = item?.association_status?.users_email_check?.valid_emails?.length + " valid, " + item?.association_status?.users_email_check?.invalid_emails?.length + " invalid"
            tableData.push(processObj)
        }
        let processObj = {}

        processObj.process = "Registration email sent to "
        let adminEmails = item?.association_status?.admins_email_check
        let userEmails = item?.association_status?.users_email_check
        let registeredEmails = []
        let notregisteredEmails = []

        if (adminEmails?.valid_emails.length > 0) {
            registeredEmails = adminEmails?.valid_emails?.filter((item) =>
                item.reg_link_sent
            )?.map((item) => item.email)
        }
        if (userEmails?.valid_emails.length > 0) {
            let userregisteredEmails = userEmails?.valid_emails?.filter((item) =>
                item.reg_link_sent
            )?.map((item) => item.email)
            registeredEmails = [...registeredEmails, ...userregisteredEmails]
            processObj.status = Emaildisplay(registeredEmails)
        }
        tableData.push(processObj)

        processObj = {}

        processObj.process = "Registration email not sent to"
        if (adminEmails?.valid_emails.length > 0) {
            notregisteredEmails = adminEmails?.valid_emails?.filter((item) =>
                !item.reg_link_sent
            )?.map((item) => item.email)
        }
        if (userEmails?.valid_emails.length > 0) {
            let notregisteredUserEmails = userEmails?.valid_emails?.filter((item) =>
                !item.reg_link_sent
            )?.map((item) => item.email)
            notregisteredEmails = [...notregisteredEmails, ...notregisteredUserEmails]
            processObj.status = Emaildisplay(notregisteredEmails)
        }
        tableData.push(processObj)

        setSelectedItem(tableData)

    }

    return (
        <>

            <Card style={{
                padding: "1em", border: " 1px solid #E9E9E9",
                boxShadow: '1px 2px 16px rgba(78, 78, 78, 0.1)',
                borderRadius: '20px'
            }} elevation={2}>
                <div style={{ display: "flex" }}>
                    <CardHeader style={{ width: "90%" }} title={"Associated Pharmacies"} titleTypographyProps={{ variant: 'h5' }} classes={{ title: classes.headerTitle }}>

                    </CardHeader>
                    <p onClick={() => { setIsRefech(true) }} style={{cursor: "pointer"}}>
                        <Refresh/>
                    </p>

                </div>
                <ListComponent
                    isRefetch={isRefetch}
                    setRefecth={() => { setIsRefech(false); props.setChange && props.setChange() }}
                    filterbar={false}
                    recordType="pharmacy_association"
                    EditFunction={EditFunction}
                    PrintFunction={EditAssciatepharmFunc}
                    NodataDisplay={NodataDisplay}
                    onlyTable={true}
                    operation="filter"
                    onStatusViewed={onStatusViewed}
                    groupDetails={props.groupDetails}
                    head={[
                        { value: "Remove", issortable: false },
                        { value: "Edit", issortable: false },
                        { value: "Account Number", issortable: false },
                        { value: "Pharmacy Name", issortable: false },
                        { value: "Postcode", issortable: false },
                        { value: "Email Address", issortable: false },
                        { value: "Association Status", issortable: false },

                    ]}
                />

            </Card>

            <Dialog open={isDeleteOpen}>
                <DeleteModal
                    loading={isLoading}
                    heading={"Are you sure you want to remove this pharmacy?"}
                    Column={false}
                    handleChangeWhiteButton={() => { setIsDeleteOpen(false) }}
                    handleChangeGreenButton={() => {
                        handledelete(item.id, item.pharmacy_group_account_number)

                    }}
                    buttonGreentext={"Remove"}
                    buttonWhitetext={"Cancel"}
                />
            </Dialog>

            <Dialog open={isStatusViewed} style={{ width: "80%", margin: "auto" }}   >

                <Box className={classes.associationStatusPage}>
                    <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "left", width: "90%" }}>

                        </div>

                        <div style={{ marginLeft: "auto" }}>

                            <Typography id="modal-modal-title"
                            >

                                <Hidden smDown implementation='css' >
                                    <Typography onClick={() => { setViewStatus(false) }} style={{ display: "flex", marginTop: "0.5em", alignItems: "center", cursor: "pointer", marginLeft: "auto" }} id="modal-modal-title" variant="h6" component="h2">
                                        <p style={{ margin: 0, padding: 0, fontSize: "15px", color: "#007BB8", fontWeight: "bold" }}>Close</p>
                                        <CloseIcon style={{ color: "#007BB8", height: 15 }} />
                                    </Typography>
                                </Hidden>

                                <Hidden mdUp implementation='css' >
                                    <CloseIcon onClick={() => { setViewStatus(false) }} style={{ color: "#007BB8", height: 15, marginTop: "1.1em", }} />

                                </Hidden>

                            </Typography>
                        </div>

                    </div>

                    <div style={{ marginLeft: "auto" }}>
                        <Typography style={{ display: "flex", color: "red", marginTop: "0.5em", alignItems: "center", cursor: "pointer", marginLeft: "auto" }} id="modal-modal-title" variant="h6" component="h2">
                            {statusMessage}
                        </Typography>
                        <Tablecomponent
                            head={[{ value: "Process", issortable: false }, { value: "Message", issortable: false }]}
                            body={seletedItem}
                        />
                    </div>
                </Box>
            </Dialog>


        </>
    )
}

AssociatedPharmacyList.propTypes = {
    groupDetails: PropTypes.any.isRequired,
};

export default AssociatedPharmacyList