import React from 'react'
import {
    Button,
    Typography,
  } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';
import PrintIcon from '@material-ui/icons/Print';


const useStyle = makeStyles({
    savebutton: {
      textTransform: "none",
      backgroundColor: "#EBEBEB",
      borderRadius: "8px",
      "&:hover": {
        backgroundColor: "#EBEBEB",
      },
      color:"#F7F7F7",
      border: "2px solid rgba(0, 154, 219, 0.2)",
      boxShadow: "0px 1px 0px #A7A7A7",
    },
    buttontext: {
      padding: "0.25em",
      fontWeight: 600,
      color:"#222222"
    },
  });


function GreyButton({text,handleChangeGreyButton,disable,symbol}) {
    const classes = useStyle();
  return (
    <>
    {disable ? 
    <Button 
    disabled 
    variant="contained" 
    size="large" 
    disableElevation
    disableRipple
    className={classes.savebutton} 
    fullWidth >
    <Typography variant="body2" className={classes.buttontext}>
                    {text}
    </Typography>
    </Button>
    :
    <Button 
    variant="contained" 
    size="large" 
    disableElevation
    disableRipple
    className={classes.savebutton} 
    onClick={handleChangeGreyButton}
    fullWidth
    >
    <Typography variant="body2" className={classes.buttontext}>
                    {text}{symbol&&<PrintIcon fontSize='small' style={{verticalAlign:'middle',marginLeft:"0.5rem"}}/>}
    </Typography>
    </Button>}
    </>
  )
}

GreyButton.propTypes = {
  text : PropTypes.string.isRequired,
  handleChangeGreyButton : PropTypes.func.isRequired,
  disable : PropTypes.bool.isRequired
}

export default GreyButton