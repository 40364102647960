import React from 'react'
import {Box,Modal} from '@material-ui/core';
import {showSuccessmsg} from '../../Components/notification/Notification'
import PropTypes from 'prop-types';

function SuccessModal(props) {
    const styleerrormodal = {width:"40%",position: 'absolute',top: '15%',left: '50%',transform: 'translate(-50%, -50%)', borderRadius:10, p: 3,}
  return (
    <Modal
                open={props.open}
                onClose={props.close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                  <Box sx={styleerrormodal} role="successlisting">
                  {props.successmessage && showSuccessmsg(props.successmessage)}
                  </Box>
        </Modal>
  )
}

SuccessModal.propTypes = {
  open : PropTypes.bool,
  close : PropTypes.func,
  successmessage : PropTypes.string
}

export default SuccessModal
