const REACT_APP_USER_MANAGEMENT_BASE_PATH =
  process.env.REACT_APP_USER_MANAGEMENT_BASE_PATH;
const REACT_APP_FP_SERVICES_BASE_PATH =
  process.env.REACT_APP_FP_SERVICES_BASE_PATH;
export const REACT_APP_AMPLITUDE = process.env.REACT_APP_AMPLITUDE;
export const NODE_ENV = process.env.REACT_APP_AWS_ENV;
export const REACT_APP_API_KEY_ORDER_CLOSE = process.env.REACT_APP_API_KEY_ORDER_CLOSE;


const PAGE_SIZE = 10;
export const ORDER_STATUS_KEY = "record_status";
export const ORDER_PATIENT_NAME = "patient_name";

export const ORDER_DELIVERY_DATE_KEY = "order_delivery_date";
export const ORDER_SEARCH_ID ="order_search_id"
export const ORDER_AWARD_SCRIPT_NO_KEY = "awards_script_number";

//Function for conversion of nested json objects to string for evaluating last evaluated key
export const flattenDictionaryToQueryString = (dictionary) => {
  let str = "";
  Object.entries(dictionary).forEach(([key, value]) => {
    Object.entries(value).forEach(([key1, value1]) => {
      str +=
        "lastEvaluatedKey." +
        `${key}` +
        "." +
        `${key1}` +
        "=" +
        `${value1}` +
        "&";
    });
  });
  return str;
};

export const FeatureEnabled=(name)=>{
  const features = JSON.parse(process.env.REACT_APP_FEATURE_FLAGS)
  let feture = features?.filter((feature)=>feature.name === name)
  return feture[0]?.isActive
}

export const PRODUCT_CODE='fp';
export const UserOpsRoutes = {
  USER_REGISTRATION_START_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/register/start`,
    method: "POST",
  },
  USER_REGISTRATION_COMPLETE_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/register/complete`,
    method: "POST",
  },
  USER_REFRESH_TOKEN_ENDPOINT: {
    uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/user/refresh`,
    method: "POST",
  },
  USER_LOGOUT_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/logout`,
    method: "POST",
  },
  USER_LOGIN_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/login`,
    method: "POST",
  },
  USER_FORGOT_PASS_START_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/forgotpass/start`,
    method: "POST",
  },
  USER_GET_SESSION_ID_ENDPOINT: {
    uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/user/session/email`,
    method: "POST",
  },
  STAFF_REGISTRATION_START_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/staff/register/start`,
    method: "POST",
  },
  STAFF_REGISTRATION_COMPLETE_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/staff/register/complete`,
    method: "POST",
  },
  HQ_REGISTRATION_START_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/hq/register/start`,
    method: "POST",
  },
  HQ_REGISTRATION_COMPLETE_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/hq/register/complete`,
    method: "POST",
  },
  USER_FORGOT_PASS_COMPLETE_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/forgotpass/complete`,
    method: "POST",
  },
  USER_UPDATE_ROLE_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/role`,
    method: "PUT",
  },
  USER_UPDATE_DETAILS_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user`,
    method: "PUT",
  },
  ADMIN_USER_ADD_START_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/adminadd/start`,
    method: "POST",
  },
  ADMIN_USER_ADD_COMPLETE_ENDPOINT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/adminadd/complete`,
    method: "POST",
  },
  GET_USER_DETAILS_BY_EMAIL: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/email/#email`,
    method: "GET",
  },
  GET_USER_EMAIL_IS_EXIST: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/exist/#email`,
    method: "GET",
  },GET_USER_EMAIL_IS_REGISTERED: {
    uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/user/registration/status/#email`,
    method: "GET",
  },
  GET_USER_DETAILS_BY_ID: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/id/#id`,
    method: "GET",
  },
  DELETE_USER_BY_EMAIL: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/remove/#email`,
    method: "DELETE",
  },
  LIST_USERS_BY_PHARMACY: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/pharmacy/fp/#accountNo`,
    method: "GET",
  },
  LIST_ALL_USERS: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/all/fp?pageSize=${PAGE_SIZE}`,
    method: "GET",
  },
  USER_RESET_PASSWORD_AFTER_LOGIN: {
    uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/user/changepassword`,
    method: "POST",
  },
  USER_LOGOUT: {
    uri: `${REACT_APP_USER_MANAGEMENT_BASE_PATH}/user/logout`,
    method: "POST",
  },
};

//Notication URL & Methods declaration
export const notificationServices = {
  GET_ALL_NOTIFICATIONS: {
    uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/fp/notifications/#email`,
    method: "GET",
  },
};

//FP Services URLs & Methods declaration
export const FpServicesRoutes = {
  OrderOps: {
    CREATE_ORDER_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/order`,
      method: "POST",
    },
    UPDATE_ORDER_DETAILS_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/order`,
      method: "PUT",
    },
    FORCE_CLOSE_ORDER: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/order/close`,
      method: "PUT",
    },
    DELETE_ORDER_DETAILS_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/order/#id/#email`,
      method: "DELETE",
    },
    LIST_ORDER_BY_EMAIL_ID_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/order/email/#email?pageSize=${PAGE_SIZE}`,
      method: "GET",
    },
    LIST_ALL_ORDER_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/order/all?pageSize=${PAGE_SIZE}`,
      method: "GET",
    },
    LIST_ORDER_BY_ORDER_ID_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/order/id/#id`,
      method: "GET",
    },
    LIST_ORDER_BY_PHARMACY_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/order/pharmacy/#accountNumber?pageSize=${PAGE_SIZE}`,
      method: "GET",
    },

    PHARMACY_LIST_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/pharmacy/name`,
      method: "GET",
    },
  },
  Validations: {
    VALIDATE_PHARMACY_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/validate/pharmacy`,
      method: "POST",
    },
  },
  ProductList: {
    SEARCH_PRODUCT_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/fp/search`,
      method: "POST",
    },
  },
  SearchFilter: {
    SEARCH_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/fp/#resourceType?pageSize=${PAGE_SIZE}`,
      method: "GET",
    },
  },
  Pharmacyops: {
    ADD_PHARMACY_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/pharmacygroup`,
      method: "POST",
    },
    EDIT_PHARMACY_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/pharmacygroup/id/{id}`,
      method: "PUT",
    },
    DELETE_PHARMACY_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/pharmacygroup/id/{id}/account/{accountNumber}`,
      method: "DELETE",
    },
    UPLOAD_PHARMACY_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/pharmacygroup/associate`,
      method: "POST",
    },
    GET_PHARMACY_GROUPNAME_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/pharmacygroup/pharmacy/#accountNumber`,
      method: "GET",
    },
    GET_PHARMACIES_BY_GROUPACCOUNTNUMBER_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/pharmacygroup/pharmacies/#groupAccountNumber`,
      method: "GET",
    },
    DELETE_ASSOCIATED_PHARMACY_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/fp/id/#id/email/#email/resource/pharmacy?pharmacyGroupAccountNumber=#groupAccountNumber`,
      method: "DELETE",
    },
    SAVE_ENDPOINT: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/pharmacygroup/pharmacies/{accountNumber}`,
      method: "GET",
    },
  },
};

//Specific Pharmacy Group & Commission Statement Services URLs & Methods declaration
export const pharmacyGroupServices = {
  pharmacyGroup: {
    uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/group`,
    method: "POST",
  },
};
export const commissionServices = {
  commissionOps: {
    TOTAL_COMMISSION: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/commissions/total`,
      method: "GET",
    },
  },
};


export const klaviyoServices = {
  klaviyo: {
    KLAVIYO_SUBSCRIBE: {
      uri: `${REACT_APP_FP_SERVICES_BASE_PATH}/fp/subscribe`,
      method: "POST",
    },
  },
};
