import { Grid, Paper,Button,TextField, InputAdornment  } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import React from 'react';
import {useState} from 'react'
import EmailIcon from '../../Assets/Mailicongreen.svg';
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom'; 
import {isEmpty, isEmail} from '../../Components/validation/Validation'
import {showErrMsg} from '../../Components/notification/Notification'
import { ForgotPassStart } from '../../api/userOps';
import { GetUserEmailExist } from '../../api/userOps';
import { makeStyles } from "@material-ui/core/styles";
import logEvent from '../../analytics/Analytics';


const useStyles = makeStyles((theme) => ({
  paperStyle: 
  {   
      margin:"auto",
      [theme.breakpoints.up('md')]: {
        width: "31%",
      },
      [theme.breakpoints.down('md')]: {
        width: "40%",
      },
      [theme.breakpoints.down('sm')]: {
        width: "50%",
      },
      [theme.breakpoints.down('xs')]: {
        width: "90%",
        padding:"4%",
      },
  },
  backbutton:{
    cursor: "pointer",
    textDecoration:"none", 
    color:"#5E5E5E", 
    display:"flex", 
    alignItems:"center", 
    paddingTop:130,
    [theme.breakpoints.up('md')]: {
          marginLeft:150,
        },
        [theme.breakpoints.down('md')]: {
          marginLeft:150,
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft:5,
          paddingLeft:"4%",
        },
      },
  textfield:{
    boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", 
    background:"white",
    marginTop:"30px"
    },
  Resetbutton:{
    background: "#3D8541",
    color:"white", 
    marginTop:"17px", 
    textTransform: "none"
    }
}));

const initialState = {
  email: '',
  err:'',
}

function ForgotPasswordEmail() {
    
    const classes = useStyles();

    let navigate = useNavigate()

    const [user, setUser] = useState(initialState)
    const {email,err} = user

    const handleChangeInput = e => {
      const {name, value} = e.target
      setUser({...user, [name]:value, err: '',err1: '', success: ''})
    }
    const handleSubmit = async e => {
      e.preventDefault()
    const emaillowercaser= email.toLowerCase()
    if(isEmpty(email.trim()))
            return setUser({...user, err: "Please enter your email address before continuing.", success: ''})

    if(!isEmail(email.trim()))
        return setUser({...user, err: "Invalid email", success: ''})
        logEvent("Reset password clicked")
 
        GetUserEmailExist(emaillowercaser.trim())
            .then((res) => res.json())
                    .then((data) => {
                        if (data.userExist === true) {
                            return (setUser({...user, err1: "", success: ''},
                            ForgotPassStart(email)
                            .then((res) => res.json())
                            .then(() => {
                              navigate("/checkmail",{state:{email:email}});
                            },
                            error => {
                              return setUser({...user, err: error, success: ''})
                            })
                            )
                            )
                          } else {
                            setUser({...user, err:"We don’t recognise your email address. Make sure you have entered it correctly and try again.", success: ''},
                            )
                        }
                    },
                    error => {
                        console.error('There was an error!', error);
                    });  
                  }

    return (
      <>
      <Grid>
        <Grid className={classes.backbutton} >
                    <ArrowBackIcon onClick={() => {navigate("/");}} />
                    <Typography data-testid="back-button" onClick={() => {navigate("/");}} >Back</Typography>
        </Grid>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Paper elevation={0} className={classes.paperStyle}>
                <h2 data-testid="Header" style={{fontSize: 40,margin:0, marginBottom:5, textAlign:"start"}}>Enter your email address</h2>
                <Typography variant="caption" style={{fontSize:18}}>Enter the email address you used to create your account.</Typography>

                <TextField size="small"
                classes={classes.textfield}
                placeholder="Email address" 
                variant="outlined" 
                label={isEmpty(email)?null:"Email address"}
                type="text" id="email"
                data-testid="Input"
                value={email} 
                name="email" 
                onChange={handleChangeInput}
                fullWidth
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={EmailIcon} alt="error" />
                      </InputAdornment>
                    ),
                  }}
                />
                <div data-testid="error">
                 {err && showErrMsg(err,()=>{
                   setUser({...user,err:''})
                  })}
                  </div>
                <Button className={classes.Resetbutton} variant="contained" fullWidth size="large" disableElevation type="submit" data-testid="reset-btn">Reset password</Button>
        </Paper>
        </form>
    </Grid>
    </>
    );
  }
  
  export default ForgotPasswordEmail;