import React from 'react'
import OutlinedDropDown from './OutlinedDropDwn'
import { Grid } from "@material-ui/core";
import PropTypes from 'prop-types';


function index3({itemlist,handleChangeDropDown}) {
  return (
    <Grid container md={12} sm={12} lg={12} xs={12}>
        {itemlist.map((item, index)=>{
            if(item.row){
                return(
                    <Grid item key={index} style={{marginTop:'1em'}} sm={6} md={6} xs={12} lg={6}>
                    <OutlinedDropDown label={item.label} type={item.type} value={item.value} handleChange={handleChangeDropDown} innerformat={item.innerformat}/>
                    </Grid>
                )
            }else{
                return(
                    <Grid item key={index} direction='column' style={{marginTop:'1em'}} md={12} sm={12} lg={12} xs={12}>
                    <OutlinedDropDown label={item.label} type={item.type} value={item.value} handleChange={handleChangeDropDown}/>
                    </Grid>
                )
            }
        })}
    </Grid>
  )
}

index3.propTypes = {
    itemlist : PropTypes.array,
    handleChangeDropDown : PropTypes.func
}

export default index3