import * as React from 'react';
import { useEffect } from 'react';
import Tabs from './Tabs';
import {useState} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Hidden } from '@material-ui/core';
import { logEventWithProperties } from '../../analytics/Analytics';

function Createorder(props) {

const {isSignedIn,userDetails } = props
const [user,setUser] = useState([])

  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
  window.history.go(1);
  };


  //aMPLITUDE TRACK
  useEffect(()=>{
      logEventWithProperties("User visited create order",{userId:userDetails?.id})
  },[])

  useEffect(() => {
    if (isSignedIn && props.userDetails) {
      setUser(userDetails)
    }
  }, [userDetails])
  
  
  return (
    <>
  
    <div style={{display:"grid", justifyItems:"center",paddingTop:"90px",margin:"10px"}}>
      <div style={{display:"flex", justifyContent:"right", width:"100%", color:"#5E5E5E",fontWeight:"bold" , marginTop:"2%"}}>
    <Hidden smDown implementation="css" >
      <p  style={{paddingRight:140,}}>Welcome, {user.first_name}</p>
    </Hidden>

    </div>
    <div style={{marginBottom:20}}>
      <h2>Create a new order</h2></div>
      <Tabs/>
    </div>
  </>
  );
}
const mapStateToProps = state => {
    return {
      userDetails: state.userDetails,
      isSignedIn:state.isSignedIn
    };
 };

 Createorder.propTypes = {
  userDetails: PropTypes.object,
  isSignedIn: PropTypes.bool,
  dispatch: PropTypes.func,
};

  export default connect(mapStateToProps) (Createorder);