import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import PrintIcon from "@material-ui/icons/Print";
import CreateIcon from "@material-ui/icons/Create";
const useStyles = makeStyles((theme) => ({
  tablecell: {
    backgroundColor: "#5E5E5E",
    color: "#fff",
    [theme.breakpoints.up("md")]: {
      height: "50px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "35px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "50px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "50px",
    },
  },
  image: {
    marginLeft: "0.5em",
  },
  tabledata: {
    border: "0px",
    [theme.breakpoints.up("md")]: {
      height: "50px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "35px",
    },
    [theme.breakpoints.up("sm")]: {
      height: "40px",
    },
    [theme.breakpoints.up("lg")]: {
      height: "50px",
    },
    [theme.breakpoints.up("xl")]: {
      height: "50px",
    },
  },
  tablerow: {
    "&:hover": {
      backgroundColor: (props) => (props.isLoading ? "" : "#C3D8F7"),
    },
  },
  selected: {
    "&.Mui-selected": {
      backgroundColor: "#C3D8F7",
      "&:hover": {
        backgroundColor: (props) => (props.isLoading ? "" : "#C3D8F7"),
      },
    },
  },
  container: {
    minWidth: "100%",

    [theme.breakpoints.down("md")]: {
      maxHeight: "65vh",
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: "35vh",
      width: "50vh",
      overflow: (props) => (props.body?.length > 0 ? "scroll" : "hidden"),
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: "45vh",
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: "66vh",
    },
    [theme.breakpoints.up("xl")]: {
      maxHeight: "69vh",
    },
  },
  modifiedcontainer: {
    minWidth: "100%",

    [theme.breakpoints.down("md")]: {
      maxHeight: "67vh",
      minHeight: "67vh",
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: "35vh",
      width: "50vh",
      overflow: (props) => (props.body?.length > 0 ? "scroll" : "hidden"),
    },
    [theme.breakpoints.up("sm")]: {
      maxHeight: "47vh",
      minHeight: "47vh",
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: "48vh",
      minHeight: "48vh",
    },
    [theme.breakpoints.up("xl")]: {
      maxHeight: "32vh",
      minHeight: "32vh",
    },
  },
}));

function Tablecomponent(props) {
  const classes = useStyles(props);
  const [selected, setSelected] = useState(undefined);
  const recordType = props.recordType;
  const handleonClick = () => {};

  return (
    <>
      <div style={{ position: "relative" }}>
        <TableContainer
          className={
            recordType === "pharmacy_association" && props.body?.length > 0 
              ? classes.modifiedcontainer
              : classes.container
          }
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {props.head.map((item, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    onClick={() => {
                      handleonClick(item);
                    }}
                    className={classes.tablecell}
                  >
                    {item.value}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              style={
                props.loading === true
                  ? { left: "0", color: "black", minHeight: "", opacity: "0.4" }
                  : { background: "white", width: "100%" }
              }
            >
              {props.body &&
                props.body?.length > 0 &&
                props.body?.map((data, index) => {
                  return (
                    <TableRow
                      key={index}
                      onClick={() => setSelected(index)}
                      selected={index === selected}
                      className={
                        selected === index
                          ? [classes.tablerow, classes.selected]
                          : classes.tablerow
                      }
                    >
                      {Object.values(data).map((items, i) => {
                        if (items === "edit-img") {
                          return (
                            <TableCell
                              key={i}
                              align="center"
                              className={classes.tabledata}
                            >
                              <CreateIcon style={{ color: "#007BB8" }} />
                            </TableCell>
                          );
                        } else if (items === "print-img") {
                          return (
                            <TableCell
                              key={i}
                              align="center"
                              className={classes.tabledata}
                            >
                              <PrintIcon style={{ color: "#007BB8" }} />
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={i}
                            align="center"
                            className={classes.tabledata}
                          >
                            {items}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}

              {props.loading === true && (
                <div style={{ textAlign: "center", minHeight: "100px" }}>
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      top: "40%",
                      left: "0",
                    }}
                  >
                    <h4>
                      <CircularProgress />
                      <br></br>
                      Loading...
                    </h4>
                  </div>
                </div>
              )}

              {props.body?.length === 0 &&
                props.loading === false &&
                !props.noData && (
                  <div style={{ height: "80px" }}>
                    <div style={{ textAlign: "center" }}>
                      <div style={{ position: "absolute", width: "100%" }}>
                        <h4>{!props.noData && "No more data"}</h4>
                      </div>
                    </div>
                  </div>
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
Tablecomponent.propTypes = {
  loading: PropTypes.bool,
  body: PropTypes.array,
  head: PropTypes.array,
  noData: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default Tablecomponent;
