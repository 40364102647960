import React, { useEffect, useState } from 'react';
import { Grid, Typography, Paper, Button, Dialog, TextField, InputLabel, MenuItem, Select, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/KeyboardArrowLeft";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Modal2 from '../../../Components/Modal/Modal2';
import { DeleteAssociatedPharmacy, EditassociatedpharmacyAPi, } from '../../../api/pharmacyops';
import {
  FormControl,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import Edit from "../../../Assets/Edit.svg";
import Delete from "../../../Assets/DeleteIcon.svg";
import {
  isEmail,
  isEmpty,
  isSpace,
  // isSpace,
} from "../../../Components/validation/Validation";
import {
  showErrMsg,
  // showErrMsg,
  showErrMsgEmpty,
  showSuccessmsg,
  // showSuccessmsg,
} from "../../../Components/notification/Notification";
import { ValidatePharmacyDetails } from '../../../api/validations';
import { getUserDetails } from '../../../api/getUserDetails';

const useStyles = makeStyles((theme) => ({
  box: {
    border: "1px solid #CDCDCD",
    borderRadius: 10,
    margin: 'auto',
    color: "#5E5E5E",
    background: "#FCFCFC",
    padding: "1rem",
  },
  tablesstyle: {
    border: "1px solid #CDCDCD",
    borderRadius: 10,
    margin: 'auto',
    marginBottom: '1rem',
    color: "#5E5E5E",
    background: "#FCFCFC",
  },
  backbutton: {
    textDecoration: "none",
    color: "#5E5E5E",
    display: "flex",
    alignItems: "center",
    paddingTop: 80,
    [theme.breakpoints.up('md')]: {
      marginLeft: 150,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 150,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      paddingLeft: "4%",
    },
  },
  removebutton: {
    backgroundColor: "#fff",
    textTransform: "none",
    color: "#BB4035",
    fontWeight: "600",
    borderWidth: "0px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  tabledata: {
    border: "0px",
    padding: "10px",
  },
  addbutton: {
    backgroundColor: "#007BB8",
    textTransform: "none",
    // marginTop: "1em",
    boxShadow: '0px 2px 0px #005179, inset 0px 1px 0px #009ADB',
    borderRadius: '4px',
    color: "#fff",
    "&:hover": {
      backgroundColor: "#007BB8",
    },
  },
  savebutton: {
    textTransform: "none",
    boxShadow: '0px 2px 0px #306B33, inset 0px 1px 0px #62B767',
    borderRadius: "4px",
    height: "3rem",
    backgroundColor: "#3D8541",
    color: "white",
    "&:hover": {
      backgroundColor: "#3D8541",
    },
  },
  dialog:{
    opacity:0.5
  }
}));



function Editassociatedpharmacy() {
  let classes = useStyles()
  const location = useLocation();
  const state = location?.state?.item;
  const groupDetails = location?.state?.groupDetails;
  let navigate = useNavigate();
  const [open, setOpen] = useState(false)
  const [userType, setUserType] = useState("");
  const [email, setEmail] = useState("");
  const [emailerror, setemailerror] = useState("");
  const [loading,setIsLoading] = useState(false)

  const initialState = {
    Accountnumber: state?.pharmacy_account_number,
    Pharmacyname: state?.pharmacy_name,
    Postcode: state?.pharmacy_post_code,
    err: "",
    err1: "",
    success: "",
  };

  const [user, setUser] = useState(initialState);
  const { Accountnumber, Pharmacyname, Postcode, err1, success,
    // err,
  } = user;
  const [userTypeEror, setUserTypeerror] = useState("");

  useEffect(() => {
    let Useremails = state.user_emails.map((value) => {
      return {
        id: lists.length,
        user: "standard",
        email: value
      }
    })

    let Adminemails = state?.admin_user_emails.map((value) => {
      return {
        id: lists.length,
        user: "admin",
        email: value,
      }
    })

    let datas = [...Useremails, ...Adminemails]


    setLists(datas)

  }, [])



  const [lists, setLists] = useState([]);

  const handledelete = () => {
    DeleteAssociatedPharmacy(state.id, state.pharmacy_group_account_number).then(() => {
      setOpen(false)
      setTimeout(() => {
        return navigate('/group/pharmacy', { state: { groupDetails: groupDetails } })
      }, 2000);
    })
  }

  const adduser = (user, email) => {
    return {
      id: lists.length,
      user: user,
      email: email,
    };
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value, err: "" });
  };
  // console.log("state", state)
  console.log("list", lists)
  console.log("groupDetails", groupDetails)
  // console.log("useremails", useremails)

  const handleadd = () => {
    setUser({
      ...user,
      success: "",
      err1: "",
    });
    if (isEmpty(email.trim())) {
      return setemailerror("Please enter a email address.");

    }
    if (!isEmail(email.trim())) {
      return setemailerror("Please enter a email address.");

    }
    if (!userType) {
      // setShowRole(true)
      return setUserTypeerror("Please select type of user.");

    }

    if (isEmail(email.trim())) {
      setLists((prevState) => [...prevState, adduser(userType, email)]);
      setUserType("");
      setemailerror("");

      setEmail("");
    }

  };

  const editProduct = (user) => {
    let newlist = lists.filter((item) => item.email !== user.email);
    setLists(newlist);
    // setUser(user);
    setEmail(user.email);
  };

  const handledeleteprodect = (deleteindex) => {
    const filteredList = lists.filter((x, index) => {
      if (deleteindex !== index) {
        return x;
      }
    });
    setLists(filteredList);
  };

  const getAdminUSer = () => {
    let admins = lists?.filter((item) => item.user === "admin");
    return admins.map((item) => item.email).join(";");
  };
  const getUSers = () => {
    let admins = lists?.filter((item) => item.user !== "admin");
    return admins.map((item) => item.email).join(";");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true)
    let body = JSON.stringify({
      createdBy: state.created_by,
      pharmacyName: Pharmacyname,
      pharmacyAccountNumber: Accountnumber,
      adminUsers: getAdminUSer(),
      users: getUSers(),
      pharmacyPostCode: Postcode,
      pharmacyGroupAccountNumber: state.pharmacy_group_account_number,
      pharmacyGroupName: state.pharmacy_group_name,
      // createdDateString: state.created_date_string,
      // id:state.id,
      // email: state.email,
      // createdDate: state.created_date,
      // pharmacyGroupSearchId:state.pharmacy_group_search_id,
      // recordType:state.record_type,
      // associationStatus:state.association_status
    })

    if (isEmpty(Pharmacyname) || isEmpty(Postcode) || isEmpty(Accountnumber)) {
      setUser({
        ...user,
        success: false,
        err: "This field is required",
        err1: "Please check the information above is correct",
      });
    } else if (isSpace(Pharmacyname) || isSpace(Postcode) || isSpace(Accountnumber)) {
      setUser({
        ...user,
        success: false,
        err1: "Please check the information above is correct",
      });
    }
    else {
      ValidatePharmacyDetails(
        Pharmacyname,
        Accountnumber,
        Postcode,
        getUserDetails().email
      )
        .then((res) => res.json())
        .then((data) => {
          setIsLoading(false)
          if (data.pharmacyDetails) {
            EditassociatedpharmacyAPi(body, state.id)
              .then(res => res.json())
              .then(data => {
                // setLoading(false)
                if (data.message) {
                  setUser({
                    ...user,
                    success: "success",
                    err: "",
                    err1: "",
                  });
                  setTimeout(() => {
                    return navigate('/group/pharmacy', { state: { groupDetails: groupDetails } })
                  }, 2000);
                }
                else {
                  // setError("Sorry, We could not update your users details")
                }
              })
          } else {
            setUser({
              ...user,
              success: "",
              err: "",
              err1: "Pharmacy postcode and account number don’t match. Check you have entered the correct information.",
            });
          }
        }
        )
    }



  }




  return (
    <Grid style={{ paddingTop: '3em' }}>
      <Grid className={classes.backbutton} >
        <ArrowBackIcon onClick={() => {
          navigate("/group/pharmacy", {
            state: { groupDetails: groupDetails },
          })
        }} />
        <Typography onClick={() => {
          navigate("/group/pharmacy", {
            state: { groupDetails: groupDetails },
          })
        }}>Back</Typography>
      </Grid>

      <form onSubmit={handleSubmit}>
        <Paper elevation={0}>

          <Grid md={5} lg={5} xs={10} sm={8} style={{ margin: 'auto' }}>
            <Typography data-testid="header" variant="h4" style={{ fontWeight: 700, letterSpacing: '0.005em', lineHeight: '120%' }}>Edit associated pharmacy</Typography>
            <Typography variant="body2" style={{ marginBottom: '1em', marginTop: '0.5em' }} data-testid="innertext">
              Update or change the pharmacy admins name and email address.
            </Typography>
          </Grid>

          <Grid md={5} lg={5} xs={10} sm={8} style={{ margin: "auto" }}>
            <Typography variant="h6" style={{ fontWeight: 700, letterSpacing: '0.005em', lineHeight: '120%', marginBottom: '0.5em' }}>
              Associated pharmacy details
            </Typography>
            <TextField
              fullWidth
              style={{
                marginBottom: "1em",
                backgroundColor: "#FFFFFF",
                boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
                borderRadius: "6px",
              }}
              //   error={isEmpty(pharm_name) && err ? true : false}
              placeholder="Account number"
              //   label={isEmpty(pharm_name) ? null : "Pharmacy name"}
              label="Account number"
              type="text"
              name="Accountnumber"
              value={Accountnumber}
              onChange={(e) => handleChangeInput(e)}
              variant="outlined"
            />
            {/* {isEmpty(pharm_name) && showErrMsgEmpty(err)} */}

            <TextField
              fullWidth
              style={{
                marginBottom: "1em",
                backgroundColor: "#FFFFFF",
                boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
                borderRadius: "6px",
              }}
              //   error={isEmpty(accno) && err ? true : false}
              placeholder="Pharmacy name"
              //   label={isEmpty(accno) ? null : "Account number"}
              label="Pharmacy name"
              type="text"
              name="Pharmacyname"
              value={Pharmacyname}
              onChange={(e) => handleChangeInput(e)}
              variant="outlined"
            />
            {/* {isEmpty(accno) && showErrMsgEmpty(err)} */}
            <TextField
              fullWidth
              style={{
                marginBottom: "1em",
                backgroundColor: "#FFFFFF",
                boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
                borderRadius: "6px",
              }}
              //   error={isEmpty(postcode) && err ? true : false}
              placeholder="Post code"
              //   label={isEmpty(postcode) ? null : "Postcode"}
              label="Post code"
              type="text"
              name="Postcode"
              value={Postcode}
              onChange={(e) => handleChangeInput(e)}
              variant="outlined"
            />
            {/* {isEmpty(postcode) && showErrMsgEmpty(err)} */}
            <Typography variant="h6" style={{ fontWeight: 700, letterSpacing: '0.005em', lineHeight: '120%', marginBottom: '0.5em' }}>
              Add Users
            </Typography>
            <TextField
              style={{
                marginBottom: "1em",
                backgroundColor: "#FFFFFF",
                boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
                borderRadius: "6px",
              }}
              fullWidth
              type="text"
              value={email}
              onChange={(e) => {
                setemailerror("");
                setEmail(e.target.value);
                //     setItems({
                //       ...items,
                //       success: "",
                //       err1: "",
                // });
              }
              }
              placeholder="Email address"
              label="Email address"
              variant="outlined"
            />
            {emailerror && showErrMsgEmpty(emailerror)}
          </Grid>

          <Grid
            container
            md={5}
            lg={5}
            xs={10}
            sm={8}
            style={{ margin: "auto", marginBottom: "1rem" }}
          >

            <Grid
              item
              style={{
                paddingLeft: 0,
                width: "50%",
              }}
            >
              <FormControl label="Type of User" fullWidth style={{ boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", }} variant="outlined" size="medium">
                <InputLabel label="Type of User" id="demo-simple-select-label">Type of User</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  data-testid="show-role"
                  id="demo-simple-select"
                  value={userType}
                  onChange={(e) => {
                    setUserType(e.target.value);
                    //   setUserTypeerror(""); 
                    //   setUser({
                    //   ...user,
                    //   success: "",
                    //   err1: "",
                    // });
                  }}
                  name="user"
                  label="Type of User"
                >
                  <MenuItem value={"admin"} data-testid="account-admin">Admin</MenuItem>
                  <MenuItem value={"standard"}>Standard</MenuItem>
                </Select>
              </FormControl>
            </Grid>


            <Grid item style={{ marginLeft: "auto", }}>
              <Button
                data-testid="add-user-btn"
                variant="contained"
                onClick={handleadd}
                disableRipple
                size="large"
                className={classes.addbutton}
              >
                Add User
              </Button>

            </Grid>
            {userTypeEror && showErrMsg(userTypeEror, () => {
              setUserTypeerror("")
            })}
          </Grid>


          {lists.length > 0 ? (
            <Grid md={5} lg={5} xs={10} sm={8} style={{ margin: 'auto' }}>
              <Grid item style={{ marginTop: "1em", marginBottom: "1rem" }}>
                <TableContainer
                  component={Paper}
                  elevation={2}
                  style={{
                    border: "1px solid #CDCDCD",
                    borderRadius: 4,
                    color: "#5E5E5E",
                  }}
                >
                  <Table>
                    <TableBody>
                      {lists?.map((value, index) => (
                        <TableRow key={index} style={{ width: "40px" }}>
                          <TableCell
                            align="center"
                            className={classes.tabledata}
                          >
                            {value?.user[0]?.toUpperCase() + value?.user?.substring(1)}
                            {/* {value?.user} */}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tabledata}
                          >
                            {value?.email}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.tabledata}
                          >
                            <img
                              src={Edit}
                              onClick={() => editProduct(value)}
                              alt="error"
                              style={{
                                cursor: "pointer",
                                height: "10px",
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tabledata}
                          >
                            <img
                              src={Delete}
                              alt="error"
                              onClick={() => handledeleteprodect(index)}
                              style={{ cursor: "pointer" }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>


            </Grid>
          ) : undefined}

          <Grid md={5} lg={5} xs={10} sm={8} style={{ margin: 'auto' }}>
            {err1 &&
              showErrMsg(err1, () => {
                setUser({
                  ...user,
                  success: false,
                  err: "",
                  err1: ""
                });
              })}
            {success &&
              showSuccessmsg(
                "Successfully Edited the associted pharmacy details"
              )}
          </Grid>



          <Grid md={5} lg={5} xs={10} sm={8} style={{ margin: 'auto' }}>
            <Button
              data-testid="mainbtn"
              variant="contained"
              fullWidth
              size="large"
              disableRipple
              disableElevation
              //   disabled = {isEdit && isDisabledSaveChanges}
              //   className={isEdit && isDisabledSaveChanges ? classes.disabledsavebutton : classes.savebutton}
              className={classes.savebutton}
              type="submit"
            >
              Save changes
            </Button>
            {/* {isEdit &&  */}
            <Button
              style={{ marginTop: '1em' }}
              fullWidth
              variant="outlined"
              disableRipple
              disableElevation
              className={classes.removebutton}
              onClick={() => setOpen(true)}
            >
              Remove group
            </Button>
          </Grid>
        </Paper>
      </form>

      <Dialog open={open} fullWidth>
        <Modal2
          handleChangeWhiteButton={() => setOpen(false)}
          heading={`Are you sure you want to remove "${state?.pharmacy_name}" from this account?`}
          para={"All data from this group will be removed from this account. "}
          buttonWhitetext={"Cancel"}
          buttonGreentext={"Remove"}
          Column={true}
          handleChangeGreenButton={() => handledelete()}
        />
      </Dialog>
      <Dialog fullScreen open={loading} classes={{ paperFullScreen: classes.dialog }}>
        <Grid style={{ margin: 'auto' }}>
          <Typography style={{ fontWeight: 600 }}><CircularProgress /><br /><br />Loading...</Typography>
        </Grid>
      </Dialog>
    </Grid>
  )
}

export default Editassociatedpharmacy
