
let tokenExpireTimerId = null
export function clearLoginTimer(){
    clearTimeout(tokenExpireTimerId)
    tokenExpireTimerId= null
}
export function startLoginTimer(onTimerEnd,expiresIn) {

let time =expiresIn-(expiresIn*10)/100

tokenExpireTimerId = setTimeout(()=>{
    clearTimeout(tokenExpireTimerId)
    tokenExpireTimerId= null
    onTimerEnd()

    
},time*1000)
}
