import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputAdornment,
  Grid,
  Tooltip,
  Typography,
  Badge,
  makeStyles
} from "@material-ui/core";
import { KeyboardArrowDown, SearchOutlined } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import FilterIcon from "../../Assets/filtergreyicon.svg";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import FilterMonthComponent from "./FilterMonthComponent";
import PropTypes from "prop-types";
import livehelpblackicon from "../../Assets/livehelpblackicon.svg";
import { withStyles } from '@material-ui/core/styles';
import { logEventWithProperties } from "../../analytics/Analytics";


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#EBF8FF",
    maxWidth: 'none',
    fontSize: theme.typography.pxToRem(2),
    border: "1px solid #dadde9",
    color: '#5E5E5E',
    marginTop:'-10px',
  },
}))(Tooltip);


const useStyles = makeStyles((theme)=>({
  filterBar:{ display: "flex", 
  height: "40px", 
  justifyContent: "right",
  [theme.breakpoints.only('md')]: {
  justifyContent: "right",
   
      
    },
    [theme.breakpoints.only('xs')]: {
  justifyContent: "left",
   
      
    },
    [theme.breakpoints.only('sm')]: {
  justifyContent: "left",
    
      
    },
    [theme.breakpoints.only('lg')]: {
  justifyContent: "right",
    
      
    },  
    [theme.breakpoints.only('xl')]: {
  justifyContent: "right",
   
      
    },
}
}))
function FilterBar(props) {
  const classes = useStyles()
  let navigate = useNavigate();
  const [isFilterApplied, setIsFilterApplied] =
    props.recordType === "user"
      ? useState(props.isUserFilterApplied)
      : useState(props.isOrderFilterApplied);

  useEffect(() => {
    props.recordType === "user"
      ? setIsFilterApplied(props.isUserFilterApplied)
      : setIsFilterApplied(props.isOrderFilterApplied);
  }, [props]);

  return (
    <>
      <Grid
        container
        style={
          { display: "flex", alignItems: "center", marginTop: 10 } &&
          props.recordType === "pharmacy_group"
            ? { marginBottom: -25 }
            : null
        }
      >
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="body2" style={{ color: "#5E5E5E", fontSize: 13 }}>
            {props.subtext}
          </Typography>
          {props.recordType === "user" ? (
            <Button
              variant="contained"
              onClick={() => {
                logEventWithProperties("Add new user clicked")
                navigate("/adduser");
              }}
              disableElevation
              style={{
                background: "#3D8541",
                fontSize: "18px",
                cursor: "pointer",
                color: "white",
                textTransform: "none",
                boxShadow: "0px 2px 0px #306B33, inset 0px 1px 0px #62B767",
              }}
            >
              Add a new user
            </Button>
          ) : null}
          {props.recordType === "commission_statement" ? (
            <FilterMonthComponent
              onSelect={props.onDateSelect}
              filterClicked={props.calendarOpen}
            />
          ) : null}
          {props.recordType === "pharmacy_group" ? (
            <Button
              variant="contained"
              onClick={() => {
                navigate("/addpharmacygroup");
              }}
              disableElevation
              style={{
                background: "#3D8541",
                fontSize: "18px",
                cursor: "pointer",
                color: "white",
                textTransform: "none",
                boxShadow: "0px 2px 0px #306B33, inset 0px 1px 0px #62B767",
              }}
            >
              Add a new group
            </Button>
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          className={classes.filterBar}
        >

          {!props.hideFilter && (
            <Badge variant="dot" invisible={!isFilterApplied} color="primary">
              <Button
              size="large"
                variant="outlined"
                style={{ textTransform: "capitalize" , border: '2px solid #007BB8',
                boxShadow: '2px 2px 7px rgba(205, 205, 205, 0.5)',
                borderRadius: '10px',height:'3em' }}
                onClick={() => props.filterClicked()}
              >
                <img src={FilterIcon} height={22}/>
                <Typography style={{fontWeight:600, marginRight:'0.25em',marginLeft:'0.25em'}}>Filter</Typography> <KeyboardArrowDown style={{color:'#007BB8'}}/>
              </Button>
            </Badge>
          )}

          {!props.hideSearch && (
            <TextField
              style={{
                marginLeft: "1em",
                boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)",
                border: '1px solid #fff',
                borderRadius: '4px',
                width: '35%',
                boxSizing: 'border-box'
              }}
              placeholder={
                props.recordType
                  ? `Search ${props.recordType?.replaceAll("_", " ")}`
                  : "Search"
              }
              variant="outlined"
              onChange={(e) => {
                if (!e.target.value) props.onSearchTextChanged(e.target.value);
              }}
              type="text"
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  props.onSearchTextChanged(e.target.value);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <HtmlTooltip
                    placement="bottom-end"
                      title={
                        <React.Fragment>
                            <Grid container>
                            <Grid item>
                           <img src={livehelpblackicon} alt="Question"/>
                           </Grid>
                           <Grid item>
                           <Typography variant="body2" style={{fontWeight:400}}>
                          {props.searchPlaceHolder}
                           </Typography>
                            </Grid>
                            </Grid>
                        </React.Fragment>
                      }
                    >
                      <InfoIcon style={{ color: "#CDCDCD" }} />
                    </HtmlTooltip>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchOutlined style={{ color: "grey" }} />
                  </InputAdornment>
                ),
              }}
              size="large"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.isSignedIn,
    userDetails: state.userDetails,
    isUserFilterApplied: state.isUserFilterApplied,
    isOrderFilterApplied: state.isOrderFilterApplied,
  };
};

FilterBar.propTypes = {
  userDetails: PropTypes.object,
  recordType: PropTypes.string,
  searchPlaceHolder: PropTypes.string,
  subtext: PropTypes.string,
  filterClicked: PropTypes.func,
  onSearchTextChanged: PropTypes.func,
  hideFilter: PropTypes.any,
  hideSearch: PropTypes.any,
  isUserFilterApplied: PropTypes.bool,
  isOrderFilterApplied: PropTypes.bool,
  onDateSelect: PropTypes.func,
  calendarOpen: PropTypes.func,
};

export default connect(mapStateToProps)(FilterBar);
