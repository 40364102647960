import { FeatureEnabled } from "../api/constants";
import { GetAssociatedPharmaciesbyGroupAccountnumber } from "../api/pharmacyops";
import { GetUserDetailsByEmail, Login, refreshToken } from "../api/userOps";
import { clearNotificationPolling, notificationPolling } from "../Utilities/NotificationUtils";
import { startLoginTimer } from "../Utilities/TokenExpireTimer";
import { isTokenRefreshing, setAssociatedPharmacies, setIsSIgnedIn, setLoginError, setNotifications, setToken, setUserDetails, setUSerLoginDetails, updateToken } from "./actions/actions";
import {setUserId } from "../analytics/Analytics";

export function getLoginUserDetails(emaillowercaser) {

  return (dispatch, getState) => {
    let token = getState().token.AccessToken
    GetUserDetailsByEmail(emaillowercaser, token)
      .then((res) => res.json())
      .then((data) => {

        dispatch(setUserDetails(data.userDetails))
        dispatch(setLoginError({ isError: false, error: "" }))

        let role = data.userDetails?.role
        let notifications = data.userDetails?.notifications
        dispatch(setNotifications(notifications ? notifications : []))
        if (role === 'fp@wardleHqAdmin' || role === 'fp@wardleHqUser') {
          const pharmacy_group_account_number = data.userDetails?.pharmacy_group_account_number
          GetAssociatedPharmaciesbyGroupAccountnumber(pharmacy_group_account_number)
            .then((res) => res.json())
            .then((data) => {
              dispatch(setAssociatedPharmacies(data))
            })
        }
        FeatureEnabled("Notifications") && notificationPolling(dispatch)
        dispatch(setIsSIgnedIn(true))
     
      })
  }
}

// eslint-disable-next-line no-unused-vars
export function Relogin(email, password) {
  return (dispatch, getState) => {

    dispatch(LoginUser(getState().userLoginDetails?.userEmail, getState().userLoginDetails?.userPassword))
  }
}

export function LoginUser(email, password, reTryCall) {
  return (dispatch, getState) => {
    let state = getState()
    Login(email, password, state)
      .then((res) => res.json())
      .then((data) => {
        setUserId({"email":email})
        if (data.AccessToken !== undefined) {
          dispatch(setToken(data))
          dispatch(setIsSIgnedIn(true))
          dispatch(setLoginError({ isError: false, error: "" }))
          dispatch(setUSerLoginDetails({ userEmail: email, userPassword: password }))
          startLoginTimer(() => {
            dispatch(OnTokenExpire(reTryCall))
          }, data.ExpiresIn)


        } else {

          const error = data.error.message;
          dispatch(setLoginError({ isError: true, error: error }))

        }
      },
        error => {
          error = error.message;
          dispatch(setLoginError({ isError: true, error: error }))

        });
  }
}


export const OnTokenExpire = (callback) => {
  return (dispatch, getState) => {
    dispatch(isTokenRefreshing(true))
    refreshToken(getState().token?.sessionId) 
    .then((res) => res.json())
      .then((data) => {

        if (data.AccessToken !== undefined) {
          dispatch(updateToken(data))
          dispatch(setIsSIgnedIn(true))
          dispatch(isTokenRefreshing(false))

          dispatch(setLoginError({ isError: false, error: "" }))
          startLoginTimer(() => {
            dispatch(OnTokenExpire(callback))
          }, data.ExpiresIn)
          callback && callback()
        }else if(data.error.errorCode === "SessionLoggedOut" || data.error.errorCode === "KeyError"){
          dispatch(setIsSIgnedIn(false))
            dispatch(setUserDetails({}))
            dispatch(setToken())
            dispatch(setIsSIgnedIn(false))
            clearNotificationPolling()
            window.location.replace("/")
        }
      })
  }
}