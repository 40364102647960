import React, { useEffect, useState } from "react";
import "./Yourstatements.css";
import { Grid, Paper, Typography } from "@material-ui/core";
import { connect } from 'react-redux';
import ViewAsset from '../../Assets/ViewAsset.svg'
import RemoveAsset from '../../Assets/RemoveAsset.svg'
import DetailsComp from "../../Utilities/DetailsComp";
import ListComponent from "../../Components/ListComponent/ListComponent";
import { months } from "../../Components/Calendar/CalendarComponent";
import { GetTotalCommission } from "../../api/commissionstatementops";
import { debugBase64 } from "../../Utilities/fileUtilitties.js";

function yourstatement(props) {
  const { userDetails } = props
  const name = userDetails.pharmacy_name
  const account = userDetails.pharmacy_account_number
  const role = userDetails.role
  const pharmacy_group_name = userDetails.pharmacy_group_name

  const [totalComission, setTotalCommission] = useState(0)
  const [filterParams, setFilterParams] = useState(props.commisionFilter)
  const [commission_cycle, setCommissionCycle] = useState(months[new Date().getMonth()] + ". " + new Date().getFullYear() + "-" + months[new Date().getMonth()] + ". " + new Date().getFullYear())
  useEffect(() => {
    let cycle = ''
    if (props.commisionFilter && props.commisionFilter?.toDate && props.commisionFilter?.fromDate) {
      let from = props.commisionFilter.fromDate?.split("/")
      let fromMonth = months[from[1] - 1]
      let fromYear = from[0]
      cycle = cycle + fromMonth + ". " + fromYear

      let to = props.commisionFilter.toDate?.split("/")
      let toMonth = months[to[1] - 1]
      let toYear = to[0]
      cycle = cycle + "-" + toMonth + ". " + toYear

      setCommissionCycle(cycle)

    }
  }, [props.commisionFilter])


  useEffect(() => {
    let pharmacies = props.associatedPharmacies?.length > 0 ?
      props.associatedPharmacies?.map((item) => item.pharmacy_account_number)
      : props.userDetails.pharmacy_account_number

    let filter = { pharmacyAccountNumber: pharmacies }
   if (props.commisionFilter && props.commisionFilter?.fromDate && props.commisionFilter?.toDate) {
      filter ={ ...filter, ...{ commissionCycle: props.commisionFilter.fromDate + "," + props.commisionFilter.toDate } }
   }
   GetTotalCommission(filter).then((res) => res.json()).then((data) => {
    //props.dispatch(setComissionFilter({fromDate:data?.start_cycle,toDate:data?.end_date}))
    setTotalCommission(data?.total_commissions)
  })
    // } else {
    //   searchfilter("commission_statement", "filter", null, "", "", pharmacies)
    //     .then((res) => res.json())
    //     .then((data) => {
    //       props.dispatch(setComissionFilter({ fromDate: data?.items[0]?.start_cycle, toDate: data?.items[0]?.end_cycle }))
    //       props.dispatch(setisComissionFilter(false))

    //       setTotalCommission(data?.items[0]?.total_commissions)
    //     })

    // }

  }, [props.isComissionFilterApplied])

  useEffect(() => {
    setFilterParams(props.commisionFilter)
  }, [props.commisionFilter])

  function Righthead() {
    return (
      <Grid item >
        <Grid container align="end" direction="column">
          <Grid item align="start" >
            <Typography variant="h6" style={{ color: "#5E5E5E", fontWeight: 600 }}>
              {role === "fp@wardleAdmin" || role === "fp@wardleUser" ? "Commission" : "Earned in comission"}
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: ".5em" }} xs={12}>
            <Paper
              elevation={2}
              style={{
                padding: "5px",
                border: "1px solid #CDCDCD",
                boxShadow: "2px 2px 7px #EAEAEA",
                borderRadius: "10px",
              }}
              align="center"
            >
              <Typography variant="h4" style={{ fontweight: 700 }}>
                £{totalComission}
              </Typography>
              <Typography style={{ color: "#3D8541", fontWeight: 500 }}>
                {commission_cycle}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    )
  }


  function ViewDownloadFunction(item) {
    return (
      <Grid container justifyContent='center' style={{ gap: "0.5em" }}>
        <Grid item>
          <a
            onClick={() => debugBase64(`data:application/pdf;base64,${item["base64_encoded_file_content"]}`)}
            onKeyPress={() => debugBase64(`data:application/pdf;base64,${item["base64_encoded_file_content"]}`)}>

            <img src={ViewAsset} alt='View' style={{cursor:"pointer"}} /></a>
          <a href={`data:application/pdf;base64,${item["base64_encoded_file_content"]}`} download={item.commission_statement_file_name}> <img src={RemoveAsset} alt='Download' /></a>
        </Grid>
      </Grid>
    )
  }

  function subheading() {
    return (role === "fp@wardleAdmin" || role === "fp@wardleUser" ?
      <DetailsComp items={[{ key: "Pharmacy name:", value: name }, { key: "Account number:", value: account }]} />
      :
      <Grid item >
        <Typography
          style={{ color: "#5E5E5E", fontWeight: 500 }}
          variant="body1"
        >
          On this page you can view and download statements for your pharmacy.
        </Typography>
      </Grid>

    )
  }
  function Overheading() {
    return (role === "fp@wardleAdmin" || role === "fp@wardleUser" ?
      null
      :
      <Grid item>
        <Typography
          variant="h5"
          style={{ color: "#5E5E5E", fontWeight: 600, marginTop: "1em", marginBottom: "0.2em" }}
        >
          {pharmacy_group_name}
        </Typography>
      </Grid>
    )
  }

  return (
    <>
      {(role === "fp@wardleAdmin" || role === "fp@wardleUser") ?
        <ListComponent
          hideFilter
          reducedWidth
          hideSearch={role === 'fp@wardleAdmin'}
          heading={"Your statements"}
          subheading={subheading()}
          EditFunction={ViewDownloadFunction}
          Righthead={Righthead}
          Overheading={Overheading}
          searchPlaceHolder={
            "Search by account number or pharmacy name"
          }
          filterParams={filterParams}
          recordType="commission_statement"
          operation="filter"
          head={[{ value: "Reference", issortable: false },
          { value: "Commission total", issortable: false },
          { value: "View/Download", issortable: false }]}
        /> : null}
      {(role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser") ?
        <ListComponent
          hideFilter
          reducedWidth
          // hideSearch={role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser"}
          heading={"Your statements"}
          subheading={subheading()}
          EditFunction={ViewDownloadFunction}
          Righthead={Righthead}
          Overheading={Overheading}
          recordTypenew="commission_statementnew"
          searchPlaceHolder={
            "Search by account number or pharmacy name"
          }
          filterParams={filterParams}
          recordType="commission_statement"
          operation="filter"
          head={[{ value: "Reference", issortable: false },
          { value: "Account number", issortable: false },
          { value: "Pharmacy name", issortable: false },
          { value: "Commission total", issortable: false },
          { value: "View/Download", issortable: false }]}

        /> : null}
    </>
  );
}
const mapStateToProps = state => {
  return {
    userDetails: state.userDetails,
    commisionFilter: state.commisionFilter,
    isComissionFilterApplied: state.isComissionFilterApplied,
    isUserFilterApplied: state.isUserFilterApplied,

    associatedPharmacies: state.associatedPharmacies,

  };
};
export default connect(mapStateToProps)(yourstatement);
