import { FpServicesRoutes } from "./constants";
import { getHeaders } from "./getHeaders";
import { handleFetch } from "./HandleApiCall";

//Function for creating Stock Amnesty
export function CreateStockAmnestyList(object) {
  let headers = { "Content-Type": "application/json" };
  const requestOptions = {
    method: FpServicesRoutes.OrderOps.CREATE_ORDER_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify(object),
  };
  return handleFetch(
    FpServicesRoutes.OrderOps.CREATE_ORDER_ENDPOINT.uri,
    requestOptions
  );
}

//Function for updating Stock Amnesty
export function UpdateStockAmnestyDetails(object) {
  let headers = { "Content-Type": "application/json" };
  const requestOptions = {
    method: FpServicesRoutes.OrderOps.UPDATE_ORDER_DETAILS_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify(object),
  };
  return handleFetch(
    FpServicesRoutes.OrderOps.UPDATE_ORDER_DETAILS_ENDPOINT.uri,
    requestOptions
  );
}
