import React from "react";
import {
  Paper,
  Grid,
  Typography
} from "@material-ui/core";
import ListComponent from "../../../Components/ListComponent/ListComponent";
import { useNavigate } from "react-router-dom";
import { Visibility } from '@material-ui/icons';
import { logEventWithProperties } from "../../../analytics/Analytics";




function PharmacyGroup() {
   
  const navigate = useNavigate()

  function EditFunction(item) {
    return (
      <Visibility
        onClick={() => {
          logEventWithProperties("Pharmacy group viewed ",item?.pharmacyGroupAccountNumber)
          navigate(`/group/pharmacy`, {
            state: {groupDetails:item},
          });
        }}
        style={{ color: "#007BB8", height: 16, width: 16,cursor:"pointer" }}
      />
    );
  }

  function NodataDisplay(){
    return(
        <Paper style={{
          border: "1px solid #E9E9E9", boxSizing: "border-box", boxShadow: "1px 2px 16px rgba(78, 78, 78, 0.1)",
          borderRadius: "10px"
        }} elevation={2}>
          <Grid container style={{ height: "25em", }}>
            <Grid items style={{ margin: "auto auto" }}>
              <Typography data-testid="empty-msg" variant="body1" style={{ fontWeight: 500 }}>
                You have no pharmacies in your group.<br />
                To get started choose ‘Add a new group’;
              </Typography>
            </Grid>
          </Grid>
        </Paper>
    )
  }

  
    return (
    <>
     
     <ListComponent
              heading={"Manage pharmacy groups"}
              subheading={"Add a new pharmacy group or edit an existing group by selecting it from the list below."}    
              EditFunction={EditFunction}
              NodataDisplay={NodataDisplay}
              searchPlaceHolder={
                "Search by group id or pharmacy group"
              }
              recordType="pharmacy_group"
              operation="filter"
              hideFilter
              head={[
                { value: "View", issortable: false },
                { value: "Group ID", issortable: false },
                { value: "Pharmacy group", issortable: false },
                { value: "Postcode", issortable: true },
                { value: "Associated pharmacies", issortable: false },
                { value: "Contact email", issortable: true },
              ]}
              />

     
      
    {/* <Container style={{position: 'fixed', top: '94vh', left: '86vw', display: 'flex', fontWeight:"bold", marginBottom:"100px"}}>
        <a href="https://bestwaymedhub.topdesk.net/tas/public/login/form" target='blank' style={{display:"flex",alignItems:"center",textDecoration:"none",color:"#007BB8", }} >
          <img src={Helpicon} style={{height:18, width:18}} alt="error" />
          <p>&nbsp;&nbsp;Customer support</p>
        </a>
    </Container> */}
    </>
  );
}

export default PharmacyGroup;
