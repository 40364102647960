import React from 'react'
import { Grid, Typography } from "@material-ui/core";
import TextFieldComponent from '../TextField/index3'
import BlueButton from "../../Components/Buttons/BlueButton";
import BlueTextButton from "../../Components/Buttons/BlueTextButton";
import GreenButton from "../../Components/Buttons/GreenButton";
import NormalButton from "../../Components/Buttons/NormalButton";
import ControlledDropDown from '../Dropdown/ControlledDropDown';
import { showErrMsg } from '../notification/Notification';
import PropTypes from 'prop-types';


function Modal3({Error,heading,texts,nextheading,items,handleChange,itemlist,handleChangeDropDown,primarybutton,secondarybutton,primarybuttontext,secondarybuttontext,handleButtonChange,onclose}) {
  return (
    <Grid container direction='column' style={{padding:"2em",gap:"1em"}}>
        <Grid item>
        <Typography variant="h5" style={{fontWeight:600}}>{heading}</Typography>
        </Grid>
        <Grid item>
        <Typography variant="body1">{texts}</Typography>
        </Grid>
        <Grid item>
        <Typography variant="h6" style={{fontWeight:700}}>{nextheading}</Typography>
        </Grid>
        <Grid item>
        <TextFieldComponent items={items} handleChange={handleChange}/>
        </Grid>
        <Grid item>
          <ControlledDropDown itemlist={itemlist} handleChangeDropDown={handleChangeDropDown}/>
        </Grid>
        <Grid item>
          <div data-testid="modal-msg">
            {Error && showErrMsg(Error,()=>{
              
            })}
            </div>
        </Grid>
        <Grid item>
            {primarybutton==='blue' && <BlueButton text={primarybuttontext} handleChangeBlueButton={handleButtonChange}/>}
            {primarybutton==='green' && <GreenButton text={primarybuttontext} handleChangeGreenButton={handleButtonChange}/>}
        </Grid>
        <Grid item>
            {secondarybutton==='bluetextbutton' && <BlueTextButton text={secondarybuttontext} handleChangeBlueTextButton={onclose}/>}
            {secondarybutton==='normalbutton' && <NormalButton text={secondarybuttontext} handleChangeNormalButton={onclose}/>}
        </Grid>
    </Grid>
  )
}

Modal3.propTypes = {
  Error:PropTypes.string,
  heading:PropTypes.string,
  texts:PropTypes.string,
  nextheading:PropTypes.string,
  items:PropTypes.arrayOf(PropTypes.object),
  handleChange:PropTypes.func,
  itemlist:PropTypes.array,
  handleChangeDropDown:PropTypes.func,
  primarybutton:PropTypes.string,
  secondarybutton:PropTypes.string,
  primarybuttontext:PropTypes.string,
  secondarybuttontext:PropTypes.string,
  handleButtonChange:PropTypes.func,
  onclose:PropTypes.func
}

export default Modal3