import React, { useEffect } from 'react';
import { Grid, Paper,Button,makeStyles } from '@material-ui/core';
import Right from '../../Assets/Right.svg';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLoginUserDetails, LoginUser } from '../../redux/middleware';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paperStyle: 
  {   
      margin:"auto",
      paddingTop:150,
      [theme.breakpoints.up('md')]: {
        width: "27%",
      },
      [theme.breakpoints.down('md')]: {
        width: "40%",
      },
      [theme.breakpoints.down('sm')]: {
        width: "50%",
      },
      [theme.breakpoints.down('xs')]: {
        width: "90%",
      },
  }
}));

function Resetsuccess(props) {
  let navigate= useNavigate()
  const location = useLocation();
  const email = location?.state?.email;
  const emaillowercaser = email;
  const classes = useStyles();
  
  const password = location?.state?.password;
  var error = ''

  useEffect(()=>{
    if(props.isSignedIn && props.token.AccessToken){
      props.dispatch(getLoginUserDetails(emaillowercaser,true))
      navigate("/createorder")

    }
  },[props.isSignedIn,props.token])

  function accountLogin()  {
      props.dispatch(LoginUser(emaillowercaser,password))
  }
  

  
  return (
  <>
    <Grid align="center">
        <Paper elevation={0} className={classes.paperStyle} sx={{textAlign:"center"}} >
          <img src={Right} alt="" srcSet=""/>
          <h1 data-testid="Header" style={{fontSize: 25,margin:0, marginBottom:25,width:"70%"}}>Thanks, we’ve reset your password</h1>
          <p style={{fontSize:"80%",textAlign:"start"}}>You’ll need to use your new password to access your account in future.</p>
          <Button data-testid="btn" variant="outlined" onClick={() => {location.state?.email?accountLogin():navigate("/")}} size="large" fullWidth disableElevation style={{background:"#3D8541", color:"white", marginTop:"8px", fontSize:"12px", textTransform: "none"}} >Go to my account</Button>
        </Paper>
        {error}      
    </Grid>

  </>
  );
}
const mapStateToProps = state => {
  return {
      isSignedIn:state.isSignedIn,
      token:state.token,
    userDetails: state.userDetails
  };
};

Resetsuccess.propTypes = {
  dispatch: PropTypes.func,
  userDetails: PropTypes.object,
  isSignedIn: PropTypes.bool,
  token: PropTypes.object,
};

export default connect(mapStateToProps) (Resetsuccess);
