import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar/SideBar';
import PropTypes from 'prop-types';

function SideDrawer(props) {

  const [collapse, setCollapse] = useState(props.collapse);


  useEffect(()=>{
    setCollapse(props.collapse)
  },[props.collapse])

  return (
      <>
      <Sidebar isMobile={false} handleCollapse={props.handleCollapse} collapse={collapse} />
      
      </>
  );
}
SideDrawer.propTypes = {
  collapse: PropTypes.bool,
  handleCollapse: PropTypes.func,
};
export default SideDrawer;


