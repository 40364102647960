/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { useState , useEffect } from 'react';
import { Grid, Paper, TextField, Button,makeStyles, InputAdornment } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import Question from '../../../Assets/questionmark.svg';
import ArrowDown from '../../../Assets/Arrowdown.svg';
import { isPasswordValid, isMatch, isEmpty, isSpace,} from '../../../Components/validation/Validation'
import { showErrMsgEmpty, showErrMsg } from '../../../Components/notification/Notification';
import Lockgreen from '../../../Assets/Lockgreen.svg';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { WardleStaffRegisterComplete } from '../../../api/userOps';
import AccountCircleOutlinedgreen from "../../../Assets/AccountCircleOutlinedgreen.svg";
import { InformationModal } from '../../../Components/Modal/InformationModal';
import { VerifyRegisteredEmail } from '../../../api/userOps';
import logEvent, { logEventWithProperties } from '../../../analytics/Analytics';



const useStyles = makeStyles((theme) => ({
  maincontainer:{
    margin: "auto",
    padding :10,
    [theme.breakpoints.up('md')]: {
      width: "27%",
      paddingTop:140, 
    },
    [theme.breakpoints.down('md')]: {
      width: "40%",
      paddingTop:140, 
    },
    [theme.breakpoints.down('sm')]: {
      width: "55%",
      paddingTop:140, 
    },
    [theme.breakpoints.down('xs')]: {
      width: "90%",
      padding:"4%",
      paddingTop:120, 
    },
  },
  
}))

const initialState = {
  password: '',
  cf_password: '',
  err: '',
  err1: '',
  err2: '',
  success: '',
  showPassword: false,
  showConfirmPassword: false,
  firstName: '',
  lastName: '',
}

function CompleteWardleStaffAccountCreation() {
  let navigate = useNavigate();
  const classes = useStyles()
  const { rnd, email, token } = useParams();

  const handleClickShowPassword = () => {
    setUser({
      ...user,
      showPassword: !user.showPassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setUser({
      ...user,
      showConfirmPassword: !user.showConfirmPassword,
    });
  };

  React.useEffect(()=>{
    logEvent("Activate staff visited")
  },[])
  useEffect(() => {
    VerifyRegisteredEmail(email)
        .then((res)=>res.json())
        .then((data)=>{
          if(data.registration_status==="registered"){
            navigate("/register/alreadyregistered")
        }
        })
  }, [])
  
  const modaltext = [
    { value: "Strong passwords are unique, at least 8 characters long and contain upper and lowercase letters, numbers and symbols."},
    { value: "Try swapping letters for numbers or symbols to make your password harder to guess. For example, instead of S try using $."},
    { value: "You could also try using the first letter of each word from your favourite song lyric to create a word that won't be obvious to other people."},
    {value: "Avoid using personal details other people might know or common words like 'password'."},]

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [user, setUser] = useState(initialState)
  // eslint-disable-next-line no-unused-vars
  const { password, cf_password, err, err1, err2, firstName, lastName } = user

  const handleChangeInput = e => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value, err: '', success: '' })
  }

  const handleSubmit = async e => {
    e.preventDefault()


    if (isEmpty(firstName) || isEmpty(lastName))
      return setUser({ ...user, err: "This field is required", err1: "Please check the information above is correct", success: '' })
   

    if (isSpace(firstName) || isSpace(lastName)) {
      return setUser({ ...user, err: "This field is required", err1: "Please check the information above is correct", err2: "Please provide a valid phone number", success: '' })
    }

    // if (!isPhoneValid(phoneNumber)) {
    //   return setUser({ ...user, err2: "Please provide a valid phone number", err1: "Please check the information above is correct.", success: '' })
    // }
    if (isEmpty(password) )
    return setUser({ ...user, err: "This field is required", err1: "Please enter your password before continuing.", success: '' })
    
    if (isEmpty(cf_password))
    return setUser({ ...user, err: "This field is required", err1: "Please enter your confirm password before continuing.", success: '' })
   
    if (!isPasswordValid(password))
      return setUser({ ...user, err1: "Make sure your password is at least 8 characters long and contains upper and lowercase letters, numbers and symbols.", success: '' })

    if (!isPasswordValid(cf_password))
      return setUser({ ...user, err1: "Make sure your password is at least 8 characters long and contains upper and lowercase letters, numbers and symbols.", success: '' })

    if (!isMatch(password, cf_password))
      return setUser({ ...user, err1: "Make sure your password matches in both fields.", success: '' })

  
logEventWithProperties("Activate staff account submitted",{
  email: email,
  role: "Staff"})
    WardleStaffRegisterComplete(email, firstName, lastName, password, rnd, token)
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Account created successfully") {
          logEventWithProperties("Staff account created successfully",{
            email: email,
            role:"Staff"})
          navigate("/staff/register/successfull", { state: { email: email, password: password } });
        }else if(data.error.errorCode === "TokenAlreadyUsed"){
          navigate("/register/alreadyregistered")
        }
        else {
          logEventWithProperties("Staff account creation failed",{...{
            email: email,
            role: "Staff"},...data})
          return setUser({ ...user, err: data.message, success: '' })
        }
      },
        error => {
          console.error('There was an error!', error);
        });
  }


  return (
    <>
      <Grid>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Paper elevation={0} className={classes.maincontainer} sx={{ textAlign: "center" }}>

            <h2 data-testid="Header" style={{ fontSize: 25, margin: 0, marginBottom: 20, marginTop: 20, textAlign: "left" }}>Your details</h2>
            <p style={{ fontSize: 17, }}>Enter your details and choose a password to keep your data secure.</p>

            <TextField
              size="small"
              style={{ marginTop: '5%', marginBottom: '5%', boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background: "white" }}
              className="Textfields"
              placeholder="First name"
              error={(isEmpty(firstName) && (err?true:false)) || (isSpace(firstName) && (err?true:false))}
              variant="outlined"
              label={isEmpty(firstName) ? null : "First name"}
              type="text"
              id="firstName"
              value={firstName}
              name="firstName"
              data-testid="firstName"
              onChange={handleChangeInput}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={AccountCircleOutlinedgreen} alt="error" />
                  </InputAdornment>
                ),
              }}
            />
            <div data-testid="error">
            {(isEmpty(firstName) && showErrMsgEmpty(err)) || (isSpace(firstName) && showErrMsgEmpty(err))}
            </div>
            <TextField
              size="small"
              className="Textfields"
              style={{ marginBottom: '5%', boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background: "white" }}
              placeholder="Last name"
              variant="outlined"
              error={(isEmpty(lastName) && (err?true:false)) || (isSpace(lastName) && (err?true:false))}
              label={isEmpty(lastName) ? null : "Last name"}
              type="text"
              id="lastName"
              data-testid="lastName"
              value={lastName}
              name="lastName"
              onChange={handleChangeInput}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={AccountCircleOutlinedgreen} alt="error" />
                  </InputAdornment>
                ),
              }}
            />
            <div data-testid="error">
            {(isEmpty(lastName) && showErrMsgEmpty(err)) || (isSpace(lastName) && showErrMsgEmpty(err))}
            </div>
            <TextField size="small"
              error={isEmpty(password) && (err?true:false)}
              style={{ marginBottom: '5%', boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background: "white" }}
              placeholder="Password"
              variant="outlined"
              fullWidth
              type={user.showPassword ? 'text' : 'password'}
              id="password"
              data-testid="password"
              value={password} name="password" onChange={handleChangeInput}
              label={isEmpty(password) ? null : "Password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={Lockgreen} alt="error" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {user.showPassword ? <Visibility style={{ color: "#A8D7AB", height: 19, width: 19 }} /> : <VisibilityOff style={{ color: "#A8D7AB", height: 19, width: 19 }} />}
                    </IconButton>
                  </InputAdornment>)
              }}
            />
            <div data-testid="error">
            {isEmpty(password) && showErrMsgEmpty(err)}
            </div>
            <TextField size="small"
              error={isEmpty(cf_password) && (err?true:false)}
              style={{ marginBottom: '5%', boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background: "white" }}
              placeholder="Confirm Password"
              type={user.showConfirmPassword ? 'text' : 'password'}
              label={isEmpty(cf_password) ? null : "Confirm Password"}
              variant="outlined"
              fullWidth
              id="cf_password"
              data-testid="CfPassword"
              value={cf_password} name="cf_password" onChange={handleChangeInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={Lockgreen} alt="error" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {user.showConfirmPassword ? <Visibility style={{ color: "#A8D7AB", height: 19, width: 19 }} /> : <VisibilityOff style={{ color: "#A8D7AB", height: 19, width: 19 }} />}
                    </IconButton>
                  </InputAdornment>)
              }}
            />
            <div data-testid="error">
            {isEmpty(cf_password) && showErrMsgEmpty(err)}
            </div>



            <br />
            <Button onClick={handleOpen} variant="contained" size="large" disableElevation style={{ background: "#E8F8FF", color: "#0066BE", borderRadius: '6px', fontSize: "18px", textTransform: "none", display: "flex", justifyContent: "space-between", border: "1px solid #7DD4FF" }} fullWidth>
              <div style={{ display: "flex", justifyContent: "flex-start", margin: 0, padding: 0 }}>
                <img src={Question} alt="error" />
                <p style={{ margin: 0, padding: 0, marginLeft: '10px', fontSize: 16 }}>Help setting a strong password</p>
              </div>
              <img src={ArrowDown} alt="error" />
            </Button>
            <div data-testid="error1">
            {err1 && showErrMsg(err1, () => {
              setUser({ ...user, err1: '' })
            })}
            </div>
            <Grid align="center">
              <br />
              <Button data-testid="btn" variant="contained" fullWidth size="large" disableElevation style={{ background: "#3D8541", color: "white", fontSize: "12px", textTransform: "none" }} type="submit" >Confirm my details</Button>

            </Grid>

            <InformationModal 
            handleClose={handleClose} 
            open={open} 
            heading={"Help setting a strong password"}
            modaltext={modaltext}
            />

          </Paper>
        </form>
      </Grid>

    </>
  );
}

export default CompleteWardleStaffAccountCreation;



