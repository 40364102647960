import React, {useEffect} from 'react';
import { Grid, Paper,Button } from '@material-ui/core';
import Right from '../../../Assets/Right.svg';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getLoginUserDetails, LoginUser } from '../../../redux/middleware';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';


function RegisterSuccesshq(props) {
  const location = useLocation();
  const email = location?.state?.email;
  const emaillowercaser = email;
  let navigate= useNavigate()
  const password = location?.state?.password;
  const paperStyle={padding :10,paddingTop:110, width:340, margin:"auto",};
  var error = ''
  

  useEffect(()=>{
    if(props.isSignedIn && props.token.AccessToken){
      props.dispatch(getLoginUserDetails(emaillowercaser,true))
      navigate("/yourstatements")

    }
  },[props.isSignedIn,props.token])


  function accountLogin()  {
      props.dispatch(LoginUser(emaillowercaser,password))
  }
  
  return (
  <>
    {/* <Navbar /> */}
    <Grid align="center">
        <Paper elevation={0} style={paperStyle} sx={{textAlign:"center"}}>
          <img src={Right} alt="" srcSet=""/>
          <h1 data-testid="Header" style={{fontSize: 25,margin:0, marginBottom:25,width:"70%"}}>Your account is created</h1>
          <Button data-testid="btn" variant="outlined" onClick={() => {location.state?.email?accountLogin():navigate("/")}} size="large" fullWidth disableElevation style={{background:"#3D8541", color:"white", marginTop:"8px", fontSize:"12px", textTransform: "none"}} >{location.state?.email?"Go to my account":"Go to Signin"}</Button>
        </Paper>
        {error}      
    </Grid>

  </>
  );
}
const mapStateToProps = state => {
  return {
      isSignedIn:state.isSignedIn,
      token:state.token,
    userDetails: state.userDetails,
    loginError:state.loginError
  };
};

RegisterSuccesshq.propTypes = {
  isSignedIn: PropTypes.bool,
  token: PropTypes.object,
  dispatch: PropTypes.func,
};

export default connect(mapStateToProps)(RegisterSuccesshq);
