import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import Accountcirclewhite from '../../Assets/accountCircleWhite.svg';
import Createorder from "../../Assets/Createorder.svg";
import ManagePharmacyusers from "../../Assets/ManagePharmacyusers.svg";
import Manageusers from "../../Assets/Manageusers.svg";
import Ourrange from "../../Assets/Ourrange.svg";
import Pharmacylist from "../../Assets/Pharmacylist.svg";
import Stockamnsesty from "../../Assets/Stockamnsesty.svg";
import Yourorders from "../../Assets/Yourorders.svg";
import Yourstatements from "../../Assets/Yourstatements.svg";
import "./Sidebar.css";

import { Hidden } from "@material-ui/core";
import React from "react";
import { MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { getUserDetails } from "../../api/getUserDetails";
import Accountcircleblue from '../../Assets/Accountcircleblue.svg';


export const AdminComponent = (props) => {
  let navigate = useNavigate();
  const hideCommissionAndUser =
    getUserDetails().role === "fp@wardleAdmin" ? true : false;
  const pharmacyAccountNumber =
    getUserDetails().pharmacy_account_number;
  const pharmacyName = getUserDetails().pharmacy_name;

  return (
    <React.Fragment>
      <MenuItem
        className="sidebar-menuitem"
        icon={
          <div className="sidebaricon">
            <img src={Createorder} alt="error" />
          </div>
        }
        active={window.location.pathname === "/createorder"}
        onClick={() => {
          props.onSelect();
          navigate("/createorder");
        }}
      >
        Create order
      </MenuItem>

     

      <MenuItem
        className="sidebar-menuitem"
        onClick={() => {
          props.onSelect();

          navigate("/orderlist");
        }}
        active={window.location.pathname === "/orderlist"}
        icon={
          <div className="sidebaricon">
            <img src={Yourorders} alt="error" />
          </div>
        }
      >
        Your orders
      </MenuItem>

      {hideCommissionAndUser ? (
        <MenuItem
          className="sidebar-menuitem"
          active={window.location.pathname === "/yourstatements"}
          onClick={() => {
            props.onSelect();

            navigate("/yourstatements");
          }}
          icon={
            <div className="sidebaricon">
              <img src={Yourstatements} alt="error" />
            </div>
          }
        >
          Your statements
        </MenuItem>) : null}
      <MenuItem
        className="sidebar-menuitem"
        onClick={() => {
          props.onSelect();

          navigate("/stockamnestyhome");
        }}
        active={window.location.pathname === "/stockamnestyhome"}
        icon={
          <div className="sidebaricon">
            <img src={Stockamnsesty} alt="error" />
          </div>
        }
      >
        Stock amnesty
      </MenuItem>

      {hideCommissionAndUser ? (
        <MenuItem
          className="sidebar-menuitem"
          icon={
            <div className="sidebaricon">
              <img src={Manageusers} alt="error" />
            </div>
          }
          active={window.location.pathname === "/manageusers"}
          onClick={() => {
            props.onSelect();

            navigate("/manageusers");
          }}
        >
          Manage users
        </MenuItem>
      ) : null}
      <Hidden mdUp implementation="css">

      <MenuItem
        className="sidebar-menuitem"
        icon={
          <div className="sidebaricon">
            <img src={Accountcircleblue} alt="error" />
          </div>
        }
        active={window.location.pathname === "/myprofile"}
        onClick={() => {
          props.onSelect();

          navigate("/myprofile");
        }}
      >
        My Profile
      </MenuItem>
</Hidden>

      {/* <MenuItem className="sidebar-menuitem" icon={<div className="sidebaricon"><img src={HowToGuides}  alt="error"/></div>}>
             How to guides
          </MenuItem> */}

      {!props.collapse ? (
        <>
          <hr style={{ marginLeft: 30, marginRight: 100, marginTop: 10 }}></hr>

          <p
            style={{
              marginLeft: 30,
              color: "white",
              fontSize: 13,
              fontWeight: "bold",
            }}
          >
            Pharmacy account number:
          </p>
          <p style={{ marginLeft: 30, color: "white", fontSize: 13 }}>
            {pharmacyAccountNumber}
          </p>

          <p
            style={{
              marginLeft: 30,
              color: "white",
              fontSize: 13,
              fontWeight: "bold",
            }}
          >
            Pharmacy name:
          </p>
          <p style={{ marginLeft: 30, color: "white", fontSize: 13 }}>
            {pharmacyName}
          </p>
        </>
      ) : null}
    </React.Fragment>
  );
};

export const HqComponent = (props) => {
  let navigate = useNavigate();

  return (
    <>
      <MenuItem
        active={window.location.pathname === "/yourstatements"}
        onClick={() => {
          navigate("/yourstatements");
        }}
        className="sidebar-menuitem" icon={<div className="sidebaricon"><img src={Yourstatements} alt="error" /></div>}>
        Your statements
      </MenuItem>

      <MenuItem
        active={window.location.pathname === "/range"}
        onClick={() => {
          navigate("/range");
        }}
        className="sidebar-menuitem"
        icon={
          <div className="sidebaricon">
            <img src={Ourrange} alt="error" />
          </div>
        }
      >
        Range
      </MenuItem>

      <MenuItem
        className="sidebar-menuitem"
        active={window.location.pathname === "/orderlist"}
        onClick={() => {
          navigate("/orderlist");
        }}
        icon={
          <div className="sidebaricon">
            <img src={Yourorders} alt="error" />
          </div>
        }
      >
        Orders
      </MenuItem>

      <MenuItem
        active={window.location.pathname === "/manageusers"}
        onClick={() => {
          navigate("/manageusers");
        }}
        className="sidebar-menuitem"
        icon={
          <div className="sidebaricon">
            <img src={Manageusers} alt="error" />
          </div>
        }
      >
        Manage users
      </MenuItem>

      <MenuItem
        active={window.location.pathname === "/pharmacylisthq"}
        onClick={() => {
          navigate("/pharmacylisthq");
        }}
        className="sidebar-menuitem"
        icon={
          <div className="sidebaricon">
            <img src={Pharmacylist} alt="error" />
          </div>
        }
      >
        Pharmacy list
      </MenuItem>

      <Hidden mdUp implementation="css">

      <MenuItem
        className="sidebar-menuitem"
        icon={
          <div className="sidebaricon">
            <img src={Accountcirclewhite} alt="error" />
          </div>
        }
        active={window.location.pathname === "/myprofile"}
        onClick={() => {
          props.onSelect();

          navigate("/myprofile");
        }}
      >
        My Profile
      </MenuItem>
      </Hidden>
    
    </>
  );
};
HqComponent.propTypes = {
  onSelect:PropTypes.func
};
export const StaffComponent = () => {
  let navigate = useNavigate();

  return (
    <>
      <MenuItem
        className="sidebar-menuitem"
        icon={
          <div className="sidebaricon">
            <img src={Yourorders} alt="error" />
          </div>
        }
        active={window.location.pathname === "/orderlist"}
        onClick={() => {
          navigate("/orderlist");
        }}
      >
        Orders
      </MenuItem>

      <MenuItem
        active={window.location.pathname === "/managepharmacygroup"}
        onClick={() => {
          navigate("/managepharmacygroup");
        }}
        className="sidebar-menuitem"
        icon={
          <div className="sidebaricon">
            <img src={ManagePharmacyusers} alt="error" />
          </div>
        }
      >
        Pharmacy groups
      </MenuItem>

      <MenuItem
        active={window.location.pathname === "/manageusers"}
        onClick={() => {
          navigate("/manageusers");
        }}
        className="sidebar-menuitem"
        icon={
          <div className="sidebaricon">
            <img src={Manageusers} alt="error" />
          </div>
        }
      >
        Manage users
      </MenuItem>

      <MenuItem
        active={window.location.pathname === "/stockamnestyhome"}
        onClick={() => {
          navigate("/stockamnestyhome");
        }}
        className="sidebar-menuitem" icon={<div className="sidebaricon"><img src={Stockamnsesty} alt="error" /></div>}>
        Stock amnesty
      </MenuItem>
    </>
  );
};

AdminComponent.propTypes = {
  collapse: PropTypes.bool,
  onSelect:PropTypes.func
};