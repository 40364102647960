import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

window.console.log = function(){
  if (process.env.REACT_APP_AWS_ENV != 'PROD') {
    window.console.log = function() {
      return false;
    };
  }
}

if(window.self === window.top) {
  ReactDOM.render(
    <React.StrictMode>
     <App/>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

reportWebVitals();