import React, { useEffect } from 'react';
import './App.css';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/reducer/store'
import WardleRoutes from './Routes/WardleRoutes';
import { ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';
import  { init } from './analytics/Analytics';

const theme = createTheme({
  typography:
    { fontFamily: '"Gilroy Alt"' }
})
function App() {
  useEffect(() => {
    //amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE, null, { includeReferrer: true });
    init()

  })
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <WardleRoutes />

        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;


