import React, { createContext, useEffect, useState } from "react";
import wardlesLogowhite from "../../Assets/wardles-white-out-logo.svg";
import Helpicon from "../../Assets/Helpicon.svg";
import { store } from "../../redux/reducer/store";
import {
  Grid,
  TextField,
  Button,
  Checkbox,
  InputAdornment,
  FormControlLabel,
  IconButton,
  Typography,
  Hidden,
  LinearProgress,
} from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import { isEmpty } from "../../Components/validation/Validation";
import {
  showErrMsg,
  showErrMsgEmpty,
} from "../../Components/notification/Notification";
import { getLoginUserDetails, LoginUser } from "../../redux/middleware";
import { connect } from "react-redux";
import {
  clearState,
  isRemeberPassword,
  setIsSIgnedIn,
  setLoginError,
  setToken,
  setUserDetails,
  setUSerLoginDetails,
} from "../../redux/actions/actions";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Lockgreen from "../../Assets/Lockgreen.svg";
import { makeStyles } from "@material-ui/core/styles";
import Navbar from "../../Components/Navbar/Navbar";
import PropTypes from "prop-types";
import EmailIcon from "../../Assets/Mailicongreen.svg";
import { clearLoginTimer } from "../../Utilities/TokenExpireTimer";
import { hardLogout, userLogout } from "../../api/userOps";
import logEvent from "../../analytics/Analytics";

export const useStyles = makeStyles((theme) => ({
  leftcontainer: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "10%",
      backgroundColor: "#009ADB",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "15%",
      paddingRight: "15%",
      paddingTop: "1%",
      paddingBottom: "5%",
      backgroundColor: "#009ADB",
      order: 2,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "5%",
      paddingRight: "5%",
      paddingTop: "0",
      paddingBottom: "2em",
      backgroundColor: "#009ADB",
      textAlign: "center",
      order: 2,
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: "#009ADB",
      paddingLeft: "15%",
      paddingTop: "7em",
      paddingRight: "10%",
    },
  },
  loadingContainer: {
    textAlign: "center",
    width: "50%",
    height: "100vh",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  loading: {
    position: "absolute",
    width: "100%",
    top: "20%",
    [theme.breakpoints.down("md")]: {
      top: "50%",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",

      top: "30%",
    },
    [theme.breakpoints.down("xs")]: {
      top: "30%",
      width: "100%",
    },
  },
  rightcontainer: {
    opacity: (props) => (props ? "0.5" : "1"),
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10%",
      paddingRight: "20%",
      paddingTop: "3%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "15%",
      paddingRight: "15%",
      paddingTop: "3%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "5%",
      paddingRight: "5%",
      paddingTop: "3%",
      paddingBottom: "1em",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "15%",
      paddingTop: "3em",
      paddingRight: "27%",
    },
  },
  Header: {
    fontWeight: 600,
    [theme.breakpoints.up("lg")]: {
      fontSize: 43,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 30,
      marginTop: 110,
    },
  },
}));

export const UserContext = createContext();

function Signin(props) {
  const location = useLocation();
  let [signout, setSignout] = useState(
    location?.state?.SignoutNavigation !== undefined
      ? location?.state?.SignoutNavigation
      : false
  );
  let stateval = store.getState();
  const [isLoading, setIsLoading] = useState(false);
  const isSignOutLoading = stateval.isLoading;
  const classes = useStyles(isLoading || isSignOutLoading);
  const { loginError, userLoginDetails, userDetails } = props;
  const emailFromCookie = userLoginDetails.userEmail;
  const passwordFromCookie = userLoginDetails.userPassword;
  const initialState = {
    email: emailFromCookie || "",
    password: passwordFromCookie || "",
    err: "",
    err1: "",
    success: "",
    showPassword: false,
  };
  let navigate = useNavigate();

  const [user, setUser] = useState(initialState);
  const [checkbox, setCheckbox] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const loadingSignOutText = stateval.isLoading && "Logging you out...";
  const { email, password, err, err1 } = user;

  const emaillowercaser = email.toLowerCase().trim();

  useEffect(() => {
    if (!signout) {
      if (props.userDetails?.role) {
        setLoadingText("");

        if (userDetails.role === "fp@wardleStaff") {
          navigate("/orderlist", { state: { role: userDetails.role } });
        } else if (
          userDetails.role === "fp@wardleHqUser" ||
          userDetails.role === "fp@wardleHqAdmin"
        ) {
          props.associatedPharmacies !== null &&
            navigate("/yourstatements", { state: { role: userDetails.role } });
        } else {
          navigate("/createorder", { state: { role: userDetails.role } });
        }
        setIsLoading(false);
      } else {
        if (props.isSignedIn && props.token.AccessToken) {
          emaillowercaser &&
            props.dispatch(getLoginUserDetails(emaillowercaser, true));
        }
      }
    }
  }, [props.isSignedIn, props.token, signout]);

  useEffect(() => {
    if (loginError?.isError) {
      if (loginError.error === "Password is incorrect") {
        setUser({
          ...user,
          err: "We don’t recognise your password. Please try again or select ‘Forgotten your password?’ to reset. ",
          success: "",
        });
      } else if (loginError.error === "The email is not valid") {
        setUser({
          ...user,
          err: "Please check email you entered ",
          success: "",
        });
      } else if (loginError.error === "User not registered") {
        setUser({
          ...user,
          err: "We don’t recognise your email address. Please try again.",
          success: "",
        });
      } else if (loginError.error === "User not confirmed") {
        setUser({
          ...user,
          err: "Please activate your account to proceed further",
          success: "",
        });
      } else if (loginError.error === "User not found") {
        setUser({
          ...user,
          err: "We don’t recognise your email address. Please try again.",
          success: "",
        });
      } else if (loginError.error === "User already has an active session") {
        setUser({
          ...user,
          err: "Your previous session is active. Please click on logout to be able to login here.",
          success: "",
        });
      }
      setLoadingText("");
      setIsLoading(false);
    } else {
      setUser({ ...user, err: "", success: "" });
    }
  }, [loginError]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value, err: "", success: "" });
  };
  const handleChangeCheckbox = () => {
    setCheckbox(!checkbox);
  };

  const handleClickShowPassword = () => {
    setUser({
      ...user,
      showPassword: !user.showPassword,
    });
  };

  const handleSubmit = async (e) => {
    setSignout(false);
    e.preventDefault();
    if (isEmpty(email.trim()) || isEmpty(password.trim())) {
      return setUser({
        ...user,
        err: "Please enter your email address and password before continuing.",
        success: "",
      });
    }
    if (checkbox === true) {
      props.dispatch(isRemeberPassword(true));
    }
    props.dispatch(
      setUSerLoginDetails({ userEmail: email, userPassword: password })
    );
    setIsLoading(true);
    setLoadingText("Logging you in...");
    props.dispatch(LoginUser(emaillowercaser, password));
  };

  function Logout(token, isHard = false) {
    clearLoginTimer();

    isHard
      ? hardLogout(emaillowercaser).then((data) => {
          if (data.ok) props.dispatch(LoginUser(email, password));
          setLoadingText("Logging you in...");
        })
      : userLogout(token?.AccessToken, token?.sessionId);
    navigate("/");
    if (isHard) {
      props.dispatch(setUserDetails({}));
      props.dispatch(setIsSIgnedIn(false));

      props.dispatch(setToken({}));
      props.dispatch(clearState());
    }
  }
  const logoutFromOtherDevices = () => {
    var link = <a href="#">Log out from other sessions</a>;

    return (
      <div
        style={{
          justifyContent: "center",
          display: "flex",
          marginBottom: "10px",
          cursor: "pointer",
        }}
        onClick={() => {
          setIsLoading(true);
          setLoadingText("Logging out previous session");
          Logout(props.token, true);
        }}
      >
        {link}
      </div>
    );
  };

  useEffect(() => {
    if (!signout) {
      let { userDetails, isSignedIn } = props;
      if (isSignedIn && userDetails) {
        setLoadingText("");

        if (userDetails.role === "fp@wardleStaff") {
          navigate("/orderlist", { state: { role: userDetails.role } });
        } else if (
          userDetails.role === "fp@wardleHqUser" ||
          userDetails.role === "fp@wardleHqAdmin"
        ) {
          if (props.associatedPharmacies !== null) {
            navigate("/yourstatements", { state: { role: userDetails.role } });
          }
        } else {
          navigate("/createorder", { state: { role: userDetails.role } });
        }
        setIsLoading(false);
      }
    }
  }, [props.userDetails, props.associatedPharmacies, signout]);
  return (
    <Grid container style={{ height: "100vh" }}>
      <Grid
        item
        className={classes.leftcontainer}
        lg={6}
        md={6}
        sm={12}
        xs={12}
      >
        <Hidden mdUp implementation="css">
          <Navbar />
        </Hidden>
        <Hidden smDown>
          <img src={wardlesLogowhite} alt="error" className="wardlesLogo" />
        </Hidden>
        <Typography
          style={{
            fontStyle: "normal",
            fontSize: "24px",
            lineHeight: "120%",
            color: "#fff",
            marginTop: "2em",
          }}
        >
          Wardles, our independent Dispensing Appliance Contractor (DAC) is part
          of Bestway Medhub group and are Part IX specialists.
        </Typography>
        <Typography
          style={{
            fontStyle: "normal",
            fontSize: "24px",
            lineHeight: "120%",
            color: "#fff",
            marginTop: "1em",
          }}
        >
          {" "}
          Wardles helps pharmacies and dispensing practices by enhancing profits
          and supports cash flow, whilst offering an enhanced service to your
          patients. We do this by offering an easy and simple to use agency
          scheme which saves both time and money.
        </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid container justifyContent="flex-end">
          <Hidden mdDown>
            <a
              href="https://www.bestwaymedhub.co.uk/Wardles"
              target="_blank"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
              }}
              rel="noopener noreferrer"
            >
              <p
                style={{
                  textDecoration: "none",
                  color: "black",
                  marginRight: "4em",
                  fontWeight: 600,
                }}
              >
                <img src={Helpicon} alt="error" />
                &nbsp;Help
              </p>
            </a>
          </Hidden>
        </Grid>
        <fieldset
          disabled={isSignOutLoading || isLoading}
          style={{ border: "none" }}
        >
          <Grid item className={classes.rightcontainer}>
            <form onSubmit={handleSubmit} autoComplete="off">
              {/* <input type="hidden" value="prayer" /> */}
              <Typography className={classes.Header} data-testid="Header">
                Sign in
              </Typography>
              <Typography
                variant="body1"
                style={{
                  fontWeight: 500,
                  marginTop: "0.3em",
                  marginBottom: "2.2em",
                }}
              >
                Enter your email address and password.
              </Typography>
              <TextField
                fullWidth
                size="small"
                placeholder="Email address"
                variant="outlined"
                style={{
                  boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
                  borderRadius: "6px",
                  marginBottom: "0.25em",
                }}
                type="text"
                id="email"
                value={email}
                error={isEmpty(email) && (err1 ? true : false)}
                name="email"
                onChange={handleChangeInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={EmailIcon} alt="error" />
                    </InputAdornment>
                  ),
                }}
              />
              {isEmpty(email) && showErrMsgEmpty(err1)}
              <br />
              {email ? <br /> : null}
              <TextField
                fullWidth
                size="small"
                error={isEmpty(password) && (err1 ? true : false)}
                style={{
                  boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
                  borderRadius: "6px",
                }}
                placeholder="Password"
                type={user.showPassword ? "text" : "password"}
                id="password"
                value={password}
                name="password"
                variant="outlined"
                onChange={handleChangeInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={Lockgreen} alt="error" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {user.showPassword ? (
                          <Visibility
                            className="visibility"
                            style={{ color: "#A8D7AB" }}
                          />
                        ) : (
                          <VisibilityOff
                            className="visibility"
                            style={{ color: "#A8D7AB" }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {isEmpty(password) && showErrMsgEmpty(err1)}
              <Grid item style={{ marginTop: "1em", marginBottom: "1em" }}>
                <FormControlLabel
                  label="Remember password"
                  inputprops={{
                    maxLength: 8,
                  }}
                  control={
                    <Checkbox
                      color="primary"
                      value={checkbox}
                      onChange={handleChangeCheckbox}
                    />
                  }
                />
                <br />
              </Grid>
              {err &&
                showErrMsg(err, () => {
                  props.dispatch(setLoginError({ isError: false, error: "" }));
                })}
              {err &&
                err ===
                  "Your previous session is active. Please click on logout to be able to login here." &&
                logoutFromOtherDevices()}

              {loadingText &&
                showErrMsg(loadingText, () => {
                  "Logging you in...";
                })}
              {isSignOutLoading &&
                showErrMsg(loadingSignOutText, () => {
                  "Signing out...";
                })}

              {isLoading && <LinearProgress />}
              {isSignOutLoading && <LinearProgress />}
              <Button
                style={{
                  backgroundColor: "#3D8541",
                  boxShadow: "0px 4px 0px #125A16",
                  color: "#fff",
                  marginTop: "10px",
                  textTransform: "none",
                  fontSize: "18px",
                }}
                fullWidth
                data-testid="btn"
                variant="contained"
                size="large"
                disableElevation
                type="submit"
                disabled={isLoading}
              >
                Sign In
              </Button>
              <br />
              <div style={{ textAlign: "Center" }}>
                <p style={{ color: "black", textDecoration: "none" }}>
                  Don’t have an account?
                </p>
              </div>
              <Button
                style={{
                  backgroundColor: "#fff",
                  border: "1.5px solid #222222",
                  boxSizing: "border-box",
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "18px",
                }}
                fullWidth
                data-testid="btnsignup"
                onClick={() => {
                  logEvent("Create account clicked")

                  navigate("/signup");
                }}
                variant="outlined"
                size="large"
                disableElevation
                type="submit"
                disabled={isLoading}
              >
                Create account
              </Button>
              <div style={{ textAlign: "Center" }}>
                <p
                  onClick={() => {
                  logEvent("Forgot password clicked")

                    !isLoading && navigate("/forgotpasswordemail");
                  }}
                  style={{
                    cursor: "pointer",
                    color: "#007BB8",
                    textDecoration: "underline",
                  }}
                >
                  Forgotten your password?
                </p>
              </div>
            </form>
          </Grid>
        </fieldset>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.isSignedIn,
    token: state.token,
    userLoginDetails: state.userLoginDetails,
    userDetails: state.userDetails,
    loginError: state.loginError,
    associatedPharmacies: state.associatedPharmacies,
  };
};

Signin.propTypes = {
  userDetails: PropTypes.object,
  token: PropTypes.object,
  dispatch: PropTypes.func,
  isSignedIn: PropTypes.bool,
  loginError: PropTypes.object,
  userLoginDetails: PropTypes.object,
};

export default connect(mapStateToProps)(Signin);
