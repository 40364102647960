import {Box,Modal, Button,Typography} from '@material-ui/core';
import {showErrMsg, showSuccessmsg} from '../../Components/notification/Notification'
import PropTypes from 'prop-types';
import React from 'react';



function CreateOrderModal(props){
    const styleerrormodal = {position: 'absolute',top: '8%',left: '50%',transform: 'translate(-50%, -50%)', borderRadius:10, p: 3,};
    const style = {position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)',bgcolor: 'white', borderRadius:10, p: 3,};
       
return(
    <React.Fragment>

    {/*Modal for clear product - Modal 2  */}
        <Modal
                open={props.open1}
                onClose={props.handleClose1}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{width:"40%", padding:30,display:"grid", alignItems:"center", justifyItems:"center"}} align="center">
                  <Typography  id="modal-modal-title" variant="h6" component="h2">
                        <p  style={{margin:0,padding:0,textAlign:"center", marginLeft:5,marginBottom:17,marginTop:17, fontSize:"17px",color:"#222222",fontWeight:"bold"}}>Are you sure you want <br/>to clear your order?</p>
                  </Typography>
                  <Typography  id="modal-modal-title"  component="h2">
                        <p  style={{margin:0,padding:0,textAlign:"center", marginLeft:5,marginBottom:17, fontSize:"17px",color:"#222222",}}>If you clear the information before you place your order you will <br/>lose your progress. Are sure you want to continue?</p>
                  </Typography>

                  <Button  onClick={props.clearProductDetails}  variant="contained" size="large" disableElevation style={{background: "#3D8541",marginTop:18, color:"white",width:"60%",  fontSize:"18px", textTransform: "none", boxShadow: "0px 2px 0px #306B33, inset 0px 1px 0px #62B767"}} type="submit" >Yes, clear my order</Button>
                  <Button onClick={props.handleClose1}  variant="outlined"  size="large" disableElevation style={{background: "white",marginTop:18, color:"black",width:"60%",  fontSize:"18px", textTransform: "none",}}>Cancel</Button>
                                     
                </Box>
              </Modal>

    {/*Modal for delete order - Modal 3  */}
        <Modal
                open={props.open2}
                onClose={props.handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} style={{width:"30%", padding:30,display:"grid", alignItems:"center", justifyItems:"center"}} align="center">
                  <Typography  id="modal-modal-title" variant="h6" component="h2">
                        <p  style={{margin:0,padding:0,textAlign:"center", marginLeft:5,marginBottom:17,marginTop:17, fontSize:"17px",color:"#222222",fontWeight:"bold"}}>Would you like to remove this <br/>item from your prescription?</p>
                  </Typography>
                
                  <Button onClick={props.deleteProductdetails}   variant="contained" size="large" disableElevation style={{background: "#3D8541",marginTop:18, color:"white",width:"60%",  fontSize:"18px", textTransform: "none", boxShadow: "0px 2px 0px #306B33, inset 0px 1px 0px #62B767"}} type="submit" >Yes</Button>
                  <Button onClick={props.handleClose2}  variant="outlined"  size="large" disableElevation style={{background: "white",marginTop:18, color:"black",width:"60%",  fontSize:"18px", textTransform: "none",}} >Cancel</Button>
                                         
                </Box>
              </Modal>
    {/* Modal for success or error message */}
        <Modal
                open={props.open3}
                onClose={props.handleClose3}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                  <Box sx={styleerrormodal}>
                  {props.successmessage && showSuccessmsg(props.successmessage)}
                  {props.errormessage && showErrMsg(props.errormessage,()=>{
                    props.closeError()
                  })}
                  </Box>
        </Modal>
    </React.Fragment>
)
}

CreateOrderModal.propTypes = {
  open1 : PropTypes.func,
  open2 : PropTypes.func,
  open3 : PropTypes.func,
  handleClose1 : PropTypes.func,
  handleClose2 : PropTypes.func,
  handleClose3 : PropTypes.func,
  clearProductDetails: PropTypes.func,
  deleteProductdetails : PropTypes.func,
  successmessage : PropTypes.string,
  errormessage : PropTypes.string,
  closeError : PropTypes.func
}

export default CreateOrderModal