import { FpServicesRoutes } from "./constants";
import { getUserDetails } from "./getUserDetails";
import { handleFetch } from "./HandleApiCall";

//Function for validating Pharmacy details based on Pharmacy name , Pharmacy Account Number , Pharmacy post Code And email
export function ValidatePharmacyDetails(
  pharmacyName,
  pharmacyAccountNumber,
  pharmacyPostCode,
  email
) {
  const requestOptions = {
    method: FpServicesRoutes.Validations.VALIDATE_PHARMACY_ENDPOINT.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      pharmacyAccountNumber: pharmacyAccountNumber,
      pharmacyName: pharmacyName,
      pharmacyPostCode: pharmacyPostCode,
      email: email || getUserDetails().email,
    }),
  };
  return handleFetch(
    FpServicesRoutes.Validations.VALIDATE_PHARMACY_ENDPOINT.uri,
    requestOptions
  );
}
