import { store } from "../redux/reducer/store";

//Function for getting user details of a user from redux store
export function getUserDetails() {
  let state = store.getState();
  const { userDetails } = state;
  return userDetails;
}

store.subscribe(getUserDetails);
