import React from "react";
import {
  Grid,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  TableHead
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Edit from '../../../Assets/Edit.svg'
import Delete from '../../../Assets/DeleteIcon.svg'

const useStyles = makeStyles({
  tablecell: {
    backgroundColor: "#5E5E5E",
    color: "#fff",
  },

  tabledata: {
    border: "0px",
  },
})

function index({ textheading, theads, lists , handleEdit , handleCross }) {
  const classes = useStyles();

    
  return (
    <Grid container>
      {lists.length === 0 ? (
        <Box
          style={{ border: "1px solid #CDCDCD", borderRadius: '6px', padding: 10 ,width:'100%'}}
        >
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#5E5E5E",
            }}
          >
            Your list is empty, choose ‘Add new item’ to get started
          </p>
        </Box>
      ) : (
        <>
          {textheading && (
            <p style={{ color: "#5E5E5E", marginTop: 18, marginBottom: 18 }}>
              {textheading}
            </p>
          )}
          <Grid item style={{ marginTop: "0.5em" }}>
            <TableContainer
              component={Paper}
              elevation={0}
              style={{
                color: "#5E5E5E",
              }}
            >
              <Table>
                {theads && (
                  <>
                        <TableHead>
                            <TableRow>
                    {theads.map((thead,index) => {
                      return (
                            <TableCell key={index} align="center">{thead}</TableCell>
                            );
                        })}
                        </TableRow>
                    </TableHead>
                  </>
                )}
                <TableBody>
                  {lists.map((data, index) => {

                    return (
                      <TableRow
                        key={index}
                      >
                        {Object.entries(data).map(([key,value]) => {
                          if (key === "Edit") {
                            return (
                                <>
                              <TableCell
                                key={key}
                                align="center"
                                className={classes.tabledata}
                              >
                                <Grid container direction="row" style={{width:"150%", gap:"0.5em"}}>
                                <img
                                  src={Edit}
                                    onClick={()=>handleEdit(index)}
                                  alt="error"
                                  style={{ cursor: "pointer" }}
                                  />
                                <img
                                  src={Delete}
                                  alt="error"
                                    onClick={()=>handleCross(index)}
                                  style={{ cursor: "pointer" }}
                                  />
                                  </Grid>
                                  </TableCell>
                                  </>
                            );
                          }
                          // else if(key === "expiryDate"){
                          //   let d = new Date(value)
                          //   let formated = d ? (d.toLocaleString('default', { month: 'long' }).toString())  + "-" + (d.getFullYear().toString()) : d
                          //   return (
                          //     <TableCell
                          //       key={key}
                          //       align="center"
                          //       className={classes.tabledata}
                          //     >
                          //       {formated}
                          //     </TableCell>
                          //   );
                          // }
                          else{
                            return (
                              <TableCell
                                key={key}
                                align="center"
                                className={classes.tabledata}
                              >
                                {value}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default index;
