/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { useState } from "react";
import "./Tab.css";
import Barcodeicon from '../../Assets/Barcodeicon.svg'
import Paperprescriptionicon from '../../Assets/Paperprescriptionicon.svg'
import CloseIcon from '@material-ui/icons/Close';
import QuantityDropdown from "../../Components/Dropdown/QuantityDropdown"
import EditIcon from '../../Assets/Edit.svg'
import Delete from '../../Assets/DeleteIcon.svg'
import { isEmpty, isSpace } from '../../Components/validation/Validation'
import { showErrMsg, showSuccessmsg } from '../../Components/notification/Notification'
import { CreateorderPaperprescription } from '../../api/orderops'
import ElectronicPrescription from './ElectronicPrescription'
import CreateOrderModal from '../../Components/CreateorderModal/CreateorderModal'
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Table,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Box,
  Modal,
  Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import RangeSearch from '../../Components/RangeSearchComponent/RangeSearch';
import { logEventWithProperties } from '../../analytics/Analytics';


const useStyles = makeStyles((theme) => ({
  tablecell: {
    backgroundColor: '#5E5E5E',
    color: '#fff'
  },
  tabledata: {
    border: '0px',
  },
  tabledataexpansion: {
    border: '0px',
    overflow: "auto",
    wordBreak: "break-all"
  },
  tabContainer: {
    display: "flex",
    flexDirection: "column",
    /* word-break: break-all;  */
    [theme.breakpoints.only('md')]: {
      maxWidth: "60%"

    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: "90%"

    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: "60%"

    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: "60%"

    },
    [theme.breakpoints.only('xl')]: {
      maxWidth: "60%"

    },
  },
  addItemList: {
    [theme.breakpoints.only('md')]: {
      width: "40%",

    },
    [theme.breakpoints.only('xs')]: {
      width: "100%",
      marginTop: "0px",
      height: "95vh",
    },
    [theme.breakpoints.only('sm')]: {
      width: "100%",
      marginTop: "0px",
      height: "95vh"

    },
    [theme.breakpoints.only('lg')]: {
      width: "40%",
      // height:"85vh",

    },
    [theme.breakpoints.only('xl')]: {
      width: "40%",
      // height:"85vh",

    },
  }
}));


const initialState = {
  firstName: '',
  lastName: '',
  err: '',
  err1: "",
  success: '',
}

const prodDetails = {
  productId: 0,
  productName: "",
  customisation: "",
  quantity: "",
  singles: "",
  err: false,
  vmpId: ""
}

const errorSuccess = {
  err1: "",
  success: ""
}

function Tabs(props) {
  const classes = useStyles();
  const { userDetails } = props

  const [Edit, setEdit] = useState(false)
  const [toggleState, setToggleState] = useState(1);
  const [user, setUser] = useState(initialState);
  const { firstName, lastName, err, success } = user
  const [productList, setProductList] = useState([]);
  const [productDropList, setProductDropList] = useState([]);
  const [product, setProduct] = useState(prodDetails)
  const [message, setMessage] = useState(errorSuccess)
  const [disableSubmittButton, setDisableSubmittButton] = useState(false);                                                                                    //useState added for disableSubmittButton
  const buttonEnabledStyle = { background: "#3D8541", marginTop: 18, color: "white", fontSize: "18px", textTransform: "none", boxShadow: "0px 2px 0px #306B33, inset 0px 1px 0px #62B767" }
  const buttonDisabledStyle = { background: "#EBEBEB", marginTop: 18, color: "#535353", opacity: disableSubmittButton ? "50%" : "unset", fontSize: "18px", textTransform: "none" }   //disableSubmittButton style changes

  const pharmacyAccountNumber = userDetails?.pharmacy_account_number;
  const pharmacyName = userDetails?.pharmacy_name;
  const pharmacyPostCode = userDetails?.pharmacy_post_code;
  const email = userDetails?.email;

  const handleChangeInput = e => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value, err: '', success: '' })
  }

  const handleSubmitadditem = async e => {

    if (isEmpty(firstName) || isEmpty(lastName)) {
      return setUser({ ...user, err: "Required fields are empty, review and try again.", success: '' })
    }
    else if (isSpace(firstName) || isSpace(lastName)) {
      return setUser({ ...user, err: "Required fields are empty, review and try again.", success: '' })
    }
    handleOpen();

    e.preventDefault()
  }

  //aMPLITUDE TRACK
  React.useEffect(() => {
    logEventWithProperties("User on create Manual order page",{userId:userDetails?.id})
  }, [])

  const editProduct = (item) => {
    setEdit(true)
    setProduct(item);
    setOpen(true);

  }
  const toggleTab = (index) => {
    setToggleState(index);
  };

  // modal 1
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => { setProduct({}); setOpen(false); }
  const handleCloseModalIcon = () => {
    if (Edit === true) {
      setProduct([{ ...prodDetails, productId: product.productId }])
      setOpen(false);
      setEdit(false);
    }
    else {
      setOpen(false);
    }
  }
  const style = { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'white', borderRadius: 10, p: 3, };

  // modal 2
  const [deleteindex, setDeleteIndex] = React.useState(-1);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);

  // modal 3
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = (index) => () => {
    setOpen2(true);
    setDeleteIndex(index);

  };
  const handleClose2 = () => setOpen2(false);

  // modal 2
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  // paper prescription functionalities
  function handleChangeProductdetails(event) {
    const { name, value } = event.target;
    const ALPHA_NUMERIC_DASH_REGEX = /^[A-Za-z0-9 ]+$/;

    if (value !== "" && !ALPHA_NUMERIC_DASH_REGEX.test(value)) {
      return;
    }
    setProduct({ ...product, [name]: value }
    )
  }
  const [envelope, setEnvelop] = useState(false)
  const handleChangeCheckbox = () => {
    setEnvelop(!envelope)
  }

  const addProductdetails = async (event) => {

    event.preventDefault();
    if (isEmpty(product.productName) || isSpace(product.productName)) {
      setProduct({ ...product, err: true, err1: "Please product you want to add." });
      setMessage({ ...message, err1: "Please select product you want to add." });
      handleOpen3()
      setTimeout(() => handleClose3(), 2000);
      return setTimeout(() => setMessage({ ...message, success: '', err: false, err1: '' }), 2000);
    } else if (!product.quantity ||  product.quantity.trim()==='') {
      setMessage({ ...message, err1: "Please select quantity." });
      handleOpen3()
      setTimeout(() => handleClose3(), 2000);
      return setTimeout(() => setMessage({ ...message, success: '', err: false, err1: '' }), 2000);
    } else if (productList.length === 10 && !Edit) {
      setMessage({ ...message, err1: "Item limit reached. If you need to submit more items, call customer service." });
      setTimeout(() => handleClose(), 2000);
      handleOpen3()
      setTimeout(() => handleClose3(), 2000);
      setEdit(false)
      setProduct([{ ...prodDetails, productId: product.productId }])
      return setTimeout(() => setMessage({ ...message, success: '', err: false, err1: '' }), 2000);
    }
    else if (productDropList.length === 0) {
      setMessage({ ...message, err1: "Item not included in our range." });
      handleOpen3()
      setTimeout(() => handleClose3(), 2000);
      return setTimeout(() => setMessage({ ...message, success: '', err: false, err1: '' }), 2000);
    }
    else {
      let isExist = productList.find(
        (item) => {
          return item.productName === product.productName
        })
      // if((!valueFound && Edit === true && )||(!valueFound && Edit===false)) {

      //   setMessage({ ...message, err1: "Item not included in our range." });
      //   setProduct([{ ...prodDetails, productId: product.productId }])
      //   setTimeout(() => handleClose(), 2000);
      //   handleOpen3()
      //   setTimeout(() => handleClose3(), 2000);
      //   setEdit(false);
      //   return setTimeout(() => setMessage({ ...message, success: '', err: false, err1: '' }), 2000);
      // }
      if (!isExist) {
        setProductList(prev => {
          setProduct({ ...prodDetails, productId: product.productId + 1 })
          return [...prev.filter(item => item.productName !== product.productName), product]
        })
        // if (product.singles === 'Singles') {
        //   setMessage({ ...message, success: `${product.quantity} singles of ${product.productName} added to your prescription.` })
        //   handleOpen3()
        //   setTimeout(() => handleClose3(), 2000);
        //} 
        {
          setMessage({ ...message, success: `${product.quantity} of ${product.productName} added to your prescription.` })
          handleOpen3()
          setTimeout(() => handleClose3(), 2000);
        }
        setTimeout(() => handleClose(), 2000);
        setTimeout(() => setMessage({ ...message, success: '', err: false, err1: '' }), 2000);
        setTimeout(() => handleClose3(), 2000);
        setEdit(false);
      } else {
        if (!Edit) {
          setMessage({ ...message, err1: "Item already added." });
          handleOpen3()
          setTimeout(() => handleClose3(), 2000);
          return setTimeout(() => setMessage({ ...message, success: '', err: false, err1: '' }), 2000);
        } else {
          setProductList(prev => {
            return [...prev.filter(item => item.productName !== product.productName), product]
          })
          setTimeout(() => handleClose(), 2000);
          setTimeout(() => setMessage({ ...message, success: 'Updated product details', err: false, err1: '' }), 2000);
          setTimeout(() => handleClose3(), 2000);

          setEdit(false);
        }
      }

    }
  }


  function deleteProductdetails() {
    const filteredList = productList.filter((x, index) => {
      if (deleteindex !== index) {
        return x;
      }
    });
    setProductList(filteredList)
    handleClose2();
  }

  function clearProductDetails() {
    const filteredList = productList.filter(() => null);
    handleClose1();
    setProductList(filteredList)
  }

  // create or submit for paper prescription

  const handleSubmitCreateorderpp = async e => {
    e.preventDefault()
    setDisableSubmittButton(true);   
    if (isEmpty(firstName) || isEmpty(lastName)) {
    setDisableSubmittButton(false);                                                                 //Enable the submit button once the details have been entered in the form    
      return setUser({ ...user, err: "Required fields are empty, review and try again.", success: '' })
    } else if (isSpace(firstName) || isSpace(lastName)) {
    setDisableSubmittButton(false);
      return setUser({ ...user, err: "Required fields are empty, review and try again.", success: '' })
    } else if (productList.length === 0) {
    setDisableSubmittButton(false);
      return setUser({ ...user, err: "No items in your prescription. Add an item and try again.", success: '' })

    } else {
                                                                                               
      CreateorderPaperprescription(email, firstName, lastName, pharmacyAccountNumber, pharmacyName, pharmacyPostCode, productList,envelope)
        .then((res) => res.json())
        .then((data) => {
                                                                  
          if (data.error) {
            logEventWithProperties("Manual order creation failed", {...{orderType:"manual",userId:userDetails?.id,pharmacyAccountNumber:userDetails?.pharmacyAccountNumber},...data?.error})
            setDisableSubmittButton(false);//Enabling the submit button if there is an error
            return setUser({ ...user, err: data.error.message, success: '' })

          } else {
            logEventWithProperties("Manual order successfully created", {...{orderType:"manual",userId:userDetails?.id,pharmacyAccountNumber:userDetails?.pharmacyAccountNumber},...data,})
            setUser({ ...user, err: "", success: 'Prescription successfully sent' })
            setTimeout(function () { //Start the timer
              setUser({
                ...user,
                firstName: '',
                lastName: '',
                success: '',
                err: ''
              })
              setProductList([])
              setDisableSubmittButton(false);//Enable the submit button after the details entered in the form for consecutive orders
            }.bind(this), 1000)
          }
        },
          error => {
            setDisableSubmittButton(false); //Enabling the submit button when the api fails and there is an error                                                           
            console.error('There was an error!', error);
          });
    }
  }

  const addProductToList = (item) => {
    let produts = productDropList
    produts.push({ ...product, productName: item.prodDesc })
    setProductDropList(produts);
    setProduct({ ...product, productName: item.prodDesc })

    // handleAutoCompleteChange(item?.drugName)
  }
  return (

    <div className={classes.tabContainer}>
      <div className="bloc-tabs">
        <button
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(1)}
        >
          <img src={Barcodeicon} alt="error" />
          <p style={{ margin: 0, padding: 0, fontSize: 16, marginLeft: 5, fontWeight: "bold" }}>Electronic prescription</p>
        </button>
        <button
          data-testid="paper-prescription-btn"
          style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
          onClick={() => toggleTab(2)}
        >
          <img src={Paperprescriptionicon} alt="error" />
          <p style={{ margin: 0, padding: 0, fontSize: 16, marginLeft: 5, fontWeight: "bold" }}>Paper prescription</p>
        </button>
      </div>

      {/* electronic prescription content */}


      <div className="content-tabs">

        <div
          className={"content  active-content"}
        >
          {toggleState === 1 &&
            <ElectronicPrescription toggleState={toggleState} />

          }
          {toggleState === 2 &&
            <div>
              <p>To place a new order for a paper prescription, enter the patient's name, select 'Add new item' and fill out the information required. Your items will then be added to the prescription list below. Please review the list before placing your order. </p>
              <p style={{ color: "#5E5E5E", fontWeight: "bold", marginTop: 18, marginBottom: 18 }}>Patient details</p>

              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "auto" }}>
                <TextField
                  style={{ boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", width: "48%", }}
                  data-testid="input-first-name"
                  placeholder="First name"
                  variant="outlined"
                  type="text"
                  size="small"
                  onChange={handleChangeInput}
                  name="firstName"
                  value={firstName}
                />
                <TextField
                  style={{ boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", width: "48%", }}
                  data-testid="lastname"
                  placeholder="Last name"
                  variant="outlined"
                  type="text"
                  size="small"
                  name="lastName"
                  value={lastName}
                  onChange={handleChangeInput}
                />
              </div>

              <Button data-testid="additem" disabled={productList.length === 10} onClick={handleSubmitadditem} variant="contained" size="large" disableElevation style={productList.length === 10 ? { background: "#e0e0e0", marginTop: 18, fontSize: "18px", textTransform: "none" } : { background: "#007BB8", marginTop: 18, color: "white", fontSize: "18px", textTransform: "none", boxShadow: " 0px 2px 0px #005179, inset 0px 1px 0px #009ADB" }} fullWidth  >Add new item</Button>
              {toggleState ? err && showErrMsg(err, () => {
                setUser({ ...user, err: '' })
              }) : null}
              <div data-testid="success-msg">
                {toggleState ? success && showSuccessmsg(success) : null}
              </div>
              <p style={{ color: "#5E5E5E", fontWeight: "bold", marginTop: 18, marginBottom: 18 }}>Prescription list</p>

              {!productList.length ?
                <Box style={{ border: "1px solid #CDCDCD", borderRadius: 4, padding: 10, }}>
                  <p data-testid="empty-text" style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "#5E5E5E" }}>Your list is empty, choose ‘Add new item’ to get started</p>
                </Box>
                : <>
                  <p style={{ color: "#5E5E5E", marginTop: 18, marginBottom: 18 }}>Review the items in your prescription before placing your order.</p>


                  <Grid item style={{ marginTop: '2em', }}>
                    <TableContainer component={Paper} elevation={2} style={{ border: "1px solid #CDCDCD", borderRadius: 4, color: "#5E5E5E" }} >
                      <Table >
                        <TableBody>
                          {productList.map((value, index) =>
                            <TableRow key={index} className={classes.tablerow} >
                              <TableCell align="center" className={classes.tabledata}>{value.productName}</TableCell>
                              <TableCell align="center" className={classes.tabledata}>{value.productName === "" ? null : value.quantity}</TableCell>
                              <TableCell align="center" className={classes.tabledataexpansion}>{value.customisation}</TableCell>
                              <TableCell align="center" className={classes.tabledata}>{value.productName === "" ? null : <img src={EditIcon} onClick={() => editProduct(value)} alt="error" style={{ cursor: "pointer" }} />}</TableCell>
                              <TableCell align="center" className={classes.tabledata}>{value.productName === "" ? null : <img src={Delete} alt="error" onClick={handleOpen2(index)} style={{ cursor: "pointer" }} />}</TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </>}
                <div style={{margin:0,padding:15,marginTop:17, display:"flex", alignItems:"center",border: "1px solid #CEDCE9",boxSizing: "border-box",boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)",borderRadius: 4}}>
                <Checkbox style={{padding:0, marginRight:5, color:"#007BB8",}} 
              id="checkbox"
              value={envelope}
              checked={envelope}
              data-testid="checkbox" 
              name="checkbox" 
              onChange={handleChangeCheckbox}
              />
              <p style={{marginLeft:10, padding:0}}>Are pre paid envelopes required with this order?</p>
              

            </div>
              <Button
                data-testid="createOrderBtn"
                onClick={handleSubmitCreateorderpp}
                variant="contained"
                size="large"
                disableElevation
                disabled={productList.length > 0 && !disableSubmittButton ? false : true}                                   //disableSubmittButton condition changed
                style={productList.length > 0 && !disableSubmittButton ? buttonEnabledStyle : buttonDisabledStyle}          //disableSubmittButton style condition changed
                fullWidth
              >
                Submit
              </Button>
              {
                productList.length > 0 ?
                  <p data-testid="clearOrder" onClick={handleOpen1} style={{ color: "#007BB8", fontWeight: "bold", marginTop: 25, cursor: "pointer", textAlign: "center" }}>Clear order</p>
                  : null
              }
              {/* Modal for add items - Modal 1  */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >

                <Box sx={style} className={classes.addItemList} align="justify">
                  <form onSubmit={(e) => addProductdetails(e)} style={{ padding: "10px" }}>
                    <Typography style={{ display: "flex", alignItems: "center", cursor: "pointer", justifyContent: "right", paddingTop: "0.5em" }} onClick={handleCloseModalIcon} id="modal-modal-title" variant="h6" component="h2">
                      <p style={{ margin: 0, padding: 0, marginLeft: 5, fontSize: "15px", color: "#007BB8", fontWeight: "bold" }}>Close</p>
                      <CloseIcon style={{ color: "#007BB8", height: 15 }} />
                    </Typography>

                    <Typography style={{ display: "flex", alignItems: "center", justifyContent: "left" }} id="modal-modal-title" variant="h6" component="h2">
                      <p style={{ margin: 0, padding: 0, marginLeft: 5, marginBottom: 17, marginTop: 17, fontSize: "17px", color: "#222222", fontWeight: "bold" }}>Add items to your prescription</p>
                    </Typography>

                    <Typography style={{ display: "flex", alignItems: "center", justifyContent: "left" }} id="modal-modal-title" variant="h6" component="h2">
                      <p style={{ margin: 0, padding: 0, marginLeft: 5, fontSize: "15px", color: "#222222", }}>Use the boxes below to add an item to your prescription. Once you’re happy that all of the information is correct, choose ‘Add to list’ and close the box.<br /> If your prescription has more than one item, simply add your first item to the list and enter the details of your next item.</p>
                    </Typography>

                    <Typography style={{ display: "flex", alignItems: "center", justifyContent: "left" }} id="modal-modal-title" variant="h6" component="h2">
                      <p style={{ margin: 0, padding: 0, marginLeft: 5, marginBottom: 17, marginTop: 17, fontSize: "17px", color: "#222222", fontWeight: "bold" }}>Product details</p>
                    </Typography>
                    <RangeSearch onItemClick={addProductToList} item={product} />
                    {/* <Autocomplete
                    data-testid="autocomplete"
                    id="free-solo-demo"
                    value={product.productName}
                    onChange={(event, newValue) => handleAutoCompleteChange(event, newValue)}
                    filterOptions={() => {
                      return productDropList.map((option) => option.drugName);
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disablePortal
                    options={productDropList.map((option) => option.drugName)}
                    renderInput={(params) => <TextField {...params}
                      style={{ boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)"}}
                      fullWidth
                      data-testid="ProductNameTextField"
                      variant="outlined"
                      type="text"
                      size="small"
                      error={product.err}
                      value={product.productName}
                      label={isEmpty(product.productName) || isSpace(product.productName) ? "Enter product name, product code" : "Enter product name or product code"}
                      onChange={(e) => { handleChangeProductdetails(e); fetchProductDetails(e.target.value);setAutocompleteEdit(true)}}
                      name="productName" />
                    }
                  /> */}

                    <TextField
                      style={{ boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", marginTop: 17, marginBottom: 17 }}
                      fullWidth
                      data-testid="Customization"
                      placeholder="Add customisation measurement here. For example, cut to fit 45mm"
                      // label="Customisation"
                      multiline
                      rows={2}
                      variant="outlined"
                      type="text"
                      size="small"
                      value={product.customisation}
                      name="customisation"
                      onChange={handleChangeProductdetails}
                    />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <QuantityDropdown quantity={product.quantity} handleChangeProductdetails={handleChangeProductdetails} />
                    </div>
                    <Button data-testid="addtolist" variant="contained" type='submit' size="large" disableElevation style={{ background: "#007BB8", marginTop: 18, marginBottom: 18, color: "white", fontSize: "18px", textTransform: "none", boxShadow: " 0px 2px 0px #005179, inset 0px 1px 0px #009ADB" }} fullWidth>{Edit ? "Update list" : "Add to list"}</Button>
                  </form>
                </Box>
              </Modal>

              <CreateOrderModal closeError={() => {
                setMessage({ ...message, err1: '' })
              }} open={open} handleClose={handleClose} product={product} setProduct={setProduct} open1={open1} handleClose1={handleClose1} clearProductDetails={clearProductDetails} open2={open2} handleClose2={handleClose2} deleteProductdetails={deleteProductdetails} open3={open3} handleClose3={handleClose3} successmessage={message.success} errormessage={message.err1} />
            </div>}



        </div>
      </div>
    </div>

  );
}
const mapStateToProps = state => {
  return {
    isSignedIn: state.isSignedIn,
    userDetails: state.userDetails
  };
};

Tabs.propTypes = {
  userDetails: PropTypes.object,
  toggleState: PropTypes.number.isRequired,
};
export default connect(mapStateToProps)(Tabs);