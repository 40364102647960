import React from 'react';
import { Grid, Paper,Button,makeStyles } from '@material-ui/core';
import Right from '../../Assets/Right.svg';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getLoginUserDetails, LoginUser } from '../../redux/middleware';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  paperStyle: {   
      margin:"auto",
      paddingTop:150,
      [theme.breakpoints.up('md')]: {
        width: "25%",
      },
      [theme.breakpoints.down('md')]: {
        width: "40%",
      },
      [theme.breakpoints.down('sm')]: {
        width: "50%",
      },
      [theme.breakpoints.down('xs')]: {
        width: "90%",
      },
  }
}));

function RegisterSuccessStaff(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;
  const emaillowercaser = email;
  const classes = useStyles();

  
  const password = location.state?.password;
  var error = ''

  // useEffect(()=>{
  //   if(props.userDetails)
  //   if (props.userDetails.role === "fp@wardleStaff") {
  //     navigate("/orderlist", { state: { role: props.userDetails.role } });
  //   } else if (
  //     props.userDetails.role === "fp@wardleHqUser" ||
  //     props.userDetails.role === "fp@wardleHqAdmin"
  //   ) {
  //     navigate("/yourstatements", {state: { role: props.userDetails.role } });
  //   } else {
  //     navigate("/createorder", { state: { role: props.userDetails.role } });
  //   }
  // },[props.userDetails])
  function accountLogin()  {
    props.dispatch(LoginUser(emaillowercaser,password))
    props.dispatch(getLoginUserDetails(emaillowercaser,true))
    navigate("/createorder")

   
  }
  return (
  <>
    <Grid align="center">
        <Paper elevation={0} className={classes.paperStyle} sx={{textAlign:"center"}}>
          <img src={Right} alt="" srcSet=""/>
          <h1 data-testid="Header" style={{fontSize: 25,margin:0, marginBottom:25,width:"70%"}}>Your account is created</h1>
          <Button data-testid="btn" variant="outlined" onClick={() => {location.state?.email?accountLogin():navigate("/")}} size="large" fullWidth disableElevation style={{background:"#3D8541", color:"white", marginTop:"8px", fontSize:"12px", textTransform: "none"}} >{location.state?.email?"Go to my account":"Go to Signin"}</Button>
        </Paper>
        {error}      
    </Grid>

  </>
  );
}
const mapStateToProps = state => {
  return {
      isSignedIn:state.isSignedIn,
      token:state.token,
    userDetails: state.userDetails,
    loginError:state.loginError
  };
};

RegisterSuccessStaff.propTypes = {
  isSignedIn: PropTypes.bool,
  token: PropTypes.object,
  dispatch: PropTypes.func,
  userDetails: PropTypes.object,
};


export default connect(mapStateToProps) (RegisterSuccessStaff);
