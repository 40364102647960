import React from 'react'
import ColumnComp from './index'
import { Grid } from "@material-ui/core";


function index3({items,handleChange}) {
  return (
    <Grid container md={12} sm={12} lg={12} xs={12}>
        {items.map((item, index)=>{
            if(item.row){
                return(
                    <Grid item key={index} sm={6} md={6} xs={12} lg={6}>
                    <ColumnComp minDate={item.minDate} format={item.format} placeholder={item.placeholder} text={item.text} type={item.type} value={item.value} disable={item.disable} err={item.err} helperText={item.helperText} handleChange={handleChange}/>
                    </Grid>
                )
            }else{
                return(
                    <Grid item key={index} direction='column' md={12} sm={12} lg={12} xs={12}>
                    <ColumnComp minDate={item.minDate} format={item.format} placeholder={item.placeholder} text={item.text} type={item.type} value={item.value} disable={item.disable} err={item.err} helperText={item.helperText} handleChange={handleChange}/>
                    </Grid>
                )
            }
        })}
    </Grid>
  )
}

export default index3