export const filterString = (state, type,append="&") => {
  let filter = ""

  if (type === "order") {
    filter = state?.status ? filter + `&recordStatus=${state?.status?.toLowerCase()}` : filter
    let d = state?.date
    let formated = state?.isDateChanged ? d?.getFullYear().toString() + "-" + ((d?.getMonth() + 1).toString().length == 2 ? (d?.getMonth() + 1).toString() : "0" + (d?.getMonth() + 1).toString()) + "-" + (d?.getDate().toString().length == 2 ? d?.getDate().toString() : "0" + d?.getDate().toString()) : ''

    filter = state?.isDateChanged ? filter + `&orderDate=${formated}` : filter

  } else if (type === "user") {
    filter = state?.role ? filter + `&role=${state?.role}&active=true` : filter
    filter = state?.status ? filter + `&recordStatus=${state?.status?.toLowerCase()}` : filter
  } else if (type === 'commission_statement') {
    if(state?.fromDate && state?.toDate)
    filter = filter + `&commissionCycle=${state?.fromDate + "," + state?.toDate}`
  } else {
    let entries = state && Object.entries(state)
    let str = entries && entries.length > 0?append:''
    let filters = entries && entries?.map((key) => { 
     return `${key[0]}=${key[1]}`
    })

    str = filters?str+filters?.join("&"):str
    filter = filter + str

  }

  return filter

}