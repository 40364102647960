export   const debugBase64=(base64URL)=>{
  var win = window.open();
  win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
}

export  const printFile=(base64URL)=>{
    var win = window.open();
  win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  win.print()
}


export const printPreview = (data, type = 'application/pdf') => {
  let blob = null;
  blob = b64toBlob(data, type);
  const blobURL = URL.createObjectURL(blob);
  const theWindow = window.open(blobURL);
  const theDoc = theWindow.document;
  const theScript = document.createElement('script');
  function injectThis() {
      window.print();
  }
  theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
  theDoc.body.appendChild(theScript);
};

const b64toBlob = (content, contentType) => {
  contentType = contentType || '';
  const sliceSize = 512;
   // method which converts base64 to binary
  const byteCharacters = window.atob(content); 

  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, {
      type: contentType
  }); // statement which creates the blob
  return blob;
};