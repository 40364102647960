import React, { useState } from "react";
import "../../App.css";
import CallIcon from "../../Assets/CallIcon.svg";
import AccountCircleOutlinedgreen from "../../Assets/AccountCircleOutlinedgreen.svg";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Button,
  InputAdornment,
  TextField,
  makeStyles,
  Typography
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/KeyboardArrowLeft";
import { useLocation } from "react-router-dom";
import {
  isEmpty,
  isPhoneValid,
  isSpace,
} from "../../Components/validation/Validation";
import {
  showErrMsg,
  showErrMsgEmpty,
} from "../../Components/notification/Notification";
import { logEventWithProperties } from "../../analytics/Analytics";
import { useEffect } from "react";


const useStyles = makeStyles((theme) => ({
  backbutton:{
    cursor: "pointer",
    textDecoration:"none", 
    color:"#5E5E5E", 
    display:"flex", 
    alignItems:"center", 
    paddingTop:130,
    [theme.breakpoints.up('md')]: {
          marginLeft:150,
        },
        [theme.breakpoints.down('md')]: {
          marginLeft:150,
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft:0,
          paddingLeft:"4%",
        },
      },
  yourdetailscontainer:{
    margin: "auto",
        [theme.breakpoints.up('md')]: {
          width: "31%",
        },
        [theme.breakpoints.down('md')]: {
          width: "40%",
        },
        [theme.breakpoints.down('sm')]: {
          width: "50%",
        },
        [theme.breakpoints.down('xs')]: {
          width: "90%",
          padding:"4%",
        },
  },
  Textfields:{
    marginBottom: "4%",
    boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)",
    background: "white"
  },
  buttongreen:{
    color: "white",
    textTransform: "none",
    marginTop: "1%",
  }


}))

function Yourdetails() {
  const classes = useStyles()
  const location = useLocation();
  const email = location?.state?.email;
  const password = location?.state?.password;
  const receiveOffer = location?.state?.receiveOffer;


  const verifyDetails = location?.state?.verifyDetails

  let initialState1 = {
    phoneNumber: verifyDetails?.phoneNumber || location?.state?.phoneNumber,
    firstName: verifyDetails?.firstName || location?.state?.firstName,
    lastName: verifyDetails?.lastName || location?.state?.lastName,
    err: "",
    err1: "",
    err2: "",
  };

  let navigate = useNavigate();
  const [user1, setUser1] = useState(initialState1);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUser1({ ...user1, [name]: value, err: "" });
  };

  let { firstName, lastName, phoneNumber, err, err1, err2 } = user1;

  useEffect(()=>{
    logEventWithProperties("User visits your details page")
  },[])


    const handleSubmit = async e => {
        e.preventDefault()
        console.log("First name : ", firstName)
        console.log("Last name : ", lastName)
        if(isEmpty(firstName) || isEmpty(lastName) || isEmpty(phoneNumber)) {
            return setUser1({...user1, err:"This field is required",err2:"Please provide a valid phone number", err1: "Please check the information above is correct", success: ''})
        }

        if (!isPhoneValid(phoneNumber)) {
            return setUser1({...user1,err:"Please provide a valid phone number", err1: "Please provide a valid phone number",success: ''})
        }

        if (isSpace(phoneNumber) || isSpace(firstName) || isSpace(lastName)) {
            return setUser1({...user1, err1: "Please check the information above is correct",success: ''})
        }
        logEventWithProperties("User submits details on your details page",{email:email,firstName:firstName,lastName:lastName, receiveOffer:receiveOffer,phoneNumber:phoneNumber, verifyDetails:verifyDetails })

        navigate('/pharmacydetails',{state:{email:email,firstName:firstName,lastName:lastName, password:password,receiveOffer:receiveOffer,phoneNumber:phoneNumber, verifyDetails:verifyDetails }})
    }

    function backbuttonFunc(){
      return(verifyDetails.email != undefined ?  (navigate(`/verify-user/${verifyDetails.email}/${verifyDetails.firstName}/${verifyDetails.firstName}/${verifyDetails.role}/${verifyDetails.pharm_name}/${verifyDetails.pharm_accno}/${verifyDetails.pharm_code}/${verifyDetails.rnd}/${verifyDetails.token}`, {
        state: {
          email: location?.state?.email,
          password: location?.state?.password,
          checkbox: location?.state?.receiveOffer
        },
      }))
      :
      (navigate("/signup", {
        state: {
          email: location?.state?.email,
          password: location?.state?.password,
          checkbox: location?.state?.receiveOffer
        },
      }))   
        
      )
    }
  

  return (
    <Grid>
      <Grid className={classes.backbutton}>
        <ArrowBackIcon onClick={() => { backbuttonFunc()       
            }} />
        <Typography onClick={() => { backbuttonFunc()
          }} >Back</Typography>
      </Grid>

      <form onSubmit={handleSubmit} autoComplete="off">
        <Paper elevation={0} className={classes.yourdetailscontainer} >
          <h2 data-testid="Header" style={{margin: 0, marginBottom:"2%", fontSize: 40}} >
            Your details
          </h2>

          <TextField
            size="small"
            className={classes.Textfields}
            placeholder="First name"
            error={isEmpty(firstName) && (err?true:false)}
            variant="outlined"
            label={isEmpty(firstName) ? null : "First name"}
            type="text"
            id="firstName"
            value={firstName === "first+name" ? "" : firstName}
            data-testid="firstName"
            name="firstName"
            onChange={handleChangeInput}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={AccountCircleOutlinedgreen} alt="error" />
                </InputAdornment>
              ),
            }}
          />
          <div data-testid="error">
          {isEmpty(firstName) && showErrMsgEmpty(err)}
          </div>
          <TextField
            size="small"
            className={classes.Textfields}
            placeholder="Last name"
            variant="outlined"
            error={isEmpty(lastName) && (err?true:false)}
            label={isEmpty(lastName) ? null : "Last name"}
            type="text"
            id="lastName"
            data-testid="lastName"
            value={lastName === "last+name" ? "" : lastName}
            name="lastName"
            onChange={handleChangeInput}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={AccountCircleOutlinedgreen} alt="error" />
                </InputAdornment>
              ),
            }}
          />
          <div data-testid="error">
          {isEmpty(lastName) && showErrMsgEmpty(err)}
          </div>
          <TextField
            size="small"
            className={classes.Textfields}
            placeholder="Phone number"
            error={
              (isEmpty(phoneNumber) && (err?true:false)) ||
              (!isPhoneValid(phoneNumber) && (err2?true:false))
            }
            variant="outlined"
            label={isEmpty(phoneNumber) ? null : "Phone number"}
            type="text"
            data-testid="phoneNumber"
            id="phoneNumber"
            value={phoneNumber}
            name="phoneNumber"
            onChange={handleChangeInput}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={CallIcon} alt="error" />
                </InputAdornment>
              ),
            }}
          />
          <Grid data-testid="error">
          {(isEmpty(phoneNumber) && showErrMsgEmpty(err)) ||
            (!isPhoneValid(phoneNumber) && showErrMsgEmpty(err2))}
          </Grid>

          <Grid data-testid="error1">
          {err1 && showErrMsg(err1,()=>{
                    setUser1({...user1,err1:'',err:"",err2:""})
                  })}
          </Grid>

          <Grid align="center">
            <Button
              variant="contained"
              fullWidth
              size="large"
              disableElevation
              data-testid="next-btn"
              className={classes.buttongreen}
              style={{
                background: "#3D8541",
                boxShadow: "0px 2px 0px #306B33, inset 0px 1px 0px #62B767",
              }}
              type="submit"
            >
              Next
            </Button>
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
}

export default Yourdetails;
