import React, { useEffect } from 'react';
import ListComponent from "../../Components/ListComponent/ListComponent";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import logEvent from '../../analytics/Analytics';


function Orderlist(props) {

  
  const role = props.userDetails?.role
  const first_name = props.userDetails?.first_name

  useEffect(()=>{
    logEvent("Orders viewed")

  },[])

  return (
    <>
     {(role === "fp@wardleAdmin" || role === "fp@wardleUser" )? 
    <ListComponent 
      heading={"Your Orders"}
      subtext={
        `On this page you will find orders that have been
      placed on your account. For more details select the order that you wish to view.`
      }
      searchPlaceHolder={
        "Search by pharmacy name or patient name"
      }
      recordType="order"
      operation="filter"
      filterParams={props.orderFilters}
      head={[
        { value: "View", issortable: false },
        { value: "Order Number", issortable: true },
        { value: "Order Date", issortable: false },
        { value: "Pharmacy Name", issortable: true },
        { value: "Postcode", issortable: false },
        { value: "Patient Name", issortable: false },
        { value: "Order Status", issortable: false },
      ]}
      />  : null}

    {role === "fp@wardleStaff" ? 
    <ListComponent 
    first_name={first_name}
    heading = {role === "fp@wardleStaff" ? "Orders and requests" : "Your Orders"}
    subtext={
      `On this page you will find orders that have been
      placed on your account. For more details select the order that you wish to view.`
    }
    searchPlaceHolder={
      "Search by pharmacy name, pharmacy acccount number or patient name"
    }
    recordType="order"
    operation="all"
    filterParams={props.orderFilters}

    head={[
      { value: "View", issortable: false },
      { value: "Order Number", issortable: true },
      { value: "Order Date", issortable: false },
      { value: "Account Number", issortable: true },
      { value: "Pharmacy Name", issortable: true },
      { value: "Postcode", issortable: false },
      { value: "Patient Name", issortable: false },
      { value: "Order Status", issortable: false },
    ]}
    /> : null}

    {role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser" ? 
    <ListComponent 
    first_name={first_name}
    heading = {"Your Orders"}
    subtext={
      `On this page you will find orders that have been
      placed on your account. For more details select the order that you wish to view.`
    }
    searchPlaceHolder={
      "Search by pharmacy name, pharmacy acccount number or patient name"
    }
    recordType="order"
    operation="all"
    filterParams={props.orderFilters}

    head={[
      { value: "View", issortable: false },
      { value: "Order Number", issortable: true },
      { value: "Order Date", issortable: false },
      { value: "Pharmacy Name", issortable: true },
      { value: "Postcode", issortable: false },
      { value: "Patient Name", issortable: false },
      { value: "Order Status", issortable: false },
    ]}
    /> : null}

      
      {/* {role === "fp@wardleAdmin" || role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser" ? 
      <Container style={{ position: 'fixed', top: '92vh', left: '86vw', display: 'flex', fontWeight: "bold" }}>
        <a href="https://bestwaymedhub.topdesk.net/tas/public/login/form" target='blank' style={{ display: "flex", alignItems: "center", textDecoration: "none", color: "#007BB8", }} >
          <img src={Helpicon} style={{ height: 18, width: 18 }} alt="error" />
          <p data-testid="linkcustomer">&nbsp;&nbsp;Customer support</p>
        </a>
      </Container> : null} */}
    </>
  );
}
const mapStateToProps = state => {
  return {
    isSignedIn: state.isSignedIn,
    orderFilters: state.orderFilters,

    userDetails: state.userDetails
  };
};


Orderlist.propTypes = {
  userDetails: PropTypes.object,
  orderFilters: PropTypes.func
};

export default connect(mapStateToProps)(Orderlist);
