import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';


const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  heading: {
    fontWeight: 700,
    fontSize: "1em"
  },
}));


function AccordionComp(Props) {
    const classes = useStyles();
  return (
    <div className={classes.root}>
    <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography data-testid='header' className={classes.heading}>{Props.heading}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Paper elevation={2} style={{border: '1px solid #E9E9E9',boxShadow: '2px 2px 7px rgba(205, 205, 205, 0.5)',borderRadius: '10px',padding:"1em",width:"100%"}}>
        {Props.items.map((item,index)=>{
            return(
        <Grid container style={{marginBottom:"0.5em"}} key={index}>
            <Grid item>
            <Typography data-testid="data-key" variant='body1' style={{color:"#5E5E5E",fontWeight:700}}>
            {item.key}
          </Typography>
          </Grid>
          <Grid item style={{marginLeft:"0.5em"}}>
            <Typography data-testid="data-value" variant='body1'>
            {item.value}
          </Typography>
          </Grid>
        </Grid>
            )
        })}
          </Paper>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

AccordionComp.propTypes = {
  heading: PropTypes.string.isRequired,
  items : PropTypes.arrayOf(PropTypes.object).isRequired
}

export default AccordionComp