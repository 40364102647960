/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { Grid,Paper, Button, Typography,makeStyles,Hidden } from '@material-ui/core';
import Grnmail from '../../Assets/GrnMail.svg';
import OpenMail from '../../Assets/OpenMail.svg';
import Question from '../../Assets/questionmark.svg';
import ArrowDown from '../../Assets/Arrowdown.svg';
import { useLocation } from 'react-router';
import { AccessStart, AdminAddUserComplete } from '../../api/userOps';
import { showSuccessmsg } from '../../Components/notification/Notification';
import { InformationModal } from '../../Components/Modal/InformationModal';

const useStyles = makeStyles((theme) => ({
  maincontainer:{
    margin: "auto",
    padding :10,
    [theme.breakpoints.up('md')]: {
      width: "27%",
      paddingTop:140, 
    },
    [theme.breakpoints.down('md')]: {
      width: "40%",
      paddingTop:140, 
    },
    [theme.breakpoints.down('sm')]: {
      width: "55%",
      paddingTop:140, 
    },
    [theme.breakpoints.down('xs')]: {
      width: "90%",
      padding:"4%",
      paddingTop:120, 
    },
  },
  innercontainer:{
    padding:15, 
    display:"flex",
    border: "1px solid #52B057",
    boxSizing: "border-box",
    boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", 
    borderRadius: 4, 
    overflow:"clip",
    marginBottom:"4%"
  }
}))

function EMailActivate() {
  
  const location = useLocation();
  const verifyDetails = location?.state?.verifyDetails
  const classes = useStyles()
  const email = location?.state?.email;

  const modaltext = [
    { value: "Try resending the email and check your junk or spam folders. If you still can't see an email from us after a few minutes, use the help button at the top of the screen."},]

  const [open, setOpen] = React.useState(false);
  const [resendSuccess,setResendSuccess] = React.useState(false)
  const [disableResend,setDisableResend] = React.useState(false)

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async e => {
    e.preventDefault()
    setDisableResend(true)
    setResendSuccess(false)
        let response = {};
        if(!verifyDetails){
        AccessStart(
            location.state?.email,
            location.state?.password,
            location.state?.phoneNumber,
            location.state?.firstName,
            location.state?.lastName,
            location.state?.pharm_name,
            location.state?.pharm_code,
            location.state?.pharm_accno
        ).then((res) => res.json())
        .then((data) => {
            response = data
            setResendSuccess(true)
            setDisableResend(false)
        },
        );
      }else{
        AdminAddUserComplete(
          verifyDetails.email,
          location.state.password,

          location.state.phoneNumber,
          
          location.state.firstName,
          location.state.lastName,
          response.pharmacy_name,

          verifyDetails?.pharm_code?.replaceAll("+"," "),
          verifyDetails?.pharm_accno?.replaceAll("+"," "),
          response.collection_address,
          location.state.receiveOffer,
          verifyDetails.role,
          verifyDetails.rnd,
          verifyDetails.token


      ).then((res) => res.json())
      .then((data) => {
          response = data
          setResendSuccess(true)
          setDisableResend(false)
      });
      }
    
}
  
  return (
    <Grid>
            
            <Paper elevation={0} className={classes.maincontainer}>
                <Grid align='Center'>
                <img src={OpenMail} alt="error"/>
                <h2 style={{marginTop:0}} data-testid="Header">Check your email to <br/>activate your account</h2>
                </Grid>
              
        
            <Paper elevation={0} className={classes.innercontainer} >
              <Hidden smDown>
                <img src={Grnmail} alt="error" />
              </Hidden>
              
              <Grid style={{marginLeft:15}}>
                <Typography style={{padding:0, margin:0,fontWeight:"bolder"}}>We’ve sent an email to </Typography>
                <Typography style={{padding:0, margin:0,color:"#007BB8"}}>{email}</Typography>
              </Grid>
            </Paper>
            
    

                <Typography style={{color:"#5E5E5E", marginBottom:"2%"}}>To complete sign up and activate your account we need to confirm your email address. </Typography>
                <Typography style={{color:"#5E5E5E", marginBottom:"2%"}}>Please check your email and follow the link in the email we sent you. It might take a few minutes for the email to arrive.</Typography>
                <Typography style={{color:"#5E5E5E", marginBottom:"2%"}}>Make sure to check your junk and spam folders.</Typography>

                <Button onClick={handleOpen} variant="contained" size="large" disableElevation style={{background: "#E8F8FF",marginTop:17, color:"#0066BE",borderRadius: '6px',marginBottom:17, fontSize:"18px", textTransform: "none", display:"flex", justifyContent:"space-between", border: "1px solid #7DD4FF"}} fullWidth>
                      <Grid style={{display:"flex", justifyContent:"flex-start", margin:0,padding:0}}>
                        <img src={Question} alt="error" />
                        <Typography style={{margin:0,padding:0, marginLeft:10, fontSize:16}}>Didn’t get an email?</Typography>
                      </Grid>
                      <img src={ArrowDown} alt="error" />
                </Button>
                <div data-testid="successmessage">
                  {resendSuccess && showSuccessmsg(`Email has been sent successfully on ${email}`)}
                </div>
                <Button disabled={disableResend} data-testid="submit-button" onClick={handleSubmit} variant="outlined" size="small" disableElevation style={{background: disableResend?"gray":"white",color:"black",marginTop:"10px", marginBottom:"17px", border: "1.5px solid #07283C", fontSize:"18px", textTransform: "none"}} fullWidth  type="submit" >Resend email</Button>
              
                <InformationModal 
                  handleClose={handleClose} 
                  open={open} 
                  heading={"Didn’t get an email?"}
                  modaltext={modaltext}
                  />

            </Paper> 
     </Grid>
    
  );
}

export default EMailActivate;
