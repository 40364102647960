import { Button, CircularProgress, Dialog, Grid, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import EmailIcon from "../../../Assets/Mailicongreen.svg";
import CallIcon from "../../../Assets/CallIcon.svg";
import AccountCircleOutlinedgreen from "../../../Assets/AccountCircleOutlinedgreen.svg";
// import Location from "../../../Assets/Location.svg";
import { showErrMsg, showErrMsgEmpty, showSuccessmsg } from '../../../Components/notification/Notification';
import { isEmail, isEmpty, isPhoneValid, isMatchemail } from '../../../Components/validation/Validation';
import { AddPharmacyGroup, DeletePharmacyGroup, EditPharmacyGroup } from '../../../api/pharmacyops';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../../api/getUserDetails';
import { makeStyles } from "@material-ui/core/styles";
import Modal2 from '../../../Components/Modal/Modal2';
import ArrowBackIcon from "@material-ui/icons/KeyboardArrowLeft";
import logEvent, { logEventWithProperties } from '../../../analytics/Analytics';
import { WardleHqRegisterStart, GetUserEmailExist } from '../../../api/userOps';

const useStyles = makeStyles((theme) => ({
  buttontext: {
    padding: "0.25em",
  },
  box: {
    border: "1px solid #CDCDCD",
    borderRadius: 10,
    margin : 'auto',
    color: "#5E5E5E",
    background: "#FCFCFC",
    padding: "1rem",
  },
  savebutton: {
    textTransform: "none",
    boxShadow: '0px 2px 0px #306B33, inset 0px 1px 0px #62B767',
    borderRadius:"4px",
    height: "3rem",
    backgroundColor: "#3D8541",
    color: "white",
    "&:hover": {
      backgroundColor: "#3D8541",
    },
  },
  disabledsavebutton: {
    textTransform: "none",
    height: "3rem",
    borderRadius:"4px",
  },
  cancelbutton: {
    textTransform: "none",
    height: "3rem",
    backgroundColor: "white",
    color: "black",
    border: "2px solid #222222",
  },
  removebutton: {
    backgroundColor: "#fff",
    textTransform: "none",
    color: "#BB4035",
    fontWeight: "600",
    borderWidth: "0px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  disablebutton: {
    backgroundColor: "#EBEBEB",
    textTransform: "none",
    color: "#535353",
    fontWeight: "600",
    borderWidth: "0px",
  },
  dialog:{
    opacity:0.5
  },
  backbutton:{
    textDecoration:"none", 
    color:"#5E5E5E", 
    display:"flex", 
    alignItems:"center", 
    paddingTop:80,
    [theme.breakpoints.up('md')]: {
          marginLeft:150,
        },
        [theme.breakpoints.down('md')]: {
          marginLeft:150,
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft:0,
          paddingLeft:"4%",
        },
      },
}));


function AddNewPharmacyGroup() {
  const location = useLocation()
  const groupDetails = location.state?.groupDetails
  const isEdit = location.state?.isEdit
  const [isDisabledSaveChanges, setIsDisabledSaveChanges] = useState(true);
  const [user, setUser] = useState();
  const [loading , setLoading] = useState(false)

  const [state, setState] = useState({
    list: [{ type: "Group name", value: isEdit ? groupDetails?.pharmacyGroupName : "", error: '', datatype: 'pharmacyGroupName', isShow: !isEdit },
    { type: "Group account number", value: isEdit ? groupDetails?.pharmacyGroupAccountNumber : "", error: '', datatype: "pharmacyGroupAccountNumber", isShow: !isEdit },
    // { type: "Address line1", value: groupDetails?.addressLine1 , error: '', datatype: 'addressLine1', isShow: true },
    // { type: "Address line2", value: groupDetails?.addressLine2, error: '', datatype: 'addressLine2', isShow: true },
    // { type: "Post code", value: groupDetails?.pharmacyGroupPostCode, error: '', datatype: 'pharmacyGroupPostCode', isShow: true,startIcon: Location },
    { type: "First name", value: groupDetails?.firstName, error: '', startIcon: AccountCircleOutlinedgreen, datatype: 'firstName', isShow: true },
    { type: "Last name", value: groupDetails?.lastName, error: '', startIcon: AccountCircleOutlinedgreen, datatype: 'lastName', isShow: true },
    { type: "Phone number", value: groupDetails?.phoneNumber, error: '', startIcon: CallIcon, datatype: 'phoneNumber', isShow: true },
    { type: "Email address", value: groupDetails?.managerEmail, error: '', startIcon: EmailIcon, datatype: 'email', isShow: true },
    { type: "Confirm email address", value: groupDetails?.managerEmail, error: '', startIcon: EmailIcon, isShow: true }],
    error: '', isValidated: false
  })

  const [open, setOpen] = useState(false)

  const [error, setError] = useState('')
  const [success,setSuccess] = useState('')

  let navigate = useNavigate();

  let classes = useStyles()

  useEffect(()=>{
    logEvent("Add new pharmacy group page visited")
  },[])

  const handleSubmit = async (e) => {
    let err = ''
    e.preventDefault();
    setState((prev) => {

      let list = prev.list?.map((item) => {
        if (isEmpty(item.value)) {
          err = 'Please check the information above is correct.'

          return { ...item, error: 'This field is required.' }
        } else if (item.type === "Phone number" && !isPhoneValid(item.value)) {
          err = 'Please check the information above is correct.' 

          return { ...item, error: 'Please provide a valid phone number' }
        } else if ((item.type === "Email address" || item.type === "Confirm email address") && !isEmail(item?.value?.trim())) {
          err = 'Please check the information above is correct.' 

          return { ...item, error: 'Invalid email' }
        }
        else if (!isMatchemail((prev.list[5].value?.trim()),(prev.list[6].value?.trim()))) {
          err = 'Make sure your email address matches in both fields.' 

          return { ...item, error: '' }
        }
         else {
          return { ...item, error: '' }
        }
      })

      return { list: list, error: err, isValidated: isEmpty(err) }
    })


  }


  useEffect(() => {

    let body = isEdit ? { "modifiedBy": getUserDetails().email } : { "createdBy": getUserDetails().email }
    body["pharmacyGroupAccountNumber"] = getUserDetails().pharmacy_group_account_number
    body["pharmacyGroupName"] = getUserDetails().pharmacy_group_name
    body["id"] = groupDetails?.id;
    logEventWithProperties("New group details submitted",body)
    
    if (state.isValidated && isEmpty(state.error)) {
      state.list?.forEach(element => {
        if (element.type !== "Confirm email address") {
          body[element.datatype] = element.value
        }
      });
      if (isEdit) {
        setLoading(true)
        EditPharmacyGroup(body, groupDetails?.id)
        .then(res => res.json())
        .then(data=>{
          setLoading(false)
          logEventWithProperties("Pharmacy group edited successfully",body)
          if(data.data){
            setSuccess("We have updated your users details");
            setTimeout(() => {
              return navigate('/group/pharmacy', { state: { groupDetails: data.data } })
            }, 2000);
          }
          else {
            setError("Sorry, We could not update your users details")
          }
        })
        
      } else {
        setLoading(true)
        AddPharmacyGroup(body, isEdit)
          .then(res => res.json())
          .then(createResponse => {
            if (createResponse.message.includes('created successfully')) {
              GetUserEmailExist(body.email.toLowerCase())
              .then((res) => res.json())
              .then((data) => {
                  if (data.userExist === false) {
          logEventWithProperties("Pharmacy group added successfully",body)

                      return (setUser({...user, err1: "", success: ''},

                      WardleHqRegisterStart(
                        body.email.toLowerCase(),
                        body.firstName,
                        body.lastName,
                        body.phoneNumber,
                        body.pharmacyGroupAccountNumber,
                        body.pharmacyGroupName
                      )
                      .then((res) => res.json())
                      .then(() => {
                        setLoading(false)
                        setSuccess(`We have sent a registration link to ${body.email.toLowerCase()}`);
                        setTimeout(function() {
                          return navigate('/group/pharmacy', { state: { groupDetails: createResponse } })  
                        }, 2500);
                        
                      },
                      error => {
                        setLoading(false)
                        return setError(error)
                      })
                      )
                      )
                    } else {
                      setLoading(false)
                      setSuccess("The provided email already has an active account.")
                      setTimeout(function() {
                        return navigate('/group/pharmacy', { state: { groupDetails: createResponse } })  
                      }, 2500);
                  }
              },
              error => {
                  console.error('There was an error!', error);
              });
            }else{
              return setError(createResponse.message)
            }

          })
      }
    } 

  }, [state])

  const handleChange = (e, type) => {
    let value = e.target.value;
    if (isEdit) {
      let dataType = "";
      let dataTypeChangedList = state.list.map(function (item) {
        if (item.type === type) {
          return item.datatype;
        }
      });
      for (var i = 0; i < 7; i++ ) {
        if (dataTypeChangedList[i] != undefined) {
          dataType = dataTypeChangedList[i]
          if (groupDetails[dataType] === value) {
            setIsDisabledSaveChanges(true);
          } else {
            setIsDisabledSaveChanges(false);
          }
        }
      }
    }
    setState({ ...state, error: '' })
    setState((prev) => {
      let list = prev.list?.map((item) => {
        return item.type === type ? { ...item, error: '', value: value } : item
      })
      return { ...prev, list: list,isValidated:false }
    })
  }

  const handleSubmitdelete = async () => {
    // e.preventDefault()
    setLoading(true)
    DeletePharmacyGroup(groupDetails?.id,groupDetails?.pharmacyGroupAccountNumber)
      .then((res) => res.json())
      .then((data) => {
        logEventWithProperties("Pharmacy group deleted successfully",groupDetails)

        setOpen(false)
        if (data.error) {
          return setUser({ ...user, err1: data.error, success: '' })
        } else if (data.message?.includes('deleted sucessfully')) {
          navigate('/managepharmacygroup')

        }
      },
        error => {
          console.error('There was an error!', error);
        });
        setLoading(false)
  };

  return (
    <>
      <Grid style={{paddingTop : '3em'}}>
        
          <Grid className={classes.backbutton} >
            <ArrowBackIcon onClick={() => {
            !isEdit ? navigate('/managepharmacygroup'):navigate("/group/pharmacy",{state:{groupDetails:groupDetails}}) 
          }} style={{cursor : 'pointer'}}/>
            <Typography data-testid="back-addpharmacygroup" onClick={() => {
            !isEdit ? navigate('/managepharmacygroup'):navigate("/group/pharmacy",{state:{groupDetails:groupDetails}}) 
          }} style={{cursor : 'pointer'}}>Back</Typography>
          </Grid>
        <>

          <form onSubmit={handleSubmit}>
            <Paper elevation={0}>

              <Grid md={5} lg={5} xs={10} sm={8} style={{margin : 'auto'}}>
                <Typography data-testid="header" variant="h4" style={{fontWeight : 700 , letterSpacing : '0.005em', lineHeight: '120%'}}>{isEdit ? "Edit pharmacy group" : "Add Pharmacy Group"}</Typography>
              {isEdit && <p>
                Edit and update group details
              </p>}
              <Typography variant="body2" style={{marginBottom:'1em' , marginTop : '0.5em'}} data-testid="innertext">
                {isEdit ? `The email address used will be the one that they need to access their account. ` : "Enter the details of the pharmacy group you would like to add."}
              </Typography>
              </Grid>
              {isEdit && 
              <Grid style={{marginBottom : '1em'}}>
              <Grid container direction='column' className={classes.box} md={5} lg={5} xs={10} sm={8}>
                <Grid item>
                  <Grid container justifyContent='space-between' style={{marginBottom:'0.5em'}}>
                <Grid>
                    <Typography
                      variant="body1"
                      style={{ color: "#5E5E5E" }}
                    >
                      Group name :
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography
                      variant="body1"
                      style={{ color: "#5E5E5E" }}
                      >
                      {groupDetails?.pharmacyGroupName}
                    </Typography>
                  </Grid>
                </Grid>
                </Grid>
                <Grid item>
                <Grid container justifyContent='space-between'>
                    <Grid>
                      <Typography
                        variant="body1"
                        noWrap
                        style={{ color: "#5E5E5E" }}
                      >
                        Group number:

                      </Typography>
                    </Grid>
                    <Grid>

                      <Typography
                        variant="body1"
                        noWrap
                        style={{ color: "#5E5E5E" }}
                        >
                        {groupDetails?.pharmacyGroupAccountNumber}

                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              </Grid>}
              {state.list?.map((item, index) => {
                if (item.datatype !== 'createdBy') {
                  return <>
                    {item.isShow && 
                    <Grid md={5} lg={5} xs={10} sm={8} style={{ margin : 'auto' }}>
                      <TextField
                        size="small"
                        style={{marginBottom:'1em' , backgroundColor: '#FFFFFF',
                        boxShadow: '2px 2px 7px rgba(205, 205, 205, 0.5)',
                        borderRadius: '6px'}}
                        error={!isEmpty(item.error) && !isEmpty(state.error)}
                        className='Textfields'
                        placeholder={item.type}
                        variant="outlined"
                        label={item.type}
                        type="text"
                        id={index}
                        value={item.value}
                        name={item.type}
                        onChange={(e) => handleChange(e, item.type)}
                        fullWidth
                        InputProps={item.startIcon ? {
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={item.startIcon} alt="error" />
                            </InputAdornment>
                          )
                        } : {}}
                      />
                
                      {!isEmpty(item.error) && showErrMsgEmpty(item.error)}

                    </Grid>}
                  </>
                }
              })}
              <Grid md={5} lg={5} xs={10} sm={8} style={{ margin : 'auto' }} data-testid="errormsg">
              {!isEmpty(state.error) && showErrMsg(state.error, () => {
                setState({ ...state, error: '' })
              })}
              {error && showErrMsg(error)}
              {success && showSuccessmsg(success)}
              </Grid>
              

              <Grid md={5} lg={5} xs={10} sm={8} style={{ margin : 'auto' }}>
                <Button
                data-testid="mainbtn"
                  variant="contained"
                  fullWidth
                  size="large"
                  disableRipple
                  disableElevation
                  disabled = {isEdit && isDisabledSaveChanges}
                  className={isEdit && isDisabledSaveChanges ? classes.disabledsavebutton : classes.savebutton}
                  type="submit"
                >
                  {isEdit ? "Save changes" : "Create Group"}
                </Button>
                {isEdit && <Button
                style={{marginTop:'1em'}}
                  fullWidth
                  variant="outlined"
                  disableRipple
                  disableElevation
                  className={classes.removebutton}
                  onClick={() => setOpen(true)}
                >
                  Remove group
                </Button>}
              </Grid>
            </Paper>
          </form>
          <Dialog open={open} fullWidth>
            <Modal2 
            handleChangeWhiteButton={()=>setOpen(false)}
              heading={`Are you sure you want to remove "${groupDetails?.pharmacyGroupName}" from this account?`}
              para={"All data from this group will be removed from this account. "}
              buttonWhitetext={"Cancel"}
              buttonGreentext={"Remove"}
              Column={true}
              handleChangeGreenButton={()=>handleSubmitdelete()} 
              />
          </Dialog>
        </>
      </Grid>
      <Dialog fullScreen open={loading} classes={{paperFullScreen: classes.dialog}}>
      <Grid style={{margin : 'auto'}}>
          <Typography style={{fontWeight:600}}><CircularProgress/><br/><br/>Loading...</Typography>
      </Grid>
      </Dialog>
    </>
  )
}

export default AddNewPharmacyGroup