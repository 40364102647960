import * as React from "react";
import { useState } from "react";
import "./Tab.css";
import { TextField, Button } from "@material-ui/core";
import { isEmpty, isSpace, isGuid, isWhiteSpace } from "../../Components/validation/Validation";
import {
  showErrMsg,
  showSuccessmsg,
} from "../../Components/notification/Notification";
import { CreateOrderElectronic } from "../../api/orderops";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logEventWithProperties } from "../../analytics/Analytics";

const initialStateep = {
  guid: "",
  errep: "",
  successep: "",
};

const initialState = {
  firstName: "",
  lastName: "",
  err: "",
  err1: "",
  success: "",
};

function ElectronicPrescription(props) {
  const { userDetails } = props;
  const [user, setUser] = useState(initialState);
  const pharmacyAccountNumber = userDetails?.pharmacy_account_number;
  const pharmacyName = userDetails?.pharmacy_name;
  const pharmacyPostCode = userDetails?.pharmacy_post_code;
  const email = userDetails?.email;

  const [disableSubmitt, setDisableSubmitt] = useState(false);

  const [userep, setUserep] = useState(initialStateep);
  const { guid, errep, successep } = userep;
  const handleChangeInputep = (e) => {
    const { name, value } = e.target;
    setDisableSubmitt(false);
    setUserep({ ...userep, [name]: value, errep: "", successep: "" });
  };

  const handleSubmitCreateorderep = async (e) => {
    e.preventDefault();
    setDisableSubmitt(true);
    if (isEmpty(guid)) {
      setUserep({
        ...userep,
        errep: "Please enter your prescription before continuing.",
        successep: "",
      });
    } else if (isSpace(guid)) {
      setUserep({
        ...userep,
        errep: "Please enter your prescription before continuing. ",
        successep: "",
      });
    } else if (!isGuid(guid)) {
      setUserep({
        ...userep,
        errep:
          "GUID not recognised. Please ensure you are using hyphens (-) as per the barcode and not spaces.",
        successep: "",
      });
    } else if (isWhiteSpace(guid)) {                 //changes for white spaces in guid 
      setUserep({
        ...userep,
        errep: "GUID not recognised. Please ensure you are using hyphens (-) as per the barcode and not spaces.",
        successep: "",
      });
    } else {
      CreateOrderElectronic(
        email,
        guid,
        pharmacyAccountNumber,
        pharmacyName,
        pharmacyPostCode
      )
        .then((res) => res.json())
        .then(
          (data) => {
            setDisableSubmitt(false);
            if (data?.error?.errorCode) {
              logEventWithProperties("EPS order creation failed",{...{orderType:"eps",userId:userDetails?.id,pharmacyAccountNumber:userDetails?.pharmacyAccountNumber},...data?.error})
              setUserep({
                ...userep,
                errep: data?.error?.message,
                successep: "",
              });
              setTimeout(
                function () {
                  //Start the timer
                  setUserep({ ...userep, successep: "", errep: "", guid: "" });
                }.bind(this),
                3000
              );
            } else { 
              logEventWithProperties("EPS order successfully created",{...{orderType:"eps",userId:userDetails?.id,pharmacyAccountNumber:userDetails?.pharmacyAccountNumber},...data})

              setUserep({
                ...userep,
                errep: "",
                successep: "Prescription successfully sent",
              });
              setUser({
                ...user,
                err: "",
                success: "Prescription successfully sent",
              });
              setTimeout(
                function () {
                  //Start the timer
                  setUserep({ ...userep, successep: "", errep: "", guid: "" });
                }.bind(this),
                3000
              );
            }
          },
          (error) => {
            console.error("There was an error!", error);
          }
        );
    }
  };

  return (
    <div>
      <p style={{ marginBottom: 17 }}>
        To place a new order for an electronic prescription, you can either scan
        your barcode or enter it manually.{" "}
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          style={{ boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)" }}
          placeholder="Enter EPS GUID"
          error={errep}
          label={errep ? "EPS GUID error" : null}
          variant="outlined"
          type="text"
          size="small"
          data-testid="guid"
          name="guid"
          value={guid}
          fullWidth
          onChange={handleChangeInputep}
          inputProps={{ maxLength: 20 }}
        />
        {/* <Button variant="contained" size="large" onClick={() => setIsScanOpen(true)} disableElevation style={{ background: "#007BB8", width: "38%", color: "white", textTransform: "none", }}  >Scan barcode</Button> */}
      </div>
      <Button
        data-testid="createepsorder"
        onClick={handleSubmitCreateorderep}
        disable={disableSubmitt}
        variant="contained"
        size="large"
        disableElevation
        style={{
          background: "#3D8541",
          marginTop: 18,
          color: "white",
          opacity: disableSubmitt ? "50%" : "unset",
          fontSize: "18px",
          textTransform: "none",
          boxShadow: "0px 2px 0px #306B33, inset 0px 1px 0px #62B767",
        }}
        fullWidth
        type="submit"
      >
        Submit
      </Button>

      <div data-testid="guiderror">
        {props.toggleState === 1
          ? errep &&
            showErrMsg(errep, () => {
              setUserep({ ...userep, errep: "" });
            })
          : null}
        {props.toggleState === 1
          ? successep && showSuccessmsg(successep)
          : null}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.isSignedIn,
    userDetails: state.userDetails,
  };
};

ElectronicPrescription.propTypes = {
  userDetails: PropTypes.object,
  toggleState: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(ElectronicPrescription);
