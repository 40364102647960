import React, { useEffect } from "react";
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { connect } from "react-redux";
import RangeSearch from "../../Components/RangeSearchComponent/RangeSearch";
import logEvent from "../../analytics/Analytics";


const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    paddingTop: 120,
    overflow:"hidden",
    [theme.breakpoints.only('xs')]: {
      width: "90%"
    },
    [theme.breakpoints.only('md')]: {
      width: "40%"
    },
    [theme.breakpoints.only('lg')]: {
      width: "40%"
    },
    [theme.breakpoints.only('xl')]: {
      width: "40%"
    },
  }
}))

const mapStateToProps = state => {
  return {
    isSignedIn: state.isSignedIn,
    userDetails: state.userDetails
  };
};



export default connect(mapStateToProps)(() => {
  const classes = useStyles()
  useEffect(()=>{
    logEvent("User visted range")
  })
  return (
        <div className={classes.container} elevation={0} >

          <Typography
            mb={5}
            style={{
              fontSize: 25,
              fontWeight: "bold"
            }}
          >Our Range
          </Typography>
          <br />
          <Typography
            mb={5}
            style={{
              fontSize: 17,
            }}
          >Check which items are available in our range under Part IX
          </Typography>
         <RangeSearch />
        </div>
  )
})