import {
  Button,
  CircularProgress,
  Dialog,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import {
  showErrMsg,
  showErrMsgEmpty,
  showSuccessmsg,
} from "../../../Components/notification/Notification";
import {
  isEmail,
  isEmpty,
  isSpace,
} from "../../../Components/validation/Validation";
import { addManualPharmacies } from "../../../api/pharmacyops";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Edit from "../../../Assets/Edit.svg";
import Delete from "../../../Assets/DeleteIcon.svg";


import {
  FormControl,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { ValidatePharmacyDetails } from "../../../api/validations";
import ArrowBackIcon from "@material-ui/icons/KeyboardArrowLeft";
import { getUserDetails } from "../../../api/getUserDetails";

import * as React from 'react';
import logEvent, { logEventWithProperties } from "../../../analytics/Analytics";

const useStyles = makeStyles((theme) => ({
  buttontext: {
    padding: "0.25em",
  },
  formLabel: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    color: "#000",
    "&.Mui-focused": {
      color: "#000",
    },
    marginBottom: "0.5em",
  },
  dialog: {
    opacity: 0.5
  },
  addbutton: {
    backgroundColor: "#007BB8",
    textTransform: "none",
    marginTop: "1em",
    boxShadow: '0px 2px 0px #005179, inset 0px 1px 0px #009ADB',
    borderRadius: '4px',
    color: "#fff",
    "&:hover": {
      backgroundColor: "#007BB8",
    },
  },
  savebutton: {
    textTransform: "none",
    height: "3rem",
    backgroundColor: "#3D8541",
    color: "white",
    "&:hover": {
      backgroundColor: "#3D8541",
    },
  },
  removebutton: {
    backgroundColor: "#fff",
    textTransform: "none",
    color: "#BB4035",
    borderWidth: "0px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  tabledata: {
    border: "0px",
    padding: "10px",
  },
  backbutton: {
    textDecoration: "none",
    color: "#5E5E5E",
    display: "flex",
    alignItems: "center",
    paddingTop: 80,
    [theme.breakpoints.up('md')]: {
      marginLeft: 150,
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: 150,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      paddingLeft: "4%",
    },
  },
}));

// const BlackRadio = withStyles({
//   root: {
//     "&$checked": {
//       color: "black",
//     },
//   },
//   checked: {},
// })((props) => <Radio color="default" {...props} />);

function EnterManually() {
  const location = useLocation();
  const groupDetails = location.state?.groupDetails;

  let navigate = useNavigate();

  const initialState = {
    pharm_name: "",
    postcode: "",
    success: false,
    accno: "",
    err: "",
    err1: "",
  };
  const [items, setItems] = useState(initialState);
  const { pharm_name, postcode, accno, err, err1, success } = items;

  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [emailerror, setemailerror] = useState("");
  const [userTypeEror, setUserTypeerror] = useState("");
  const [loading, setLoading] = useState(false)

  const [lists, setLists] = useState([]);
  // const [showRole, setShowRole] = useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    logEvent("Enter manually page visited")
  }, [])

  const handleChangeInput = (e) => {
    let { name, value } = e.target;
    setItems({ ...items, success: false, err1: "", [name]: name !== "pharm_name" ? value?.toUpperCase() : value });
  };

  const getAdminUSer = () => {
    let admins = lists?.filter((item) => item.user === "admin");
    return admins.map((item) => item.email).join(";");
  };
  const getUSers = () => {
    let admins = lists?.filter((item) => item.user !== "admin");
    return admins.map((item) => item.email).join(";");
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (isEmpty(pharm_name) || isEmpty(postcode) || isEmpty(accno)) {
      setItems({
        ...items,
        success: false,
        err: "This field is required",
        err1: "Please check the information above is correct",
      });
    } else if (isSpace(pharm_name) || isSpace(postcode) || isSpace(accno)) {
      setItems({
        ...items,
        success: false,
        err1: "Please check the information above is correct",
      });
    }
    else {
        getUserDetails()?.id
      logEventWithProperties("Associate New pharmacy to group submitted", {pharmacyGroupAccountNumber:groupDetails?.pharmacyGroupAccountNumber,pharmacyAccountNumber:accno,createdBy:getUserDetails()?.id})
      setLoading(true)
      ValidatePharmacyDetails(
        pharm_name,
        accno,
        postcode,
        getUserDetails()?.id
      )
        .then((res) => res.json())
        .then((data) => {

          if (Object.prototype.hasOwnProperty.call(data, "pharmacyDetails")) {
            let data = {
              pharmacyName: pharm_name,
              pharmacyAccountNumber: accno,
              pharmacyPostCode: postcode,
              adminusers: getAdminUSer(),
              users: getUSers(),
            };
            addManualPharmacies(
              data,
              groupDetails?.pharmacyGroupName,
              groupDetails?.pharmacyGroupAccountNumber
            ).then(() => {
              setLoading(false)
              logEventWithProperties("Pharmacy associated successfully", {pharmacyGroupAccountNumber:groupDetails?.pharmacyGroupAccountNumber,pharmacyAccountNumber:accno,createdBy:getUserDetails()?.id})
              setEmail("");

              setItems({ ...initialState, success: true });
              setLists([])
              setUser("")


            });
          } else {
            setLoading(false)

            setItems({
              ...items,
              success: "",
              err1: "Pharmacy postcode and account number don’t match. Check you have entered the correct information.",
            });
          }
        });
    }
  };

  const adduser = (user, email) => {
    return {
      id: lists.length,
      user: user,
      email: email,
    };
  };
  const handledelete = (deleteindex) => {
    const filteredList = lists.filter((x, index) => {
      if (deleteindex !== index) {
        return x;
      }
    });
    setLists(filteredList);
  };
  const handleadd = () => {
    setItems({
      ...items,
      success: "",
      err1: "",
    });
    if (isEmpty(email.trim())) {
      return setemailerror("Please enter a email address.");

    }
    if (!isEmail(email.trim())) {
      return setemailerror("Please enter a email address.");

    }
    if (!user) {
      // setShowRole(true)
      return setUserTypeerror("Please select type of user.");

    }

    if (isEmail(email.trim())) {
      setLists((prevState) => [...prevState, adduser(user, email)]);
      setUser("");
      setemailerror("");

      setEmail("");
    }

  };

  const editProduct = (user) => {
    let newlist = lists.filter((item) => item.email !== user.email);
    setLists(newlist);
    setUser(user);
    setEmail(user.email);
  };

  return (
    <>
      <Grid style={{ paddingTop: "3em" }}>

        <Grid className={classes.backbutton} >
          <ArrowBackIcon onClick={() => {
            navigate("/group/pharmacy", {
              state: { groupDetails: groupDetails },
            });
          }} style={{ cursor: 'pointer' }} />
          <Typography data-testid="back-addpharmacygroup" onClick={() => {
            navigate("/group/pharmacy", {
              state: { groupDetails: groupDetails },
            });
          }} style={{ cursor: 'pointer' }}>Back</Typography>
        </Grid>
        <>
          <form onSubmit={handleSubmit} style={{ marginTop: "10px" }}>
            <Paper elevation={0}>
              <Grid md={5} lg={5} xs={10} sm={8} style={{ margin: "auto" }}>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 700,
                    letterSpacing: "0.005em",
                    lineHeight: "120%",
                  }}
                  data-testid="header"
                >
                  Associated Pharmacies
                </Typography>
                <Typography
                  data-testid="innertext"
                  variant="body2"
                  style={{
                    color: "#5E5E5E",
                    marginBottom: "1em",
                    marginTop: "0.5em",
                  }}
                >
                  To add associated pharmacies to your group fill out the
                  information below. You can add multiple users to the
                  associated pharmacy and assign their roles. Once you’re happy
                  the information is correct choose ‘Save Changes’.
                </Typography>
              </Grid>
              <Grid md={5} lg={5} xs={10} sm={8} style={{ margin: "auto" }}>
                <Typography variant="h6" style={{ fontWeight: 700, letterSpacing: '0.005em', lineHeight: '120%', marginBottom: '0.5em' }}>
                  Pharmacy Details
                </Typography>
                <TextField
                  fullWidth
                  style={{
                    marginBottom: "1em",
                    backgroundColor: "#FFFFFF",
                    boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
                    borderRadius: "6px",
                  }}
                  error={isEmpty(pharm_name) && err ? true : false}
                  placeholder="Pharmacy name"
                  label={isEmpty(pharm_name) ? null : "Pharmacy name"}
                  type="text"
                  name="pharm_name"
                  value={pharm_name}
                  onChange={(e) => handleChangeInput(e)}
                  variant="outlined"
                />
                {isEmpty(pharm_name) && showErrMsgEmpty(err)}

                <TextField
                  fullWidth
                  style={{
                    marginBottom: "1em",
                    backgroundColor: "#FFFFFF",
                    boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
                    borderRadius: "6px",
                  }}
                  error={isEmpty(accno) && err ? true : false}
                  placeholder="Account number"
                  label={isEmpty(accno) ? null : "Account number"}
                  type="text"
                  name="accno"
                  value={accno}
                  onChange={(e) => handleChangeInput(e)}
                  variant="outlined"
                />
                {isEmpty(accno) && showErrMsgEmpty(err)}
                <TextField
                  fullWidth
                  style={{
                    marginBottom: "1em",
                    backgroundColor: "#FFFFFF",
                    boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
                    borderRadius: "6px",
                  }}
                  error={isEmpty(postcode) && err ? true : false}
                  placeholder="Postcode"
                  label={isEmpty(postcode) ? null : "Postcode"}
                  type="text"
                  name="postcode"
                  value={postcode}
                  onChange={(e) => handleChangeInput(e)}
                  variant="outlined"
                />
                {isEmpty(postcode) && showErrMsgEmpty(err)}
                <Typography variant="h6" style={{ fontWeight: 700, letterSpacing: '0.005em', lineHeight: '120%', marginBottom: '0.5em' }}>
                  Add Users
                </Typography>
                <TextField
                  style={{
                    marginBottom: "1em",
                    backgroundColor: "#FFFFFF",
                    boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
                    borderRadius: "6px",
                  }}
                  fullWidth
                  type="text"
                  value={email}
                  onChange={(e) => {
                    setemailerror("");
                    setEmail(e.target.value);
                    setItems({
                      ...items,
                      success: "",
                      err1: "",
                    });
                  }
                  }
                  placeholder="Email address"
                  label="Email address"
                  variant="outlined"
                />
                {emailerror && showErrMsgEmpty(emailerror)}
              </Grid>
              <Grid
                container
                md={5}
                lg={5}
                xs={10}
                sm={8}
                style={{ margin: "auto" }}
              >
                {/* <Grid
                  item
                  style={{
                    borderRadius: "6px",
                    border: "1px solid #E9E9E9",
                    boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)",
                    padding: "1em",
                    width: "50%",
                  }}
                > */}
                {/* <Grid container> */}
                {/*  <Grid item>
                      <FormControl component="fieldset">
                        <FormLabel
                          classes={{ root: classes.formLabel }}
                          component="legend"
                          color="secondary"
                        >
                          <Grid container justifyContent="space-between">
                            <Grid item>Type of User</Grid>
                          </Grid>
                        </FormLabel>
                        {showRole && (
                          <RadioGroup
                            aria-label="user"
                            name="user"
                            value={user}
                            color="secondary"
                            onChange={(e) => {
                              setUser(e.target.value);
                              setUserTypeerror(""); 
                              setItems({
                              ...items,
                              success: "",
                              err1: "",
                            });}}
                          >
                            <FormControlLabel
                              value="admin"
                              control={
                                <BlackRadio
                                  data-testid="account-admin"
                                  disableRipple
                                />
                              }
                              label="Admin"
                            />
                            <FormControlLabel
                              value="standard"
                              control={<BlackRadio
                                 disableRipple
                                  />}
                              label="Standard"
                            />
                          </RadioGroup>
                        )}
                      </FormControl>
                    </Grid> */}


                {/* <Grid
                      data-testid="show-role"
                      item
                      style={{ marginLeft: "auto" }}
                      onClick={() => setShowRole(!showRole)}
                    >
                      <img src={DownArrow} alt="DwnArrw" />
                    </Grid>
                  </Grid> */}

                {/* </Grid> */}

                <Grid
                  item
                  style={{
                    padding: "1em",
                    paddingLeft: 0,
                    width: "50%",
                  }}
                >
                  <FormControl label="Type of User" fullWidth style={{ boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", }} variant="outlined" size="medium">
                    <InputLabel label="Type of User" id="demo-simple-select-label">Type of User</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      data-testid="show-role"
                      id="demo-simple-select"
                      value={user}
                      onChange={(e) => {
                        setUser(e.target.value);
                        setUserTypeerror("");
                        setItems({
                          ...items,
                          success: "",
                          err1: "",
                        });
                      }}
                      name="user"
                      label="Type of User"
                    >
                      <MenuItem value={"admin"} data-testid="account-admin">Admin</MenuItem>
                      <MenuItem value={"standard"}>Standard</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>


                <Grid item style={{ marginLeft: "auto", }}>
                  <Button
                    data-testid="add-user-btn"
                    variant="contained"
                    onClick={handleadd}
                    disableRipple
                    size="large"
                    className={classes.addbutton}
                  >
                    Add User
                  </Button>

                </Grid>
                {userTypeEror && showErrMsg(userTypeEror, () => {
                  setUserTypeerror("")
                })}
              </Grid>

              {lists.length > 0 ? (
                <Grid md={5} lg={5} xs={10} sm={8} style={{ margin: 'auto' }}>
                  <Grid item style={{ marginTop: "0.25em" }}>
                    <Typography
                      data-testid="add-user-header"
                      variant="h6"
                      style={{ fontWeight: "bold" }}
                    >
                      Added Users
                    </Typography>
                  </Grid>
                  <Grid item style={{ marginTop: "1em" }}>
                    <TableContainer
                      component={Paper}
                      elevation={2}
                      style={{
                        border: "1px solid #CDCDCD",
                        borderRadius: 4,
                        color: "#5E5E5E",
                      }}
                    >
                      <Table>
                        <TableBody>
                          {lists?.map((value, index) => (
                            <TableRow key={index} style={{ width: "40px" }}>
                              <TableCell
                                align="center"
                                className={classes.tabledata}
                              >
                                {value.user[0].toUpperCase() + value.user.substring(1)}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tabledata}
                              >
                                {value.email}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tabledata}
                              >
                                <img
                                  src={Edit}
                                  onClick={() => editProduct(value)}
                                  alt="error"
                                  style={{
                                    cursor: "pointer",
                                    height: "10px",
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                align="center"
                                className={classes.tabledata}
                              >
                                <img
                                  src={Delete}
                                  alt="error"
                                  onClick={() => handledelete(index)}
                                  style={{ cursor: "pointer" }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>


                </Grid>
              ) : undefined}
              <Grid md={5} lg={5} xs={10} sm={8} data-testid="page-message" style={{ margin: "auto" }}>
                {err1 &&
                  showErrMsg(err1, () => {
                    setItems({
                      ...items,
                      success: false,
                      err: "",
                      err1: ""
                    });
                  })}
                {success &&
                  showSuccessmsg(
                    "successfully added to " +
                    groupDetails?.pharmacyGroupName
                  )}
              </Grid>
              <Grid md={5} lg={5} xs={10} sm={8} style={{ margin: "auto" }}>
                <Button
                  data-testid="btn"
                  variant="contained"
                  fullWidth
                  size="large"
                  disableElevation
                  style={{
                    background: "#3D8541",
                    boxShadow: "0px 2px 0px #306B33, inset 0px 1px 0px #62B767",
                    borderRadius: "4px",
                    color: "#fff",
                    textTransform: "none",
                    marginTop: "1em",
                  }}
                  type="submit"
                >
                  Save Changes
                </Button>
              </Grid>
            </Paper>
          </form>
        </>

      </Grid>
      <Dialog fullScreen open={loading} classes={{ paperFullScreen: classes.dialog }}>
        <Grid style={{ margin: 'auto' }}>
          <Typography style={{ fontWeight: 600 }}><CircularProgress /><br /><br />Loading...</Typography>
        </Grid>
      </Dialog>
    </>
  );
}

export default EnterManually;
