import React, { useRef, useState } from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Tablecomponent from "../../../Components/Table/Table1";
import { CSVLink } from "react-csv";
import { UploadPharmacyFile } from "../../../api/pharmacyops";
import Papa from "papaparse";
import { savefile } from "../../../api/otherOps";
import {
  showErrMsg,
  showSuccessmsg,
} from "../../../Components/notification/Notification";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { logEventWithProperties } from "../../../analytics/Analytics";

const useStyle = makeStyles({
  savebutton: {
    textTransform: "none",
    backgroundColor: "#007BB8",
    borderRadius: "2px",
    color: "white",
    "&:hover": {
      backgroundColor: "#007BB8",
    },
  },
  continuebutton: {
    backgroundColor: "#3D8541",
    textTransform: "none",
    borderRadius: "2px",
    color: "white",
    "&:hover": {
      backgroundColor: "#3D8541",
    },
  },
  buttontext: {
    padding: "0.25em",
    fontWeight: 600,
  },
  uploadbutton: {
    backgroundColor: "#F6F6F6",
    textTransform: "none",
    borderRadius: "2px",
    "&:hover": {
      backgroundColor: "#F6F6F6",
    },
  },
});

function DrawerComponent({
  close,
  change,
  pharmacyGroupName,
  pharmacyGroupAccountNumber,
  createdBy,
  setHeight,
}) {
  const classes = useStyle();
  const [error, setError] = useState("");
  const [list, setList] = useState([]);
  const [data, setData] = useState("");
  const [saveData, setsaveData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [text, setText] = useState("");
  const csvLink = useRef()

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (evt) => {
      setData(evt.target.result);
      logEventWithProperties("Associate pharmacy group file loaded successfully")
    };
    Papa.parse(file, {
      complete: function (results) {
        setList(results.data);
      },
    });
    setError("")
    // reader.onerror = function () {
    // };
  };

  const handleContinue = () => {
    logEventWithProperties("Associate pharmacy group file uploaded continue clicked",{pharmacyGroupAccountNumber:pharmacyGroupAccountNumber})

    if (data.length > 0) {
      setText("Associating Pharmacies...")
      setLoading(true)
      UploadPharmacyFile(
        data?.split(",")[1],
        pharmacyGroupName,
        pharmacyGroupAccountNumber,
        createdBy
      ).then((res) => {
        if (res.status === 200) {
          logEventWithProperties("Associate pharmacy group file uploaded successful",{pharmacyGroupAccountNumber:pharmacyGroupAccountNumber,createdBy:createdBy})

          setLoading(false)
          close(true);
          change();
          setError("");
          setMessage("");
        }
      });
    } else {
      setLoading(false)
      close(false);
    }
  };

  const changeformat = (list) => {
    let list1 = [];
    list[0].map((item) => {
      list1.push({
        value:
          item.replace("_", " ").charAt(0).toUpperCase() +
          item.replace("_", " ").substr(1).toLowerCase(),
        issortable: false,
      });
    });
    return list1;
  };

  const handleSave = () => {
    setText("Saving file...")
    setLoading(true);
    savefile(pharmacyGroupAccountNumber)
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        logEventWithProperties("Associate pharmacy group file save successful",{pharmacyGroupAccountNumber:pharmacyGroupAccountNumber,createdBy:createdBy})

        if (data.length > 0) {
          setsaveData(data);
          setMessage("File is being saved!");
          csvLink.current.link.click()
        } else {
          setError("There is no associated pharmacies in this group",{pharmacyGroupAccountNumber:pharmacyGroupAccountNumber,createdBy:createdBy});
          setsaveData([]);
        }
      });
  };

  const toreadableformat = (list) => {
    let list1 = list.slice(1);
    return list1;
  };

  return (
    <>
      <Grid container style={loading ? { gap: "1em", margin: "auto", padding: "2em", opacity: 0.5 } : { gap: "1em", margin: "auto", padding: "2em" }} direction="column">
        <Typography
          onClick={() => close()}
          style={{
            cursor: "pointer",
            color: "blue",
            fontWeight: "600",
            textAlign: "right",
          }}
        >
          Close
        </Typography>
        <Grid item style={{ margin: "auto" }}>
          <Typography
            data-testid="Header"
            variant="h5"
            style={{ fontWeight: 600, color: "#222222" }}
          >
            Associated pharmacies
          </Typography>
        </Grid>

        {list.length > 0 && (
          <Grid
            item
            style={{
              width: "100%",
              marginTop: "1em",
              overflow: "scroll",
              height: "40vh",
            }}
          >
            <Tablecomponent
              head={changeformat(list)}
              body={toreadableformat(list)}
            />
          </Grid>
        )}
        {error && showErrMsg(error)}
        {message && showSuccessmsg(message)}
        {list.length > 0 && setHeight("80%")}
        {/* {list.length>0   ?  setHeight("80%") : setHeight("40%") } */}
        <Grid item style={{ marginTop: "3em" }}>
          <Grid container alignItems="center">
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <input
                    style={{ display: "none" }}
                    type="file"
                    data-testid="uploadCsvFile"
                    name="Upload a file"
                    accept=".csv,.xlsx,.xls"
                    onChange={(e) => handleFileUpload(e)}
                    id="button-file"
                  />
                  <label htmlFor="button-file">
                    <Button
                      variant="contained"
                      component="span"
                      disableRipple
                      disableElevation
                      className={classes.uploadbutton}
                    >
                      <Typography
                        data-testid="Uploadpharmacyfilebtn"
                        variant="body2"
                        className={classes.buttontext}
                      >
                        Upload pharmacy file
                      </Typography>
                    </Button>
                  </label>
                </Grid>
                <Grid item style={{ marginTop: "0.5em" }}>
                  <Typography variant="caption">
                    Attach updated associated pharmacy files
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item style={{ marginLeft: "auto" }}>
              <Grid container style={{ gap: "1em" }}>
                <Grid item>
                  <CSVLink
                    data={saveData}
                    ref={csvLink}
                    headers={[
                      { label: "pharmacy_name", key: "pharmacy_name" },
                      {
                        label: "account_number",
                        key: "pharmacy_account_number",
                      },
                      { label: "post_code", key: "post_code" },
                      { label: "admin_users", key: "admin_users" },
                      { label: "users", key: "users" },
                    ]}
                    onClick={() => {
                      if (saveData.length > 0) {
                        return true;
                      } else {
                        return false;
                      }
                    }}
                    filename={"PharmacyGroupUserTemplate.csv"}
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    <Button
                      disableRipple
                      disableElevation
                      className={classes.savebutton}
                      variant="contained"
                      data-testid="savebutton"
                      onClick={() => handleSave()}
                    >
                      <Typography
                        variant="body2"
                        className={classes.buttontext}
                      >
                        Download existing
                      </Typography>
                    </Button>
                  </CSVLink>
                </Grid>
                <Grid item>
                  <Button
                    disableRipple
                    disableElevation
                    disabled={list.length > 0 ? false : true}
                    data-testid={"handleContinue"}
                    className={classes.continuebutton}
                    variant="contained"
                    onClick={() => handleContinue()}
                  >
                    <Typography variant="body2" className={classes.buttontext}>
                      Continue
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loading === true && (
        <div style={{ textAlign: "center", minHeight: "100px" }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: "40%",
              left: "0",
            }}
          >
            <h4>
              <CircularProgress />
              <br></br>
              {text}
            </h4>
          </div>
        </div>
      )}
    </>
  );
}

DrawerComponent.propTypes = {
  close: PropTypes.func.isRequired,
  change: PropTypes.func,
  pharmacyGroupName: PropTypes.string,
  pharmacyGroupAccountNumber: PropTypes.string,
  createdBy: PropTypes.string,
  setHeight: PropTypes.any,
};

export default DrawerComponent;
