import React from 'react';
import NavbarLogged from '../Navbar/Navbarlogged';
import Navbar from '../Navbar/Navbar';
import { connect } from 'react-redux';
import { Hidden } from '@material-ui/core';
import NavBarMobile from '../Navbar/NavBarMobile';
import PropTypes from 'prop-types';


function Header(props) {

  return (
      <>
      {!props.isSignedIn &&<Navbar />}
      {props.isSignedIn &&<Hidden smDown implementation='css' ><NavbarLogged toggleSideBar={props.toggleSideBar}/></Hidden>}
      {props.isSignedIn &&<Hidden mdUp implementation='css' ><NavBarMobile toggleSideBar={props.toggleSideBar}/></Hidden>}


      </>
  );
}
const mapStateToProps = state => {
    return {
        isSignedIn:state.isSignedIn,
       
    };
 };
 Header.propTypes = {
    isSignedIn: PropTypes.bool,
    toggleSideBar:PropTypes.bool
  };
export default connect(mapStateToProps)(Header);


