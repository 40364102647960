import React, { useState } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';


import NextCalendar from '../../Assets/NextCalendar.svg'
import NextCalendarDisable from '../../Assets/NextCalendarDisable.svg'
import PrevCalendar from '../../Assets/PrevCalendar.svg'
import MonthComponent from './MonthComponent';

export const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

const useStyle = makeStyles({
    selectbutton: {
        backgroundColor: '#007BB8',
        color: "white",
        textTransform: 'none',
        height: '4em',
        "&:hover": {
            backgroundColor: '#007BB8'
        },
    },
    cancelbutton: {
        textTransform: 'none',
        height: '4em',
        border: "0px",
        color: "#007BB8"
    },
})
function CalendarComponent({ type, otherMonth,otherDate, currdate, currmonth, setCurrDate, setCurrMonth, close, index, handleSubmitt }) {
    const [currDate, setcurrDate] = useState(currdate)
    const [month, setMonth] = useState(months[currmonth])
    const [selectedIndex, setSelectedIndex] = useState(index);
    const classes = useStyle({ selectedIndex });
    const handlesubmit = () => {
        setCurrDate(currDate)
        if (currDate === new Date().getFullYear()) {
            if (months.indexOf(month) <= new Date().getMonth()) {
                setCurrMonth(months.indexOf(month))
            }
        } else {
            setCurrMonth(months.indexOf(month))
        }
        handleSubmitt(months.indexOf(month), currDate)

        close()
    }
    const checkDisable = (month) => {
        if (type !== "fromDate") {
            if(otherDate < currDate){
                return false
            }else{
            if (otherMonth > months.findIndex((item) => item === month))
                return true
            else
                return false
            }
        }
    }

    return (
        <Grid container style={{ padding: "1em" }} direction="column">
            <Grid item>
                <Grid container spacing={{ md: 3 }} columns={{ sm: 12, md: 12 }}>
                    <Grid item md={4} sm={4} xs={2} align="start">
                    
                     {type !== "fromDate"?otherDate < currDate && <img  onClick={() => setcurrDate(currDate - 1)} src={PrevCalendar} alt="Prev"  />: <img  onClick={() => setcurrDate(currDate - 1)} src={PrevCalendar} alt="Prev"  />  }
                    </Grid>
                    <Grid item md={4} sm={4} xs={8} align="center">
                        <Typography variant="h3" style={{ fontWeight: 600, color: '#07283C' }}>{currDate}</Typography>
                    </Grid>
                    <Grid item md={4} sm={4} xs={2} align="end">
                        {currDate === new Date().getFullYear()  ?
                            <img onClick={() => setcurrDate(currDate + 1 > new Date().getFullYear() ? new Date().getFullYear() : currDate + 1)} src={NextCalendarDisable} alt="Next" />
                            :
                           <img onClick={() => setcurrDate(currDate + 1 > new Date().getFullYear() ? new Date().getFullYear() : currDate + 1)} src={NextCalendar} alt="Next" />
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container>
                    {months.map((month, index) => {
                        return (
                            <Grid item sm={3} md={3} xs={3} key={index} align="center" selected={selectedIndex === index} onClick={() => { setMonth(month); }}>
                                <MonthComponent disable={() => checkDisable(month)} text={month} index={index} selected={selectedIndex === index} handleClick={(index) => setSelectedIndex(index)} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
            <Grid item style={{ marginTop: "2em" }}>
                <Grid container spacing={3} columns={{ sm: 12, md: 12 }} justifyContent="center">
                    <Grid item md={6} sm={6} align="center">
                        <Button variant="outlined" className={classes.cancelbutton} onClick={() => close()} fullWidth>
                            <Typography variant="body1" style={{ fontWeight: 600 }} >Cancel</Typography>
                        </Button>
                    </Grid>
                    <Grid item md={6} sm={6} align="center">
                        <Button variant="contained" className={classes.selectbutton} onClick={() => handlesubmit()} fullWidth>
                            <Typography variant="body1" style={{ fontWeight: 600 }} >Select</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

CalendarComponent.propTypes = {
    currdate: PropTypes.number.isRequired,
    currmonth: PropTypes.number.isRequired,
    setCurrDate: PropTypes.func.isRequired,
    setCurrMonth: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    handleSubmitt: PropTypes.func.isRequired
}

export default CalendarComponent