/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import {useState} from 'react';
import { Grid, Paper,TextField,Button,InputAdornment,makeStyles } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom'; 
import cautionicon from '../../Assets/Caution.svg'
import Question from '../../Assets/questionmark.svg';
import ArrowDown from '../../Assets/Arrowdown.svg';
import {isPasswordValid, isMatch ,isEmpty} from '../../Components/validation/Validation'
import {showErrMsgEmpty, showErrMsg} from '../../Components/notification/Notification';
import Lockgreen from '../../Assets/Lockgreen.svg';
import { Visibility , VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { ForgotPassComplete } from '../../api/userOps';
import { InformationModal } from '../../Components/Modal/InformationModal';
import logEvent from '../../analytics/Analytics';

const useStyles = makeStyles((theme) => ({
  paperStyle: 
  {   
      margin:"auto",
      paddingTop:150,
      [theme.breakpoints.up('md')]: {
        width: "27%",
      },
      [theme.breakpoints.down('md')]: {
        width: "40%",
      },
      [theme.breakpoints.down('sm')]: {
        width: "50%",
      },
      [theme.breakpoints.down('xs')]: {
        width: "90%",
      },
  }
}));

const initialState = {
  password: '',
  cf_password: '',
  err: '',
  err1:'',
  success: '',
  showPassword: false,
  showConfirmPassword: false,
}

function ChangePassword() {
  let navigate = useNavigate();
  const { rnd, token, email } = useParams();
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setUser({
      ...user,
      showPassword: !user.showPassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setUser({
      ...user,
      showConfirmPassword: !user.showConfirmPassword,
    });
  };

  const modaltext = [
    { value: "Strong passwords are unique, at least 8 characters long and contain upper and lowercase letters, numbers and symbols."},
    { value: "Try swapping letters for numbers or symbols to make your password harder to guess. For example, instead of S try using $."},
    { value: "You could also try using the first letter of each word from your favourite song lyric to create a word that won't be obvious to other people."},
    {value: "Avoid using personal details other people might know or common words like 'password'."},]

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [user, setUser] = useState(initialState)
  const {password,cf_password, err,err1} = user

  React.useEffect(()=>{
    logEvent("Reset password magic link clicked")
  },[])
  const handleChangeInput = e => {
    const {name, value} = e.target
    setUser({...user, [name]:value, err: '', success: ''})
  }

  
  const handleSubmit = async e => {
    e.preventDefault()
    if(isEmpty(password) || isEmpty(cf_password)) 
            return setUser({...user, err: "This field is required.", success: ''})

    if(!isPasswordValid(password))
    return setUser({...user,err1:"Make sure your password is at least 8 characters long and contains upper and lowercase letters, numbers and symbols.", success:''})
    
    if(!isMatch(password, cf_password))
        return setUser({...user, err1: "Make sure your password matches in both fields.", success: ''})

    ForgotPassComplete(rnd, token, email, password)
      .then((res) => res.json())
      .then((data) => {
        if (data?.error) {
          return setUser({...user, err1: data.error.message, success: ''})          
        } else {
          navigate("/resetsuccess",{state:{email:email, password:password}});
        }        
      },
      error => {
          console.error('There was an error!', error);
      });
  }


    return (
      <>
            
      <Grid>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Paper elevation={0} className={classes.paperStyle} sx={{textAlign:"center"}}>

                <Grid align="center">
                <img src={cautionicon} alt="" srcSet=""  />
                </Grid>

                <h2 data-testid="Header" style={{fontSize: 25,margin:0, marginBottom:25,marginTop:20, textAlign:"center"}}>Reset password</h2>
                <p style={{fontSize: 15,}}>Choose a password to keep your data secure. You’ll need this password your email address to access your account in future.</p>

                <TextField size="small"
                data-testid="Password"
                error= {isEmpty(password) && (err?true:false)}
                style={{marginBottom:13,boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background:"white"}}
                placeholder="Password" 
                variant="outlined" 
                fullWidth
                type={user.showPassword ? 'text' : 'password'}
                id="password"
                value={password} name="password" onChange={handleChangeInput}
                label={isEmpty(password)?null:"Password"}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Lockgreen} alt="error" />
                      </InputAdornment>
                    ),
                    endAdornment:(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {user.showPassword ? <Visibility style={{color: "#A8D7AB",height:19,width:19}}/> : <VisibilityOff style={{color: "#A8D7AB",height:19,width:19}}/>}
                        </IconButton>
                      </InputAdornment>),
                  }}
                />
                {isEmpty(password)  && showErrMsgEmpty(err)}
                 
            <TextField size="small"
                data-testid="Cf-Password"
                error= {isEmpty(password) && (err?true:false)}
                style={{marginBottom:13,boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background:"white"}}
                placeholder="Confirm Password" 
                type={user.showConfirmPassword ? 'text' : 'password'}
                label={isEmpty(cf_password)?null:"Confirm Password"}
                variant="outlined" 
                fullWidth
                id="cf_password"
                value={cf_password} name="cf_password" onChange={handleChangeInput}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Lockgreen} alt="error" />
                      </InputAdornment>
                    ),
                    endAdornment:(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {user.showConfirmPassword ? <Visibility style={{color: "#A8D7AB",height:19,width:19}}/> : <VisibilityOff style={{color: "#A8D7AB",height:19,width:19}}/>}
                        </IconButton>
                      </InputAdornment>),
                  }}
                />

                <div data-testid="error">
                {isEmpty(cf_password)  && showErrMsgEmpty(err)}
                </div>

              
              
                    
                  <Button onClick={handleOpen} variant="contained" size="large" disableElevation style={{background: "#E8F8FF", color:"#0066BE",borderRadius: '6px',marginTop:"Auto", fontSize:"18px", textTransform: "none", display:"flex", justifyContent:"space-between", border: "1px solid #7DD4FF"}} fullWidth>
                      <div style={{display:"flex", justifyContent:"flex-start", margin:0,padding:0}}>
                        <img src={Question} alt="error" />
                        <p style={{margin:0,padding:0, marginLeft:10, fontSize:16}}>Help setting a strong password</p>
                      </div>
                      <img src={ArrowDown} alt="error" />
                  </Button>
                  
                  <div data-testid="error1">
                  {err1 && showErrMsg(err1,()=>{
                    setUser({...user,err1:''})
                  })}
                  </div>
                  
              <Grid align="center">
                <Button data-testid="btn" variant="contained" fullWidth size="large" disableElevation style={{background: "#3D8541",color:"white", marginTop:"17px", fontSize:"12px", textTransform: "none"}} type="submit" >Reset Password</Button>
              </Grid>

              <InformationModal 
              handleClose={handleClose} 
              open={open} 
              heading={"Help setting a strong password"}
              modaltext={modaltext}
              />

             
                </Paper>
                </form>
            </Grid>

    </>
    );
  }
  
  export default ChangePassword;



