import React, { useState, useEffect } from 'react'
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeft';
import { Grid, Paper, Button, Checkbox, makeStyles } from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import { EditUserDetails } from '../../api/userOps';
import { connect } from 'react-redux';
import { getLoginUserDetails } from '../../redux/middleware';
import PropTypes from 'prop-types';

import Termsncondition from "../../Assets/TNC.pdf"
import Privacypolicy from "../../Assets/PNP.pdf"
import { showSuccessmsg } from '../../Components/notification/Notification';
import logEvent, { logEventWithProperties } from '../../analytics/Analytics';
const useStyles =  makeStyles((theme) => ({
  arrowBack:{
    cursor: "pointer",
    textDecoration:"none",
    color:"#5E5E5E",
    display:"flex",
    alignItems:"center",
    margin:"0",
    [theme.breakpoints.only('xs')]: {
     justifyContent:"left",
      paddingLeft:0,
    },
    [theme.breakpoints.only('sm')]: {
      justifyContent:"left",
      paddingLeft:0,  
      marginTop:"2rem"
    },
    [theme.breakpoints.only('xs')]: {
      justifyContent:"left",
      paddingLeft:0,
      marginTop:"4rem"
     },
     [theme.breakpoints.only('md')]: {
      paddingLeft:(props)=>props?150:300,     
     },
     [theme.breakpoints.only('lg')]: {
      paddingLeft:(props)=>props?150:300,     
     },
     [theme.breakpoints.only('xl')]: {
      paddingLeft:(props)=>props?150:300,     
     },
  },
  paperStyle:{
    paddingTop: 30,
    margin: "auto",
    [theme.breakpoints.only('xs')]: {
      width:"95%",
     },
     [theme.breakpoints.only('sm')]: {
      width:450, 
    },
    [theme.breakpoints.up('sm')]: {
      width:450, 
      padding: 10, 
      },
  }
}))

function MyProfile(props) {
  const classes = useStyles()

  const { userDetails, isSignedIn } = props
  let navigate = useNavigate();
  const label = { inputProps: { 'aria-label': 'Checkbox demo' }, };
  const [user, setUser] = useState(userDetails)
  const [success, setSuccess] = useState('')



  const role = userDetails?.role;
  const email = userDetails?.email;
  const pharmacyAccountNumber = userDetails?.pharmacy_account_number;
  const pharmacyName = userDetails?.pharmacy_name;
  const phoneNumber = userDetails?.phone_number;
  const userDetailsreceiveOffer = userDetails?.receive_offer;
  const pharmacyGroupAccountNumber = userDetails?.pharmacy_group_account_number;
  const pharmacyGroupName = userDetails?.pharmacy_group_name;

  const [checkbox, setCheckbox] = useState(false)

  useEffect(()=>{
    logEventWithProperties("Profile visited",userDetails?.id)
  },[])

  function fetchData() {
    props.dispatch(getLoginUserDetails(email,false))
    
  }
  useEffect(() => {
    if (isSignedIn && userDetails) {
      setUser(userDetails)
      setCheckbox(userDetailsreceiveOffer)

    } 
  }, [])
  useEffect(() => {
    fetchData();
  }, [checkbox])

  const handleChangeCheckbox = () => {
    setCheckbox(!checkbox)
    EditUser();
  }

  function EditUser() {
    EditUserDetails('receive_offer', !checkbox)
      .then((res) => res.json())
      .then((data) => {
        if (data.modifyUser.statusCode === 200) {
          setSuccess('We’ve updated your details.');
          // userDetailsfirstName = user.firstName;
          setTimeout(() => {
            setSuccess('');

          }, 2000)
        }
      });
  }

  return (
    <>
      {/* <Navbar /> */}
      {/* <Sidebar  handleCollapse={handleCollapse} collapse={collapse} role={role}/>  */}
        <div className={classes.arrowBack}>
          <button onClick={() => { role==='fp@wardleHqAdmin' || role==='fp@wardleHqUser' ? navigate("/orderlist") : navigate("/createorder")}}  className="backbuttonstyle2" >
              <ArrowBackIcon />
              <p>Back</p>
          </button> 
        </div>   
        <Grid>
        <Paper elevation={0} className={classes.paperStyle}>
                <h2 data-testid="Header" style={{fontSize: 25,margin:0, marginBottom:15, textAlign:"start"}}>My Profile</h2>

              <div style={{margin:0,padding:15,marginBottom:17, display:"grid",border: "1px solid #CEDCE9",boxSizing: "border-box",boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)",borderRadius: 4}}>
              <p data-testid="Userdetails" style={{margin:0, padding:0, fontSize:16, fontWeight:"bold", marginBottom:6}}>User Details</p>
              <div style={{display:"flex",color:"#5E5E5E",marginBottom:4}}>
              <p style={{margin:0, padding:0, fontSize:15, fontWeight:"bold"}}>Name:</p>
              <p style={{margin:0, padding:0, fontSize:15,marginLeft:4,textTransform: "capitalize"}}>{user.first_name}{" "}{user.last_name}</p>
              </div>

              <div style={{display:"flex",color:"#5E5E5E",marginBottom:4}}>
              <p style={{margin:0, padding:0, fontSize:15, fontWeight:"bold"}}>User Type:</p>
              <p style={{margin:0, padding:0, fontSize:15,marginLeft:4,}}>{role=== "fp@wardleAdmin" && "Wardles Admin"}</p>
              <p style={{margin:0, padding:0, fontSize:15,marginLeft:4,}}>{role=== "fp@wardleUser" && "Standard"}</p>
              <p style={{margin:0, padding:0, fontSize:15,marginLeft:4,}}>{role=== "fp@wardleStaff" && "Wardles Staff"}</p>
              <p style={{margin:0, padding:0, fontSize:15,marginLeft:4,}}>{role=== "fp@wardleHqUser" && "Standard"}</p>
              <p style={{margin:0, padding:0, fontSize:15,marginLeft:4,}}>{role=== "fp@wardleHqAdmin" && "Manager"}</p>
              </div>

              <div style={{display:"flex",color:"#5E5E5E",marginBottom:4}}>
              <p style={{margin:0, padding:0, fontSize:15, fontWeight:"bold"}}>Email Address:</p>
              <p style={{margin:0, padding:0, fontSize:15,marginLeft:4,textTransform: "capitalize"}}>{email}</p>
              </div>

              {(role === 'fp@wardleAdmin' || role === "fp@wardleUser") && <div style={{display:"flex",color:"#5E5E5E",marginBottom:4}}>
              <p style={{margin:0, padding:0, fontSize:15, fontWeight:"bold"}}>Account Number:</p>
              <p style={{margin:0, padding:0, fontSize:15,marginLeft:4,textTransform: "capitalize"}}>{pharmacyAccountNumber}</p>
              </div>}

              {(role === 'fp@wardleHqAdmin' || role === "fp@wardleHqUser") && <div style={{display:"flex",color:"#5E5E5E",marginBottom:4}}>
              <p style={{margin:0, padding:0, fontSize:15, fontWeight:"bold"}}>Pharmacy Group Account Number:</p>
              <p style={{margin:0, padding:0, fontSize:15,marginLeft:4,textTransform: "capitalize"}}>{pharmacyGroupAccountNumber}</p>
              </div>}

              <div style={{display:"flex",color:"#5E5E5E",marginBottom:4}}>
              <p style={{margin:0, padding:0, fontSize:15, fontWeight:"bold"}}>Phone Number:</p>
              <p style={{margin:0, padding:0, fontSize:15,marginLeft:4,textTransform: "capitalize"}}>{phoneNumber}</p>
              </div>

              {(role === 'fp@wardleAdmin' || role === "fp@wardleUser") && <div style={{display:"flex",color:"#5E5E5E",marginBottom:4}}>
              <p style={{margin:0, padding:0, fontSize:15, fontWeight:"bold"}}>Pharmacy Name:</p>
              <p style={{margin:0, padding:0, fontSize:15,marginLeft:4,textTransform: "capitalize"}}>{pharmacyName}</p>
              </div>}

              {(role === 'fp@wardleHqAdmin' || role === "fp@wardleHqUser") && <div style={{display:"flex",color:"#5E5E5E",marginBottom:4}}>
              <p style={{margin:0, padding:0, fontSize:15, fontWeight:"bold"}}>Pharmacy Group Name:</p>
              <p style={{margin:0, padding:0, fontSize:15,marginLeft:4,textTransform: "capitalize"}}>{pharmacyGroupName}</p>
              </div>}

              {/* {role==='fp@wardleHqAdmin' || role==='fp@wardleHqUser'?null:<div style={{display:"flex",color:"#5E5E5E",marginBottom:4}}>
              <p style={{margin:0, padding:0, fontSize:15, fontWeight:"bold"}}>Collection Address:</p>
              <p style={{margin:0, padding:0, fontSize:15,marginLeft:4,textTransform: "capitalize"}}>{collectionAddress}</p>
              </div>} */}
            </div>

            {role==='fp@wardleHqAdmin' || role==='fp@wardleHqUser'? null :
            <div style={{margin:0,padding:15,marginBottom:17, display:"flex", alignItems:"center",border: "1px solid #CEDCE9",boxSizing: "border-box",boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)",borderRadius: 4}}>
              <p style={{margin:0, padding:0, fontSize:13}}>I would like to receive health advice and offers from Wardles by email. (You can change your marketing preferences at any time).</p>
              <Checkbox {...label} style={{padding:0, marginRight:5, color:"#007BB8",}} 
              id="checkbox"
              value={checkbox}
              checked={checkbox}
              data-testid="checkbox" 
              name="checkbox" 
              onChange={handleChangeCheckbox}
              />

            </div>
            }

            <p style={{margin:0,fontSize:15, marginBottom:5}}>By continuing you are agreeing to our <a href={Termsncondition} target='blank' style={{color:'#007BB8',textDecoration:'none'}}>terms and conditions</a> and <a href={Privacypolicy} target='blank' style={{textDecoration:'none',color:'#007BB8'}}>privacy policy.</a></p>

                   
            <Grid align="center">
                <Button data-testid="editdetailsbtn" onClick={() => {
                  logEvent("Edit profile clicked")
                  navigate("/edityourdetails")}} variant="contained" fullWidth size="large" disableElevation style={{background: "#007BB8",color:"white", marginTop:"22px", fontSize:"15px", textTransform: "none",borderRadius:"2px", boxShadow: '0px 2px 0px #005179, inset 0px 1px 0px #009ADB'}} >Edit details</Button>
            </Grid>
            <div data-testid="success">
            {success && showSuccessmsg(success)}
            </div>
            <p data-testid="resetpasswrdbtn" onClick={() => {navigate("/resetpassword")}} style={{margin:0,fontSize:16,color:"#007BB8",fontWeight:600,cursor:"pointer", marginTop:15, textAlign:"center"}}>Change password</p>


        </Paper>
    </Grid>


    </>
  );
}
const mapStateToProps = state => {
  return {
    userDetails: state.userDetails,
    isSignedIn: state.isSignedIn
  };
};

MyProfile.propTypes = {
  userDetails: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isSignedIn:PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(MyProfile);