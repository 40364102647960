/* eslint-disable react/prop-types */
import * as React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {isEmpty} from '../validation/Validation';
import {showErrMsgEmpty} from '../notification/Notification';


export default function Dropdown(props) {
  return (
    <>
      <FormControl label="User" style={{width:"100%", marginBottom:12}} variant="outlined" size="small">
        <InputLabel label="User" id="demo-simple-select-label">{props.title || "Selected Role"}</InputLabel>
        <Select
          error= {isEmpty(props.value) && props.err}
          labelId="demo-simple-select-label"
          data-testid="role-select-choice"
          value={props.value}
          id="roleSelectChoice"
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          name={props.name}
          label={props.title || "Selected Role"}
        >
          <MenuItem value={props.defaultValue === "fp@wardleHqAdmin" || props.defaultValue === "fp@wardleHqUser"?"fp@wardleHqAdmin":"fp@wardleAdmin"}>Admin</MenuItem>
          <MenuItem value={props.defaultValue ==="fp@wardleHqUser" || props.defaultValue === "fp@wardleHqAdmin"?"fp@wardleHqUser":"fp@wardleUser"}>Standard</MenuItem>
        </Select>
      </FormControl>
      {isEmpty(props.value)  && showErrMsgEmpty(props.err)}
      </>
  );
}
