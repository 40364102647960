/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Footer from './Footer';
import Header from './Header';
import PropTypes from 'prop-types';
import { Button, Dialog, Grid, Hidden, makeStyles, Paper, Typography } from '@material-ui/core';
import SideDrawer from "./SideDrawer"
import ApiErrorComponent from '../ErrorComponent/ApiErrorComponent';
import { FeatureEnabled } from '../../api/constants';
import { setIsSIgnedIn, setToken, setUserDetails, toggleUSerAway } from '../../redux/actions/actions';
import { getSeessionId } from '../../api/userOps';
import { clearNotificationPolling } from '../../Utilities/NotificationUtils';
import { useEffect } from 'react';
import IsolationIcon from '../../Assets/IsolationModeIcon.svg';

const useStyles = makeStyles((theme) => ({

  container: {
    // marginLeft: (props) => props ? "5vh" : "40vh",
    overflowX: "hidden",
    zoom: "90% !important",
    minHeight: "85%",
    [theme.breakpoints.only('md')]: {
      marginLeft: (props) => props ? "5vh" : "30vh",
    },
    [theme.breakpoints.only('lg')]: {
      marginLeft: (props) => props ? "5vh" : "30vh",
    },
    [theme.breakpoints.only('xl')]: {
      marginLeft: (props) => props ? "5vh" : "30vh",
    },
    [theme.breakpoints.only('sm')]: {
      margin: "2vh",
    },
    [theme.breakpoints.only('xs')]: {
      marginLeft: "2vh",
    },
  },
  paperStyle: 
  {   
      margin:"auto",
      paddingTop:250,
      [theme.breakpoints.up('md')]: {
        width: "25%",
      },
      [theme.breakpoints.down('md')]: {
        width: "40%",
      },
      [theme.breakpoints.down('sm')]: {
        width: "50%",
      },
      [theme.breakpoints.down('xs')]: {
        width: "90%",
      },
  },

  featureNotEnabled: {
    top: "50%",
    left: "40%",
    position: 'absolute',
    [theme.breakpoints.only('sm')]: {
      left: "30%",

    },
    [theme.breakpoints.only('xs')]: {
      left: "20%",

    },
  },
  dialog: {
    opacity: 0.8
  },

}))

function Container(props) {
  const [collapse, setCollapse] = useState(true);
  const classes = useStyles(collapse)
  const isFeatureEnabled = FeatureEnabled(props.feature)

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);


  const handleCollapse = () => {
    setCollapse((prevState) => !prevState);
  };


  window.onfocus = ((event) => {
    let { dispatch } = props
    if (window.location.pathname !== "/" || window.location.pathname !== "/signin") {
      dispatch(toggleUSerAway())
      props.userDetails.email && getSeessionId(props.userDetails.email)
        .then((res) => res.json())
        .then((data) => {
          if (props.token?.sessionId && data.sessionId !== props.token?.sessionId) {
            dispatch(setIsSIgnedIn(false))
            dispatch(setUserDetails({}))
            dispatch(setToken())
            dispatch(setIsSIgnedIn(false))
            clearNotificationPolling()
            window.location.replace("/")
          }
        })
    }
  })
  window.onblur = ((event) => {
    props.dispatch(toggleUSerAway())
  })

  return (
    <div style={{ height: "100vh", }}>
      {!props.hideHeader && <Header toggleSideBar={() => { setCollapse(!collapse) }} />}
      {!props.hideSideBar && <Hidden implementation='css' smDown>
        <SideDrawer handleCollapse={handleCollapse} collapse={collapse} />
      </Hidden>
      }
      {(props.feature === undefined || isFeatureEnabled) && <div className={classes.container}>{props.children}</div>}
      {(props.feature !== undefined && !isFeatureEnabled) && <div className={classes.featureNotEnabled}>We are working on it. Please Visit again later.</div>}

      {(!props.hideFooter || props.userDetails?.role !== "fp@wardleStaff") && <Footer />}
      {props.apiError && <ApiErrorComponent />}
      <Dialog fullScreen open={!isOnline} classes={{ paperFullScreen: classes.dialog }}>
      <Grid align="center">
        <Paper elevation={0} className={classes.paperStyle} sx={{textAlign:"center"}}>
          <img src={IsolationIcon} alt="" srcSet=""/>
          <h1 data-testid="Header" style={{fontSize: 25,margin:0, marginBottom:25,width:"70%"}}>No internet connection.</h1>
        </Paper>
    </Grid>
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userDetails: state.userDetails,
    apiError: state.apiError,
    token: state.token,
    isUserAway: state.isUserAway

  };
};
Container.propTypes = {
  userDetails: PropTypes.object,
  hideHeader: PropTypes.bool,
  hideSideBar: PropTypes.bool,
  hideFooter: PropTypes.bool,
  apiError: PropTypes.string,
  children: PropTypes.any
};

export default connect(mapStateToProps)(Container);


