import { FpServicesRoutes } from "./constants";
import { getHeaders } from "./getHeaders";
import { getUserDetails } from "./getUserDetails";
import { handleFetch } from "./HandleApiCall";

//Function for adding New Pharmacy group
export function AddPharmacyGroup(body, isEdit = false) {
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: isEdit
      ? "PUT"
      : FpServicesRoutes.Pharmacyops.ADD_PHARMACY_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify(body),
  };
  return handleFetch(
    FpServicesRoutes.Pharmacyops.ADD_PHARMACY_ENDPOINT.uri,
    requestOptions
  );
}

//Function for editing existing pharmacy group
export function EditPharmacyGroup(body, id) {
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: FpServicesRoutes.Pharmacyops.EDIT_PHARMACY_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify(body),
  };
  return handleFetch(
    FpServicesRoutes.Pharmacyops.EDIT_PHARMACY_ENDPOINT.uri.replace("{id}", id),
    requestOptions
  );
}
//Function for deleting existing pharmacy group
export function DeletePharmacyGroup(id, accountNumber) {
  let headers = { "Content-Type": "application/json" };

  let uri = FpServicesRoutes.Pharmacyops.DELETE_PHARMACY_ENDPOINT.uri
    .replace("{id}", id)
    .replace("{accountNumber}", accountNumber);
  const requestOptions = {
    method: FpServicesRoutes.Pharmacyops.DELETE_PHARMACY_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
  };

  return handleFetch(uri, requestOptions);
}

//Function for deleting associated pharmacy in a pharmacy group
export function DeleteAssociatedPharmacy(id, groupAccountNumber) {
  let headers = { "Content-Type": "application/json" };

  let uri = FpServicesRoutes.Pharmacyops.DELETE_ASSOCIATED_PHARMACY_ENDPOINT.uri
    .replace("#id", id)
    .replace("#email", "pharmacy_association")
    .replace("#groupAccountNumber", groupAccountNumber);
  const requestOptions = {
    method:
      FpServicesRoutes.Pharmacyops.DELETE_ASSOCIATED_PHARMACY_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
  };
  return handleFetch(uri, requestOptions);
}

//Function for uploading Pharmacy Files
export function UploadPharmacyFile(
  data,
  pharmacyGroupName,
  pharmacyGroupAccountNumber
) {
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: FpServicesRoutes.Pharmacyops.UPLOAD_PHARMACY_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify({
      templateContents: data,
      pharmacyGroupName: pharmacyGroupName,
      pharmacyGroupAccountNumber,
      createdBy: getUserDetails().email,
    }),
  };
  return handleFetch(
    FpServicesRoutes.Pharmacyops.UPLOAD_PHARMACY_ENDPOINT.uri,
    requestOptions
  );
}

//Function for getting Pharmacy Name by Account Number
export function GetPharmacynamebyAccountnumber(accountNumber) {
  let headers = { "Content-Type": "application/json" };

  let uri =
    FpServicesRoutes.Pharmacyops.GET_PHARMACY_GROUPNAME_ENDPOINT.uri.replace(
      "#accountNumber",
      accountNumber
    );
  const requestOptions = {
    method: FpServicesRoutes.Pharmacyops.GET_PHARMACY_GROUPNAME_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
  };
  return handleFetch(`${uri}`, requestOptions);
}

//Function for adding associated pharmacies manually
export function addManualPharmacies(
  data,
  pharmacyGroupName,
  pharmacyGroupAccountNumber
) {
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: FpServicesRoutes.Pharmacyops.UPLOAD_PHARMACY_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify({
      pharmacyName: data.pharmacyName,
      pharmacyAccountNumber: data.pharmacyAccountNumber,
      pharmacyPostCode: data.pharmacyPostCode,
      adminUsers: data.adminusers,
      users: data.users,
      pharmacyGroupName: pharmacyGroupName,
      pharmacyGroupAccountNumber,
      createdBy: getUserDetails().email,
    }),
  };
  return handleFetch(
    FpServicesRoutes.Pharmacyops.UPLOAD_PHARMACY_ENDPOINT.uri,
    requestOptions
  );
}
export function EditassociatedpharmacyAPi(
  body
) {
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: FpServicesRoutes.Pharmacyops.UPLOAD_PHARMACY_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: body
  };
  return handleFetch(
    FpServicesRoutes.Pharmacyops.UPLOAD_PHARMACY_ENDPOINT.uri,
    requestOptions
  );
}
//Function for Getting associated pharmacies by Pharmacy Group Account Number
export function GetAssociatedPharmaciesbyGroupAccountnumber(
  groupAccountNumber
) {
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method:
      FpServicesRoutes.Pharmacyops.GET_PHARMACIES_BY_GROUPACCOUNTNUMBER_ENDPOINT
        .method,
    headers: { ...headers, ...getHeaders() },
  };
  return handleFetch(
    FpServicesRoutes.Pharmacyops.GET_PHARMACIES_BY_GROUPACCOUNTNUMBER_ENDPOINT.uri.replace(
      "#groupAccountNumber",
      groupAccountNumber
    ),
    requestOptions
  );
}
