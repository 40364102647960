import { CLEAR_NOTIFICATION_LIST,CLEAR_STATE, GET_TOKEN, IS_LOADING, IS_LOGIN_ERROR, IS_NOTIFICATION_ENABLED, IS_REMEBER_PASSWORD, IS_SIGNED_IN, IS_TOKEN_REFRESHING, IS_USER_AWAY, REFRESH_TOKEN, RETRY_ARRAY_APIS, SET_API_ERROR, SET_COMISSION_FILTER, SET_HQ_ASSOCIATED_PHARMACIES, SET_IS_COMMISSION_FILTER, SET_IS_ORDER_FILTER_APPLIED, SET_IS_USER_FILTER_APPLIED, SET_NOTIFICATION_LIST, SET_ORDER_FILTER, SET_RESET_ORDER_FILTER, SET_RESET_USER_FILTER, SET_USER_DETAILS, SET_USER_FILTER, SET_USER_LOGIN_DETAILS } from './constants'

export const setToken = (data) => {
    return  {
        type: GET_TOKEN,payload:data
    }
}

export const updateToken = (data) => {
    return  {
        type: REFRESH_TOKEN,payload:data
    }
}


export const setUserDetails = (data) => {
    return ({
        type: SET_USER_DETAILS, payload: data
    })
}

export const setIsSIgnedIn = (data) => {
    return ({
        type: IS_SIGNED_IN, payload: data
    })
}

export const setLoginError=(error)=>{
    return({
        type:IS_LOGIN_ERROR,payload:error
    })
}

export const setUSerLoginDetails=(loginDetails)=>{
    return({
        type:SET_USER_LOGIN_DETAILS,payload:loginDetails
    })
}

export const setAssociatedPharmacies=(pharmacies)=>{
    return({
        type:SET_HQ_ASSOCIATED_PHARMACIES,payload:pharmacies
    })
}

export const setUserFilter=(filter)=>{
    return({
        type:SET_USER_FILTER,payload:filter
    })
}

export const setOrderFilter=(filter)=>{
    return({
        type:SET_ORDER_FILTER,payload:filter
    })
}

export const reSetOrderFilter=()=>{
    return({
        type:SET_RESET_ORDER_FILTER
    })
}

export const reSetUserFilter=()=>{
    return({
        type:SET_RESET_USER_FILTER
    })
}

export const setisUserFilterApplied=(isFilterApplied)=>{
    return({
        type:SET_IS_USER_FILTER_APPLIED,payload:isFilterApplied
    })
}

export const setisOrderFilterApplied=(isFilterApplied)=>{
    return({
        type:SET_IS_ORDER_FILTER_APPLIED,payload:isFilterApplied
    })
}

export const setNotifications=(notifications)=>{
    return({
        type:SET_NOTIFICATION_LIST,payload:notifications
    })
}

export const triggerInAppNotifications=(notifications)=>{
    return({
        type:SET_NOTIFICATION_LIST,payload:notifications
    })
}

export const clearNotifications=()=>{
    return({
        type:CLEAR_NOTIFICATION_LIST
    })
}

export const setisloadingfunction=(data)=>{
    return({
        type:IS_LOADING,payload:data
    })
}


export const setComissionFilter=(filter)=>{
    return({
        type:SET_COMISSION_FILTER,payload:filter
    })

}

export const setisComissionFilter=(payload)=>{
    return({
        type:SET_IS_COMMISSION_FILTER,payload:payload
    })

}

export const clearState=()=>{
    return({
        type:CLEAR_STATE
    })

}

export const onApiError = (error)=>{
    return({
        type:SET_API_ERROR,payload:error
    })

}

export const enableNotification=(enable)=>{
    return({
        type:IS_NOTIFICATION_ENABLED,payload:enable
    })
}

export const isRemeberPassword=(enable)=>{
    return({
        type:IS_REMEBER_PASSWORD,payload:enable
    })
}

export const isTokenRefreshing =(isRefresh)=>{
    return({
        type:IS_TOKEN_REFRESHING,payload:isRefresh
    })
}

export const updateReTryApis =(array)=>{
    return({
        type:RETRY_ARRAY_APIS,payload:array
    })

}

// eslint-disable-next-line no-unused-vars
export const toggleUSerAway =(flag)=>{
    return({
        type:IS_USER_AWAY
    })
}