import React, { useState } from "react";
import {
    Button,
    TextField,
    InputAdornment,
    Grid,
    Typography,
    makeStyles
} from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import FilterIcon from "../../Assets/filtergreyicon.svg";
import CalendarIcon from "../../Assets/Calendar.svg";

import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logEventWithProperties } from "../../analytics/Analytics";


const useStyles = makeStyles((theme) => ({

    title: {
        fontSize: "2em",
        fontWeight: "bold",
    },
    rightHead: {
        display: "flex",
        justifyContent: "right",
        [theme.breakpoints.only('xs')]: {
            justifyContent: "left",


        },
    },
    filterBar: {
        display: "flex",
        height: "40px",
        justifyContent: "right",
        [theme.breakpoints.only('md')]: {
            justifyContent: "right",


        },
        [theme.breakpoints.only('xs')]: {
            justifyContent: "left",


        },
        [theme.breakpoints.only('sm')]: {
            justifyContent: "left",


        },
        [theme.breakpoints.only('lg')]: {
            justifyContent: "right",


        },
        [theme.breakpoints.only('xl')]: {
            justifyContent: "right",


        },
    },
    searchfield: {
        marginLeft: "1em",
        // boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.07)",
        border: '1px solid #fff',
        borderRadius: '4px',

        boxSizing: 'border-box',
        background: "white",
        height: "3em",
        [theme.breakpoints.only('xs')]: {
            zIndex: 1,
            position: "absolute",
            width: '95%',
            align: "left",
            marginLeft: 0
        },
        [theme.breakpoints.only('sm')]: {
            zIndex: 1,
            position: "absolute",
            width: '95%',
            marginLeft: 0

        },
    }
}))
function FilterBarMobile(props) {
    const classes = useStyles()
    let navigate = useNavigate();
    const [hideSearchField, setSearchField] = useState(true)

    return (
        <>
            <Grid container >
                <Grid item style={{ width: "50%", marginTop: "60px", zIndex: -1 }}>
                    {props.Overheading && props.Overheading()}
                </Grid>
                <Grid item container style={{ display: "flex", justifyContent: "space-between" }} >
                    <Grid item xs={6} sm={6}>
                        <Typography className={classes.title}>{props.heading}</Typography>
                    </Grid>


                    {!hideSearchField &&
                        <TextField
                            className={classes.searchfield}
                            placeholder={
                                props.recordType
                                    ? `Search ${props.recordType?.replaceAll("_", " ")}`
                                    : "Search"
                            }
                            variant="outlined"
                            onChange={(e) => {
                                if (!e.target.value) props.onSearchTextChanged(e.target.value);
                            }}
                            type="text"
                            onKeyDown={(e) => {
                                if (e.keyCode == 13) {
                                    props.onSearchTextChanged(e.target.value);
                                    setSearchField(!hideSearchField)
                                }
                            }}
                            InputProps={{

                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchOutlined
                                            onClick={() => setSearchField(!hideSearchField)}
                                            style={{ color: "grey" }} />
                                    </InputAdornment>
                                ),
                            }}
                            size="small"
                        />
                    }
                    <Grid item xs={6} sm={6} style={{ display: "flex", justifyContent: "right" }}>

                        <>
                            {!props.hideFilter ?
                                <Button
                                    size="large"
                                    variant="outlined"
                                    style={{
                                        textTransform: "capitalize", border: '2px solid #007BB8',
                                        // boxShadow: '2px 2px 7px rgba(205, 205, 205, 0.5)',
                                        borderRadius: '10px', height: '3em',
                                        // zIndex:-1
                                    }}
                                    onClick={() => { props.filterClicked() }}
                                >
                                    <img src={FilterIcon} height={22} />
                                </Button> :

                                props.recordType === "commission_statement" &&

                                <Button
                                    size="large"
                                    variant="outlined"
                                    style={{
                                        textTransform: "capitalize", border: '2px solid #007BB8',
                                        boxShadow: '2px 2px 7px rgba(205, 205, 205, 0.5)',
                                        borderRadius: '10px', height: '3em',
                                    }}
                                    onClick={() => { props.filterClicked() }}
                                >
                                    <img src={CalendarIcon} height={22} />
                                </Button>
                            }

                        </>



                        {!props.hideSearch && (
                            <>

                                {/* {hideSearchField === true && */}

                                <Button
                                    size="large"
                                    variant="outlined"
                                    style={{
                                        textTransform: "capitalize", border: '2px solid #007BB8',
                                        // boxShadow: '2px 2px 7px rgba(205, 205, 205, 0.5)',
                                        borderRadius: '10px', height: '3em',
                                        marginLeft: "10px",
                                        // zIndex: 2,
                                        background: "white"

                                    }}
                                    onClick={() => setSearchField(!hideSearchField)}
                                >
                                    <SearchOutlined style={{ color: "grey" }} />
                                </Button>
                                {/* }  */}
                            </>)
                        }

                    </Grid>



                </Grid>
                <Grid item xs={12} sm={12} md={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Grid container>

                        <Grid item xs={12} sm={6} md={6} justifyContent="center" style={{ marginTop: "40px" }}>

                            {props.subheading || props.subtext}

                            {props.subcontainer && props.subcontainer()}

                        </Grid>
                        <Grid item xs={12} sm={6} md={6} justifyContent="right" className={classes.rightHead} >
                            {props.Righthead && props.Righthead()}

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} >

                    {props.recordType === "user" ? (
                        <Button
                            variant="contained"
                            onClick={() => {
                                logEventWithProperties("Add new user clicked")

                                navigate("/adduser");
                            }}
                            disableElevation
                            style={{
                                background: "#3D8541",
                                fontSize: "18px",
                                cursor: "pointer",
                                color: "white",
                                textTransform: "none",
                                boxShadow: "0px 2px 0px #306B33, inset 0px 1px 0px #62B767",
                            }}
                        >
                            Add a new user
                        </Button>
                    ) : null}
                    {/* {props.recordType === "commission_statement" ? (
                        <Hidden xsDown implementation='css' >
                            <FilterMonthComponent
                                onSelect={props.onDateSelect}
                                filterClicked={props.calendarOpen}
                            />
                        </Hidden>
                    ) : null} */}
                    {props.recordType === "pharmacy_group" ? (
                        <Button
                            variant="contained"
                            onClick={() => {
                                navigate("/addpharmacygroup");
                            }}
                            disableElevation
                            style={{
                                background: "#3D8541",
                                fontSize: "18px",
                                cursor: "pointer",
                                color: "white",
                                textTransform: "none",
                                boxShadow: "0px 2px 0px #306B33, inset 0px 1px 0px #62B767",
                            }}
                        >
                            Add a new group
                        </Button>
                    ) : null}
                </Grid>
            </Grid>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.isSignedIn,
        userDetails: state.userDetails,
        isUserFilterApplied: state.isUserFilterApplied,
        isOrderFilterApplied: state.isOrderFilterApplied,
    };
};

FilterBarMobile.propTypes = {
    userDetails: PropTypes.object,
    recordType: PropTypes.string,
    searchPlaceHolder: PropTypes.string,
    subtext: PropTypes.string,
    filterClicked: PropTypes.func,
    onSearchTextChanged: PropTypes.func,
    hideFilter: PropTypes.any,
    hideSearch: PropTypes.any,
    isUserFilterApplied: PropTypes.bool,
    isOrderFilterApplied: PropTypes.bool,
    onDateSelect: PropTypes.func,
    calendarOpen: PropTypes.func,
    Overheading: PropTypes.any,
    heading: PropTypes.any,
    subheading: PropTypes.any,
    Righthead: PropTypes.any,
    subcontainer: PropTypes.any

};

export default connect(mapStateToProps)(FilterBarMobile);
