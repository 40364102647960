import { store } from "../redux/reducer/store";

//Function for getting headers from redux store to use in API calling
export function getHeaders() {
  let state = store.getState();
  const { token } = state;
  return {
    Authorization:
      "Bearer " + token?.AccessToken + "-id-token-" + token?.IdToken,
  };
}
store.subscribe(getHeaders);

//Function for getting Tokens from redux store to use in API calling
export function getToken() {
  let state = store.getState();
  const { token } = state;
  return token?.AccessToken;
}
store.subscribe(getToken);

//Function for getting Refreshtokens from redux store to use in API calling
export function getRefreshToken() {
  let state = store.getState();
  const { token } = state;
  return token?.RefreshToken;
}

store.subscribe(getRefreshToken);
