import { logEventWithProperties } from "../analytics/Analytics";
import { UserOpsRoutes, PRODUCT_CODE, klaviyoServices } from "./constants";
import { getHeaders, getRefreshToken } from "./getHeaders";
import { getUserDetails } from "./getUserDetails";
import { handleFetch } from "./HandleApiCall";

//Function for restoring password after user logged in
export function ResetPasswordafterlogin(
  accessTokens,
  currentPassword,
  password
) {
  const requestOptions = {
    method: UserOpsRoutes.USER_RESET_PASSWORD_AFTER_LOGIN.method,
    headers: { ...{ "Content-Type": "application/json" }, ...getHeaders() },
    body: JSON.stringify({
      accessToken: accessTokens,
      oldPassword: currentPassword,
      newPassword: password,
    }),
  };
  return handleFetch(
    UserOpsRoutes.USER_RESET_PASSWORD_AFTER_LOGIN.uri,
    requestOptions
  );
}

//function for inclusion of new user
export function AdminAddUserStart(
  email,
  firstname,
  lastname,
  role,
  collection_address,
  pharmacy_name,
  pharmacy_account_number,
  pharmacy_post_code
) {
  const headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: UserOpsRoutes.ADMIN_USER_ADD_START_ENDPOINT.method,

    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify({
      email: email,
      firstName: firstname,
      lastName: lastname,
      role: role,
      productCode: "fp",
      pharmacyAccountNumber: pharmacy_account_number,
      pharmacyPostCode: pharmacy_post_code,
      pharmacyName: pharmacy_name,
      collectionAddress: collection_address,
    }),
  };
  logEventWithProperties("New user details submitted",{
    email: email,
    role: role,
    pharmacyAccountNumber: pharmacy_account_number})

  return handleFetch(
    UserOpsRoutes.ADMIN_USER_ADD_START_ENDPOINT.uri,
    requestOptions
  );
}

//Function for completion of new user addition
export function AdminAddUserComplete(
  email,
  password,
  phoneNumber,
  firstName,
  lastName,
  pharmacyName,
  pharmacyCode,
  pharmcyAccountNumber,
  collectionAddress,
  receiveOffer,
  role,
  rnd,
  token
) {
  const headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: UserOpsRoutes.ADMIN_USER_ADD_COMPLETE_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify({
      email: email,
      password: password,
      phoneNumber: phoneNumber,
      firstName: firstName,
      lastName: lastName,
      pharmacyAccountNumber: pharmcyAccountNumber,
      pharmacyName: pharmacyName,
      pharmacyPostCode: pharmacyCode,
      productCode: PRODUCT_CODE,
      collectionAddress: collectionAddress,
      receiveOffer: receiveOffer,
      role: role,
      rnd: rnd,
      token: token,
    }),
  };
  return handleFetch(
    UserOpsRoutes.ADMIN_USER_ADD_COMPLETE_ENDPOINT.uri,
    requestOptions
  );
}

//Function for editing user
export function AdminEditUser(email, id, role) {
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: UserOpsRoutes.USER_UPDATE_ROLE_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify({
      emailId: email,
      role: role,
      Id: id,
      productCode: PRODUCT_CODE,
    }),
  };
  return handleFetch(
    UserOpsRoutes.USER_UPDATE_ROLE_ENDPOINT.uri,
    requestOptions
  );
}

//Function for removing user
export function AdminRemoveUser(email, id, pharmacyAccountNumber) {
  const uri = UserOpsRoutes.DELETE_USER_BY_EMAIL.uri.replace("#email", email);
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: UserOpsRoutes.DELETE_USER_BY_EMAIL.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify({
      Id: id,
      emailId: email,
      pharmacyAccountNumber: pharmacyAccountNumber,
      productCode: PRODUCT_CODE,
    }),
  };
  return handleFetch(`${uri}/${PRODUCT_CODE}`, requestOptions);
}

//Function for Login API Call
export function Login(emaillowercaser, password) {
  const requestOptions = {
    method: UserOpsRoutes.USER_LOGIN_ENDPOINT.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: emaillowercaser,
      password: password,
      productCode: PRODUCT_CODE
    }),
  };
  return handleFetch(UserOpsRoutes.USER_LOGIN_ENDPOINT.uri, requestOptions);
}

//Function for checking if user already exists
export function GetUserEmailExist(emaillowercaser) {
  const uri = UserOpsRoutes.GET_USER_EMAIL_IS_EXIST.uri.replace(
    "#email",
    emaillowercaser
  );
  const requestOptions = {
    method: UserOpsRoutes.GET_USER_EMAIL_IS_EXIST.method,
    headers: { "Content-Type": "application/json" },
  };
  return handleFetch(`${uri}/${PRODUCT_CODE}`, requestOptions);
}

export function VerifyRegisteredEmail(emaillowercaser) {
  const uri = UserOpsRoutes.GET_USER_EMAIL_IS_REGISTERED.uri.replace(
    "#email",
    emaillowercaser
  );
  const requestOptions = {
    method: UserOpsRoutes.GET_USER_EMAIL_IS_REGISTERED.method,
    headers: { "Content-Type": "application/json" },
  };
  return handleFetch(`${uri}`, requestOptions);
}

//Function for getting user details by email
export function GetUserDetailsByEmail(emaillowercaser) {
  const uri = UserOpsRoutes.GET_USER_DETAILS_BY_EMAIL.uri.replace(
    "#email",
    emaillowercaser
  );
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: UserOpsRoutes.GET_USER_DETAILS_BY_EMAIL.method,
    headers: { ...headers, ...getHeaders() },
  };
  return handleFetch(`${uri}/${PRODUCT_CODE}`, requestOptions);
}

//Function for getting all user details
export function GetAllUserDetails() {
  const uri = UserOpsRoutes.LIST_ALL_USERS.uri;
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: UserOpsRoutes.LIST_ALL_USERS.method,
    headers: { ...headers, ...getHeaders() },
  };
  return handleFetch(`${uri}`, requestOptions);
}

//Function for getting user details by pharmacy account number
export function GetUserDetailsByPharmacy(pharmacyAccountNumber) {
  const uri = UserOpsRoutes.LIST_USERS_BY_PHARMACY.uri.replace(
    "#accountNo",
    pharmacyAccountNumber
  );
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: UserOpsRoutes.LIST_USERS_BY_PHARMACY.method,
    headers: { ...headers, ...getHeaders() },
  };
  return handleFetch(`${uri}`, requestOptions);
}

export function AccessStart(
  email,
  password,
  phoneNumber,
  firstName,
  lastName,
  pharmacyName,
  pharmacyCode,
  pharmcyAccountNumber,
  collectionAddress,
  receiveOffer,
  role
) {
  const requestOptions = {
    method: UserOpsRoutes.USER_REGISTRATION_START_ENDPOINT.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      password: password,
      phoneNumber: phoneNumber,
      firstName: firstName,
      lastName: lastName,
      pharmacyAccountNumber: pharmcyAccountNumber,
      pharmacyName: pharmacyName,
      pharmacyPostCode: pharmacyCode,
      productCode: PRODUCT_CODE,
      collectionAddress: collectionAddress,
      receiveOffer: receiveOffer,
      role: role,
    }),
  };
  logEventWithProperties("User registration started",{
    email: email,
    receiveOffer: receiveOffer,

    role: role,
    pharmacyAccountNumber: pharmcyAccountNumber})
  return handleFetch(
    UserOpsRoutes.USER_REGISTRATION_START_ENDPOINT.uri,
    requestOptions
  );
}

export function AccessComplete(rnd, token) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ token: token, productCode: PRODUCT_CODE }),
  };
  return handleFetch(
    `${UserOpsRoutes.USER_REGISTRATION_COMPLETE_ENDPOINT.uri}/${rnd}`,
    requestOptions
  );
}

//Function for resetting forgot password
export function ForgotPassStart(email) {
  const requestOptions = {
    method: UserOpsRoutes.USER_FORGOT_PASS_START_ENDPOINT.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      productCode: PRODUCT_CODE,
    }),
  };
  return handleFetch(
    UserOpsRoutes.USER_FORGOT_PASS_START_ENDPOINT.uri,
    requestOptions
  );
}

//Function for registering new wardle staff
export function WardleStaffRegisterStart(email) {
  const requestOptions = {
    method: UserOpsRoutes.STAFF_REGISTRATION_START_ENDPOINT.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      productCode: PRODUCT_CODE,
    }),
  };
  return handleFetch(
    UserOpsRoutes.STAFF_REGISTRATION_START_ENDPOINT.uri,
    requestOptions
  );
}

//Function for completing wardle staff registration
export function WardleStaffRegisterComplete(
  email,
  firstName,
  lastName,
  password,
  rnd,
  token
) {
  const requestOptions = {
    method: UserOpsRoutes.STAFF_REGISTRATION_COMPLETE_ENDPOINT.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      rnd: rnd,
      token: token,
      firstName: firstName,
      lastName: lastName,
      password: password,
      pharmacyAccountNumber: "Staff",
      pharmacyName: "Staff",
      productCode: PRODUCT_CODE,
    }),
  };
  return handleFetch(
    UserOpsRoutes.STAFF_REGISTRATION_COMPLETE_ENDPOINT.uri,
    requestOptions
  );
}

//Function for registering new wardle hq
export function WardleHqRegisterStart(email, firstName=null, lastName=null, phoneNumber=null, pharmacyGroupAccountNumber=null, pharmacyGroupName=null) {
  let body = {
    email: email,
    productCode: PRODUCT_CODE,
  }
  if(firstName != null) {
    body["firstName"] = firstName;
  }
  if(lastName != null) {
    body["lastName"] = lastName;
  }
  if(phoneNumber != null) {
    body["phoneNumber"] = phoneNumber;
  }
  if(pharmacyGroupAccountNumber != null) {
    body["pharmacyGroupAccountNumber"] = pharmacyGroupAccountNumber;
  }
  if(pharmacyGroupName != null) {
    body["pharmacyGroupName"] = pharmacyGroupName;
  }
  const requestOptions = {
    method: UserOpsRoutes.HQ_REGISTRATION_START_ENDPOINT.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };
  return handleFetch(
    UserOpsRoutes.HQ_REGISTRATION_START_ENDPOINT.uri,
    requestOptions
  );
}

//Function for completing wardle hq registration
export function WardleHqRegisterComplete(
  email,
  firstName,
  lastName,
  password,
  phoneNumber,
  pharmacyGroupName,
  pharmacyGroupAccountNumber,
  receiveOffer,
  rnd,
  token
) {
  const requestOptions = {
    method: UserOpsRoutes.HQ_REGISTRATION_COMPLETE_ENDPOINT.method,
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      rnd: rnd,
      token: token,
      firstName: firstName,
      lastName: lastName,
      password: password,
      phoneNumber: phoneNumber,
      pharmacyGroupName: pharmacyGroupName,
      pharmacyGroupAccountNumber: pharmacyGroupAccountNumber,
      receiveOffer: receiveOffer,
      productCode: PRODUCT_CODE,
    }),
  };
  return handleFetch(
    UserOpsRoutes.HQ_REGISTRATION_COMPLETE_ENDPOINT.uri,
    requestOptions
  );
}

// Function for editing user details
export function EditUserDetails(
  updateKey,
  updateValue,
  userId = getUserDetails().id,
  emailId = getUserDetails().email
) {
  const headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: UserOpsRoutes.USER_UPDATE_DETAILS_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify({
      updateKey: updateKey,
      updateValue: updateValue,
      emailId: emailId,
      Id: userId,
      productCode: PRODUCT_CODE,
    }),
  };
  return handleFetch(
    UserOpsRoutes.USER_UPDATE_DETAILS_ENDPOINT.uri,
    requestOptions
  );
}

//Function for completing forgot password operation
export function ForgotPassComplete(rnd, token, email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      token: token,
      productCode: PRODUCT_CODE,
      email: email,
      password: password,
    }),
  };
  return handleFetch(
    UserOpsRoutes.USER_FORGOT_PASS_COMPLETE_ENDPOINT.uri + "/" + rnd,
    requestOptions
  );
}

//Function for user Logging Out
export function userLogout(token, sid) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      accessToken: token,
      sessionId: sid,
      email: getUserDetails().email,
      id: getUserDetails().id,
      productCode: PRODUCT_CODE,


    }),
  };

  return fetch(UserOpsRoutes.USER_LOGOUT.uri, requestOptions);

}


export function hardLogout(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email,
      logout: "hard",
      productCode: PRODUCT_CODE,

    }),
  };
  return fetch(UserOpsRoutes.USER_LOGOUT.uri, requestOptions);
}

export function getSeessionId(email) {
  const requestOptions = {
    method: "GET",
  };
  return fetch(UserOpsRoutes.USER_GET_SESSION_ID_ENDPOINT.uri + "/" + email, requestOptions);
}


//Function for handling Refresh Token As Middleware
export const refreshToken = (sid) => {
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: UserOpsRoutes.USER_REFRESH_TOKEN_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify({
      refreshToken: getRefreshToken(),
      sessionId: sid,
      email: getUserDetails()?.email
    }),
  };
  return fetch(UserOpsRoutes.USER_REFRESH_TOKEN_ENDPOINT.uri, requestOptions)
};



export const subscribeToKlaviyo = (userDetails)=>{
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: userDetails.email,
      firstName: userDetails.first_name,
      lastName: userDetails.last_name,

    }),
  };
  return fetch(klaviyoServices.klaviyo.KLAVIYO_SUBSCRIBE.uri, requestOptions);
}