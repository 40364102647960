import React from 'react'
import {
    Button,
    Typography,
  } from "@material-ui/core";
  import { makeStyles } from "@material-ui/styles";
  import PropTypes from 'prop-types';


  const useStyles = makeStyles({
    buttontext: {
      padding: "0.25em",
      fontWeight: 600,
    },
    removebutton: {
      backgroundColor: "#fff",
      textTransform: "none",
      color: "#007BB8",
      fontWeight: "600",
      borderWidth: "0px",
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
  });

function BlueTextButton({text,handleChangeBlueTextButton}) {
    const classes = useStyles();
  return (
    <>
    <Button
                  fullWidth
                  data-testid="bluetext"
                  variant="outlined"
                  disableRipple
                  disableElevation
                  className={classes.removebutton}
                  onClick={handleChangeBlueTextButton} 
                >
                  <Typography
                  variant="body2"
                  className={classes.buttontext}>
                    {text}
                  </Typography>
    </Button>
    </>
  )
}

BlueTextButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleChangeBlueTextButton : PropTypes.func.isRequired
}

export default BlueTextButton