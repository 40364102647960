import { logEventWithProperties } from "../analytics/Analytics";
import { FpServicesRoutes, flattenDictionaryToQueryString, REACT_APP_API_KEY_ORDER_CLOSE } from "./constants";
import { getHeaders } from "./getHeaders";
import { getUserDetails } from "./getUserDetails";
import { handleFetch } from "./HandleApiCall";

//Function for creating order through electronic prescription
export function CreateOrderElectronic(
  email,
  guid,
  pharmacyAccountNumber,
  pharmacyName,
  pharmacyPostCode
) {
  logEventWithProperties("EPS order submitted", {
      userId: getUserDetails()?.id,
      orderType: "eps",
      pharmacyAccountNumber: pharmacyAccountNumber,
  })
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: FpServicesRoutes.OrderOps.CREATE_ORDER_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify({
      email: email,
      orderType: "eps",
      guid: guid,
      orderStatus: "request submitted",
      pharmacyAccountNumber: pharmacyAccountNumber,
      pharmacyName: pharmacyName,
      pharmacyPostCode: pharmacyPostCode,
    }),
  };
  return handleFetch(
    FpServicesRoutes.OrderOps.CREATE_ORDER_ENDPOINT.uri,
    requestOptions
  );
}

//function for creating order through paper prescription
export function CreateorderPaperprescription(
  email,
  firstName,
  lastName,
  pharmacyAccountNumber,
  pharmacyName,
  pharmacyPostCode,
  productList,
  envelope
) {
  let headers = { "Content-Type": "application/json" };
  logEventWithProperties("Manual order submitted", {

    "orderType": "manual",
    "orderItems": productList,
    "pharmacyAccountNumber": pharmacyAccountNumber,
    "userId": getUserDetails()?.id
  })
  const requestOptions = {
    method: FpServicesRoutes.OrderOps.CREATE_ORDER_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify({
      email,
      firstName,
      lastName,
      orderType: "manual",
      orderStatus: "request submitted",
      orderItems: productList,
      pharmacyAccountNumber: pharmacyAccountNumber,
      pharmacyName: pharmacyName,
      pharmacyPostCode: pharmacyPostCode,
      envelope
    }),
  };
  return handleFetch(
    FpServicesRoutes.OrderOps.CREATE_ORDER_ENDPOINT.uri,
    requestOptions
  );
}

//Function for listing all orders by using email id
export function ListorderbyEmail(email, lastEvaluated) {
  let uri =
    FpServicesRoutes.OrderOps.LIST_ORDER_BY_EMAIL_ID_ENDPOINT.uri.replace(
      "#email",
      email
    );
  let headers = { "Content-Type": "application/json" };
  if (lastEvaluated)
    uri = uri + `&${flattenDictionaryToQueryString(lastEvaluated)}`;
  const requestOptions = {
    method: FpServicesRoutes.OrderOps.LIST_ORDER_BY_EMAIL_ID_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
  };
  return handleFetch(`${uri}`, requestOptions);
}

//Function for listing All orders By Pharmacy Account Number
export function ListorderbyPharmacy(accountNumber, lastEvaluated) {
  let uri =
    FpServicesRoutes.OrderOps.LIST_ORDER_BY_PHARMACY_ENDPOINT.uri.replace(
      "#accountNumber",
      accountNumber
    );
  let headers = { "Content-Type": "application/json" };
  if (lastEvaluated)
    uri = uri + `&${flattenDictionaryToQueryString(lastEvaluated)}`;
  const requestOptions = {
    method: FpServicesRoutes.OrderOps.LIST_ORDER_BY_PHARMACY_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
  };
  return handleFetch(`${uri}`, requestOptions);
}

//Function for listing all orders using pagination
export function ListAllorder(lastEvaluated) {
  let uri = FpServicesRoutes.OrderOps.LIST_ALL_ORDER_ENDPOINT.uri;
  let headers = { "Content-Type": "application/json" };
  if (lastEvaluated)
    uri = uri + `&${flattenDictionaryToQueryString(lastEvaluated)}`;
  const requestOptions = {
    method: FpServicesRoutes.OrderOps.LIST_ALL_ORDER_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
  };
  return handleFetch(`${uri}`, requestOptions);
}

//Function for updating all order specific details
export function UpdateOrderDetails(key, value, id, email) {
  let headers = { "Content-Type": "application/json" };
  const requestOptions = {
    method: FpServicesRoutes.OrderOps.UPDATE_ORDER_DETAILS_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
    body: JSON.stringify({
      updateKey: key,
      updateValue: value,
      id: id,
      email: email,
      modifiedBy: getUserDetails()?.email,
    }),
  };
  logEventWithProperties("Order details updated with"+key,{
    updateKey: key,
    updateValue: value,
    orderId: id,
    modifiedById: getUserDetails()?.id
  })

  return handleFetch(
    FpServicesRoutes.OrderOps.UPDATE_ORDER_DETAILS_ENDPOINT.uri,
    requestOptions
  );
}

export function forceCloseOrder(id,email) {
  let headers = { "Content-Type": "application/json" };
  const requestOptions = {
    method: FpServicesRoutes.OrderOps.FORCE_CLOSE_ORDER.method,
    headers: { ...headers,...{"x-api-key":REACT_APP_API_KEY_ORDER_CLOSE} },
    body: JSON.stringify({
      updateKey: "order_open",
      updateValue: "close",
      id: id,
      email: email,
      modifiedBy: getUserDetails()?.email,
    }),
  };
  return handleFetch(
    FpServicesRoutes.OrderOps.FORCE_CLOSE_ORDER.uri,
    requestOptions
  );
}

//Function for listing all orders by using id
export function ListorderbyId(id) {
  const uri =
    FpServicesRoutes.OrderOps.LIST_ORDER_BY_ORDER_ID_ENDPOINT.uri.replace(
      "#id",
      id
    );
  let headers = { "Content-Type": "application/json" };

  const requestOptions = {
    method: FpServicesRoutes.OrderOps.LIST_ORDER_BY_ORDER_ID_ENDPOINT.method,
    headers: { ...headers, ...getHeaders() },
  };
  return handleFetch(`${uri}`, requestOptions);
}
