import React, { useEffect, useState } from "react";

import CircularProgressWithLabel from "../Components/ProgressBar/CircularProgressBarWithLabel";

export default function CountdownTimer(props) {

    let timerId = null
    let intervalTimerId = null

    const totalTime =  20*60 * 1000
    let leftTime =  20*60 * 1000
    let [progress, setProgress] = useState(100)
    const [timerString, setTimerString] = useState("00:00")

    useEffect(()=>{
        if(props.endTimer){
            resetTimer()
        }
    },[props.endTimer])

    const startTimer = () => {
        timerId = setTimeout(() => {
            resetTimer()

        },  20*60 * 1000)
    }

    const resetTimer = () => {
        clearInterval(intervalTimerId)
        intervalTimerId = null

        clearTimeout(timerId)
        timerId = null
        props.onTimeEnd()
    }
    const tick = () => {
        intervalTimerId = setInterval(() => {

            leftTime = leftTime - 1000
            progress = Math.round((leftTime / totalTime) * 100)
            setProgress(progress)
            calculateTimerString()
        }, 1000)
    }

    useEffect(() => {
        tick()
        return () => clearInterval(timerId);
    }, [])

    useEffect(() => {
        startTimer()
    }, [])

    const calculateTimerString = () => {
        if (leftTime !== 0) {
            const seconds = Math.floor((leftTime / 1000) % 60);
            const minutes = Math.floor((leftTime / 1000 / 60) % 60);
            setTimerString(minutes + ":" + seconds)
        } else {
            resetTimer()

        }
    }

    return (<>
        <CircularProgressWithLabel timerString={timerString} value={progress} /><br></br>

    </>)


}

