import {
  AppBar,
  Badge,
  Button,
  Hidden,
  IconButton,
  makeStyles,
  Popover,
} from "@material-ui/core";
import { MenuOutlined } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EditUserDetails, userLogout } from "../../api/userOps";
import Accountcircleblue from "../../Assets/Accountcircleblue.svg";
import Belliconblue from "../../Assets/Belliconblue.svg";
import Logo from "../../Assets/Logo.svg";
import Signout from "../../Assets/Signout.svg";
import {
  clearNotifications,
  clearState,
  setIsSIgnedIn,
  setToken,
  setUserDetails,
  setisloadingfunction,
} from "../../redux/actions/actions";
import { clearNotificationPolling } from "../../Utilities/NotificationUtils";
import Notification from "./Notification";
import React from "react";
import { clearLoginTimer } from "../../Utilities/TokenExpireTimer";
import { FeatureEnabled } from "../../api/constants";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: "10vh",
    display: "flex",
    background: "#FCFCFC",
    alignItems: "center",
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.09)",
  },
  logo: {
    height: "5vh",
    alignSelf: "start",
    cursor: "pointer",
    paddingTop: "1.5em",
    marginTop: "10px",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  help: {
    display: "flex",
    marginRight: "50px",
    cursor: "pointer",
    alignSelf: "end",
    color: "black",
    marginTop: "-40px",
  },
}));
function Navbar(props) {
  const { userDetails } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const userRole = userDetails?.role;

  const [notificationCount, setNotificationCount] = useState(0);
  function returnToDashboard() {
    if (userRole === "fp@wardleStaff") {
      navigate("/orderlist", { state: { role: userRole } });
    } else if (
      userRole === "fp@wardleHqUser" ||
      userRole === "fp@wardleHqAdmin"
    ) {
      navigate("/orderlist", { state: { role: userRole } });
    } else {
      navigate("/createorder", { state: { role: userRole } });
    }
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function Logout(userId, email, token) {
    clearNotificationPolling();
    clearLoginTimer();

    navigate("/", { state: { SignoutNavigation: true } });
    EditUserDetails("notifications", props.notificationList)
      .then((res) => res.json(), userId, email)
      .then(() => {
        props.dispatch(clearNotifications());
      });
    props.dispatch(setisloadingfunction(true));
    userLogout(token?.AccessToken, token?.sessionId)
      .then((res) => res.json(), userId, email)
      .then(() => {
        props.dispatch(setUserDetails({}));
        props.dispatch(setIsSIgnedIn(false));

        props.dispatch(setToken({}));
        props.dispatch(clearState());
        props.dispatch(setisloadingfunction(false));
      });
  }

  const role = userDetails?.role;

  useEffect(() => {
    setNotificationCount(props.notificationList?.length);
  }, [props.notificationList]);

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <IconButton
          color="default"
          disableRipple
          edge="start"
          className={classes.logo}
          onClick={props.toggleSideBar}
        >
          <Hidden smUp implementation="css">
            <MenuOutlined />
          </Hidden>

          <img src={Logo} onClick={returnToDashboard} alt="error" />
        </IconButton>

        <div className={classes.help}>
          {role === "fp@wardleStaff" ? null : (
            <Hidden smDown implementation="css">
              <div
                onClick={() => {
                  navigate("/myprofile");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img src={Accountcircleblue} alt="error" />
                <p style={{ marginLeft: 5 }}>My Profile</p>
              </div>
            </Hidden>
          )}
          <Hidden smDown implementation="css">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "#007BB8",
                marginLeft: 30,
              }}
            >
              {FeatureEnabled("Notifications") && (
                <Button
                  aria-describedby={id}
                  disableRipple
                  style={{
                    textTransform: "none",
                    border: "0px",
                    fontColor: "#007BB8",
                    fontSize: "1em",
                    marginTop: "10px",
                  }}
                  variant="outlined"
                  onClick={handleClick}
                >
                  <Badge
                    badgeContent={notificationCount}
                    color="primary"
                    style={{ marginRight: "10px" }}
                  >
                    <img src={Belliconblue} alt="error" />
                  </Badge>
                  Notification
                </Button>
              )}
              <Popover
                id={id}
                style={{ marginTop: "20px", width: "200vh" }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Notification />
              </Popover>
            </div>
          </Hidden>
          <Hidden smDown implementation="css">
            <div
              onClick={() => {
                let userId = props.userDetails?.id;
                let email = props.userDetails?.email;
                let token = props.token;
                Logout(userId, email, token);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                marginLeft: 40,
              }}
            >
              <img src={Signout} alt="error" />
              <p style={{ marginLeft: 5 }}>Sign out</p>
            </div>
          </Hidden>
        </div>
      </AppBar>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.isSignedIn,
    userDetails: state.userDetails,
    notificationList: state.notificationList,
    token: state.token,
  };
};
Navbar.propTypes = {
  userDetails: PropTypes.object,
  notificationList: PropTypes.array,
  isSignedIn: PropTypes.bool,
  dispatch: PropTypes.func,
  token: PropTypes.object,
  toggleSideBar: PropTypes.bool,
};
export default connect(mapStateToProps)(Navbar);
