import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Card, CardContent, Typography, CardHeader , Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import CallPhrmcyIcon from '../../../Assets/CallPhmrcyIcon.svg'
// import LocationMapIcon from '../../../Assets/LocationMapIcon.svg'
import MailPhrmcyIcon from '../../../Assets/MailPhrmcyIcon.svg'
import ContactIcon from '../../../Assets/ContactIcon.svg'

const useStyles = makeStyles(() => ({
headerTitle: {
  fontWeight : 700
}
}))


function PhamacyGroupAddress({ firstName, lastName, Email, Phonenumber }) {
  const classes = useStyles()
  return (
    <Card style={{ padding: "0.5em", height: "22em", border: " 1px solid #E9E9E9" , borderRadius:'4px' }} elevation={0}>
      <CardHeader title={"Contact Details"} titleTypographyProps={{variant:'h5'}} classes={{title: classes.headerTitle}}></CardHeader>
      <CardContent>
       <Grid container style={{gap:'1em'}} direction="column">
       <Grid item container>
        <Grid item>
       <img src={ContactIcon} alt="Contact" />
        </Grid>
        <Grid item style={{marginLeft:"1em" }}>
        <Typography data-testid="fullname" variant="body2" style={{ fontWeight: 500}}>
          {firstName} {lastName}
        </Typography>
        </Grid>
        </Grid>
       <Grid item container>
       <Grid item>
       <img src={MailPhrmcyIcon} alt="Mail" />
        </Grid>
        <Grid item style={{marginLeft:"1em"}}>
        <Typography data-testid="email" variant="body2" style={{ fontWeight: 500, color: '#007BB8' }}>
          {Email}
        </Typography>
        </Grid>
        </Grid>
        <Grid item container>
          <Grid item>
        <img src={CallPhrmcyIcon} alt="Call" />
          </Grid>
          <Grid item style={{marginLeft:"1em"}}>
        <Typography data-testid="phonenumber" variant="body2" style={{ fontWeight: 500 }}>
          {Phonenumber}
        </Typography>
          </Grid>
        </Grid>
        {/* <Grid item container data-testid="full-address"> */}
          {/* <Grid item> */}
        {/* <img src={LocationMapIcon} alt="Location" /> */}
          {/* </Grid> */}
          {/* <Grid item style={{marginLeft:"1em"}}>
          <Grid item>
        <Typography  variant="body2" style={{ fontWeight: 500, wordWrap: 'break-word' }}>
          {address1}
        </Typography>
            </Grid>
            <Grid item>
        <Typography  variant="body2" style={{ fontWeight: 500, wordWrap: 'break-word' }}>
          {address2}
        </Typography>
            </Grid>
            <Grid item>
        <Typography variant="body2" style={{ fontWeight: 500, wordWrap: 'break-word' }}>
          {postCode}
        </Typography>
            </Grid>
          </Grid> */}
        {/* </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  )
}

PhamacyGroupAddress.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  Phonenumber: PropTypes.string,
  Email: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  postCode: PropTypes.string,
};

export default PhamacyGroupAddress