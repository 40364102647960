import { notificationServices } from "../api/constants";
import { getHeaders } from "../api/getHeaders";
import { getUserDetails } from "./getUserDetails";

//Function for fetching all notifications
export const getAllNotification = () => {
  let headers = { "Content-Type": "application/json" };
  const requestOptions = {
    method: notificationServices.GET_ALL_NOTIFICATIONS.method,
    headers: { ...headers, ...getHeaders() },
  };
  return fetch(
    notificationServices.GET_ALL_NOTIFICATIONS.uri?.replace(
      "#email",
      getUserDetails().email
    ),
    requestOptions
  );
};
