import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types';
import SinglePacketDropdown from './SinglePacketDropdown'


const useStyles = makeStyles({
  formControl: {
    border: '1px solid #E9E9E9',
    borderRadius: '6px'
  },
});

export default function OutlinedDropDown(props) {
  const classes = useStyles();
  const itemlist = [];
  for (let i = 1; i < 151; i++) {
    itemlist.push(<option value={i}>{i}</option>);
  }
  return (
    <>
    {props.innerformat === "Singles" ?
    <SinglePacketDropdown widthfull={true} singles={props.value} handleChangeProductdetails={e=>props.handleChange(e,props.type)}/>
    :
        <FormControl fullWidth variant="outlined" size='small' className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-native-simple">{props.label}</InputLabel>
        <Select
          native
          value={props.value}
          onChange={e=>props.handleChange(e,props.type)}
          label={props.label}
          inputProps={{
            name: `${props.label}`,
            id: 'outlined-age-native-simple',
          }}
          >
          <option aria-label="None" value="" />
          {itemlist}
        </Select>
      </FormControl>
      }
      </>
  )
}

OutlinedDropDown.propTypes = {
 label : PropTypes.string,
 type : PropTypes.string,
 handleChange : PropTypes.func,
 value: PropTypes.any
}
