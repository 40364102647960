/* eslint-disable no-useless-escape */
export const isEmpty = value => {
    if(!value) return true
    return false
}

export const isEmail = email => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const isPhoneValid = phoneNumber => {
    const re = /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;
    return re.test(phoneNumber);
}

export const isAwardscriptnumber = guid => {
    const re = /^[A-Za-z0-9]{3,}$/;
    return re.test(guid);
}

export const isPasswordValid = password => {
    const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(password);
}

export const isGuid = guid =>{
    const guidregex = /[A-Za-z0-9]{3,}-[A-Za-z0-9]{3,}-[A-Za-z0-9]{3,}/;
    return guidregex.test(guid);
}

export const isMatch = (password, cf_password, ) => {
    if(password === cf_password) return true
    return false
}

export const isMatchemail = (email, confirm_email) => {
    if(email === confirm_email) return true
    return false
}

export const isChecked = (checked) => {
    if(checked === true ) return true
    return false
}

export const isSpace = value =>{
    const spaceValidator = /^\s*$/;
    return spaceValidator.test(value)
}

export const isWhiteSpace = guid => {                //changes for white spaces in guid
    const whiteSpaceValidator = /\s/ ;   
    return whiteSpaceValidator.test(guid);
}

