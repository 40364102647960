
import { getAllNotification } from "../api/notifications";
import { setNotifications } from "../redux/actions/actions";

let notificationTimer = null
export let isRunning = false

export const setIsRunning=()=>{
  isRunning = false
}

export const getIsPollRunning=()=>{
  return isRunning
}
export const notificationPolling = (dispatch)=>{
  setIsRunning(true)
  clearNotificationPolling()
    notificationTimer = setInterval((dispatch) => {
      
        getAllNotification(dispatch).then((res)=>res.json())
        .then((data)=>{
          if(data?.notifications.length > 0)
          dispatch(setNotifications(data?.notifications))
        }
        )

      }, 3000,dispatch)

}

export const clearNotificationPolling = ()=>{
    clearInterval(notificationTimer)
    setIsRunning(false)
    notificationTimer = null
}
