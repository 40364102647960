import { Grid, Paper, Button, TextField, InputAdornment } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useState } from 'react'
import EmailIcon from '../../../Assets/Mailicongreen.svg';
import { useNavigate } from 'react-router-dom';
import { isEmpty, isEmail, isMatchemail, isSpace } from '../../../Components/validation/Validation'
import { showErrMsg, showErrMsgEmpty } from '../../../Components/notification/Notification'
import { WardleHqRegisterStart, GetUserEmailExist } from '../../../api/userOps';
import logEvent, { logEventWithProperties } from '../../../analytics/Analytics';
const initialState = {
  email: '',
  confirm_email: ''
}

export default function StartWardleHqAccountCreation() {

  const paperStyle = { padding: 10, paddingTop: 170, width: 420, margin: "auto" };
  let navigate = useNavigate();

  const [user, setUser] = useState(initialState)
  const { email, err, confirm_email, err1, } = user

  const emaillowercaser = email.toLowerCase()

  useEffect(() => {
    logEvent("HQ Register page visited")
  }, [])

  const handleChangeInput = e => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value, err: '', err1: '', success: '' })
  }
  const handleSubmit = async e => {
    e.preventDefault()
    logEventWithProperties("HQ Register emaail submitted", { email: email })

    const new_mail = email.trim();
    const new_confirm_mail = confirm_email.trim();

    if (isEmpty(email.trim()) || isEmpty(confirm_email.trim()))
      return setUser({ ...user, err: "This field is required", success: '' })

    if (isSpace(email.trim()) || isSpace(confirm_email.trim()) || !isEmail(email.trim()) || !isEmail(confirm_email.trim()))
      return setUser({ ...user, err1: 'Invalid Email', success: '' })


    if (!isMatchemail(new_mail, new_confirm_mail))
      return setUser({ ...user, err1: "Make sure your email address matches in both fields.", success: '' })

    GetUserEmailExist(emaillowercaser)
      .then((res) => res.json())
      .then((data) => {
        if (data.userExist === false) {
          return (setUser({ ...user, err1: "", success: '' },
            WardleHqRegisterStart(email)
              .then((res) => res.json())
              .then(() => {
                logEventWithProperties("HQ Register successful", { email: email })

                navigate("/hq/emailactivation", { state: { email: email } });
              },
                error => {
                  return setUser({ ...user, err: error, success: '' })
                })
          )
          )
        } else {
          setUser({ ...user, err1: "Email address already in use, try again." })
        }
      },
        error => {
          console.error('There was an error!', error);
        });
  }

  return (
    <>
      {/* <Navbar /> */}
      <Grid>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Paper elevation={0} style={paperStyle}>
            <h2 data-testid="Header" style={{ fontSize: 25, margin: 0, marginBottom: 15, textAlign: "start" }}>Add new user</h2>
            <Typography style={{ fontSize: 17, textAlign: "start" }} variant="caption">Enter the email address of the account you would like to create.</Typography>

            <TextField size="small"
              style={{ marginTop: 25, marginBottom: 15, boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background: "white" }}
              placeholder="Email address"
              variant="outlined"
              error={(isEmpty(email) && (err ? true : false)) || (isSpace(email) && (err ? true : false))}
              label={isEmpty(email) ? null : "Email address"}
              type="text" id="email"
              value={email}
              name="email"
              data-testid="input1"
              onChange={handleChangeInput}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={EmailIcon} alt="error" />
                  </InputAdornment>
                ),
              }}
            />
            <div data-testid="error">
              {(isEmpty(email) && showErrMsgEmpty(err)) || (isSpace(email) && showErrMsgEmpty(err))}
            </div>
            <TextField size="small"
              style={{ marginBottom: 15, boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background: "white" }}
              placeholder="Confirm email address"
              variant="outlined"
              data-testid="input2"
              error={(isEmpty(confirm_email) && (err ? true : false)) || (isSpace(confirm_email) && (err ? true : false))}
              label={isEmpty(confirm_email) ? null : "Confirm email address"}
              type="text" id="confirm_email"
              value={confirm_email}
              name="confirm_email"
              onChange={handleChangeInput}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={EmailIcon} alt="error" />
                  </InputAdornment>
                ),
              }}
            />
            <div data-testid="error">
              {(isEmpty(confirm_email) && showErrMsgEmpty(err)) || (isSpace(confirm_email) && showErrMsgEmpty(err))}
            </div>
            <div data-testid="error1">
              {err1 && showErrMsg(err1, () => {
                setUser({ ...user, err1: '' })
              })}
            </div>

            {/* {err1 && showErrMsg(err1)} */}
            <Button data-testid="btn" variant="contained" fullWidth size="large" disableElevation style={{ background: "#3D8541", color: "white", marginTop: 10, fontSize: "12px", textTransform: "none" }} type="submit" >Confirm</Button>
          </Paper>
        </form>
      </Grid>

    </>
  )
}
