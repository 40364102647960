/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Grid, Box, Modal, Paper, TextField, Typography, Button, InputAdornment, Checkbox } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import Question from '../../../Assets/questionmark.svg';
import ArrowDown from '../../../Assets/Arrowdown.svg';
import ArrowUp from '../../../Assets/Arrowup.svg'
import { isPasswordValid, isMatch, isEmpty, isSpace, isPhoneValid } from '../../../Components/validation/Validation'
import { showErrMsgEmpty, showErrMsg } from '../../../Components/notification/Notification';
import Lockgreen from '../../../Assets/Lockgreen.svg';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { subscribeToKlaviyo, WardleHqRegisterComplete } from '../../../api/userOps';
import AccountCircleOutlinedgreen from "../../../Assets/AccountCircleOutlinedgreen.svg";
import CallIcon from "../../../Assets/CallIcon.svg";
import './completeregistrationhq.css'
import { VerifyRegisteredEmail } from '../../../api/userOps';
import logEvent, { logEventWithProperties, setUserGroup, setUserId } from '../../../analytics/Analytics';


function CompleteWardleStaffAccountCreation() {
  let navigate = useNavigate();
  const { rnd, email, token, firstNameIn, lastNameIn, phoneNumberIn, pharmacyGroupAccountNumberIn, pharmacyGroupNameIn } = useParams();
  const initialState = {
    password: '',
    cf_password: '',
    err: '',
    err1: '',
    err2: '',
    success: '',
    showPassword: false,
    showConfirmPassword: false,
    firstName: firstNameIn ? firstNameIn : '',
    lastName: lastNameIn ? lastNameIn : '',
    phoneNumber: phoneNumberIn ? phoneNumberIn : '',
    pharmacyGroupName: pharmacyGroupNameIn ? pharmacyGroupNameIn.replaceAll('+', ' ') : '',
    pharmacyGroupAccountNumber: pharmacyGroupAccountNumberIn ? pharmacyGroupAccountNumberIn : ''
  }


  useEffect(() => {
    logEvent("Verify HQ user visited")
  }, [])
  const handleClickShowPassword = () => {
    setUser({
      ...user,
      showPassword: !user.showPassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setUser({
      ...user,
      showConfirmPassword: !user.showConfirmPassword,
    });
  };

  const paperStyle = { padding: 10, paddingTop: 100, width: 400, margin: "auto" };
  const [open, setOpen] = React.useState(false);
  const [checkbox, setCheckbox] = useState(false)

  const handleChangeCheckbox = () => {
    setCheckbox(!checkbox)
  }

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 450, bgcolor: 'background.paper', boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)", borderRadius: 3, p: 3, };

  const [user, setUser] = useState(initialState)
  const { password, cf_password, err, err1, err2, firstName, lastName, phoneNumber, pharmacyGroupName, pharmacyGroupAccountNumber } = user

  const handleChangeInput = e => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value, err: '', success: '' })
  }

  useEffect(() => {
    VerifyRegisteredEmail(email)
        .then((res)=>res.json())
        .then((data)=>{
          if(data.registration_status==="registered"){
            navigate("/register/alreadyregistered")
          }
        })
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()


    if (isEmpty(firstName) || isEmpty(lastName) || isEmpty(phoneNumber) || isEmpty(pharmacyGroupName) || isEmpty(pharmacyGroupAccountNumber))
      return setUser({ ...user, err: "This field is required", err1: "Please check the information above is correct", success: '' })

    if (isSpace(firstName) || isSpace(lastName) || isSpace(phoneNumber) || isSpace(pharmacyGroupName) || isSpace(pharmacyGroupAccountNumber)) {
      return setUser({ ...user, err: "This field is required", err1: "Please check the information above is correct", err2: "Please provide a valid phone number ", success: '' })
    }

    if (isEmpty(password) && isEmpty(cf_password))
      return setUser({ ...user, err: "This field is required", err1: "Please enter your password and confirm password before continuing.", success: '' })
    if (isEmpty(password))
      return setUser({ ...user, err: "This field is required", err1: "Please enter your password before continuing.", success: '' })
    if (isEmpty(cf_password))
      return setUser({ ...user, err: "This field is required", err1: "Please enter your confirm password before continuing.", success: '' })

    if (!isPhoneValid(phoneNumber)) {
      return setUser({ ...user, err2: "Please provide a valid phone number", err1: "Please check the information above is correct.", success: '' })
    }

    if (!isPasswordValid(password))
      return setUser({ ...user, err1: "Make sure your password is at least 8 characters long and contains upper and lowercase letters, numbers and symbols.", success: '' })

    if (!isPasswordValid(cf_password))
      return setUser({ ...user, err1: "Make sure your password is at least 8 characters long and contains upper and lowercase letters, numbers and symbols.", success: '' })

    if (!isMatch(password, cf_password))
      return setUser({ ...user, err1: "Make sure your password matches in both fields.", success: '' })
    logEventWithProperties("HQ user details submitted", {
      email: email,
      role:"HQ user",
      pharmacyGroupAccountNumber: pharmacyGroupAccountNumber,
      receiveOffer: checkbox
    })
    WardleHqRegisterComplete(email, firstName, lastName, password, phoneNumber, pharmacyGroupName, pharmacyGroupAccountNumber, checkbox, rnd, token)
      .then((res) => res.json())
      .then((data) => {
        if (data.message != "Account created successfully") {
          logEventWithProperties("HQ user account creation failed", {
            email: email,
            role:"HQ user",
            pharmacyGroupAccountNumber: pharmacyGroupAccountNumber,
            receiveOffer: checkbox
        })
        if (data.error?.errorCode === "TokenAlreadyUsed") {
          logEventWithProperties("Hq register magic link revisited", { email: email })
          navigate("/register/alreadyregistered")
        } if (data.error === "pharmacy group not present") {                 
          logEventWithProperties("Hq register failed due to invalid pharmacy group details", {
            email: email,
            pharmacyGroupAccountNumber: pharmacyGroupAccountNumber
          })
          return setUser({ ...user, err: data.error, err1: data.error, success: '' })
        }
          return setUser({ ...user, err: data.error.message, success: '' })
        }
        else {
          checkbox && subscribeToKlaviyo({ email: email, first_name: firstName, last_name: lastName })
          logEventWithProperties("HQ user account creation successful", {
            email: email,
            role:"HQ user",
            pharmacyGroupAccountNumber: pharmacyGroupAccountNumber,
            receiveOffer: checkbox
          
          })
          setUserGroup("fp@wardleHqAdmin")
          setUserId(email)
          navigate("/hq/register/successfull", { state: { email: email, password: password } });
        }
      },
        error => {
          console.error('There was an error!', error);
        });
  }


  return (
    <>
      <Grid>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Paper elevation={0} style={paperStyle} sx={{ textAlign: "center" }}>

            <h2 data-testid="Header" style={{ fontSize: 25, margin: 0, marginBottom: 20, marginTop: 20, textAlign: "left" }}>Your details</h2>
            <p style={{ fontSize: 17, }}>Enter your details and choose a password to keep your data secure.</p>

            <TextField
              size="small"
              style={{ marginTop: '5%', marginBottom: '5%', boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background: "white" }}
              className="Textfields"
              placeholder="Pharmacy group name"
              error={(isEmpty(pharmacyGroupName) && (err ? true : false)) || (isSpace(pharmacyGroupName) && (err ? true : false))}
              variant="outlined"
              label={isEmpty(pharmacyGroupName) ? null : "Pharmacy group name"}
              type="text"
              id="pharmacyGroupName"
              value={pharmacyGroupName}
              name="pharmacyGroupName"
              data-testid="pharmacyGroupName"
              onChange={handleChangeInput}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={AccountCircleOutlinedgreen} alt="error" />
                  </InputAdornment>
                ),
              }}
            />
            <div data-testid="error">
              {(isEmpty(pharmacyGroupName) && showErrMsgEmpty(err)) || (isSpace(pharmacyGroupName) && showErrMsgEmpty(err))}
            </div>
            <TextField
              size="small"
              style={{ marginBottom: '5%', boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background: "white" }}
              className="Textfields"
              placeholder="Pharmacy group account number"
              error={(isEmpty(pharmacyGroupAccountNumber) && (err ? true : false)) || (isSpace(pharmacyGroupAccountNumber) && (err ? true : false))}
              variant="outlined"
              label={isEmpty(pharmacyGroupAccountNumber) ? null : "Pharmacy group account number"}
              type="text"
              id="pharmacyGroupAccountNumber"
              value={pharmacyGroupAccountNumber}
              data-testid="pharmacyGroupAccountNumber"
              name="pharmacyGroupAccountNumber"
              onChange={handleChangeInput}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={AccountCircleOutlinedgreen} alt="error" />
                  </InputAdornment>
                ),
              }}
            />
            <div data-testid="error">
              {(isEmpty(pharmacyGroupAccountNumber) && showErrMsgEmpty(err)) || (isSpace(pharmacyGroupAccountNumber) && showErrMsgEmpty(err))}
            </div>


            <TextField
              size="small"
              style={{ marginBottom: '5%', boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background: "white" }}
              className="Textfields"
              placeholder="First name"
              error={(isEmpty(firstName) && (err ? true : false)) || (isSpace(firstName) && (err ? true : false))}
              variant="outlined"
              label={isEmpty(firstName) ? null : "First name"}
              type="text"
              id="firstName"
              data-testid="firstName"
              value={firstName}
              name="firstName"
              onChange={handleChangeInput}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={AccountCircleOutlinedgreen} alt="error" />
                  </InputAdornment>
                ),
              }}
            />
            <div data-testid="error">
              {(isEmpty(firstName) && showErrMsgEmpty(err)) || (isSpace(firstName) && showErrMsgEmpty(err))}
            </div>
            <TextField
              size="small"
              className="Textfields"
              style={{ marginBottom: '5%', boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background: "white" }}
              placeholder="Last name"
              variant="outlined"
              error={(isEmpty(lastName) && (err ? true : false)) || (isSpace(lastName) && (err ? true : false))}
              label={isEmpty(lastName) ? null : "Last name"}
              type="text"
              id="lastName"
              data-testid="lastName"
              value={lastName}
              name="lastName"
              onChange={handleChangeInput}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={AccountCircleOutlinedgreen} alt="error" />
                  </InputAdornment>
                ),
              }}
            />
            <div data-testid="error">
              {(isEmpty(lastName) && showErrMsgEmpty(err)) || (isSpace(lastName) && showErrMsgEmpty(err))}
            </div>
            <TextField
              size="small"
              className="Textfields"
              placeholder="Phone number"
              style={{ marginBottom: '5%', boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background: "white" }}
              error={
                (isEmpty(phoneNumber) && (err ? true : false)) ||
                (!isPhoneValid(phoneNumber) && (err2 ? true : false))
              }
              variant="outlined"
              label={isEmpty(phoneNumber) ? null : "Phone number"}
              type="text"
              id="phoneNumber"
              data-testid="phoneNumber"
              value={phoneNumber}
              name="phoneNumber"
              onChange={handleChangeInput}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={CallIcon} alt="error" />
                  </InputAdornment>
                ),
              }}
            />
            <div data-testid="error">
              {(isEmpty(phoneNumber) && showErrMsgEmpty(err)) ||
                (!isPhoneValid(phoneNumber) && showErrMsgEmpty(err2))}
            </div>
            <TextField size="small"
              error={isEmpty(password) && (err ? true : false)}
              style={{ marginBottom: '5%', boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background: "white" }}
              placeholder="Password"
              variant="outlined"
              fullWidth
              type={user.showPassword ? 'text' : 'password'}
              id="password"
              data-testid="password"
              value={password} name="password" onChange={handleChangeInput}
              label={isEmpty(password) ? null : "Password"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={Lockgreen} alt="error" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {user.showPassword ? <Visibility style={{ color: "#A8D7AB", height: 19, width: 19 }} /> : <VisibilityOff style={{ color: "#A8D7AB", height: 19, width: 19 }} />}
                    </IconButton>
                  </InputAdornment>)
              }}
            />
            <div data-testid="error">
              {isEmpty(password) && showErrMsgEmpty(err)}
            </div>
            <TextField size="small"
              error={isEmpty(cf_password) && (err ? true : false)}
              style={{ marginBottom: '5%', boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background: "white" }}
              placeholder="Confirm Password"
              type={user.showConfirmPassword ? 'text' : 'password'}
              label={isEmpty(cf_password) ? null : "Confirm Password"}
              variant="outlined"
              fullWidth
              id="cf_password"
              data-testid="CfPassword"
              value={cf_password} name="cf_password" onChange={handleChangeInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={Lockgreen} alt="error" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      // onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {user.showConfirmPassword ? <Visibility style={{ color: "#A8D7AB", height: 19, width: 19 }} /> : <VisibilityOff style={{ color: "#A8D7AB", height: 19, width: 19 }} />}
                    </IconButton>
                  </InputAdornment>)
              }}
            />
            <div data-testid="error">
              {isEmpty(cf_password) && showErrMsgEmpty(err)}
            </div>

            <div className="checkboxcontainer body-2">
              <p>
                I would like to receive health advice and offers from Wardles by
                email. (You can change your marketing preferences at any time).
              </p>
              <Checkbox
                checked={checkbox}
                style={{ padding: 0, marginRight: 5, color: "#007BB8", }}
                className='checkbox'
                onChange={handleChangeCheckbox}
              />
            </div>

            <Button onClick={handleOpen} variant="contained" size="large" disableElevation style={{ background: "#E8F8FF", color: "#0066BE", borderRadius: '6px', fontSize: "18px", textTransform: "none", display: "flex", justifyContent: "space-between", border: "1px solid #7DD4FF" }} fullWidth>
              <div style={{ display: "flex", justifyContent: "flex-start", margin: 0, padding: 0 }}>
                <img src={Question} alt="error" />
                <p style={{ margin: 0, padding: 0, marginLeft: '10px', fontSize: 16 }}>Help setting a strong password</p>
              </div>
              <img src={ArrowDown} alt="error" />
            </Button>
            <div data-testid="error1">
              {err1 && showErrMsg(err1, () => {
                setUser({ ...user, err1: '' })
              })}
            </div>

            <Grid align="center">
              <br />
              <Button data-testid="btn" variant="contained" fullWidth size="large" disableElevation style={{ background: "#3D8541", color: "white", fontSize: "12px", textTransform: "none" }} type="submit" >Confirm my details</Button>

            </Grid>

            {/* Modal */}

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} align="justify" style={{ background: '#EBF8FF', border: "1px solid #7DD4FF" }}>

                <Typography id="modal-modal-title" variant="h6" component="h2">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", justifyContent: "flex-start", margin: 0, padding: 0 }}>
                      <img src={Question} width="15" height="25" alt="error" />
                      <p style={{ margin: 0, padding: 0, marginLeft: 5, fontSize: "15px", color: "#007BB8", fontWeight: "bold" }}>Help setting a strong password</p>
                    </div>
                    <img src={ArrowUp} onClick={handleClose} width="20" height="15" alt="error" />
                  </div>
                </Typography>
                <Typography id="modal-modal-description" style={{ marginTop: 22, marginBottom: 22, fontSize: "14px", color: "#222222", lineHeight: "130%" }}>
                  Strong passwords are unique, at least 8 characters long and contain upper and lowercase letters, numbers and symbols.
                </Typography>
                <Typography id="modal-modal-description" style={{ marginBottom: 22, fontSize: "14px", lineHeight: "130%", color: "#222222" }}>
                  Try swapping letters for numbers or symbols to make your password harder to guess. For example, instead of S try using $.
                </Typography>
                <Typography id="modal-modal-description" style={{ marginBottom: 22, fontSize: "14px", lineHeight: "130%", color: "#222222" }}>
                  You could also try using the first letter of each word from your favourite song lyric to create a word that won't be obvious to other people.
                </Typography>
                <Typography id="modal-modal-description" style={{ fontSize: "14px", lineHeight: "130%", color: "#222222" }}>
                  Avoid using personal details other people might know or common words like 'password'.
                </Typography>
              </Box>
            </Modal>
          </Paper>
        </form>
      </Grid>

    </>
  );
}

export default CompleteWardleStaffAccountCreation;



