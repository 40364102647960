import React from "react";
import { TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import RangeSearch from "../RangeSearchComponent/RangeSearch";
import { isEmail, isEmpty, isPhoneValid } from "../validation/Validation";


function index(props) {
  // const [itemsList, setItemsList] = useState([]);
  // const [error, setError] = useState("");
  // function searchItems(value) {
  //   if (value === "") {
  //     setItemsList([]);
  //   } else {
  //     searchRange(value)
  //       .then((res) => res.json())
  //       .then((res) => {
  //         if (res.data.length === 0) {
  //           setError("Item not found.");
  //           setItemsList([]);
  //         } else {
  //           const filteredList = res.data.filter(
  //             (x) => x.isDiscontinued != true && x.isRestricted != true
  //           );
  //           if (filteredList.length > 0) {
  //             setError("");
  //             setItemsList(filteredList.slice(0, 10));
  //           } else {
  //             setError("Item not found.");
  //             setItemsList([]);
  //           }
  //         }
  //       });
  //   }
  // }
  return (
    <>
      {props.format === "normal" && (
        <TextField
          size="small"
          variant="outlined"
          error={(props.err && isEmpty(props.value))||(props.err && props.type==="pharmacyPhoneNumber" && !isPhoneValid(props.value))||(props.err && props.type==="email" && !isEmail(props.value))}
          label={props.text}
          placeholder={props.placeholder ? props.placeholder : ''}
          type="text"
          id={props.type}
          value={props.value}
          disabled={props.disable}
          name={props.type}
          data-testid={props.type}
          helperText={(props.err && isEmpty(props.value) && "Please fill all details")||(props.err && props.type==="pharmacyPhoneNumber" && !isPhoneValid(props.value) && "Please give valid phone number")||(props.err && props.type==="email" && !isEmail(props.value) && "Please give valid email address")}
          onChange={e=>props.handleChange(e,props.type,props.format)}
          fullWidth
          style={{
            boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
            marginTop: "1em",
          }}
          //   InputProps={ startIcon ? {
          //       startAdornment: (
          //           <InputAdornment position="start">
          //         <img src={ startIcon} alt="error" />
          //       </InputAdornment>
          //     )
          //   } : {}}
        />
      )}
      {props.format === "date" && (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disablePast 
          name="Date"
          fullWidth
          autoOk
          variant="inline"
          inputVariant="outlined"
          size='small'
          label={props.text}
          style={{ marginTop:"16px"}}
          format="MM-yyyy"
          value={props.value}
          views={['year', 'month']}
          InputAdornmentProps={{ position: "end" }}
          minDate={props.minDate}
          onChange={e=>props.handleChange(e,props.type,props.format)}
          KeyboardButtonProps={{
            "data-testid": "Date" }}
        />
      </MuiPickersUtilsProvider>
      )}
      {props.format === "autocomplete" && (
        <RangeSearch onItemClick={(item)=>{props.handleChange(item?.prodDesc,props.type,props.format)}} item={{prodDesc:props.value}}/>
        
      )}
    </>
  );
}

export default index;
