import React  from 'react';
import {
    CircularProgress,Box,Typography
} from "@material-ui/core";

export default function CircularProgressWithLabel(props){
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" value={props.value} />
        <Box
          style={{
            top: 0,
            left: 5,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{props.timerString}</Typography>
        </Box>
      </Box>
    );
  }