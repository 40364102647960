import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import { reSetOrderFilter, reSetUserFilter, setisOrderFilterApplied, setisUserFilterApplied, setOrderFilter, setUserFilter } from "../../redux/actions/actions";
import PropTypes from 'prop-types';
import FilterMonthComponent from "./FilterMonthComponent";

const useStyles = makeStyles((theme)=>({
  dialog: {
    border: "2px solid #E9E9E9",
    boxShadow: "2px 2px 7px rgba(205, 205, 205, 0.5)",
    borderRadius: "20px",
  },
  filterbutton: {
    marginRight: "2em",
    textTransform: "none",
    backgroundColor: "#fff",
    borderRadius: "15px",
    border: "2px solid #007BB8",
    width: 120,
    height: 75,
  },
  title: {
    fontWeight: 700,
    lineHeight: "120%",
    letterSpacing: "0.005em",
  },
  resetfilter: {
    color: "#007BB8",
    cursor: "pointer",
    textDecoration: "underline",
  },
  applyFilter: {
    backgroundColor: "#3D8541",
    boxShadow: "0px 2px 0px #306B33, inset 0px 1px 0px #62B767",
    borderRadius: "4px",
    textTransform: "none",
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: "#3D8541",
    },
    [theme.breakpoints.up('xs')]: {
      marginLeft:"0.5rem",
    },
  },
  cancelButton:{
    textTransform: "none",
    color: "#007BB8",
    fontWeight: 600,
    [theme.breakpoints.up('xs')]: {
      marginRight:"0.5rem",
      width: "100%",
  },
  },
    applyFilterRow: {
      marginBottom:"1rem",
      [theme.breakpoints.up('xs')]: {
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        width:"94%",
    },
      [theme.breakpoints.only('xs')]: {
        display:"grid",
        justifyContent:"center",
        // align:"center"
    }
  
  },
}));

const Order_Status = [
  'Select',
  "Ordered",
  "Order delivered",
  "Prescription received",
  "Prescription switched to trade",
  "prescription complete ",
  "Request submitted",
  "Return to nhs spine",
  "Order cancelled",
  "Request cancelled",
  "Please contact wardles",
  "Request Received",
  "Attempted to call - item OOS",
  "Attempted to call - to follow query",
  "Attempted to call - order cancelled",
]

const roleofuser = ["Select", "Standard", "Admin"];

const statusofuser = ["Select", "Registered", "Link sent"];

function FilterDialog(props) {
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const [state, setState] =
    props.recordType === "user"
      ? useState(props.userFilters)
      : useState(props.orderFilters);

  const handleResetClick = () => {
    props.handleresetfilter();
    if (props.recordType === "user") {
      props.dispatch(reSetUserFilter())
      props.dispatch(setisUserFilterApplied(false))
    } else {
      props.dispatch(reSetOrderFilter())
      props.dispatch(setisOrderFilterApplied(false))

    }
  };

  const applyFilter = () => {
    if (props.recordType === "user") {
      props.dispatch(setisUserFilterApplied(true));
      props.dispatch(setUserFilter(state));
    }
    else {
      props.dispatch(setOrderFilter(state))
      props.dispatch(setisOrderFilterApplied(true))
    }
    props.handleSubmit()
    setOpen(false)
  }


  const handleChangestatus = (event, name) => {
    setState({
      ...state,
      [name]: event.target.value !== "Select" ? event.target.value : "",
    });
  };
  const handleChangedate = (event) => {
    const datenew = new Date(event);
    setState({ ...state, date: datenew, isDateChanged: true });
  };

  return (
    <React.Fragment>
      <Dialog open={open} fullWidth classes={{ paper: classes.dialog }}>
        <Grid
          container
          style={{ padding: "2em" }}
          direction="row"
          wrap="nowrap"
        >
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              Filter by
            </Typography>
          </Grid>
          <Grid item style={{ marginLeft: "auto" }}>
            <Typography
              variant="body2"
              onClick={handleResetClick}
              className={classes.resetfilter}
            >
              Reset Filter
            </Typography>
          </Grid>
        </Grid>
        <DialogContent>
          {props.recordType === "user" && (
            <Grid container justify="space-between">
              <Grid item xs={12} md={6} sm={12}>
                <FormControl variant="outlined" fullWidth style={{marginBottom:".5rem"}}>
                  <InputLabel htmlFor="outlined-age-native-simple-pharmacy">
                    Role
                  </InputLabel>
                  <Select
                    native
                    value={state.role}
                    onChange={(e) => handleChangestatus(e, "role")}
                    label="Role"
                  >
                    {roleofuser.map((names, index) => {
                      let value =
                        names === "Standard"
                          ? "fp@wardleUser"
                          : names === "Admin"
                          ? "fp@wardleAdmin"
                          : "Select";

                      return (
                        <option
                          value={value}
                          hidden={names === "Select"}
                          key={index}
                        >
                        {names}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} sm={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-age-native-simple">
                    User status
                  </InputLabel>
                  <Select
                    native
                    value={state.status}
                    title={"user_status"}
                    onChange={(e) => handleChangestatus(e, "status")}
                    label="User status"
                  >
                    {statusofuser.map((names, index) => {
                      return (
                        <option
                          value={`${names}`}
                          hidden={names === "Select"}
                          key={index}
                        >
                          {names}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}



          {props.recordType === "order" && <Grid container justify="space-between">
            <Grid item xs={12} md={6} sm={12}>
              <FormControl variant="outlined" style={{ width: "100%", marginBottom:".5rem" }}>
                <InputLabel htmlFor="outlined-age-native-simple">
                  Order Status
                </InputLabel>
                <Select
                  native
                  value={state.status}
                  onChange={(e) => handleChangestatus(e, "status")}
                  label="Order status"
                  inputProps={{
                    name: `${state.status}`,
                    id: "outlined-age-native-simple",
                  }}
                >
                  {Order_Status.map((names, index) => {
                    return (
                      <option value={`${names}`} hidden={names === 'Select'} key={index}>{names}</option>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} sm={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <KeyboardDatePicker
                  style={{ width: "100%" }}
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format="dd-MM-yyyy"
                  value={state.date}
                  InputAdornmentProps={{ position: "start" }}
                  onChange={(date) => handleChangedate(date)}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>}

          {props.recordType === "commission_statement" &&
          <Grid container justifyContent="center" >
          <FilterMonthComponent
            onSelect={()=>{props.onDateSelect()}}
            filterClicked={props.calendarOpen}
          />
          </Grid>
          }
        </DialogContent>
        <DialogActions fullWidth style={{display:"flex", justifyContent:"center", alignItems:"center"}} >
          <Grid className={classes.applyFilterRow}>
              <Button
                size="large"
                className={classes.cancelButton}
                onClick={() => {
                  setOpen(false);
                  props.onClose();
                }}
              >
                cancel
              </Button>
              <Button
                size="large"
                className={classes.applyFilter}
                type="submit"
                onClick={() => {
                  applyFilter();
                }}
              >
                Apply filter
              </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  return {
    userFilters: state.userFilters,
    orderFilters: state.orderFilters,
    isUserFilterApplied: state.isUserFilterApplied,
    isOrderFilterApplied: state.isOrderFilterApplied,
  };
};

FilterDialog.propTypes = {
  recordType: PropTypes.string,
  userFilters: PropTypes.object,
  orderFilters: PropTypes.object,
  handleresetfilter: PropTypes.func,
  dispatch: PropTypes.func,
  handleSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onDateSelect:PropTypes.func,
  calendarOpen:PropTypes.func
};

export default connect(mapStateToProps)(FilterDialog);
