import { Button, Grid, makeStyles, Paper, TextField } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/KeyboardArrowLeft";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logEventWithProperties } from "../../analytics/Analytics";
import { EditUserDetails } from "../../api/userOps";
import ArrowDown from "../../Assets/Arrowdown.svg";
import Question from "../../Assets/questionmark.svg";
import { InformationModal } from "../../Components/Modal/InformationModal";
import { showSuccessmsg } from "../../Components/notification/Notification";
import { triggerInAppNotifications } from "../../redux/actions/actions";
const useStyles = makeStyles((theme) => ({
  arrowBack: {
    cursor: "pointer",
    textDecoration: "none",
    color: "#5E5E5E",
    display: "flex",
    alignItems: "center",
    margin: "0",
    [theme.breakpoints.only("xs")]: {
      justifyContent: "left",
      paddingLeft: 0,
    },
    [theme.breakpoints.only("sm")]: {
      justifyContent: "left",

      paddingTop: "20px",
      paddingLeft: 0,
    },
    [theme.breakpoints.only("xs")]: {
      justifyContent: "left",
      paddingLeft: 0,

      paddingTop: "80px",
    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: (props) => (props ? 150 : 300),

      paddingTop: "20px",
    },
    [theme.breakpoints.only("lg")]: {
      paddingLeft: (props) => (props ? 150 : 300),
    },
    [theme.breakpoints.only("xl")]: {
      paddingLeft: (props) => (props ? 150 : 300),
    },
  },
}));

function Edituser(props) {
  let userDetails = props.userDetails;
  let firstNamels = userDetails?.first_name;
  let lastNamels = userDetails?.last_name;
  let emaills = userDetails?.email;
  const initialState = {
    firstName: firstNamels,
    lastName: lastNamels,
    email: emaills,
    err: "",
    err1: "",
    success: "",
  };

  let navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = useStyles(props);
  const paperStyle = {
    padding: 10,
    paddingTop: 20,
    width: 360,
    margin: "auto",
  };
  const [user, setUser] = useState(initialState);
  const { firstName, lastName, email, err1, success } = user; // eslint-disable-line no-unused-vars
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value, err: "" });
  };
  const modaltext = [
    {
      value:
        "If you have the incorrect pharmacy account number or phone number saved to your profile contact us using the customer support link below.",
    },
  ];

  function changeUserDetails() {
    if (firstNamels != user.firstName && lastNamels == user.lastName) {
      EditUserDetails("user_search_id",userDetails.user_search_id.replace(userDetails.first_name.toLowerCase(),user.firstName.toLowerCase()))
        .then((res) => res.json())
        .then((data) => {
          if (data.modifyUser.statusCode === 200) {
            logEventWithProperties("User details edited and update on profile",userDetails?.id)
            console.log("userDetails", userDetails);
            EditUserDetails("first_name", user.firstName)
              .then((res) => res.json())
              .then((data) => {
                if (data.modifyUser.statusCode === 200) {
                  props.dispatch(
                    triggerInAppNotifications(["User details updated"])
                  );

                  setUser({
                    ...user,
                    err: "",
                    success: "We’ve updated your details.",
                  });
                  userDetails.first_name = user.firstName;
                }
              });
          }
        });
    } else if (firstNamels == user.firstName && lastNamels != user.lastName) {
      EditUserDetails("user_search_id",userDetails.user_search_id.replace(userDetails.last_name.toLowerCase(),user.lastName.toLowerCase()))
        .then((res) => res.json())
        .then((data) => {

          if (data.modifyUser.statusCode === 200) {
          logEventWithProperties("User details edited and update on profile",userDetails?.id)

            EditUserDetails("last_name", user.lastName)
              .then((res) => res.json())
              .then((data) => {
                if (data.modifyUser.statusCode === 200) {
                  props.dispatch(
                    triggerInAppNotifications(["User details updated"])
                  );

                  setUser({
                    ...user,
                    err: "",
                    success: "We’ve updated your details.",
                  });
                  userDetails.last_name = user.lastName;
                }
              });
          }
        });
    } else {
      EditUserDetails("user_search_id",userDetails.user_search_id.replace(userDetails.last_name.toLowerCase(), user.lastName.toLowerCase()).replace(userDetails.first_name.toLowerCase(), user.firstName.toLowerCase()))
        .then((res) => res.json())
        .then((data) => {
          if (data.modifyUser.statusCode === 200) {
          logEventWithProperties("User details edited and update on profile",userDetails?.id)

            EditUserDetails("first_name", user.firstName)
              .then((res) => res.json())
              .then((data) => {
                if (data.modifyUser.statusCode === 200) {
                  EditUserDetails("last_name", user.lastName)
                    .then((res) => res.json())
                    .then((data) => {
                      if (data.modifyUser.statusCode === 200) {
                        props.dispatch(
                          triggerInAppNotifications(["User details updated"])
                        );

                        setUser({
                          ...user,
                          err: "",
                          success: "We’ve updated your details.",
                        });
                        userDetails.first_name = user.firstName;
                        userDetails.last_name = user.lastName;
                      }
                    });
                }
              });
          }
        });
    }
  }

  return (
    <>
      <div className={classes.arrowBack}>
        <button
          data-testid="back-button"
          onClick={() => navigate("/myprofile")}
          className="backbuttonstyle2"
        >
          <ArrowBackIcon />
          <p>Back</p>
        </button>
      </div>
      <Grid>
        <Paper elevation={0} style={paperStyle}>
          <div data-testid="error">{success && showSuccessmsg(success)}</div>
          <h2
            data-testid="Header"
            style={{
              fontSize: 25,
              margin: 0,
              marginBottom: 15,
              textAlign: "start",
            }}
          >
            Edit your details
          </h2>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              size="small"
              style={{
                boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)",
                background: "white",
                width: "48%",
              }}
              variant="outlined"
              label="First name"
              type="text"
              id="firstName"
              data-testid="firstName"
              value={firstName}
              name="firstName"
              onChange={handleChangeInput}
            />
            <TextField
              size="small"
              style={{
                boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)",
                background: "white",
                width: "48%",
              }}
              variant="outlined"
              label="Last name"
              type="text"
              id="lastName"
              data-testid="lastName"
              value={lastName}
              name="lastName"
              onChange={handleChangeInput}
            />
          </div>

          <TextField
            size="small"
            style={{
              marginTop: "22px",
              marginBottom: "14px",
              boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)",
              background: "white",
            }}
            variant="outlined"
            label="Email address"
            type="text"
            id="email"
            value={email}
            name="email"
            disabled
            fullWidth
          />
          <Button
            onClick={handleOpen}
            variant="contained"
            size="large"
            disableElevation
            style={{
              background: "#E8F8FF",
              color: "#0066BE",
              borderRadius: "6px",
              marginTop: "Auto",
              fontSize: "18px",
              textTransform: "none",
              display: "flex",
              justifyContent: "space-between",
              border: "1px solid #7DD4FF",
            }}
            fullWidth
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                margin: 0,
                padding: 0,
              }}
            >
              <img src={Question} alt="error" />
              <p
                style={{ margin: 0, padding: 0, marginLeft: 10, fontSize: 14 }}
              >
                Need to change your pharmacy details?
              </p>
            </div>
            <img src={ArrowDown} alt="error" />
          </Button>

          <Grid align="center">
            <Button
              data-testid="btn"
              variant="contained"
              fullWidth
              size="large"
              onClick={changeUserDetails}
              disableElevation
              style={{
                background: "#3D8541",
                color: "white",
                marginTop: "22px",
                fontSize: "14px",
                textTransform: "none",
                borderRadius: "2px",
              }}
            >
              Save changes
            </Button>
            <Button
              data-testid="cancelbtn"
              variant="outlined"
              fullWidth
              size="large"
              onClick={() => {
                navigate("/myprofile");
              }}
              disableElevation
              style={{
                background: "#white",
                color: "#222222",
                border: "2px solid #222222",
                fontWeight: 600,
                marginTop: "22px",
                fontSize: "14px",
                textTransform: "none",
                borderRadius: "4px",
              }}
            >
              Cancel
            </Button>
          </Grid>

          {/* Modal */}
          <InformationModal
            handleClose={handleClose}
            open={open}
            heading={"Need to change your pharmacy details?"}
            modaltext={modaltext}
          />
        </Paper>
      </Grid>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.isSignedIn,
    userDetails: state.userDetails,
  };
};

Edituser.propTypes = {
  userDetails: PropTypes.object,
  dispatch: PropTypes.func,
};
export default connect(mapStateToProps)(Edituser);
