import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { connect } from 'react-redux';
import EditIcon from "@material-ui/icons/Edit";
import { GetPharmacynamebyAccountnumber } from "../../api/pharmacyops"
import { useNavigate } from "react-router-dom";
import ListComponent from "../../Components/ListComponent/ListComponent";
import PropTypes from 'prop-types';

function ManageUsers(props) {
  const { userDetails,userFilters } = props
  const role = userDetails?.role;
  let navigate = useNavigate();
  const [data1, setData1] = useState("")

  function fetchData() {
    if(role === "fp@wardleAdmin" || role === "fp@wardleUser") {
    GetPharmacynamebyAccountnumber(userDetails?.pharmacy_account_number)
      .then((res) => res.json())
      .then((data) => {
        if (data.pharmacyGroupName) {
          setData1(data.pharmacyGroupName)
        } else {
          setData1("N/A")
        }
      });
    }
  }
  useEffect(() => {
    fetchData();
  }, [])

  function manageuserhead(){
    return(
      <>
      {role === "fp@wardleAdmin" ? <Grid style={{marginLeft:"auto"}}>
      <Typography className="text" style={{ marginBottom: 3 }}>
        Pharmacy group: {data1}
      </Typography>
      <Typography className="text" style={{ marginBottom: 3 }}>
        Account number: {userDetails?.pharmacy_account_number}
      </Typography>
      <Typography className="text" style={{ marginBottom: '1em' }}>
        Pharmacy Name: {userDetails?.pharmacy_name}
      </Typography>
    </Grid>: null}
    </>
    )
  }

  
  function EditFunction(users) {
    return (
      <EditIcon
        onClick={() => {
          navigate(`/editusers/${users.id}`, {
            state: { user: users, role: users.role },
          });
        }}
        style={{ color: "#007BB8", height: 16, width: 16 }}
      />
    );
  }

  return (
    <>
      {role === "fp@wardleAdmin" ? 
      <ListComponent 
      Righthead={manageuserhead}
      heading={"Manage Users"}
      subheading={"Manage your users account details and access by selecting their names, or choose ‘Add a new user’."}
      EditFunction={EditFunction}
      searchPlaceHolder={
        "Search by name or email address"
      }
      recordType="user"
      operation="filter"
      filterParams={userFilters}
      head={[
        
        { value: "Edit", issortable: false },
        { value: "Name", issortable: false },
          { value: "Email address", issortable: false },
          { value: "Status", issortable: true },
          { value: "Role", issortable: false },
      ]}
      /> : null}
      {role === "fp@wardleStaff" ? 
      <ListComponent 
      heading={"Manage Users"}
      subheading={"Manage your users account details and access by selecting their names, or choose ‘Add a new user’."}    
      EditFunction={EditFunction}
      filterParams={userFilters}

      searchPlaceHolder={
        "Search by account number or pharmacy name"
      }
      recordType="user"
      operation="all"
      head={[
        
        { value: "Edit", issortable: false },
        { value: "Name", issortable: false },
        { value: "Email address", issortable: true },
        { value: "Pharmacy name", issortable: true },
        { value: "Postcode", issortable: false },
        { value: "Account number", issortable: false },
        { value: "Status", issortable: true },
        { value: "Role", issortable: true }
      ]}
      />
       : null}
      {role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser" ?
       <ListComponent 
       heading={"Manage Users"}
       subheading={"Manage your users account details and access by selecting their names, or choose ‘Add a new user’."}    
       EditFunction={EditFunction}
      filterParams={userFilters}

       searchPlaceHolder={
         "Search by account number or pharmacy name"
       }
       recordType="user"
       operation="all"
       head={[
        { value: "Edit", issortable: false },
         { value: "Name", issortable: true },
         { value: "Email address", issortable: false },
         { value: "Account number", issortable: true },
         { value: "Pharmacy name", issortable: false },
         { value: "Postcode", issortable: false },
         { value: "Status", issortable: true },
         { value: "Role", issortable: false },

       ]}
       />
       : null}
    </>
  );
}
const mapStateToProps = state => {
  return {
    isSignedIn: state.isSignedIn,
    userDetails: state.userDetails,
    userFilters: state.userFilters
  };
};

ManageUsers.propTypes = {
  userDetails: PropTypes.object,
  userFilters: PropTypes.any,
};

export default connect(mapStateToProps)(ManageUsers);
