export const toCamelCase = (item) => {
    let newObj = Object.assign({}, ...Object.keys(item).map(k => ({[`${snakeToCamel(k)}`]: item[k]})));
    return newObj
}

const snakeToCamel = str =>
{
    if(str.includes("_")){ 
    return  str.toLowerCase().replace(/[-_][a-z0-9]/g, (group) => group.slice(-1).toUpperCase()
    )
    }else{
    return str
    }
}

