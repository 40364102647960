import React from 'react';
import "../../App.css";
import { useState } from "react";
import {
  Grid,
  Paper,
  TextField,
  Button,
  Checkbox,
  InputAdornment,
  makeStyles,
  Typography
} from "@material-ui/core";
import {
  isEmpty,
  isEmail,
  isMatch,
  isMatchemail,
  isPasswordValid,
} from "../../Components/validation/Validation";
import {
  showErrMsg,
  showErrMsgEmpty,
} from "../../Components/notification/Notification";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import EmailIcon from "../../Assets/Mailicongreen.svg";
import Lockgreen from "../../Assets/Lockgreen.svg";
import Question from "../../Assets/questionmark.svg";
import ArrowDown from "../../Assets/Arrowdown.svg";
import ArrowBackIcon from "@material-ui/icons/KeyboardArrowLeft";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import Checked from "../../Assets/Checked.svg";
import { GetUserEmailExist, VerifyRegisteredEmail } from "../../api/userOps";
import Termsncondition from "../../Assets/TNC.pdf"
import Privacypolicy from "../../Assets/PNP.pdf"
import { InformationModal } from '../../Components/Modal/InformationModal';
import { useEffect } from 'react';
import { logEventWithProperties } from '../../analytics/Analytics';

const useStyles = makeStyles((theme) => ({
  backbutton:{
    cursor: "pointer",
    textDecoration:"none", 
    color:"#5E5E5E", 
    display:"flex", 
    alignItems:"center", 
    paddingTop:130,
    [theme.breakpoints.up('md')]: {
          marginLeft:150,
        },
        [theme.breakpoints.down('md')]: {
          marginLeft:150,
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft:0,
          paddingLeft:"4%",
        },
      },
  signupcontainer:{
        margin: "auto",
        [theme.breakpoints.up('md')]: {
          width: "31%",
        },
        [theme.breakpoints.down('md')]: {
          width: "40%",
        },
        [theme.breakpoints.down('sm')]: {
          width: "50%",
        },
        [theme.breakpoints.down('xs')]: {
          width: "90%",
          padding:"4%",
        },
      },
  Textfields:{
      marginBottom: "4%",
      boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)",
      background: "white"
  },
  Modalbutton: {
    borderRadius: 6,
    marginBottom: "4%",
    textTransform: "none",
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid #7DD4FF",
    height: "5% !important",
  },
  checkboxcontainer: {
    margin: 0,
    padding: "4%",
    marginBottom: "4%",
    display: "flex",
    alignItems: "center",
    border: "1px solid #CEDCE9",
    boxSizing: "borderBox",
    boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)",
    borderRadius: "4px",
  },
  buttongreen:{
    color: "white",
    textTransform: "none",
    marginTop: "3%",
  }
  
}))

function Signup() {
  
  let navigate = useNavigate();
  let location = useLocation();
  let params = useParams()
  const classes = useStyles()


  const initialState = {
    email: params?.email || location.state?.email,
    confirm_email: params?.email || location.state?.email,
    password: location?.state?.password || location.state?.password,
    cf_password: location?.state?.password || location.state?.password,
    err: '',
    err1: '',
    showPassword: false,
    showConfirmPassword: false
  }
  const passwordValidator = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z@]{8,}$/;
  const handleClickShowPassword = () => {
    setUser({
      ...user,
      showPassword: !user.showPassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setUser({
      ...user,
      showConfirmPassword: !user.showConfirmPassword,
    });
  };
  useEffect(() => {
    VerifyRegisteredEmail(email)
        .then((res)=>res.json())
        .then((data)=>{
          if(data.registration_status==="registered"){
            navigate("/register/alreadyregistered")
        }
        })
  }, [])



  const modaltext = [
  { value: "Strong passwords are unique, at least 8 characters long and contain upper and lowercase letters, numbers and symbols."},
  { value: "Try swapping letters for numbers or symbols to make your password harder to guess. For example, instead of S try using $."},
  { value: "You could also try using the first letter of each word from your favourite song lyric to create a word that won't be obvious to other people."},
  {value: "Avoid using personal details other people might know or common words like 'password'."},]



  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [user, setUser] = useState(initialState)
  let { email, confirm_email, password, cf_password, err, err1 } = user

  const emaillowercaser = email;
  const handleChangeInput = e => {
    const { name, value } = e.target
    setUser({ ...user, [name]: value, err: '', success: '' })
  }
  const [checkbox, setCheckbox] = useState(location?.state?.checkbox || false)

  const handleChangeCheckbox = () => {
    setCheckbox(!checkbox)
  }

  const navigateBack = async e => {
    e.preventDefault();
    navigate("/");
  }

  useEffect(()=>{
    logEventWithProperties("User visits signup page")
  },[])

  const handleSubmit = async e => {
    e.preventDefault();

    //------Trimming for email , confirm email , password and confirm password ----------//
    const new_mail = email?.trim();
    const new_confirm_mail = confirm_email?.trim();
    const new_password = password?.trim();
    const new_confirm_password = cf_password?.trim();

    if (isEmpty(new_mail) && isEmpty(new_confirm_mail) && isEmpty(new_password) && isEmpty(new_confirm_password))
      return setUser({ ...user, err: "This field is required", err1: "Please enter your email address and password before continuing.", success: '' })

    if (isEmpty(new_mail))
      return setUser({ ...user, err: "This field is required", err1: "Please enter your email address before continuing.", success: '' })

    if (isEmpty(new_confirm_mail))
      return setUser({ ...user, err: "This field is required", err1: "Please enter confirm email address before continuing.", success: '' })

    if (isEmpty(new_password))
      return setUser({ ...user, err: "This field is required", err1: "Please enter your password before continuing.", success: '' })

    if (isEmpty(new_confirm_password))
      return setUser({ ...user, err: "This field is required", err1: "Please enter confirm password before continuing.", success: '' })

    if (!isEmail(new_mail))
      return setUser({ ...user, err1: "Invalid email", success: '' })

    if (!isEmail(new_confirm_mail))
      return setUser({ ...user, err1: "Invalid email", success: '' })

    if (!isMatchemail(new_mail, new_confirm_mail))
      return setUser({ ...user, err1: "Make sure your email address matches in both fields.", success: '' })

    if (!isPasswordValid(new_password, passwordValidator)) {
      return setUser({ ...user, err: "Please check information entered.", err1: "Make sure your password is at least 8 characters long and contains upper and lowercase letters, numbers and symbols.", success: '' })
    }

    if (!isPasswordValid(new_confirm_password, passwordValidator)) {
      return setUser({ ...user, err: "Please check information entered.", err1: "Make sure your password is at least 8 characters long and contains upper and lowercase letters, numbers and symbols.", success: '' })
    }

    if (!isMatch(new_password, new_confirm_password)) {
      return setUser({ ...user, err: "Please check information entered.", err1: "Make sure your password matches in both fields.", success: '' })
    }

    if(Object.keys(params).length <=0){
      GetUserEmailExist(emaillowercaser)
        .then((res) => res.json())
        .then(
          (data) => {
            if (data.userExist !== true) {
              logEventWithProperties("User submits details on create account page",{ email: new_mail,  receiveOffer: checkbox, verifyDetails: params })

              return setUser(
                { ...user, err1: "", success: "" },
                navigate("/yourdetails", {
                  state: { email: new_mail, password: new_password, receiveOffer: checkbox, verifyDetails: params },
                })
              );
            } else {
              setUser({
                ...user,
                err1: "Looks like you already have an account please. sign in",
                success: "",
              });
            }
          },
          (error) => {
            console.error("There was an error!", error);
          }
        );
    }else{
      logEventWithProperties("User submits details on create account page",{ email: new_mail,  receiveOffer: checkbox, verifyDetails: params })

      navigate("/yourdetails", {
        state: { email: new_mail, password: new_password, receiveOffer: checkbox, verifyDetails: params },
      })
    }
    
  };
  return (
    <Grid>
      
      <Grid className={classes.backbutton} >
                  <ArrowBackIcon onClick={() => {navigate("/");}} />
                  <Typography data-testid="back-button" onClick={() => {navigate("/");}}>Back</Typography>
      </Grid>

      <form onSubmit={handleSubmit} autoComplete="off">
        <Paper elevation={0}  className={classes.signupcontainer}>
          <Grid align="left">
            <h2 data-testid="Header" style={{margin: 0, marginBottom:"2%", fontSize: 40}}>Create account</h2>
          </Grid>

          <Typography className="body-3" style={{marginBottom:"2%"}}>
            Enter the email address you want to use to access your account and
            choose a password to keep your data secure.
          </Typography>

          <TextField
            size="small"
            error={isEmpty(email) && (err?true:false)}
            className={classes.Textfields}
            placeholder="Email address"
            variant="outlined"
            disabled={params?.email}
            label={isEmpty(email) ? null : "Email address"}
            type="text"
            id="email"
            data-testid="email"
            value={email}
            name="email"
            onChange={handleChangeInput}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={EmailIcon} alt="error" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {isEmail(email) ? (
                    <img
                      src={Checked}
                      alt="right"
                      style={{ color: "#52B057" }}
                    />
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
          {isEmpty(email) && showErrMsgEmpty(err)}

          <TextField
            size="small"
            className={classes.Textfields}
            placeholder="Confirm email address"
            variant="outlined"
            disabled={params?.email}
            data-testid="confirm_email"
            label={isEmpty(confirm_email) ? null : "Confirm email address"}
            error={isEmpty(confirm_email) && (err?true:false)}
            type="text"
            id="confirm_email"
            value={confirm_email}
            name="confirm_email"
            onChange={handleChangeInput}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={EmailIcon} alt="error" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {isEmail(confirm_email) ? (
                    <img
                      src={Checked}
                      alt="right"
                      style={{ color: "#52B057" }}
                    />
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
          {isEmpty(confirm_email) && showErrMsgEmpty(err)}

          <TextField
            size="small"
            error={isEmpty(password) && (err?true:false)}
            className={classes.Textfields}
            placeholder="Password"
            data-testid="password"
            label={isEmpty(password) ? null : "Password"}
            variant="outlined"
            fullWidth
            type={user.showPassword ? "text" : "password"}
            id="password"
            name="password"
            value={password}
            onChange={handleChangeInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={Lockgreen} alt="error" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {user.showPassword ? (
                      <Visibility
                        style={{ color: "#A8D7AB", width: 19, height: 19 }}
                      />
                    ) : (
                      <VisibilityOff
                        className="visibility"
                        style={{ color: "#A8D7AB" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {isEmpty(password) && showErrMsgEmpty(err)}
          <TextField
            size="small"
            error={isEmpty(cf_password) && (err?true:false)}
            className={classes.Textfields}
            placeholder="Confirm password"
            type={user.showConfirmPassword ? "text" : "password"}
            label={isEmpty(cf_password) ? null : "Confirm password"}
            variant="outlined"
            fullWidth
            id="cf_password"
            value={cf_password}
            data-testid="CfPassword"
            name="cf_password"
            onChange={handleChangeInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={Lockgreen} alt="error" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    edge="end"
                  >
                    {user.showConfirmPassword ? (
                      <Visibility
                        className="visibility"
                        style={{ color: "#A8D7AB" }}
                      />
                    ) : (
                      <VisibilityOff
                        className="visibility"
                        style={{ color: "#A8D7AB" }}
                      />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {isEmpty(cf_password) && showErrMsgEmpty(err)}

          <Button
            onClick={handleOpen}
            variant="contained"
            size="large"
            style={{ color: '#0066BE', background: '#E8F8FF' }}
            disableElevation
            className={classes.Modalbutton}
            fullWidth
          >
            <Grid
              style={{
                background: '#E8F8FF',
                display: "flex",justifyContent: "flex-start"
              }}
            >
              <img src={Question} alt="error" />
              <Typography className="sub-title-2" style={{margin: 0, marginLeft: 10}}>
                Help setting a strong password
              </Typography>
            </Grid>
            <img src={ArrowDown} alt="error" />
          </Button>

          <Grid className={classes.checkboxcontainer}>
            <Typography className="body-2">
              I would like to receive health advice and offers from Wardles by
              email. (You can change your marketing preferences at any time).
            </Typography>
            <Checkbox
              checked={checkbox}
              style={{color:"grey",marginRight: "1%"}}
              onChange={handleChangeCheckbox}
            />
          </Grid>

          <Typography className="body-3" style={{margin: 0, marginBottom: "1%"}}>
            By continuing you are agreeing to our{" "}
            <a
              href={Termsncondition}
              target="blank"
              style={{color: "#007BB8",textDecoration: "none"}}
            >
              terms and conditions
            </a>{" "}and{" "}
            <a
              href={Privacypolicy}
              target="blank"
              style={{color: "#007BB8",textDecoration: "none"}}
            >
              privacy policy.
            </a>
          </Typography>
          <Grid data-testid="error">
          {err1 && showErrMsg(err1, () => {
            setUser({ ...user, err1: '' })
          })}
          </Grid>
          <Button
            variant="contained"
            size="large"
            disableElevation
            className={classes.buttongreen}
            style={{
              background: "#3D8541",
              boxShadow: "0px 2px 0px #306B33, inset 0px 1px 0px #62B767",
            }}
            fullWidth
            type="submit"
            data-testid="next-btn"
            id="next-btn"
          >
            Next
          </Button>

          <Grid className='all-buttons'>
            <Typography
              style={{marginTop: "4%", textAlign: "Center",cursor: "pointer",color:"#007BB8",fontWeight: 600,textDecoration: "none"}}
              onClick={navigateBack}
            >
              Already have an account?
            </Typography>
          </Grid>
          
          <InformationModal 
          handleClose={handleClose} 
          open={open} 
          heading={"Help setting a strong password"}
          modaltext={modaltext}
          />
         
        </Paper>
      </form>
    </Grid>
  );
}

export default Signup;
