import React, { useEffect, useState } from 'react'
import './notification.css'
import Redcross from '../../Assets/Redcross.svg';
import Greencross from '../../Assets/Greencross.svg';
import PropTypes from 'prop-types';


const Close1 =(callBack) =>{
    if(callBack)
    callBack(); 
    document.getElementById("errMsg1").style.display="none"}
const Close2 =(callBack) =>{ callBack();document.getElementById("errMsg2").style.display="none"}

export const showErrMsg = (msg,onClose) => {
    return <>
    <div className="errMsg" id="errMsg1" >
        <div style={{display:"flex", justifyContent:"flex-start", margin:0,padding:0,width:"90%"}}>
        <p style={{margin:0,padding:0, marginLeft:5, fontSize:16, color:"#BB4035"}}>{msg}</p>
        </div>
        <div  onClick={()=>{
            onClose()
        }} style={{paddingLeft:"25%"}}>
        <img src={Redcross} width="10"  height="20" alt="error" className="close"/>
        </div>
        </div>
        {Close1 && null  }
        </>
}

export const showSuccessmsg = (msg) => {
    return <div className="succesMsg" id="errMsg2">
        <div style={{display:"flex", justifyContent:"flex-start", margin:0,padding:0,width:"90%"}}>
        <p style={{margin:0,padding:0, marginLeft:5, fontSize:16, color:"#3D8541"}}>{msg}</p>
        </div>
        <div onClick={Close2} style={{paddingLeft:"25%"}}>
        <img src={Greencross} color="green" width="10" height="20" alt="error" className="close"/>
        </div>
        {Close2 && null  }
        </div>
}

export const showErrMsgEmpty = (msg) => {
    return (
        <p className="emptyfield" style={{marginTop:-15, fontSize:16}}>{msg}</p>
    )
}


export default function ErrorComponent(props){

    const [isShow,setIsShow] = useState(false)
    // const [msg, setMsg] = useState('')
    useEffect(()=>{
        setIsShow(props.msg.trim()?true:false)
        // setMsg(props.msg)
    },[props.msg])
    return( <>
    {isShow?<div className="errMsg" id="errMsg1" >
        <div style={{display:"flex", justifyContent:"flex-start", margin:0,padding:0,width:"90%"}}>
        <p style={{margin:0,padding:0, marginLeft:5, fontSize:16, color:"#BB4035"}}>{props.msg}</p>
        </div>
        <div  onClick={()=>{
            setIsShow(false)
            // setMsg('')
            props.onClose()
        }} style={{paddingLeft:"25%"}}>
        <img src={Redcross} width="10"  height="20" alt="error" className="close"/>
        </div>
        </div>: null  }
        </>)
}

ErrorComponent.propTypes = {
    msg : PropTypes.string,
    onClose : PropTypes.func 
}