import React from 'react'
import {
  Button,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from 'prop-types';


const useStyles = makeStyles({
  buttontext: {
    padding: "0.25em",
  },
  cancelbutton: {
    textTransform: "none",
    height: "3rem",
    backgroundColor: "white",
    color: "black",
    border: "2px solid #222222",
  },
});


function NormalButton({ text, handleChangeNormalButton, disable }) {
  const classes = useStyles();
  return (
    <>
      <Button
        fullWidth
        size="large"
        variant="outlined"
        disabled={disable}

        disableRipple
        disableElevation
        className={classes.cancelbutton}
        onClick={handleChangeNormalButton}
      >
        <Typography
          variant="body2" className={classes.buttontext}>
          {text}
        </Typography>
      </Button>
    </>
  )
}

NormalButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleChangeNormalButton: PropTypes.func.isRequired
}


export default NormalButton