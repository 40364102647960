import React, { useState } from "react";
import {  Grid, Typography, Card, CardContent } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { InputAdornment } from '@material-ui/core';

import { makeStyles } from '@material-ui/core';
import { showErrMsg } from "../../Components/notification/Notification";
import { searchRange } from '../../api/otherOps';
import { connect } from "react-redux";
import { logEventWithProperties } from "../../analytics/Analytics";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    paddingTop: 120,
    overflow: "hidden",
    [theme.breakpoints.only('md')]: {
      width: "40%"
    },
    [theme.breakpoints.only('lg')]: {
      width: "40%"
    },
    [theme.breakpoints.only('xl')]: {
      width: "40%"
    },
  },
  itemsListContainer: {
    maxHeight: (props) => props ? "60vh" : "20vh",
    overflowY: "scroll",
    cursor: "pointer",
    [theme.breakpoints.only('md')]: {
      maxHeight: (props) => props ? "70vh" : "20vh",

    },
    [theme.breakpoints.only('lg')]: {
      maxHeight: (props) => props ? "70vh" : "20vh",

    },
    [theme.breakpoints.only('xl')]: {
      maxHeight: (props) => props ? "70vh" : "20vh",

    },
  }
}))

const mapStateToProps = state => {
  return {
    isSignedIn: state.isSignedIn,
    userDetails: state.userDetails
  };
};


export default connect(mapStateToProps)(function RangeSearch(props) {

  let searchitem = props.item?.productName || '';
  const [itemsList, setItemsList] = useState([]);
  const [selecteditem, setselecteditem] = props.item ? useState(props.item) : useState({})
  const [error, setError] = useState('');
  const classes = useStyles(!props.hideDescription)

  let debounceTimer;

  const debounce = (func, delay) => {
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() =>  func.apply(context, args), delay);
    };
  };

  const update = debounce(function (e) {
    let value = e.target.value
    searchItems(value)
  }, 1000);


  function searchItems(value) {
    logEventWithProperties("Range search triggered",value)
    if (value.trim() === '') {
      
      setItemsList([]);
    } else {
      // if(value.match(/[a-zA-Z]/)){

      searchRange(/^[a-z]+$/i.test(value.trim())?value.trim().toUpperCase():value.trim())
        .then((res) => res.json())
        .then((res) => {

          if (!res.data || res.data.length === 0) {
            setError('Item not found.')
            setItemsList([]);
          } else {
            const filteredList = JSON.parse(res.data)
            if (filteredList.length > 0) {
              setError('');
              setItemsList(filteredList);
            } else {
              setError('Item not found.')
              setItemsList([]);
            }
    logEventWithProperties("Range search completed",{...{search:value},...{productList:filteredList}})



          }
        }
        )
      // } else
      // setError("Special characters not allowed.")
    }
  }

  return (
    <div >

      <Grid mb={2}>
        <div>
          <TextField
            data-testid="inputfield"
            autoComplete="off"
            style={{
              border: '0px !important'
            }}
            value={selecteditem?.prodDesc || selecteditem?.productName}
            onChange={(e) => {
                setselecteditem({});
                setItemsList([]);
                update(e);
            }}
            InputProps={!props.hideDescription ? {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon />
                </InputAdornment>
              )

            } : {}}
            placeholder="Enter the product code or name"
            label={searchitem.length === 0 ? null : "Item name"}
            fullWidth
            id="outlined-basic"
            variant="outlined"
          />
        </div>
      </Grid>
      <div data-testid="error">
        {error ? showErrMsg(error, () => {
          setError('')
        }) : null}
      </div>


      {itemsList?.length > 0 && <div className={classes.itemsListContainer}>
        {itemsList.map((item, index) =>
        (
          <Card variant="outlined" key={index} sx={{ minWidth: 275 }} onClick={() => {
              setItemsList([]);
              let itemVal={
                prodDesc: item.ProductName,
                productName: item.ProductName
              }
              setselecteditem(itemVal);
              props.onItemClick(itemVal)
            
          }} >
            <CardContent data-testid="product-description">
              <Typography style={{ fontSize: '1.1em', fontWeight: "bold" }} component="div">{item.ProductName}</Typography>
              {!props.hideDescription && <Typography sx={{ mb: 1.5 }} style={{ fontSize: '0.85em' }}>
                {item.CatalogNo}
              </Typography>}
            </CardContent>
          </Card>
        )
        )}
      </div>}

    </div>
  )
})