/* eslint-disable react/no-unescaped-entities */
import { Button, Grid, IconButton, InputAdornment, makeStyles, Paper, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/KeyboardArrowLeft';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ResetPasswordafterlogin } from '../../api/userOps';
import ArrowDown from '../../Assets/Arrowdown.svg';
import Lockgreen from '../../Assets/Lockgreen.svg';
import Question from '../../Assets/questionmark.svg';
import { InformationModal } from '../../Components/Modal/InformationModal';

import { showErrMsg, showErrMsgEmpty, showSuccessmsg } from '../../Components/notification/Notification';
import { isEmpty, isMatch, isPasswordValid } from '../../Components/validation/Validation';
import { setUSerLoginDetails } from '../../redux/actions/actions';

const useStyles =  makeStyles((theme) => ({
  arrowBack:{
    cursor: "pointer",
    textDecoration:"none",
    color:"#5E5E5E",
    display:"flex",
    alignItems:"center",
    margin:"0",
    [theme.breakpoints.only('xs')]: {
     justifyContent:"left",
    paddingLeft:0,

    },
    [theme.breakpoints.only('sm')]: {
      justifyContent:"left",

    paddingLeft:0,
     
      
    },
    [theme.breakpoints.only('xs')]: {
      justifyContent:"left",
     paddingLeft:0,
 
     },
     [theme.breakpoints.only('md')]: {
      paddingLeft:(props)=>props?150:300,     
     },
     [theme.breakpoints.only('lg')]: {
      paddingLeft:(props)=>props?150:300,     
     },
     [theme.breakpoints.only('xl')]: {
      paddingLeft:(props)=>props?150:300,     
     },
  }
}))
const initialState = {
  currentPassword:'',
  password: '',
  cf_password: '',
  err: '',
  err1:'',
  success: '',
  showCurrentPassword: false,
  showPassword: false,
  showConfirmPassword: false,
}


function MyProfile(props) {

    const accessTokens = props.token.AccessToken

  const classes = useStyles()

    let navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [passwordErr,setPasswordEroor] =React.useState(false)
    const [curentPasswordErr,setCurentPasswordEroor] =React.useState(false)
    const [confirmPasswordErr,setConfirmPasswordEroor] =React.useState(false)


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const paperStyle={padding :10,paddingTop:30, width:350, margin:"auto"};

    const handleClickshowCurrentPassword= () => {
      setUser({
        ...user,
        showCurrentPassword: !user.showCurrentPassword,
      });
    };

    const handleClickShowPassword = () => {
      setUser({
        ...user,
        showPassword: !user.showPassword,
      });
    };
    const handleClickShowConfirmPassword = () => {
      setUser({
        ...user,
        showConfirmPassword: !user.showConfirmPassword,
      });
    };
    const [user, setUser] = useState(initialState)
  const {currentPassword, password,cf_password, err,err1, success} = user

  const handleChangeInput = e => {
    const {name, value} = e.target
    setConfirmPasswordEroor(false)
    setPasswordEroor(false)
    setCurentPasswordEroor(false)
    setUser({...user, [name]:value, err: '',err1:'', success: ''})
  }

  const modaltext = [
    { value: "Strong passwords are unique, at least 8 characters long and contain upper and lowercase letters, numbers and symbols."},
    { value: "Try swapping letters for numbers or symbols to make your password harder to guess. For example, instead of S try using $."},
    { value: "You could also try using the first letter of each word from your favourite song lyric to create a word that won't be obvious to other people."},
    {value: "Avoid using personal details other people might know or common words like 'password'."},]

  const handleSubmit = async e => {
    e.preventDefault()

    if(isEmpty(password) || isEmpty(currentPassword) || isEmpty(cf_password)) {
     setPasswordEroor(isEmpty(password))
     setCurentPasswordEroor(isEmpty(currentPassword))
     setConfirmPasswordEroor(isEmpty(cf_password))

      return setUser({...user,err:"This field is required",err1:"Please check the above information is correct", success:''})
     
    }
    if(!isPasswordValid(currentPassword) || !isPasswordValid(password) || !isPasswordValid(cf_password)){
      return setUser({...user,err:"Not a Valid password",err1:"Make sure your password is at least 8 characters long and contains upper and lowercase letters, numbers and symbols.", success:''})
     }
     else if(isMatch(password, currentPassword)){
        return setUser({...user, err1: "New password and current password should not be same", success: ''})}
     else if(!isMatch(password, cf_password)){
        return setUser({...user, err1: "New passwords don’t match. Try again.", success: ''})}
        else ResetPasswordafterlogin(accessTokens,currentPassword,password)
        .then((res) => res.json())
                .then((data) => {
                    if (data.error) {
                      return  setUser({...user,err1:data.error?.message?.includes("NotAuthorizedException")?"Current password is not correct.":data.error?.message, err: data.error?.errorCode === "NotAuthorizedException"?"Current password is not correct.":data.error.message, success: ''})
                    }else {
                      props.dispatch(setUSerLoginDetails({userEmail:props.userLoginDetails?.userEmail,userPassword:password}))
                      setUser({...user, err:"",err1:'', success: 'We’ve updated your password. You’ll need to use your new password next time you sign in.'})     
                    }
                },
                error => {
                    console.error('There was an error!', error);
                });
              }
       
    return (
      <>
        <div className={classes.arrowBack}>
            <button data-testid="back-button" onClick={()=>navigate("/myprofile")}  className="backbuttonstyle2" >
                <ArrowBackIcon />
                <p>Back</p>
            </button> 
        </div>     
        <Grid>
        <Paper elevation={0} style={paperStyle}>

                <h2 data-testid="Header" style={{fontSize: 25,margin:0, marginBottom:25, textAlign:"left"}}>Change password</h2>

                <TextField size="small"
                 error= {isEmpty(currentPassword) && (err?true:false)}
                style={{marginBottom:13,boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background:"white"}}
                placeholder="Current password" 
                variant="outlined" 
                fullWidth
                type={user.showCurrentPassword ? 'text' : 'password'}
                id="currentPassword"
                data-testid="currentPassword"
                value={currentPassword} name="currentPassword" onChange={handleChangeInput}
                label={isEmpty(currentPassword)?null:"Current password"}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Lockgreen} alt="error" />
                      </InputAdornment>
                    ),
                    endAdornment:(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickshowCurrentPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {user.showCurrentPassword ? <Visibility style={{color: "#A8D7AB", height:19,width:19}}/> : <VisibilityOff style={{color: "#A8D7AB", height:19,width:19}}/>}
                        </IconButton>
                      </InputAdornment>)
                  }}
                />
                {(isEmpty(currentPassword) || curentPasswordErr)  && showErrMsgEmpty(err)}

                <TextField size="small"
                 error= {isEmpty(password) && (err?true:false)}
                style={{marginBottom:13,boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background:"white"}}
                placeholder="New password" 
                variant="outlined" 
                fullWidth
                type={user.showPassword ? 'text' : 'password'}
                id="password"
                data-testid="Password"
                value={password} name="password" onChange={handleChangeInput}
                label={isEmpty(password)?null:"New password"}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Lockgreen} alt="error" />
                      </InputAdornment>
                    ),
                    endAdornment:(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {user.showPassword ? <Visibility style={{color: "#A8D7AB", height:19,width:19}}/> : <VisibilityOff style={{color: "#A8D7AB",height:19,width:19}}/>}
                        </IconButton>
                      </InputAdornment>)
                  }}
                />
                {(isEmpty(password) || passwordErr) && showErrMsgEmpty(err)}
                 
            <TextField size="small"
                 error= {isEmpty(cf_password) && (err?true:false)}
                style={{marginBottom:13,boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.07)", background:"white"}}
                placeholder="Confirm new password" 
                type={user.showConfirmPassword ? 'text' : 'password'}
                label={isEmpty(cf_password)?null:"Confirm new password"}
                variant="outlined" 
                fullWidth
                data-testid="Cf-Password"
                id="cf_password"
                value={cf_password} name="cf_password" onChange={handleChangeInput}
                InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Lockgreen} alt="error" />
                      </InputAdornment>
                    ),
                    endAdornment:(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                        >
                          {user.showConfirmPassword ? <Visibility style={{color: "#A8D7AB", height:19,width:19}}/> : <VisibilityOff style={{color: "#A8D7AB",height:19,width:19}}/>}
                        </IconButton>
                      </InputAdornment>)
                  }}
                />
                {(isEmpty(cf_password) || confirmPasswordErr)  && showErrMsgEmpty(err)}

              
              
                    
                  <Button onClick={handleOpen} variant="contained" size="large" disableElevation style={{background: "#E8F8FF", color:"#0066BE",borderRadius: '6px',marginTop:"Auto", fontSize:"18px", textTransform: "none", display:"flex", justifyContent:"space-between", border: "1px solid #7DD4FF"}} fullWidth>
                      <div style={{display:"flex", justifyContent:"flex-start", margin:0,padding:0}}>
                        <img src={Question} alt="error" />
                        <p style={{margin:0,padding:0, marginLeft:10, fontSize:16}}>Help setting a strong password</p>
                      </div>
                      <img src={ArrowDown} alt="error" />
                  </Button>
                  <div data-testid="error">
                  {err1 && showErrMsg(err1,()=>{
                    setUser({...user,err1:''})
                  })}
                  {success && showSuccessmsg(success)}
                  </div>
              <Grid align="center">
                <Button data-testid="btn" onClick={handleSubmit} variant="contained" fullWidth size="large" disableElevation style={{background: "#3D8541",color:"white", marginTop:"17px", fontSize:"14px", textTransform: "none"}} >Update Password</Button>
              </Grid>

              <Grid align="center">
                <Button data-testid="cancelbtn" onClick={() => {navigate('/myprofile')}} variant="outlined" fullWidth size="large" disableElevation style={{background: "white",color:"black", marginTop:"17px", fontSize:"14px", textTransform: "none"}} >Cancel</Button>
              </Grid>

              {/* Modal */}
              <InformationModal
              handleClose={handleClose} 
              open={open} 
              heading={"Help setting a strong password"}
              modaltext={modaltext}
              />

          
        </Paper>
    </Grid>

    
    </>
    
    );
  }
  const mapStateToProps = state => {
    return {
        isSignedIn:state.isSignedIn,
      userDetails: state.userDetails,
      token:state.token,
      userLoginDetails:state.userLoginDetails
    };
 };

 
 MyProfile.propTypes = {
  userDetails: PropTypes.object,
  token: PropTypes.object
};
export default connect(mapStateToProps)(MyProfile);