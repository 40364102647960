import { GET_TOKEN,CLEAR_STATE,SET_IS_USER_FILTER_APPLIED,SET_NOTIFICATION_LIST , IS_SIGNED_IN, IS_LOGIN_ERROR, SET_USER_DETAILS, SET_USER_LOGIN_DETAILS, SET_HQ_ASSOCIATED_PHARMACIES, SET_USER_FILTER, SET_ORDER_FILTER, SET_IS_ORDER_FILTER_APPLIED, SET_RESET_USER_FILTER, SET_RESET_ORDER_FILTER, CLEAR_NOTIFICATION_LIST, SET_COMISSION_FILTER, SET_IS_COMMISSION_FILTER, SET_API_ERROR, IS_NOTIFICATION_ENABLED, IS_REMEBER_PASSWORD, IS_TOKEN_REFRESHING, RETRY_ARRAY_APIS, REFRESH_TOKEN, IS_USER_AWAY, IS_LOADING } from '../actions/constants'


const initialState = {
    token: {},
    isLoading: false,
    isSignedIn: false,
    userDetails: {},
    loginError: {},
    userLoginDetails: {},
    associatedPharmacies: null,
    userFilters: { role: '', status: '' },
    commisionFilter:{fromDate:"",toDate:""},
    orderFilters: {
        status: '', date: new Date(),
        isDateChange: false
    },
    isUserFilterApplied: false,
    isOrderFilterApplied: false,
    isComissionFilterApplied:false,
    notificationList:[],
    isNotificationEnabled:true,
    apiError:"",
    remeberPassword:false,
    isTokenRefreshing:false,
    reTryApis:[],
    isUserAway:false
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case GET_TOKEN:
            return { ...state, token: action.payload }
        case REFRESH_TOKEN:
                return { ...state, token: {...action.payload,RefreshToken:state.token.RefreshToken }}
        case SET_USER_DETAILS:
            return { ...state, userDetails: action.payload }
        case IS_SIGNED_IN:
            return { ...state, isSignedIn: action.payload }
        case IS_LOGIN_ERROR:
            return { ...state, loginError: action.payload }
        case SET_USER_LOGIN_DETAILS:
            return { ...state, userLoginDetails: action.payload }
        case SET_HQ_ASSOCIATED_PHARMACIES:
            return { ...state, associatedPharmacies: action.payload }
        case SET_USER_FILTER:
            return { ...state, userFilters: action.payload }
        case SET_RESET_USER_FILTER:
            return { ...state, userFilters: { role: '', status: '' } }
        case SET_IS_ORDER_FILTER_APPLIED:
            return { ...state, isOrderFilterApplied: action.payload }
        case SET_IS_USER_FILTER_APPLIED:
            return { ...state, isUserFilterApplied: action.payload }
        case SET_ORDER_FILTER:
            return { ...state, orderFilters: action.payload }
        case SET_COMISSION_FILTER:
            return { ...state, commisionFilter: {...state.commisionFilter,...action.payload }}
          
        case SET_IS_COMMISSION_FILTER:
            return {...state,isComissionFilterApplied:action.payload}
        case CLEAR_NOTIFICATION_LIST:
            return {...state,notificationList:[]}
        case SET_NOTIFICATION_LIST:
            return {...state,notificationList:[...state.notificationList,...action.payload]}
        case SET_RESET_ORDER_FILTER:
            return {...state, orderFilters: {
                    status: '', date: new Date(),
                    isDateChange: false
                }
            }
        case IS_LOADING:
            return {...state,isLoading:action.payload}
        case CLEAR_STATE:
            return state.remeberPassword? {...initialState,remeberPassword:state.remeberPassword,userLoginDetails:{...state.userLoginDetails}}:{...initialState}
        case SET_API_ERROR:
                return {...state,apiError:action.payload}

        case IS_NOTIFICATION_ENABLED:
            return {...state,isNotificationEnabled:action.payload}
        case IS_REMEBER_PASSWORD:
            return {...state,remeberPassword:action.payload}
        case IS_TOKEN_REFRESHING:
                return {...state,isTokenRefreshing:action.payload}
        case RETRY_ARRAY_APIS:
            return {...state,reTryApis:action.payload}
        case IS_USER_AWAY:
            return {...state,isUserAway:!state.isUserAway}
        default:
            return { ...state }
    }
}