import {
  Button,
  Grid,
  Hidden,
  Paper,
  TableContainer,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/KeyboardArrowLeft";
import { Pagination } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import logEvent from "../../analytics/Analytics";
import { UpdateOrderDetails } from "../../api/orderops";
import { getPharmacyName, searchfilter } from "../../api/otherOps";
import Orderdetails from "../../Components/order/orderdetails";
import { setisComissionFilter } from "../../redux/actions/actions";
import { ModifyList } from "../../Utilities/modifyList";
import FilterBar from "../Filter/FilterBar";
import FilterBarMobile from "../Filter/FilterBarMobile";
import FilterDialog from "../Filter/FilterDialog";
import TableComponent from "../Table/Table";

const useStyles = makeStyles((theme) => ({
  maincontainer: {
    paddingTop: "5em",
    marginBottom: "2em",
    width: "85%",
    marginLeft: "8em",
    [theme.breakpoints.only("md")]: {
      paddingTop: "6em",
    },
    [theme.breakpoints.only("xs")]: {
      paddingTop: "5em",
      marginLeft: "0em",
      width: "95%",
    },
    [theme.breakpoints.only("sm")]: {
      paddingTop: "5em",
      marginLeft: "0em",
      width: "95%",
    },
    [theme.breakpoints.only("lg")]: {
      paddingTop: "6em",
    },
    [theme.breakpoints.only("xl")]: {
      paddingTop: "8em",
    },
  },
  title: {
    fontSize: "2em",
    fontWeight: "bold",
  },
  backbuttoncss: {
    cursor: "pointer",
    textDecoration: "none",
    color: "#5E5E5E",
  },
  welcome: {
    display: "flex",
    justifyContent: "right",
    width: "100%",
    color: "#5E5E5E",
    fontWeight: "bold",
  },
  downloadAll: {
    display: "flex",
    justifyContent: "right",
    width: "100%",
    color: "#007BB8",
    marginBottom: 5,
    fontWeight: 600,
  },

  stockouterContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.only("sm")]: {
      justifyContent: "left",
    },
    [theme.breakpoints.only("xs")]: {
      justifyContent: "left",
    },
  },

  stockContainer: {
    boxSizing: "border-box",
    overflowY: "hidden",
    [theme.breakpoints.only("sm")]: {
      height: "50Vh",
    },
    [theme.breakpoints.only("lg")]: {
      height: "50Vh",
    },
    [theme.breakpoints.only("xl")]: {
      height: "50Vh",
    },
  },
  tableleftcontainer: {
    // [theme.breakpoints.down('md')]: {
    //   maxHeight:"58.5vh"
    // },
    // [theme.breakpoints.down('xs')]: {
    //   maxHeight:"28.5vh"
    // },
    [theme.breakpoints.up("sm")]: {
      maxHeight: "48.5vh",
    },
    [theme.breakpoints.up("lg")]: {
      maxHeight: "48.5vh",
    },
    [theme.breakpoints.up("xl")]: {
      maxHeight: "48.5vh",
    },
  },
}));

function ListComponent(props) {
  const {
    isUserFilterApplied,
    isOrderFilterApplied,
    userFilters,
    orderFilters,
  } = props;
  const role = props.userDetails?.role;
  const classes = useStyles();
  const EditFunction = props.EditFunction;
  const PrintFunction = props.PrintFunction;
  const onStatusViewed = props.onStatusViewed
  const Audittrial = props.Audittrial;
  let navigate = useNavigate();
  const recordType = props.recordType;

  const [state, setState] = useState(props.filterParams);

  const [isRefetch, setIsRefech] = useState(false);
  const [list, setList] = useState(props?.list ? props.list : []);
  const [orderdetailsId, setOrderdetailsId] = useState([{}, {}]);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [filterOpen, setFilterOpen] = useState(false);


  useEffect(() => {
    logEvent("User visited " + props.heading,props.userDetails)
  }, [])

  const [pages, setPages] = React.useState([
    { page: 1, lastEvaluatedKey: null },
  ]);
  let [currentPage, setCurrentPage] = React.useState({
    page: 1,
    lastEvaluatedKey: null,
  });
  const [isPageChange, setIsPageChange] = React.useState(false);
  const [filterapplied, setfilterapplied] = useState(false);
  const [inputtext, setInputtext] = useState("");
  const [pharmacyNames, setPharmacyNames] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let operation = props.operation;
  const [pharmacies, setPharmacies] = useState([]);

  let listimer = useRef(null);

  useEffect(() => {
    if (open) openOrder(orderdetailsId?.id, orderdetailsId?.email);
  }, [open]);

  useEffect(() => {
    setIsRefech(props.isRefetch);
  }, [props.isRefetch]);

  useEffect(() => {
    if (isRefetch) handleSubmit();
    else if (props.setRefecth) props.setRefecth();
  }, [isRefetch]);

  function fetchData(lastPage) {
    let pharmaFilter = "";
    if (recordType === "pharmacy_association") {
      if (role === "fp@wardleStaff")
        pharmaFilter = props.groupDetails?.pharmacyGroupAccountNumber;
      else pharmaFilter = props.userDetails?.pharmacy_group_account_number;
    } else if (role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser") {
      pharmaFilter = pharmacies?.join(",");
    }
    if (recordType === "user") {
      if (props.heading === "Audit trial" || props.isUserFilterApplied)
        operation = "filter";
      else operation = "all";
    } else if (recordType === "order" && props.isOrderFilterApplied) {
      operation = "filter";
    } else {
      operation = props.operation;
    }

    searchfilter(
      recordType,
      operation,
      lastPage?.lastEvaluatedKey,
      "",
      state,
      pharmaFilter
    )
      .then((res) => res.json())
      .then((data) => {
        let list = data.items;
        setIsLoading(false);

        filterapplied && setfilterapplied(false);

        let lastEvaluated = data.lastEvaluatedKey;
        setIsPageChange(false);
        props.dispatch(setisComissionFilter(false));

        if (
          (currentPage.page > 1 && lastPage?.lastEvaluatedKey) ||
          currentPage.page === 1
        ) {
          let pageList = pages?.map((page, index) => {
            return index + 1 === currentPage.page
              ? { page: page.page, lastEvaluatedKey: lastEvaluated }
              : page;
          });
          setCurrentPage((state) => ({
            ...state,
            lastEvaluatedKey: lastEvaluated,
          }));

          setPages(pageList);
          setList(
            ModifyList(
              list,
              recordType,
              orderdetailsId,
              setOrderdetailsId,
              handleOpen,
              Audittrial,
              EditFunction,
              PrintFunction,
              onStatusViewed
            )
          );

          if (props.setRefecth) props.setRefecth();
        }
      });
  }

  useEffect(() => {
    if (role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser") {
      if (props.associatedPharmacies?.length > 0) {
        setPharmacies(
          props.associatedPharmacies?.map(
            (item) => item.pharmacy_account_number
          )
        );
        setIsPageChange(true);
      } else {
        setIsPageChange(false);

        setIsLoading(false);
        setList([]);
      }
    } else {
      setIsPageChange(true);
    }
  }, [props.associatedPharmacies]);

  useEffect(() => {
    if (filterapplied) {

      if (recordType === "user") {

        if (isUserFilterApplied) {
          setState(userFilters);

          setPages([{ page: 1, lastEvaluatedKey: null }]);
          setCurrentPage({ page: 1, lastEvaluatedKey: null });
          clearInterval(listimer.current);
          listimer.current = null;
        }
      } else if (recordType === "order") {

        if (isOrderFilterApplied) {
          setState(orderFilters);

          setPages([{ page: 1, lastEvaluatedKey: null }]);
          setCurrentPage({ page: 1, lastEvaluatedKey: null });
          clearInterval(listimer.current);
          listimer.current = null;
        }
      } else if (recordType === "commission_statement") {

        setState(props.commisionFilter);
        setPages([{ page: 1, lastEvaluatedKey: null }]);
        setCurrentPage({ page: 1, lastEvaluatedKey: null });
        clearInterval(listimer.current);
        listimer.current = null;
      }

      setIsPageChange(true);
    }
  }, [filterapplied]);

  const polling = () => {
    listimer.current = setInterval(() => {
      let lastPage = pages.find((item) => item.page === currentPage.page - 1);
      getList(lastPage);
    }, 20000);
  };

  useEffect(() => {
    return () => {
      clearInterval(listimer.current);
      listimer.current = null;
    };
  }, []);

  useEffect(() => {
    if (isPageChange) {
      let lastPage = pages.find((item) => item.page === currentPage.page - 1);
      getList(lastPage);
      if (
        recordType === "order" &&
        (role === "fp@wardleHqAdmin" ||
          role === "fp@wardleHqUser" ||
          role === "fp@wardleHqUser" ||
          role === "fp@wardleStaff")
      ) {
        
        polling();
      } 
    }
  }, [isPageChange]);

  const searchFilterList = (type) => {
    let lastPage = pages.find((item) => item.page === currentPage.page - 1);

    let pharmaFilter = "";
    if (recordType === "pharmacy_association") {
      if (role === "fp@wardleStaff")
        pharmaFilter = props.groupDetails?.pharmacyGroupAccountNumber;
      else pharmaFilter = props.userDetails?.pharmacy_group_account_number;
    } else if (role === "fp@wardleHqAdmin" || role === "fp@wardleHqUser") {
      pharmaFilter = pharmacies?.join(",");
    }

    if (recordType === "user" && props.isUserFilterApplied) {
      operation = "filter";
    } else if (recordType === "order" && props.isOrderFilterApplied) {
      operation = "filter";
    } else {
      operation = props.operation;
    }

    searchfilter(
      recordType,
      type,
      lastPage?.lastEvaluatedKey,
      inputtext,
      state,
      pharmaFilter
    )
      .then((res) => res.json())
      .then((data) => {
        props.dispatch(setisComissionFilter(false));
        filterapplied && setfilterapplied(false);
        if (props.setRefecth)
          props.setRefecth()
        let list = data.items;
        setIsLoading(false);

        let lastEvaluated = data.lastEvaluatedKey;
        setIsPageChange(false);
        if (
          (currentPage.page > 1 && lastPage?.lastEvaluatedKey) ||
          currentPage.page === 1
        ) {
          let pageList = pages?.map((page, index) => {
            return index + 1 === currentPage.page
              ? { page: page.page, lastEvaluatedKey: lastEvaluated }
              : page;
          });
          setCurrentPage((state) => ({
            ...state,
            lastEvaluatedKey: lastEvaluated,
          }));

          setPages(pageList);
          setList(
            ModifyList(
              list,
              recordType,
              orderdetailsId,
              setOrderdetailsId,
              handleOpen,
              Audittrial,
              EditFunction,
              PrintFunction,
              onStatusViewed

            )
          );
          if (props.setRefecth) props.setRefecth();
          // console.log(list)
        } else {
          // console.log("End of pagination")
        }
      });
  };

  const getList = (lastPage) => {
    //2015-03-31 13:35:00
    filterapplied && inputtext
      ? searchFilterList("searchfilter")
      : filterapplied
        ? searchFilterList("filter")
        : inputtext
          ? searchFilterList("search")
          : fetchData(lastPage);
  };

  function openOrder(id, email) {

    if (props.userDetails?.role === "fp@wardleStaff") {
      UpdateOrderDetails("order_open", "open", id, email)
        .then((res) => res.json())
        .then(
          () => { },
          (error) => {
            console.error("There was an error!", error);
          }
        );
    }
  }

  useEffect(() => {
    if (open) openOrder(orderdetailsId?.id, orderdetailsId?.email);
  }, [open]);

  const onFilterClick = () => {
    setFilterOpen(true);
    setfilterapplied(false);

    if (pharmacyNames?.length <= 0) {
      getPharmacyName().then((resp) => {
        setPharmacyNames(resp.pharmacies);
      });
    }
  };

  const onDateSelect = () => {
    handleSubmit();
  };

  const onSearchTextChanged = (searchText) => {
    setIsPageChange(true);
    clearInterval(listimer.current);
    setIsLoading(true);
    setfilterapplied(true);
    listimer.current = null;
    setInputtext(searchText);
    setPages([{ page: 1, lastEvaluatedKey: null }]);
    setCurrentPage({ page: 1, lastEvaluatedKey: null });
    
  };

  const handleSubmit = () => {
    setIsLoading(true);

    setFilterOpen(false);

    setPages([{ page: 1, lastEvaluatedKey: null }]);
    setCurrentPage({ page: 1, lastEvaluatedKey: null });
    // setState(state)
    clearInterval(listimer.current);
    listimer.current = null;
    setfilterapplied(true);
  };

  const handleNext = () => {
    setIsLoading(true);
    let pageList = pages;
    if (
      currentPage.lastEvaluatedKey !== null &&
      currentPage.page === pageList.length
    ) {
      pageList.push({ page: pageList.length + 1, lastEvaluatedKey: {} });
    }
    pageList = pageList.map((page) => {
      return page.page === currentPage.page
        ? { ...page, lastEvaluatedKey: currentPage?.lastEvaluatedKey }
        : page;
    });
    setCurrentPage({ page: currentPage.page + 1, lastEvaluatedKey: {} });

    setPages(pageList);
    setIsPageChange(true);
    clearInterval(listimer.current);
    listimer.current = null;
  };

  const handleresetfilter = () => {
    setfilterapplied(false);
    setIsPageChange(true);
    setFilterOpen(false);
    setIsLoading(true);

    setState();
    clearInterval(listimer.current);
    listimer.current = null;
  };

  return props.onlyTable === true ? (
    <>
      <Grid item>
        <TableComponent
          head={list.length > 0 ? props.head : []}
          body={list}
          recordType="pharmacy_association"
          noData={props.NodataDisplay}
          loading={isLoading}
        />
        {list.length > 0 && (
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              margin: "10px",
            }}
          >
            <Pagination
              hidePrevButton
              hideNextButton
              page={currentPage.page}
              count={pages.length}
              onChange={(e, value) => {
                let page = pages?.find((item) => item.page === value);
                setCurrentPage(page);
                setIsPageChange(true);
                clearInterval(listimer.current);
                listimer.current = null;
              }}
            />
            <Button
              variant="text"
              style={{
                color:
                  isPageChange || !currentPage?.lastEvaluatedKey
                    ? "gray"
                    : "#0000ff",
              }}
              disabled={isPageChange || !currentPage?.lastEvaluatedKey}
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        )}
        {list.length <= 0 && !isLoading && props.NodataDisplay()}
      </Grid>
    </>
  ) : (
    <>
      <Grid
        container
        direction="column"
        className={classes.maincontainer}
      // style={
      //   collapse === false
      //     ? { paddingLeft: 190 }
      //     : { paddingLeft: 0 } && recordType === "stock_amnesty"
      //     ? { paddingLeft: 20 }
      //     : null
      // }
      >
        {Audittrial === true ? (
          <Grid container direction="row" className={classes.backbuttoncss}>
            <ArrowBackIcon onClick={() => navigate("/manageusers")} />
            <Typography onClick={() => navigate("/manageusers")}>
              Back
            </Typography>
          </Grid>
        ) : null}

        {recordType === "order" && role === "fp@wardleStaff" ? (
          <Hidden smDown implementation="css">
            <Grid className={classes.welcome}>
              <Typography>Welcome, {props.userDetails?.first_name}</Typography>
            </Grid>
          </Hidden>
        ) : null}
        {/* ////////////////////// */}
        <Hidden mdUp>
          <FilterBarMobile
            Righthead={props.Righthead}
            heading={props.heading}
            subtext={props.subheading || props.subtext}
            hideFilter={props.hideFilter}
            hideSearch={props.hideSearch}
            searchPlaceHolder={props.searchPlaceHolder}
            recordType={props.recordType}
            filterClicked={onFilterClick}
            onDateSelect={onDateSelect}
            calendarOpen={() => setfilterapplied(false)}
            onSearchTextChanged={(e) => onSearchTextChanged(e)}
          />
        </Hidden>
        {/* //////////////////////////// */}
        <Hidden smDown>
          <Grid item container direction="row">
            <Grid item style={{ width: "60%" }}>
              {props.Overheading && props.Overheading()}
              <Typography className={classes.title}>{props.heading}</Typography>
              <Typography
                variant="body2"
                style={{ lineHeight: "1.4", color: "#5E5E5E" }}
              >
                {/* {props.subheading} */}
                {props.recordType === "stock_amnesty" && props.subheading ? (
                  <>
                    <Grid item style={{ marginTop: "1em" }}>
                      {props.subheading.map((text, index) => {
                        return (
                          <Typography
                            key={index}
                            style={{ color: "#5E5E5E", fontWeight: 500 }}
                            variant="body1"
                          >
                            {text}
                          </Typography>
                        );
                      })}
                    </Grid>
                  </>
                ) : <>
                  {props.subheading}
                </>
                }
                {props.subcontainer && props.subcontainer()}
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: "auto" }}>
              {props.Righthead && props.Righthead(list?.length > 0)}
            </Grid>
          </Grid>
          <Grid item>
            {props.filterbar === false ? null : (
              <FilterBar
                subtext={props.subtext}
                hideFilter={props.hideFilter}
                hideSearch={props.hideSearch}
                searchPlaceHolder={props.searchPlaceHolder}
                recordType={props.recordType}
                filterClicked={onFilterClick}
                onDateSelect={onDateSelect}
                calendarOpen={() => setfilterapplied(false)}
                onSearchTextChanged={(e) => onSearchTextChanged(e)}
              />
            )}
          </Grid>
        </Hidden>
        {props.reducedWidth ? (
          <>
            <Grid item className={classes.stockouterContainer}>
              <TableContainer
                component={Paper}
                elevation={2}
                style={{
                  width: "95%",
                  boxSizing: "border-box",
                  marginTop: "10px",
                }}
              >
                <TableComponent
                  head={props.head}
                  body={list}
                  loading={isLoading}
                />
              </TableContainer>
            </Grid>
          </>
        ) : props.TableLeftContainer ? (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={2}
              style={{ marginTop: "1em" }}
            >
              <Hidden smDown>
                <Grid
                  item
                  md={3}
                  xs={12}
                  sm={12}
                  lg={3}
                  className={classes.tableleftcontainer}
                >
                  {props.TableLeftContainer && props.TableLeftContainer()}
                </Grid>
              </Hidden>
              <Grid item md={9} xs={12} sm={12} lg={9}>
                <TableContainer
                  component={Paper}
                  elevation={2}
                  className={classes.stockContainer}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "#222222",
                      margin: "10px",
                    }}
                  >
                    {props.InnertableHead}
                  </Typography>
                  <TableComponent
                    head={list.length > 0 ? props.head : []}
                    body={list}
                    loading={isLoading}
                    noData={props.NodataDisplay}
                  />
                  {list.length === 0 &&
                    !isLoading &&
                    props.NodataDisplay &&
                    props.NodataDisplay()}
                </TableContainer>
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    margin: "10px",
                    marginBottom: 0,
                  }}
                >
                  <Pagination
                    hidePrevButton
                    hideNextButton
                    page={currentPage.page}
                    count={pages.length}
                    onChange={(e, value) => {
                      let page = pages?.find((item) => item.page === value);
                      setCurrentPage(page);
                      setIsLoading(true);
                      setIsPageChange(true);
                      clearInterval(listimer.current);
                      listimer.current = null;
                    }}
                  />
                  <Button
                    variant="text"
                    style={{
                      color:
                        isPageChange || !currentPage?.lastEvaluatedKey
                          ? "gray"
                          : "#0000ff",
                    }}
                    disabled={isPageChange || !currentPage?.lastEvaluatedKey}
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </div>
              </Grid>
              <Hidden mdUp>
                <Grid item md={12} xs={12} sm={12}>
                  {props.TableLeftContainer && props.TableLeftContainer()}
                </Grid>
              </Hidden>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <TableContainer
                component={Paper}
                style={{
                  boxSizing: "border-box",
                  marginTop: "3em",
                }}
              >
                <TableComponent
                  head={props.head}
                  body={list}
                  loading={isLoading}
                />
              </TableContainer>
              {recordType === "pharmacy_group" &&
                !isLoading &&
                ModifyList.length === 0 &&
                props.NodataDisplay()}
            </Grid>
          </>
        )}
      </Grid>
      {!props.TableLeftContainer && (
        <div style={{ justifyContent: "center", display: "flex" }}>
          <Pagination
            hidePrevButton
            hideNextButton
            page={currentPage.page}
            count={pages.length}
            onChange={(e, value) => {
              let page = pages?.find((item) => item.page === value);
              setCurrentPage(page);
              setIsLoading(true);
              setIsPageChange(true);
              clearInterval(listimer.current);
              listimer.current = null;
            }}
          />
          <Button
            variant="text"
            style={{
              color:
                isPageChange || !currentPage?.lastEvaluatedKey
                  ? "gray"
                  : "#0000ff",
            }}
            disabled={isPageChange || !currentPage?.lastEvaluatedKey}
            onClick={handleNext}
          >
            Next
          </Button>
        </div>
      )}

      {open && (
        <Orderdetails
          open={open}
          handleClose={handleClose}
          orderdetailsId={orderdetailsId}
        />
      )}
      {filterOpen && (
        <FilterDialog
          recordType={recordType}
          handleSubmit={handleSubmit}
          onDateSelect={onDateSelect}
          onClose={() => {
            setFilterOpen(false);
          }}
          handleresetfilter={handleresetfilter}
        />
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.isSignedIn,
    userDetails: state.userDetails,
    associatedPharmacies: state.associatedPharmacies,
    userFilters: state.userFilters,
    orderFilters: state.orderFilters,
    isUserFilterApplied: state.isUserFilterApplied,
    isOrderFilterApplied: state.isOrderFilterApplied,
    commisionFilter: state.commisionFilter,
  };
};

ListComponent.propTypes = {
  isUserFilterApplied: PropTypes.bool,
  isOrderFilterApplied: PropTypes.bool,
  userFilters: PropTypes.object,
  orderFilters: PropTypes.object,
  groupDetails: PropTypes.any,
  userDetails: PropTypes.object,
  EditFunction: PropTypes.func,
  PrintFunction: PropTypes.func,
  Audittrial: PropTypes.bool,
  recordType: PropTypes.string,
  heading: PropTypes.string,
  list: PropTypes.array,
  operation: PropTypes.string,
  associatedPharmacies: PropTypes.string,
  onlyTable: PropTypes.bool,
  head: PropTypes.string,
  NodataDisplay: PropTypes.func,
  Overheading: PropTypes.func,
  subheading: PropTypes.string,
  subcontainer: PropTypes.func,
  Righthead: PropTypes.func,
  filterbar: PropTypes.func,
  subtext: PropTypes.func,
  hideFilter: PropTypes.bool,
  hideSearch: PropTypes.bool,
  searchPlaceHolder: PropTypes.string,
  reducedWidth: PropTypes.bool,
  TableLeftContainer: PropTypes.func,
  InnertableHead: PropTypes.string,
  marginIssue: PropTypes.bool,
  filterParams: PropTypes.object,
  dispatch: PropTypes.func,
  commisionFilter: PropTypes.object,
  isRefetch: PropTypes.bool,
  setRefecth: PropTypes.func,
};

export default connect(mapStateToProps)(ListComponent);